<template>
    <div class="content">
        <div class="notData flex justify-center align-center">
            <div class="center text-center">
                <p>温馨提示</p>
                <img src="@/assets/images/error/notData.svg" alt="">
                <p>{{ title == '' || title == undefined || title == 'undefined' ? '血糖数据不足(少于三天)，无法正常展示报告' : title }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                title: '',
            }
        },
        mounted(){
            this.title = this.$route.query.title;
        }
    }
</script>

<style lang="less" scoped>
    .content{
        width: 100%;
        height: 100vh;
        .notData{
            width: 100%;
            height: 100%;
            background-color: white;
            img{
                width: 350px;
            }
            p{
                font-size: 28px;
            }
        }
    }
    
    @media (max-width: 1000px ) {

        .notData{
            img{
                width: 550px !important;
            }
            p{
                font-size: 45px !important;
            }
        }

    }

</style>
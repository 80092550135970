<template>
    <div class="continuous">
        <div class="print-cont pion-rel" :style="{'margin-top' : !download ? '10px' : '0px' }" v-for="index in Math.ceil((Object.keys(daysData).length / 4 )) " :key="index">
            <div class="tiptitle">
                <div class="title">
                    <h2>{{$t('menu.list.Print.page5.title')}}</h2>
                    <p>{{ timeGet(bindTime,endTime) }}（{{days}}{{lang == 'en' ?  'days': '天' }}）
                        <span>{{$t('menu.list.Print.page5.times')}}：{{ coverage }}%</span>
                        <span>{{$t('menu.list.Print.page5.number')}}：{{ deviceBloodSugarTotal}} {{$t('menu.list.Print.page5.titUnit')}}</span>
                    </p>
                </div>
                <img class="logo" src="@/assets/images/print/pring-logo.png" alt="">
            </div>

            <div class="continuous-list solid-top mt-20" >
                <div v-for="(item,idx) in daysData" :key="idx">
                    <div class="list solid pl-20 pr-20 flex" v-if=" idx < ( index * 4 ) && idx >= ( ( index - 1 ) * 4 ) ">
                        <div class="info-left pt-20 pb-20 pr-20 solid-right">
                            <div class="tip-date flex pl-15 pr-15 font-14" :class="[ item.coverage <= 60 || !item.coverage ? 'error' : item.coverage > 60 && item.coverage <= 80 ? 'wraing' : '' ]">
                                <p>
                                    {{new Date(item.date).getFullYear()}}{{$t('menu.list.Print.page5.year')}}<span :class="{'ml-5': lang != 'en'}">{{new Date(item.date).getMonth() + 1}}{{$t('menu.list.Print.page5.mon')}}{{new Date(item.date).getDate()}}{{$t('menu.list.Print.page5.day')}}</span>
                                </p>
                                <p>{{$t('menu.list.Print.page5.times')}}：{{item.coverage ? item.coverage + '%' : '--' }}</p>
                            </div>
                            <div class="info-chat flex pt-20 pl-20">
                                <div class="chat-left">
                                    <p class="font-12 font-weight mt-10">{{$t('menu.list.Print.page4.footTitle')}}</p>
                                    <h4>
                                        {{item.avg ? ( isDefaultUnit == 1 ? Number(changUnit(isDefaultUnit,item.avg).val).toFixed(0) : Number(changUnit(isDefaultUnit,item.avg).val).toFixed(1) ): '--' }}
                                        <span class="font-normal font-20">{{ changUnit(isDefaultUnit).unit }}</span>
                                    </h4>
                                </div>
                                <div class="chat-show flex" v-if="item.tir">
                                    <div class="chat">
                                        <div class="vh" :style="{ 'height': item.tir.very_high * 100  + '%' }"></div>
                                        <div class="h mt-5 radius-2" :style="{ 'height': item.tir.high * 100 + '%' }"></div>
                                        <div class="nomal mt-5 radius-2" :style="{ 'height': item.tir.common * 100 + '%' }"></div>
                                        <div class="l mt-5 radius-2" :style="{ 'height': item.tir.low * 100 + '%' }"></div>
                                        <div class="vl mt-5 radius-2" :style="{ 'height': item.tir.very_low * 100 + '%' }"></div>
                                    </div>
                                    <div class="chat-info ml-15" :class="[lang == 'en' ? 'mt-10' : 'mt-5' ]" v-if="item.tir">
                                        <p class="font-12 font-weight">{{item.tir.very_high * 100 == 0 ? 0 : parseInt(item.tir.very_high * 100).toFixed(0) }}% {{$t('menu.list.Print.page2.tir.vhigh')}}</p>
                                        <p class="font-14 mt-5 font-weight">{{item.tir.high * 100 == 0 ? 0 : parseInt(item.tir.high * 100).toFixed(0)  }}% {{$t('menu.list.Print.page2.tir.high')}}</p>
                                        <h4 class="font-15 mt-5 font-weight">{{item.tir.common * 100 == 0 ? 0 : parseInt(item.tir.common * 100 ).toFixed(0) }}% {{$t('menu.list.Print.page2.tir.normal')}}</h4>
                                        <p class="font-14 mt-5 font-weight">{{item.tir.low * 100 == 0 ? 0 : parseInt(item.tir.low * 100 ).toFixed(0) }}% {{$t('menu.list.Print.page2.tir.low')}}</p>
                                        <p class="font-12 mt-5 font-weight">{{item.tir.very_low * 100 == 0 ? 0 : parseInt(item.tir.very_low * 100 ).toFixed(0) }}% {{$t('menu.list.Print.page2.tir.vlow')}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="info-cont flex mt-20">
                                <div class="row text-center solid-left">
                                    <p class="font-16 font-weight">LAGE</p>
                                    <span class="font-14">{{ changUnit(isDefaultUnit).unit }}</span>
                                    <h5>{{item.lage ? ( isDefaultUnit == 1 ? Number(changUnit(isDefaultUnit,item.lage).val).toFixed(0) : Number(changUnit(isDefaultUnit,item.lage).val).toFixed(1) ) : '--' }}</h5>
                                </div>
                                <div class="row text-center solid-left">
                                    <p class="font-16 font-weight">SD</p>
                                    <span class="font-14">{{ changUnit(isDefaultUnit).unit }}</span>
                                    <h5>{{item.sd ? ( isDefaultUnit == 1 ? Number(changUnit(isDefaultUnit,item.sd).val).toFixed(0) : Number(changUnit(isDefaultUnit,item.sd).val).toFixed(1) ) : '--' }}</h5>
                                </div>
                                <div class="row text-center solid-left">
                                    <p class="font-16 font-weight">CV</p>
                                    <span class="font-14">%</span>
                                    <h5>{{item.cv ? item.cv : '--' }}</h5>
                                </div>
                            </div>
                        </div>
                        <div class="infos pt-20 pb-20 pl-20">
                            <div class="infos-top pb-20 solid-bottom flex">
                                <div class="insulin justify-around flex align-center solid-right">
                                    <div class="injection text-center">
                                        <h4 class="font-17">{{$t('menu.list.Print.page2.insulin')}}</h4>
                                        <p class="font-18 font-weight mt-5">{{ item.insulin && item.insulin != '0.0' ? item.insulin : '--' }}<span class="font-14 font-normal" v-if="item.insulin != 0">U</span></p>
                                    </div>
                                    <div class="pillar notData ml-15 clear radius-5" v-if="( Number(item.long_insulin) == 0 || !item.long_insulin) && (Number(item.food_insulin) == 0 || !item.food_insulin)">
                                        <div class="quickActing" :style="{ 'height': Number(item.long_insulin) == 0 || !item.long_insulin ? '--' : (Number(item.long_insulin) / ( Number(item.long_insulin) + Number(item.food_insulin) ) * 100).toFixed(0) + (Number(item.long_insulin) == 0 || !item.long_insulin ? '' : '%') }"></div>
                                        
                                        <div class="longTerm" :style="{ 'height': Number(item.food_insulin) == 0 || !item.food_insulin ? '--' : (Number(item.food_insulin) / ( Number(item.long_insulin) + Number(item.food_insulin) ) * 100).toFixed(0) + (Number(item.food_insulin) == 0 || !item.food_insulin ? '' : '%') }"></div>
                                    </div>
                                    <div class="pillar" v-else>
                                        <div class="quickActing" :style="{ 'height': Number(item.long_insulin) == 0 || !item.long_insulin ? '0px' : (Number(item.long_insulin) / ( Number(item.long_insulin) + Number(item.food_insulin) ) * 100).toFixed(0) + (Number(item.long_insulin) == 0 || !item.long_insulin ? '' : '%') }"></div>
                                        
                                        <div class="longTerm" :style="{ 'height': Number(item.food_insulin) == 0 || !item.food_insulin ? '--' : (Number(item.food_insulin) / ( Number(item.long_insulin) + Number(item.food_insulin) ) * 100).toFixed(0) + (Number(item.food_insulin) == 0 || !item.food_insulin ? '' : '%') }"></div>
                                    </div>
                                    <div class="text ml-10">
                                        <p>
                                            {{ Number(item.long_insulin) == 0 || !item.long_insulin ? '--' : (Number(item.long_insulin) / ( Number(item.long_insulin) + Number(item.food_insulin) ) * 100).toFixed(0) }}{{ Number(item.long_insulin) == 0 || !item.long_insulin ? '' : '%' }}
                                        </p>
                                        <p style="margin-top: 30px;">{{ Number(item.food_insulin) == 0 || !item.food_insulin ? '--' : (Number(item.food_insulin) / ( Number(item.long_insulin) + Number(item.food_insulin) ) * 100).toFixed(0) }}{{ Number(item.food_insulin) == 0 || !item.food_insulin ? '' : '%' }}</p>
                                    </div>
                                    <div class="quickActing ml-10">
                                        <h4 class="flex align-center mt-5"><span class="block radius-50 mr-5"></span>{{$t('menu.list.Print.page5.long')}}</h4>
                                        <p class="text-center mt-5">{{ Number(item.long_insulin) != 0 && item.long_insulin ? item.long_insulin : '--' }}<span v-if="Number(item.long_insulin) != 0 ">U</span></p>
                                    </div>
                                    <div class="longTerm ml-10">
                                        <h4 class="flex align-center mt-5"><span class="block radius-50 mr-5"></span>{{$t('menu.list.Print.page5.meal')}}</h4>
                                        <p class="text-center mt-5">{{ Number(item.food_insulin) != 0 && item.food_insulin ? item.food_insulin : '--' }}<span v-if="Number(item.food_insulin) != 0 ">U</span></p>
                                    </div>
                                </div>
                                <div class="sports flex align-center">
                                    <div class="water ml-20">
                                        <h4 class="font-14 flex align-center mt-5"><span class="block radius-50 mr-5"></span>{{$t('menu.list.Print.page5.carbohydrate')}}</h4>
                                        <p class="font-14 ml-20 mt-5">{{ item.carb && item.carb != '0.0' ? item.carb : '--' }}<span class="font-12" v-if="item.carb != 0">{{$t('menu.list.Print.page5.unit')}}</span></p>
                                    </div>
                                    <div class="move ml-20">
                                        <h4 class="font-14 flex align-center mt-5"><span class="block radius-50 mr-5"></span>{{$t('menu.list.Print.page5.type')}}</h4>
                                        <p class="font-14 ml-20 mt-5">{{ item.calorie && item.calorie != '0.0' ? item.calorie : '--' }}<span class="font-12" v-if="item.calorie != 0">{{$t('menu.list.Print.page5.units')}}</span></p>
                                    </div>
                                </div>
                            </div>
                            <div class="line-chat">
                                <template v-if="item.line && userInfo != '' ">
                                    <ChartsLine 
                                        :value="item.line"
                                        :target_low="userInfo.target_low" 
                                        :target_high="userInfo.target_high"
                                        :isPDF="true"
                                        :longPass="false"
                                        :lineMax="lineMax"
                                        :isDefaultUnit="isDefaultUnit"
                                    />
                                </template>
                                <template v-else>
                                    <div class="notData flex justify-center align-center">
                                        <div class="center">
                                            <img src="@/assets/images/error/empty.png" alt="">
                                            <p class="font-14 text-center">{{$t('menu.list.Print.page4.notData')}}</p>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-bottom pion-abs">
                <p>{{$t('menu.list.Print.page5.date')}}：{{toDay}}</p>
                <p>{{$t('menu.list.Print.page2.form')}}：{{ lang == 'en' ? '' : '糖动' }}CGMCARE</p>
                <p>{{$t('menu.list.Print.page5.page')}}：{{ index + 3  }}/{{ 4 + Math.ceil(Object.keys(daysData).length / 4) + ( (days &lt; 16) && days > 8 ? 1 : 0 ) }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        ChartsLine
    } from "_c/print";
    import { time, changUnit } from '_js/Cache';
    export default {
        name: 'Continuous',
        components: {
            ChartsLine
        },
        props: {
            download: Boolean,
            bindTime: Number,
            endTime: Number,
            days: Number,
            coverage: Number,
            deviceBloodSugarTotal: Number,
            daysData: Array || Object,
            userInfo: Object,
            toDay: String,
            foodRecordRankLen: Number,
            lineMax: Number,
            isDefaultUnit: Number,
            lang: String
        },
        methods:{
            timeGet(start,end){
                return end ? time(new Date(start * 1000 ),this.lang == 'en' ? 'YYYY/MM/DD' : '年月日' ) + '—' + time(new Date(end * 1000 ),this.lang == 'en' ? 'MM/DD' : '月日') : time(new Date(start),'MM/DD');
            },
            changUnit(t,v){
                return changUnit(t,v);
            }
        }
    }
</script>
<style lang="less" scoped>
    
    .continuous-list{
        border-color: #e5e5e5;

        .list{
            border-color: #e5e5e5;
            box-sizing: border-box;
            margin: 30px 0px;
            .info-left{
                width: 40%;
                border-color: #e5e5e5;
                .tip-date{
                    background: #13BE9B;
                    color: white;
                    display: flex;
                    height: 38px;
                    line-height: 38px;
                    p:nth-of-type(2){
                        margin-left: auto;
                    }
                }

                .tip-date.wraing{
                    background: #F7B500;
                }
                .tip-date.error{
                    background: #E03520;
                }
                .info-chat{
                    .chat-left{
                        color: #000;
                        h4{
                            font-size: 62px;
                            span{
                                color: #d8d8d8;
                            }
                        }
                    }
                    .chat-show{
                        width: 45%;
                        margin-left: auto;
                        margin-top: -10px;
                        height: 125px;
                        overflow: hidden;
                        .chat{
                            width: 45px;
                            height: 100px;
                            &>div{
                                flex: 1;
                                min-height: 1px;
                            }
                            .vh{
                                background: #F5B940;
                                height: 5px;
                            }
                            .h{
                                background: #FBE46C;
                                height: 10px;
                            }
                            .nomal{
                                height: calc(~' 100% - 60px');
                                background: #57BB9D;
                            }
                            .l{
                                background: #EE7373;
                                height: 5px;
                            }

                            .vl{
                                background: #EE7373;
                                height: 10px;
                            }
                        }
                    }
                }
                .info-cont{
                    &>div{
                        flex: 1;
                        border-color: #e5e5e5;
                        p{
                            color: #000;
                        }
                        span{
                            color: #b8b8b8;
                        }
                        h5{
                            font-size: 32px;
                            color: #000;
                        }
                    }
                    &>div:nth-of-type(1){
                        border-left: none;
                    }
                }
            }
            .infos{
                width: 60%;
                .infos-top{
                    border-color: #e5e5e5;
                    .insulin{
                        width: 65%;
                        border-color: #e5e5e5;
                        .injection{
                            h4{
                                color: #000;
                            }
                            p{
                                color: #000;
                                span{
                                    color: #b8b8b8;
                                }
                            }
                        }
                        .pillar{
                            width: 34px;
                            height: 75px;
                            &.notData{
                                .quickActing,
                                .longTerm{
                                    height:50%;
                                }
                            }
                            .quickActing{
                                width: 100%;
                                background: #6D96FF;
                            }
                            .longTerm{
                                width: 100%;
                                background: #2055A6;
                            }
                        }
                        &>.quickActing,
                        &>.longTerm{
                            h4{
                                span{
                                    width: 14px;
                                    height: 14px;
                                    background: #6D96FF;
                                }
                            }
                        }
                        .longTerm{
                            h4{
                                span{
                                    background: #2055A6;
                                }
                            }
                        }
                    }
                    .sports{
                        width: 35%;
                        &>.water,
                        &>.move{
                            h4{
                                span{
                                    width: 14px;
                                    height: 14px;
                                    background: #F7B500;
                                }
                            }
                            p{
                                span{
                                    color: #b8b8b8;
                                }
                            }
                        }
                        .move{
                            h4{
                                span{
                                    background: #B132FF;
                                }
                            }
                        }
                    }
                }
                .line-chat{
                    width: 100%;
                    height: 180px;
                    & /deep/ .ChartsLine{
                        width: 100%;
                        height: 180px;
                    }
                    img{
                        width: 75px;
                        height: 75px;
                        margin-top: 45px;
                    }
                }
            }
        }

    }
</style>
<template>
    <div class="echarts">
        <div ref="dom" style="position: relative;width:1155px;height: 333px" v-if="imgData == ''"></div>
        <img :src="imgData" alt="" v-else>
    </div>
</template>

<script>

import echarts from "echarts";
import { on, off } from "_js/Cache";
export default {
    name: "NightCharts",
    props: {
        value: {
            type: Array,
            default: () => {
                return [];
            },
            required: false
        },
        isDownload:Boolean,
        target_high:{
            default:null
        },
        target_low:{
            default:null
        },
        specialArr: Array,
        showYLine: Boolean,
        analyze: Boolean
    },
    data() {
        return {
            dom: null,
            thisTime: 0,
            maxNum: 0,
            Yaxis:[],
            imgData: '',
        };
    },
    methods: {
        resize() {
        this.dom.resize();
        },
        
        init() {
            let option = {
                animation: false,
                tooltip:{
                    trigger: 'axis',
                    axisPointer: {
                        type: 'line'
                    }
                },
                color:['#FFE5E5','#EFFFF1'],
                xAxis:{
                    type: 'category',
                    data: ['23:00','00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00'],
                    axisTick:{
                        show: false
                    }
                },
                grid: {
                    top: '15',
                    left: '0',
                    bottom: '5%',
                    width:'100%',
                    height:'90%',
                    containLabel: true
                },
                
                yAxis: {
                    min: 0,
                    position:'right',
                    offset: -20,
                    axisTick: {
                        show: false
                    },
                    axisLine:{
                        show: false,
                    },
                    max(value){
                        const val = ( parseInt(value.max) + (4 - parseInt(value.max) % 4 ) ) > 16 ? parseInt(value.max) + (4 - parseInt(value.max) % 4 ) : 16;
                        return val;
                    },
                    interval:4,
                    type: 'value',
                    axisLabel: {
                        formatter: '{value}',
                    },
                },
                series:[
                    {
                        type: 'line',
                        markArea:{
                            data: [
                                [
                                    {
                                        yAxis: this.analyze ? '0' : '0' 
                                    },
                                    {
                                        yAxis: this.analyze ? '0' : '3'
                                    }
                                ]
                            ]
                        }
                    },
                    {
                        type: 'line',
                        markArea:{
                            data: [
                                [
                                    {
                                        yAxis: this.analyze ? '0' : '3.8'
                                    },
                                    {
                                        yAxis: this.analyze ? '0' : '8'
                                    }
                                ]
                            ]
                        }
                    },
                    {
                        type:'line',
                        name:'6.2',
                        smooth: true,
                        showSymbol: false,
                        itemStyle:{
                            normal:{
                                lineStyle: {
                                    color: '#EFFFF1'
                                }
                            }
                        },
                        data: [4.5,5.7,8.2,3.3,3.2,3.3,3.3,3.3,2.1]
                    },
                    {
                        type:'line',
                        name:'6.1',
                        smooth: true,
                        showSymbol: false,
                        itemStyle:{
                            normal:{
                                lineStyle: {
                                    color: '#3A24BE'
                                }
                            }
                        },
                        data: [2.1,1.2,3.5,5.6,3.3,4.1,5.2,3.3,5.1]
                    },
                    {
                        type:'line',
                        name:'6.1',
                        smooth: true,
                        showSymbol: false,
                        itemStyle:{
                            normal:{
                                lineStyle: {
                                    color: '#13BE9B'
                                }
                            }
                        },
                        data: [5.5,3.3,5.1,2.1,3.2,1.5,6.0,4.3,3.5]
                    },
                    {
                        type:'line',
                        name:'6.1',
                        smooth: true,
                        showSymbol: false,
                        itemStyle:{
                            normal:{
                                lineStyle: {
                                    color: '#F7B500'
                                }
                            }
                        },
                        data: [4.5,5.7,8.2,3.3,3.2,3.3,3.3,3.3,2.1]
                    },
                ]
            }
            this.dom = echarts.init(this.$refs.dom);
            this.dom.setOption(option);
            setTimeout( () =>{
                this.imgData = this.dom.getDataURL({
                    pixelRatio: 2
                });
                this.dom.dispose();
            },200)
            on(window, "resize", this.resize);
        },
    },
    mounted() {
        this.init()
    },
    beforeDestroy() {
        off(window, "resize", this.resize);
    }
}
</script>

<style lang="less" scoped>
    .echarts{
        width: 100%;
        height: 100%;
        div{
            width: 100%;
            height: 100%;
        }
        img{
            width: 100%;
            height: 100%;
        }
    }
</style>
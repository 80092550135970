<template>
    <div class="EditPopup" :class="[!Dark ? '' : 'dark']" v-if="isShow">
        <el-dialog :title="type == 1 ? '编辑姓名' : '编辑备注' " width="1240" :visible.sync="isShow" :modal-append-to-body="false" :before-close="close">
            <el-form label-position="top" ref="form" label-width="140px">
                 <el-form-item prop="nick" :label="type == 1 ? '姓名' : '备注' ">
                    <el-input v-model="data" type="textarea" :autosize="{minRows: 3,maxRows: 5}" :placeholder="type == 1 ? '请输入姓名' : '请输入备注'"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="close">取消</el-button>
                <el-button @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { editUserName, editUserNote } from 'quest'
    export default {
        props:{
            editData: Object,
            Dark: Boolean
        },
        watch:{
            editData:{
                handler(val){
                    this.isShow = val.show,
                    this.data = val.data,
                    this.type = val.type;
                },
                deep: true,
            }
        },
        data(){
            return{
                isShow: false,
                data:'',
                type: 0,
            }
        },
        methods:{
            submit(){
                let data = {
                    uid: this.$route.query.id,
                    [this.type == 1 && 'agency_nickname' || 'doctor_note' ]: this.data,
                }
                if(this.type == 1){
                    editUserName(data).then( res =>{
                        if(res.data.code == 100000){
                            this.$message.success(res.data.msg);
                            this.$emit('isEditUserName',data);
                        }else{
                            this.$message.error(res.data.msg);
                        }
                    })
                }else{
                    editUserNote(data).then( res =>{
                        if(res.data.code == 100000){
                            this.$message.success(res.data.msg);
                            this.$emit('isEditNote',data);
                        }else{
                            this.$message.error(res.data.msg);
                        }
                    })
                }
                this.isShow = false;
                this.$emit("close");
            },
            close(){
                this.isShow = false;
                this.$emit("close");
            },
        },
    }
</script>

<style scoped lang="less">
    .EditPopup /deep/ .el-dialog{
        width: 35%;
        margin-top: 22% !important;
        overflow: hidden;
        border-radius: 10px;

        .el-dialog__header{
            border-bottom: 1px solid #dcdfe6;
            padding: 14px 16px;
            span{
                font-size: 14px;
                color: #17233d;
            }
            button{
                margin-top: 4px;
            }
        }

        .el-select{
            width: 100%;
        }

        .el-form-item__label{
            font-size: 12px;
            padding-bottom: 0px;
            line-height: unset;
        }

        input{
            font-size: 12px;
            height: 32px;
            line-height: 1;

            &::placeholder{
                font-size: 12px;
            }
        }

        .addIcon{
            font-size: 32px;
            color: #16CCA6;
            cursor: pointer;
            margin-top: 10px;
        }

        .el-dialog__footer{
            border-top: 1px solid #dcdfe6;
            padding-top: 15px;

            button{
                padding: 5px 15px 6px;
                font-size: 12px;
                border-radius: 4px;
                line-height: 1.6;
                font-weight: normal;
                transition: .3s;

                span{
                    display: block;
                    padding-top: 2px;
                }
                &:nth-of-type(2){
                    transition: .3s;
                    background: var(--defalutColor);
                    border-color: var(--defalutColor);
                    color: white;

                    &:hover{
                        background-color: var(--defalutActiveColor);
                        border-color: var(--defalutActiveColor);
                        transition: .3s;
                    }
                }
            }
        }
    }

    .EditPopup.dark /deep/ .el-dialog__wrapper{
        .el-dialog,
        .el-dialog__header,
        .el-dialog__body,
        .el-dialog__footer{
            background: #202025 !important;
            border-color: #2c2c2e !important;
            .el-dialog__title,
            .el-dialog__close{
                color: #c1c1c1;
            }
            .el-form-item__label{
                color: #c1c1c1;
            }
            .el-textarea{
                margin-top: 12px;
                textarea{
                    background-color: unset;
                    border-color: #2c2c2e;
                    color: #c1c1c1;
                    &::placeholder{
                        color: #c1c1c1;
                        font-size: 15px;
                    }
                }
            }
            .el-button{
                background-color: unset;
                color: #c1c1c1;
                border-color: #2c2c2e;
            }
            .dialog-footer{
                button:nth-of-type(2){
                    background: var(--defalutActiveColor) !important;
                    border-color: var(--defalutActiveColor) !important;
                }
            }
        }
    }

</style>
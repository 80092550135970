<template>
    <div class="cycle pages pl-18 pr-18 mt-20" :class="[!Dark ? '' : 'dark', collapsed ? 'hide' : '' ]">
        <div class="scroll pt-20 pb-20 pl-20 pr-20 radius-10" v-loading="loading">
            <TabsTwo :statistics="statistics" :eight="eight" :Dark="Dark" @getDataExcel="getDataExcel" />
        </div>
    </div>
</template>

<script>
    import { TabsTwo } from '_c/Patients/index';
    import { getDeviceStatByDeviceId, bloodsugarExport } from 'quest'
    export default {
        props:{
            Dark: Boolean,
            collapsed: Boolean
        },
        components:{
            TabsTwo,
        },
        data(){
            return{
                loading: true,
                id: this.device_id || this.$route.query.id,
                statistics: null,
                eight: null,
            }
        },
        methods:{
            getDeviceStatByDeviceId() {
                getDeviceStatByDeviceId({
                    id: this.id
                }).then(res => {
                    if (res.data.code == 100000) {
                        let {
                            device_info,
                            sports,
                            food,
                            eight,
                            day_line,
                            day_tir,
                            envelope,
                            tir,
                            user_info
                        } = res.data.data;
                        this.statistics = {
                            allData: res.data.data,
                            device_info: device_info,
                            sports: sports,
                            food: food,
                            day_line: day_line,
                            envelope: envelope,
                            tir: tir,
                            user_info: user_info,
                        }
                        let day_tir_val = Object.values(day_tir);
                        let day_tir_key = Object.keys(day_tir);
                        day_tir_val.forEach((item,index)=>{
                            item.date = day_tir_key[index].slice(5)
                        })
                        this.eight = eight;
                        this.statistics.day_tir = day_tir_val;
                        this.loading = false;
                    }
                }).catch( ()=>{
                    this.$message.error('接口异常')
                    this.loading = false;
                });
            },
            getDataExcel(){
                const md5 = require('js-md5');
                let data = {
                    device_id: this.id,
                    export_sign: md5(this.id + 'yja2019_fcz'),
                    token: this.statistics.user_info.token || '',
                }
                bloodsugarExport(data).then( res =>{
                    let downloading = this.$message({
                        message: '下载中...',
                        type: 'info',
                        iconClass: 'el-icon-loading',
                    });
                    if(!res.data.code){
                        downloading.close();
                        let http = process.env.VUE_APP_ENV === 'test' ? 'https://test.cgmcare.cn/api' : 'https://td.cgmcare.cn/api',
                            fileUrl = res.config.url;
                        window.open(http + fileUrl, '_blank');
                        this.$message.success('下载成功！');
                    }else{
                        downloading.close();
                        this.$message.error(res.data.msg);
                    }
                })
            },
        },
        created(){
            if( (this.device_id || this.$route.query.id) ){
                this.getDeviceStatByDeviceId();
            }
        }
    }
</script>

<style scoped lang="less">
    .cycle{
        height: ~'calc(100% - 70px)';
        .scroll{
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            background: var(--white);
        }
        &.dark{
            .scroll{
                background: #202025 !important;
            }
        }
    }

</style>
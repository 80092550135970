<template>
    <div class="patientDetail" :class="[!Dark ? '' : 'dark']">
        <el-dialog title="健康档案" :visible.sync="visible" :top="isIpad ? '8%' : '5%' " :modal-append-to-body="false" :before-close="close">
            <div class="healthDocument">
                <div class="healthInfo">
                    <div class="title">主要健康数据：</div>
                    <div class="iconList">
                        <div class="item" v-for="(item,index) in main" :key="index">
                            <img class="img" :src="getLocalImage(( (index + 1) < 10 ? '0' + (index + 1) : (index + 1) ))" alt="">
                            <div class="key">{{item.title }}</div>
                            <div class="val">{{item.value || '-- '}}{{item.unit}}</div>
                        </div>
                    </div>
                    <div class="title">病史资料</div>
                    <div class="infoList">
                        <div class="infoItem" v-for="(item,index) in history" :key="index">
                            <div class="key">{{item.title}}：</div>
                            <div class="valList" v-if="item.value && item.value.length">
                                <div class="item active" v-for="(listItem,index) in item.value" :key="index">{{listItem}}</div>
                            </div>
                            <div class="valList" v-else>
                                <div class="item">暂无数据</div>
                            </div>
                        </div>
                    </div>
                    <div class="title">生活习惯</div>
                    <div class="infoList">
                        <div class="infoItem" v-for="(item,index) in habit" :key="index">
                            <div class="key">{{item.title}}：</div>
                            <div class="valList" v-if="item.value && item.value.length">
                                <div class="item active" v-for="(listItem,index) in item.value" :key="index">{{listItem}}</div>
                            </div>
                            <div class="valList" v-else>
                                <div class="item">暂无数据</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="line"></div>
                <div class="imgList">
                    <div class="title">
                        <span>体检报告</span>
                        <!-- <span>上传日期：2021/3/4</span> -->
                    </div>
                    <div class="swiperBox" v-if="imgList && imgList.length > 0">
                        <el-carousel>
                            <el-carousel-item v-for="item in imgList" :key="item">
                                <img :src="item" alt="">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="empty" v-else>
                        <img src="@/assets/images/error/empty.png" alt="">
                        <p>暂无数据</p>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="edit">修改档案</el-button>
                <el-button @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {getUserHealthData} from 'quest'
    export default {
        props: {
            activityData: { type: Object },
            show: Boolean,
            title: String,
            Dark: Boolean
        },
        data () {
            return {
                visible: this.show,
                main: [],
                history:[],
                habit:[],
                getLocalImage(name){
                    return require('@/assets/images/icons/icon' + name + '.png');
                },
                imgList: [],
                isIpad: false
            }
        },
        watch: {
            show (newv) {
                this.visible = newv
                if(newv){
                    this.getUserHealthData()
                }
            }
        },
        methods:{
            submit () {
                this.visible = false
                this.$emit('close', this.visible)
            },
            close () {
                this.visible = false
                this.$emit('close', this.visible)
            },

            edit () {
                this.$emit('edit')
            },
            slideChange () {
                this.activeIndex = this.$refs.mySwiper.swiper.activeIndex;
            },
            getUserHealthData(){
                getUserHealthData({
                    uid: this.$route.query.id
                }).then(res=>{
                    if(res.data.data){
                        let allData = Object.values(res.data.data);
                        this.main = Object.values(allData[0]);
                        this.history = Object.values(allData[1]);
                        this.habit = Object.values(allData[2]);
                        this.imgList = Object.values(allData[3]);
                    }
                })
            }
        },
        mounted(){
            this.isIpad = window.innerWidth >= 1024 && window.innerWidth <= 1366;
        }
    }
</script>

<style scoped lang="less">

    .patientDetail /deep/ .el-dialog__wrapper{
        overflow: hidden;
        
        .el-dialog{
            width: 80%;
            height: 80%;
            border-radius: 6px;
            margin: 0 !important;
            position: fixed;
            top: 10%;
            left: 10%;

            .el-dialog__header{
                border-bottom: 1px solid #e8eaec;
            }

            .el-dialog__body{
                height: ~'calc(100% - 120px)';
            }

            .el-dialog__footer{
                border-top: 1px solid #dcdfe6;
                padding-top: 15px;

                button{
                    padding: 5px 15px 6px;
                    font-size: 12px;
                    border-radius: 4px;
                    line-height: 1.6;
                    font-weight: normal;
                    transition: .3s;

                    span{
                        display: block;
                        padding-top: 2px;
                    }
                    &:nth-of-type(2){
                        transition: .3s;
                        background: var(--defalutColor);
                        border-color: var(--defalutColor);
                        color: white;

                        &:hover{
                            background-color: var(--defalutActiveColor);
                            border-color: var(--defalutActiveColor);
                            transition: .3s;
                        }
                    }
                }
            }
        }
    }

    .healthDocument{
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;

        .line {
            width: 1px;
            height: 100%;
            background: #f4f4f4;
        }

        .healthInfo,.imgList {
            width: 620px;
            box-sizing: border-box;
            height: 100%;
            overflow-y: scroll;
            
            .swiperBox /deep/ .el-carousel__container{
                width: 550px;
                height: 780px;
                img{
                    width: 80%;
                    display: block;
                    margin: 0 auto;
                }
            }
        }

        .title{
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #9F9F9F;
            box-sizing: border-box;
            padding: 23px 30px 40px;
        }

        .iconList{
            padding: 0 30px;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;

            .item{
                width: 85px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin:0 40px 40px 0;

                .img{
                    width: 35px;
                    height: 35px;
                    margin-bottom: 4px;
                }

                .key{
                    width: 100%;
                    text-align: center;
                    height: 18px;
                    font-size: 12px;
                    color: #9F9F9F;
                    line-height: 18px;
                    margin-bottom: 6px;
                }

                .val{
                    height: 14px;
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #242424;
                    line-height: 14px;
                }
            }
        }
        .infoList {
            padding-right: 60px;
            box-sizing: border-box;

            .infoItem{
                display: flex;
                margin-bottom: 40px;

                .key{
                    width: 160px;
                    flex-shrink: 0;
                    margin-right: 10px;
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #242424;
                    height: 22px;
                    line-height: 22px;
                    text-align: right;
                }

                .val{
                    color: #9F9F9F;
                    font-size: 15px;
                    height: 22px;
                    line-height: 22px;
                }

                .valList{
                    display: flex;
                    flex-wrap: wrap;

                    .item{
                        padding: 0 9px;
                        display: flex;
                        align-items: center;
                        height: 22px;
                        background: #F8F8F8;
                        color: #242424;
                        font-size: 12px;
                        border-radius: 2px;
                        margin: 0 16px 20px 0;
                    }

                    .item.active{
                        background: #13BE9B;
                        color: #fff;
                    }
                }
            }
        }

        .empty{
            width: 100%;
            height: 200px;
            text-align: center;
            flex-wrap: wrap;

            img{
                width: 90px;
            }

            p{
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #B8B8B8;
                width: 100% !important;
                margin-top: -70px;
            }
        }
    }

    .patientDetail.dark /deep/ .el-dialog__wrapper{
        .el-dialog,
        .el-dialog__header,
        .el-dialog__body,
        .el-dialog__footer{
            .el-dialog__title,
            .el-dialog__close{
                color: #c1c1c1;
            }
            .title{
                color: #c1c1c1 !important;
            }
            .iconList{
                .key{
                    color: #c1c1c1;
                }
                .val{
                    color: #c1c1c1;
                }
            }
            .line{
                background: #2c2c2e;
            }
            .infoList{
                .key{
                    color: #c1c1c1 !important;
                }
                .valList{
                    .item{
                        background: unset !important;
                        border: 1px solid #2c2c2e !important;
                        color: #c1c1c1 !important;
                    }
                }
            }
            .el-carousel__button{
                background: unset;
            }
            background: #202025 !important;
            border-color: #2c2c2e !important;
            .el-button{
                background-color: unset;
                color: white;
                border-color: #2c2c2e;
            }
            .dialog-footer{
                button:nth-of-type(2){
                    background: var(--defalutActiveColor) !important;
                    border-color: var(--defalutActiveColor) !important;
                }
            }
        }

    }

</style>
<template>
    <div class="title flex align-center pb-10 solid-bottom" :class="[!Dark ? '' : 'dark']">
        <h2 class="font-18 font-normal">{{ tip.title }}</h2>
        <i class="el-icon-circle-plus-outline mr-5" @click="add(index)" :style="{'margin-left': tip.isAdd == true && tip.roleId >= userRoleID ? 'auto' : ''} " v-if="tip.isAdd == true && tip.roleId >= userRoleID"></i>
        <i class="el-icon-arrow-right radius-50 font-weight" @click="go(tip.go)" :style="{'margin-left': tip.isAdd != true && tip.roleId >= userRoleID ? 'auto' : ''}" v-if="tip.go != '' && tip.roleId >= userRoleID"></i>
    </div>
</template>

<script>

    import { getItem } from '_js/Cache';
    export default {

        props:{
            tip: Object,
            index: Number,
            Dark: Boolean
        },

        data(){
            return{
                userRoleID: getItem('userInfo').role_id,
            }
        },
        methods:{
            add(index){
                this.$emit('add',index);
            },
            go(page,){
                this.$router.push({
                    path: page
                })
            }
        }

    }

</script>

<style lang="less" scoped>
    .title{
        border-color: #f6f6f6;
        transition: .3s;
        i{
            font-size: 24px;
            color: #979797;
            cursor: pointer;
        }
        i:nth-last-of-type(1){
            font-size: 14px;
            padding: 2.5px;
            border: 1.5px solid #979797;
        }
    }

    .title.dark{
        border-color: #2c2c2e !important;
        color: #c1c1c1;
        transition: .3s;
        i{
            color: #c1c1c1;
        }
        i:nth-last-of-type(1){
            border: 1.5px solid #c1c1c1;
        }
    }

</style>
<template>
    <div class="test" v-if="Show">
        <el-dialog title="选择负责人" width="280" class="AddPatient" :class="[!Dark ? '' : 'dark']" :visible.sync="Show" :append-to-body="true" :before-close="close" >
            <el-input v-model="userName" @input="search" placeholder="请输入内容" />
            <div class="list" v-if="user && user.length" >
                <div class="workerItem" v-for="(item,index) in user" @click="select(item)" :key="index">
                    <img :src="item.avatar" onerror="this.src='https://webimg.ziroom.com/1c853bc6-21fc-4e32-8a6f-18ec2c6fef32.png'" />
                    <p class="name">{{item.name}}</p>
                    <p class="role">{{item.role_name}}</p>
                </div>
            </div>
            <div v-else class="empty">暂无合适的负责人</div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        props:{
            Show: Boolean,
            UserList: Array,
            health: Object,
            isAddAide: Boolean,
            Dark: Boolean
        },
        data(){
            return{
                user: [],
                userName: '',
            }
        },
        methods:{
            close(){
                this.$emit('addClose', false);
            },

            search(){
                if(this.userName){
                    let list = this.user.filter(item=>{
                        return item.name.search(this.userName) != -1
                    })
                    this.user = list;
                }else{
                    this.user = this.UserList;
                }
            },
            select(director){
                let isHas = false;
                if((this.health && this.health.length == 0) || !this.isAddAide){
                    isHas = false
                }else{
                    this.health.map(item =>{
                        if(director.id == item.id){
                            this.$message({
                                message: '添加失败！已有该成员！',
                                type: 'warning',
                            });
                            isHas = true
                        }else{
                            isHas = false;
                        }
                    })
                }

                if(!isHas){
                    this.$emit('select',director);
                    this.close();
                }

            }
        },
        mounted(){
            this.user = this.UserList
        }
    }
</script>

<style scoped lang="less">

    .AddPatient /deep/ .el-dialog{
        border-radius: 10px;
        width: 340px;

        .el-dialog__header{
            border-bottom: 1px solid #dcdfe6;
            padding: 14px 16px;

            span{
                font-size: 14px;
                color: #17233d;
            }

            button{
                margin-top: 4px;
            }
        }

        .list{
            min-height: 240px;
        }
        .workerItem{
            display: flex;
            align-items: center;
            margin-top: 20px;
            cursor: pointer;

            img{
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: 1px solid #979797;
            }
            p{
                font-size: 12px;
                color: #242424;
                margin-left: 5px;
            }
            .role{
                color: #9F9F9F;
            }
        }
        .empty{
            height: 210px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .AddPatient.dark /deep/ .el-dialog{
        .el-dialog__header,
        .el-dialog__body,
        .el-dialog__footer{
            background: #202025 !important;
            border-color: #2c2c2e !important;
            .el-dialog__title{
                color: #c1c1c1;
            }
            .el-input__inner{
                background: unset;
                border-color: #2c2c2e;
                &::placeholder{
                    color: #c1c1c1;
                    font-size: 14px;
                }
            }
            .name{
                color: #c1c1c1;
            }
        }
    }

</style>
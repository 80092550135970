<template>
    <div class="editUserInfo" :class="[!Dark ? '' : 'dark']">
        <el-dialog title="每日数据解读" width="1240" :visible.sync="show" :modal-append-to-body="false" :before-close="shutdown">
            <el-form label-position="top" ref="form" label-width="140px">
                 <el-form-item label="">
                    <el-input v-model="data.analysis" type="textarea" :autosize="{minRows: 3,maxRows: 5}" placeholder="请输入每日解读内容"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="shutdown">取消</el-button>
                <el-button @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {editInterpret} from 'quest'
    export default {
        props:{
            show: Boolean,
            text: String,
            date: String,
            Dark: Boolean
        },
        data(){
            return{
                data: {
                    analysis:'',
                    date:'',
                    uid: this.$route.query.id,
                },
            }
        },
        watch:{
            show(){
                this.data.analysis = this.text;
                this.data.date = this.date;
            },
        },
        methods:{
            shutdown(){
                this.$emit('close', false);
                // this.$message.error('取消操作');
            },
            submit(){
                editInterpret(this.data).then( res =>{
                    if(res.data.code == 100000){
                        this.$emit('close', false);
                        this.$message.success(res.data.msg);
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .editUserInfo /deep/ .ivu-modal-content{
        width: 100%;
        margin: 0 auto;
    }

    .editUserInfo /deep/ .el-dialog{
        margin-top: 22% !important;
    }

    .editUserInfo.dark /deep/ .el-dialog__wrapper{
        .el-dialog,
        .el-dialog__header,
        .el-dialog__body,
        .el-dialog__footer{
            background: #202025 !important;
            border-color: #2c2c2e !important;
            .el-dialog__title,
            .el-dialog__close{
                color: #c1c1c1;
            }
            .el-textarea{
                textarea{
                    background-color: unset;
                    color: #c1c1c1;
                    border-color: #2c2c2e;
                    &::placeholder{
                        color: #c1c1c1;
                        font-size: 15px;
                    }
                }
            }
            .el-button{
                background-color: unset;
                color: #c1c1c1;
                border-color: #2c2c2e;
            }
            .dialog-footer{
                button:nth-of-type(2){
                    background: var(--defalutActiveColor) !important;
                    border-color: var(--defalutActiveColor) !important;
                }
            }
        }
    }

</style>
<template>
    <div class="addDoctor" :class="[!Dark ? '' : 'dark']">
        
        <el-dialog title="添加成员" width="1240" :visible.sync="show" :modal-append-to-body="false" :before-close="close">
            <el-form label-position="top" :rules="addDocs" ref="form" label-width="140px" :model="form">
                 <el-form-item prop="nick" label="昵称">
                    <el-input v-model="form.nick" placeholder="请输入昵称"></el-input>
                </el-form-item>
                 <el-form-item prop="userName" label="登录用户名">
                    <el-input v-model="form.userName" placeholder="请输入登录用户名"></el-input>
                </el-form-item>
                 <el-form-item :prop="this.id == 0 ? 'passWord' : '' " label="密码">
                    <el-input type="password" v-model="form.passWord" placeholder="请输入密码"></el-input>
                </el-form-item>
                 <el-form-item label="手机号">
                    <el-input type="number" v-model="form.phone" placeholder="手机号"></el-input>
                </el-form-item>
                 <el-form-item prop="department" label="选择部门">
                    <el-select :popper-append-to-body="false" v-model="form.department" placeholder="请选择部门">
                        <el-option v-for="item in department" :key="item.id" :label="item.title" :value="item.id">
                            <span :class="[ item.level ? 'ml-10' : '' ]" v-if="item.level">|</span>
                            {{ item.title }}
                        </el-option>
                    </el-select>
                </el-form-item>
                 <el-form-item prop="role" label="选择角色">
                    <el-select :popper-append-to-body="false" v-model="form.role" placeholder="请选择角色">
                        <el-option v-for="item in roleList" :key="item.id" :label="item.title" :value="item.id" />
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="close">取消</el-button>
                <el-button @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { addDoctor, saveDoctor } from 'quest';
    export default {
        props: {
            show: Boolean,
            id: Number,
            Dark: Boolean
        },
        watch:{
            show(){
                this.addDoctor();
            }
        },
        data(){
            return{
                form: {
                    nick: '',
                    userName: '',
                    passWord: '',
                    phone: '',
                    department: '',
                    role: ''
                },
                addDocs:{
                    nick:[
                        {
                            required: true,
                            message: '请输入昵称',
                            trigger: 'blur'
                        }
                    ],
                    userName:[
                        {
                            required: true,
                            message: '请输入登录用户名',
                            trigger: 'blur'
                        }
                    ],
                    passWord:[
                        {
                            required: true,
                            message: '请输入密码',
                            trigger: 'blur'
                        }
                    ],
                    department:[
                        {
                            required: true,
                            message: '请选择部门',
                            trigger: 'change'
                        }
                    ],
                    role:[
                        {
                            required: true,
                            message: '请选择角色',
                            trigger: 'change'
                        }
                    ],
                    
                },
                department: [],
                roleList: [],
            }
        },
        methods:{
            addDoctor(){
                addDoctor({[this.id && this.id != 0 ? 'id' : '' ]: this.id && this.id != 0 ? this.id : ''}).then( res =>{
                    if(res.data.code == 100000){
                        this.department = res.data.data.department_list,
                        this.roleList = res.data.data.agency_role_list;
                        if(this.id && this.id != 0){
                            this.form.nick = res.data.data.name,
                            this.form.userName = res.data.data.username,
                            this.form.department = res.data.data.department_id != 0 ? res.data.data.department_id : '',
                            this.form.role = res.data.data.role_id != 0 ? res.data.data.role_id : '';
                        }else{
                            this.$refs.resetFields();
                        }
                    }
                })
            },
            submit(){
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        saveDoctor({
                            [this.id && this.id != 0 ? 'id' : '' ] : this.id && this.id != 0 ? this.id : '',
                            nick: this.form.nick,
                            username: this.form.userName,
                            password: this.form.passWord,
                            phone : this.form.phone,
                            role_id: this.form.role,
                            department_id: this.form.department
                        }).then( res => {
                            if(res.data.code == 100000 ){
                                this.$message({
                                    message: '保存成功！',
                                    type: 'success',
                                });
                                this.$emit('save',true);
                                this.$refs.form.resetFields();
                            }else{
                                this.$message({
                                    message: res.data.msg,
                                    type: 'error',
                                });
                            }
                        })
                    }
                })
            },
            close(){
                this.$emit('close', false);
                this.$refs.form.resetFields();
            }
        },
    }
</script>

<style scoped lang="less">

    .addDoctor /deep/ .el-dialog{
        width: 35vw;
        overflow: hidden;
        border-radius: 10px;

        .el-dialog__header{
            border-bottom: 1px solid #dcdfe6;
            padding: 14px 16px;
            span{
                font-size: 14px;
                color: #17233d;
            }
            button{
                margin-top: 4px;
            }
        }

        .el-select{
            width: 100%;
        }

        .el-form-item__label{
            font-size: 12px;
            padding-bottom: 0px;
            line-height: unset;
        }

        input{
            font-size: 12px;
            height: 32px;
            line-height: 1;

            &::placeholder{
                font-size: 12px;
            }
        }

        .addIcon{
            font-size: 32px;
            color: #16CCA6;
            cursor: pointer;
            margin-top: 10px;
        }

        .el-dialog__footer{
            border-top: 1px solid #dcdfe6;
            padding-top: 15px;

            button{
                padding: 5px 15px 6px;
                font-size: 12px;
                border-radius: 4px;
                line-height: 1.6;
                font-weight: normal;
                transition: .3s;

                span{
                    display: block;
                    padding-top: 2px;
                }
                &:nth-of-type(2){
                    transition: .3s;
                    background: var(--defalutColor);
                    border-color: var(--defalutColor);
                    color: white;

                    &:hover{
                        background-color: var(--defalutActiveColor);
                        border-color: var(--defalutActiveColor);
                        transition: .3s;
                    }
                }
            }
        }
    }

    .addDoctor.dark /deep/ .el-dialog{
        .el-dialog__header,
        .el-dialog__body,
        .el-dialog__footer{
            background: #202025 !important;
            border-color: #2c2c2e !important;

            .el-select-dropdown{
                background-color: #202025;
                border-color: #0c0c0c;
                .hover{
                    background-color: #0c0c0c;
                }
                .el-select-dropdown__item{
                    color: #c1c1c1;
                }
                .popper__arrow{
                    border-bottom-color: #0c0c0c;
                    &::after{
                        border-bottom-color: #0c0c0c;
                    }
                }
            }
            .el-dialog__title,
            .el-dialog__close{
                color: #c1c1c1;
            }
            .el-form-item__label{
                color: #c1c1c1 !important;
            }
            .el-input__inner{
                color: #c1c1c1;
                background-color: unset;
                border-color: #2c2c2e;
                &::placeholder{
                    color: #c1c1c1 !important;
                    border-color: #2c2c2e !important;
                }
                &:-webkit-autofill{
                    -webkit-text-fill-color: #c1c1c1;
                }
            }
            .el-button{
                background-color: unset;
                color: #c1c1c1;
                border-color: #e6e9f0;
            }
            .dialog-footer{
                button:nth-of-type(2){
                    background: var(--defalutActiveColor) !important;
                    border-color: var(--defalutActiveColor) !important;
                }
            }
        }
    }

</style>
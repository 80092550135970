<template>
    <div class="print-cont home pion-rel" :style="{'margin-top' : !download ? '10px' : '0px' }">
        <div class="tiptitle">
            <img class="logo" src="@/assets/images/print/pring-logo.png" alt="">
        </div>
        <div class="content pion-rel">
            <div class="page-1-text pion-abs mt-10">
                <h1>{{$t('menu.list.Print.page1.title')}}</h1>
                <h3>CGM&nbsp;&nbsp;REPORT</h3>
            </div>
            <div class="footer-bar-text pion-abs flex">
                <div class="text">
                    <!-- <h4>糖动发射器+雅培瞬感</h4> -->
                    <h4>{{$t('menu.list.Print.page1.name')}}：{{userInfo.agency_nickname != '' ? userInfo.agency_nickname : userInfo.nickname }}</h4>
                    <p class="mt-10">{{ timeGet(bindTime,endTime) }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { time } from '_js/Cache';
    export default {
        name: 'Home',
        props:{
            download: Boolean,
            userInfo: Object,
            bindTime: Number,
            endTime: Number,
            isDefaultUnit: Number,
            lang: String
        },
        methods:{
            timeGet(start,end){
                return time(new Date(start * 1000 ),this.lang != 'ch' ? 'YYYY/MM/DD' : '年月日') + '—' + time(new Date(end * 1000 ), this.lang != 'ch' ? 'MM/DD' : '月日');
            }
        }
    }
</script>

<style lang="less" scoped>

    .print-cont.home{
        padding: 41px 56px 0px 130px;
        .tiptitle{
            .logo{
                width: 369px !important;
                object-fit: cover;
            }
        }
    }

    .print-cont /deep/ .content{
        height: 1515px;
        margin-top: 120px;
        &::before{
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 2px;
            height: 100%;
            background: #d8d8d8;
        }

        &::after{
            content: '';
            position: absolute;
            bottom: 240px;
            left: -60px;
            width: 300px;
            height: 2px;
            background: #d8d8d8;
        }

        .page-1-text{
            bottom: 285px;
            left: 40px;
            h1{
                font-size: 71px;
                color: #000;
            }
            h3{
                font-size: 71px;
                color: #d8d8d8;
                font-weight: 500;
            }
        }

        .footer-bar-text{
            bottom: 20px;
            left: 50px;
            .text{
                font-size: 31px;
                h4{
                    color: #000;
                }
                p{
                    font-size: 28px;
                }
            }
        }
    }

    .print-cont.home{
        padding: 41px 56px 0px 130px;
        .tiptitle{
            .logo{
                width: 369px !important;
                object-fit: cover;
            }
        }
    }
</style>
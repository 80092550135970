<template>
    <div ref="dom" class="charts chart-bar" v-if="value && x_arr"></div>
</template>

<script>
    import echarts from 'echarts'
    import tdTheme from './theme.json'
    import { on, off, getItem } from '_js/Cache'
    echarts.registerTheme('tdTheme', tdTheme)
    export default {
        name: 'ChartLine',
        props: {
            value: Object,
            x_arr: Array
        },
        data () {
            return {
                dom: null,
                loading: false,
                isDark: false,
            }
        },
        watch:{
            value:{
                handler(){
                    this.loading = false;
                    setTimeout( () =>{
                        this.init();
                    },200)
                },
                deep: true
            }
        },
        methods: {
            resize () {
                this.dom.resize()
            },
            hexToRgba (hex, opacity) {
                let rgbaColor = ''
                let reg = /^#[\da-f]{6}$/i
                if (reg.test(hex)) {
                    rgbaColor = `rgba(${parseInt('0x' + hex.slice(1, 3))},${parseInt(
                    '0x' + hex.slice(3, 5)
                    )},${parseInt('0x' + hex.slice(5, 7))},${opacity})`
                }
                return rgbaColor
            },
            init(){
                let series = []
                let dateList = Object.keys(this.value);
                let dataList = Object.values(this.value);

                dateList.forEach((item,index)=>{
                    let data = dataList[index][0].map( (items) =>{
                        return items.blood_sugar || ''
                    })

                    series.push({
                        name: item,
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        sampling: 'average',
                        connectNulls: true,
                        lineStyle: {
                            opacity: 0.5,
                            width:1.5,
                        },
                        data
                    })
                    this.loading = true;
                })
                let option = {
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        type:'scroll',
                        top: '6%',
                        right: '0',
                        show: this.isDownload ? false : true,
                        // itemGap: 50,
                        textStyle: {
                            color: '#ACCFFF',
                            fontSize: 14
                        }
                    },
                    grid: {
                        left: '0%',
                        right: '1%',
                        top: '20%',
                        bottom: '6%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: this.x_arr,
                        splitLine:{
                            show: this.isDownload ? false : true ,
                            lineStyle:{
                                color: this.isDark ? '#2c2c2e' : "#D9D9D9",
                            }
                        },
                        axisLine:{
                            lineStyle:{
                                color: this.isDark ? '#2c2c2e' : "#D9D9D9"
                            }
                        },
                        axisLabel:{
                            textStyle: {
                                color: this.isDark ? '#c1c1c1' : "#333",
                            }
                        },
                    },
                    yAxis: {
                        position: "right",
                        interval:4,
                        min:0,
                        offset: -4,
                        max(value){
                            const val = ( parseInt(value.max) + (4 - parseInt(value.max) % 4 ) ) > 16 ? parseInt(value.max) + (4 - parseInt(value.max) % 4 ) : 16;
                            return val;
                        },
                        type: 'value', 
                        boundaryGap: [0, '100%'],
                        axisLabel:{
                            textStyle: {
                                color: this.isDark ? '#c1c1c1' : "#333",
                            }
                        },
                        axisLine:{
                            lineStyle:{
                                color: this.isDark ? '#2c2c2e' : "#D9D9D9"
                            }
                        },
                        splitLine: {
                            show: true ,
                            lineStyle: {
                                color: this.isDark ? '#2c2c2e' : "#D9D9D9",
                                width:0.5
                            },
                        }
                    },
                    series: series
                }
                this.dom = echarts.init(this.$refs.dom, 'tdTheme')
                this.dom.setOption(option,true)
                on(window, 'resize', this.resize)
                window.onresize = function () {
                    this.dom.resize();
                }
            }
        },
        mounted () {
            setTimeout( () =>{
                this.init();
            },200)
        },
        created(){
            this.isDark = getItem('dark') || false;
        },
        beforeDestroy () {
            off(window, 'resize', this.resize)
        }
    }
</script>
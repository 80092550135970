<template>
    <div ref="dom" class="charts chart-bar" v-if="value"></div>
</template>

<script>
    import echarts from "echarts";
    import tdTheme from "./theme.json";
    import { on, off, getItem } from '_js/Cache'
    echarts.registerTheme("tdTheme", tdTheme);

    export default {
        name: "ChartLine",
        props: {
            value: Object
        },
        
        data() {
            return {
                dom: null,
                isDark: false
            };
        },
        watch:{
            'value':{
                handler(){
                    this.init();
                },
                deep: true,
            }
        },
        methods: {
            resize() {
                this.dom.resize();
            },
            init(){
                let xAxisData = this.value.x_arr;
                let device_10 = this.value.device_10_bloodsugar_arr;
                let device_25 = this.value.device_25_bloodsugar_arr;
                let device_75 = this.value.device_75_bloodsugar_arr;
                let device_90 = this.value.device_90_bloodsugar_arr;
                let device_avg = this.value.device_avg_bloodsugar_arr;
                let device_max = this.value.device_max_bloodsugar_arr;
                let device_min = this.value.device_min_bloodsugar_arr;
                let option = {
                    backgroundColor: "#fff",
                    tooltip: {
                        trigger: "axis"
                    },
                    grid: {
                        top: 10,
                        bottom: 20,
                        left: 0,
                        right: 0
                    },
                    xAxis: [
                        {
                            type: "category",
                            offset: -1,
                            boundaryGap: false,
                            axisLabel: {
                                formatter: "{value}",
                                textStyle: {
                                    color: "#333",
                                    fontSize: 7
                                }
                            },
                            splitLine: {
                                show: false,
                                lineStyle: {
                                    type: "dashed",
                                    color: "#E9E9E9"
                                }
                            },
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: "#D9D9D9"
                                }
                            },
                            data: xAxisData
                        }
                    ],
                    yAxis: [
                        {
                            interval: 4,
                            position: "right",
                            offset: -15,
                            type: "value",
                            axisLabel: {
                                textStyle: {
                                    color: "#666",
                                    fontSize: 7
                                }
                            },
                            nameTextStyle: {
                                color: "#666",
                                fontSize: 12,
                                lineHeight: 40
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#E9E9E9"
                                }
                            },
                            axisLine: {
                                show: false
                            },
                            axisTick: {
                                show: false
                            }
                        }
                    ],
                    series: [
                        {
                            name: "10%线",
                            type: "line",
                            smooth: true,
                            symbolSize: 3,
                            zlevel: 1,
                            symbol: "circle", // 数据交叉点样式
                            data: device_10,
                            lineStyle: {
                                width: 1
                            }
                        },
                        {
                            name: "25%线",
                            type: "line",
                            smooth: true,
                            symbolSize: 3,
                            zlevel: 2,
                            symbol: "circle", // 数据交叉点样式
                            data: device_25,
                            lineStyle: {
                                width: 1
                            }
                        },
                        {
                            name: "75%线",
                            type: "line",
                            smooth: true,
                            symbolSize: 3,
                            zlevel: 3,
                            symbol: "circle", // 数据交叉点样式
                            data: device_75,
                            lineStyle: {
                                width: 1
                            }
                        },
                        {
                            name: "90%线",
                            type: "line",
                            smooth: true,
                            symbolSize: 3,
                            zlevel: 4,
                            symbol: "circle", // 数据交叉点样式
                            data: device_90,
                            lineStyle: {
                                width: 1
                            }
                        },
                        {
                            name: "平均线",
                            type: "line",
                            smooth: true,
                            symbolSize: 3,
                            zlevel: 5,
                            symbol: "circle", // 数据交叉点样式
                            data: device_avg,
                            lineStyle: {
                                width: 1
                            }
                        },
                        {
                            name: "最高线",
                            type: "line",
                            smooth: true,
                            symbolSize: 3,
                            zlevel: 6,
                            symbol: "circle", // 数据交叉点样式
                            data: device_max,
                            lineStyle: {
                                width: 1
                            }
                        },
                        {
                            name: "最低线",
                            type: "line",
                            smooth: true,
                            symbolSize: 3,
                            zlevel: 7,
                            symbol: "circle", // 数据交叉点样式
                            data: device_min,
                            lineStyle: {
                                width: 1
                            }
                        }
                    ]
                };
                this.dom = echarts.init(this.$refs.dom, "tdTheme");
                this.dom.setOption(option);
                on(window, "resize", this.resize);
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.init();
            });
        },
        created(){
            this.isDark = getItem('dark') || false;
        },
        beforeDestroy() {
            off(window, "resize", this.resize);
        }
    };
</script>

export default {
    menu: {
        index: {
            title: 'Monitoring Center'
        },
        list: {
            title: 'Patient List',
            Print: {
                loading: 'Loading',
                button: 'Export',
                page1: {
                    title: 'Dynamic Blood Glucose Data Analysis Report',
                    name: 'Username',
                },
                page2: {
                    title: 'Report Preview',
                    times: 'Coverage Time',
                    number: 'Numbers',
                    titUnit: '',
                    avg: 'Average Blood Glucose',
                    hemoglobin: 'Estimated HbA1c',
                    volatility: 'Blood Sugar Fluctuation',
                    tir: {
                        vhigh: 'very high',
                        high: 'high',
                        normal: 'normal',
                        low: 'low',
                        vlow: 'very low'
                    },
                    insulin: 'Insulin',
                    days: 'Day',
                    longInsulin: 'Long Acting',
                    MealInsulin: 'Meal',
                    carbohydrate: 'Daily Carb',
                    unit: 'g/Day',
                    sport: 'Daily Exercise',
                    sunit: 'kcal/Day',
                    AGP: 'AGP Map',
                    minmax: 'Min/Max',
                    footTitle: 'Daily Graph',
                    date: 'Print Date',
                    form: 'Data Source',
                    page: 'Page Number'
                },
                page3:{
                    title: 'Period Graph',
                    times: 'Coverage Time',
                    number: 'Numbers',
                    titUnit: '',
                    lineTitle: 'Multi-Day Lines',
                    footTitle: 'BOX Map',
                    high: 'Higher than range',
                    low: 'Lower than range',
                    scope: 'The range',
                    avg: 'Average',
                    date: 'Print Date',
                    form: 'Data Source',
                    page: 'Page Number',
                    notVIP: 'Become a vip to view all exclusive content',
                },
                page4:{
                    title: 'Continuous Graph',
                    times: 'Coverage Time',
                    number: 'Numbers',
                    titUnit: '',
                    notData: 'No data',
                    footTitle: 'Average',
                    
                    date: 'Print Date',
                    form: 'Data Source',
                    page: 'Page Number',
                },
                page5:{
                    title: 'Daily Report',
                    times: 'Coverage Time',
                    number: 'Numbers',
                    titUnit: '',
                    year: '-',
                    mon: '-',
                    day: '',
                    long: 'Long Acting',
                    meal: 'Meal',
                    carbohydrate: 'Carbs',
                    unit: 'g',
                    type: 'Sports',
                    units: 'k',
                    date: 'Print Date',
                    form: 'Data Source',
                    page: 'Page Number',
                },
                page6: {
                    title: 'Night Analysis',
                    times: 'Coverage Time',
                    number: 'Numbers',
                    titUnit: '',
                    titles: 'Key points',
                    high: 'Higher',
                    low: 'Lower',
                    unit: '',
                    date: 'Print Date',
                    form: 'Data Source',
                    page: 'Page Number',
                },
                page7: {
                    title: 'Food Analysis',
                    times: 'Coverage Time',
                    number: 'Numbers',
                    titUnit: '',
                    titles: 'Glycemic ranking',
                    insulin: 'Insulin',
                    type1: 'Extra meal',
                    type2: 'Breakfast',
                    type3: 'Lunch',
                    type4: 'Dinner',
                    titles2: 'Blood Glucose',
                    unit: 'Fluctuation',
                    date: 'Print Date',
                    form: 'Data Source',
                    page: 'Page Number',
                },
                page8: {
                    title: 'Period Analysis',
                    times: 'Coverage Time',
                    number: 'Numbers',
                    titUnit: '',
                    titles1: 'Teriod',
                    titles2: 'TAR is higher than the range',
                    titles3: 'TIR is time in Range',
                    titles4: 'TBR is lower than the range',
                    titles5: 'Numbers',
                    titles6: 'Max',
                    titles7: 'Min',
                    titles8: 'MD',
                    titles9: 'SD',
                    titles10: 'CV',
                    PS1: `TIR (Time in Range) refers to the percentage of time that glucose levels are within a target range. In a narrow sense, TIR specifically refers to the percentage of time that glucose levels are within the target range. In a broader sense, TIR also includes the percentage of time that glucose levels are below the target range (low values) and above the target range (high values) during the monitoring period. This broader definition provides a more comprehensive reflection of a patient's blood glucose control level.`,
                    PS2: 'MD (Mean Glucose) refers to the average glucose value calculated from all glucose measurements taken during a specific period, typically a month. It is used to evaluate the overall blood glucose level of an individual.',
                    PS3: 'SD (Standard Deviation) refers to the measure of variability or dispersion of glucose values taken on different dates. ',
                    PS4: 'CV (Coefficient of Variation) is a measure of the relative variability or dispersion of glucose values taken on different dates. ',
                    PS5: 'Note: In order to ensure the display effect, some data have been smoothed.',
                    date: 'Print Date',
                    form: 'Data Source',
                    page: 'Page Number',
                },
                page9: {
                    title: 'Interpretation',
                    times: 'Coverage Time',
                    number: 'Numbers',
                    titUnit: '',
                    signature: `Signature`,
                    date: 'Print Date',
                    form: 'Data Source',
                    page: 'Page Number',
                },
                wait: 'Generating, please wait.',
                merge: 'Merging',
                exporting: '',
                building: 'Packing in progress...',
                over: 'Success',
            }
        },
        Task: {
            title: 'Mission Center',
        },
        plan: {
            title: 'Solution Template'
        },
        hospital: {
            title: 'Hospital Prescription'
        },
        Custody: {
            title: 'Central Custody'
        },
        Organization: {
            title: 'Organization'
        },
        setting: {
            title: 'Account Settings'
        }
    }
}
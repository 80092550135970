<template>
    <div class="fingerBlood" :class="[!Dark ? '' : 'dark']">
        <div v-loading="loading">
            <div class="timeSelect mb-20">
                <el-button :class="[dayType == 0 ? 'on' : '' ]" @click="selectTimes(7)">近7天</el-button>
                <el-button :class="[dayType == 1 ? 'on' : '' ]" @click="selectTimes(14)">近14天</el-button>
                <el-button :class="[dayType == 2 ? 'on' : '' ]" @click="selectTimes(30)">近1个月</el-button>
                <el-button :class="[dayType == 3 ? 'on' : '' ]" class="pion-rel">
                    自定义时间
                    <el-date-picker class="pion-abs days" v-model="mon" @change="selectTimes(mon)" :append-to-body="false" :picker-options="pickerOptions" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-button>
            </div>
            <div class="times flex justify-between pt-15 pb-15 pl-15 pr-15 font-12 radius-5">
                <div class="time">
                    <p>共检测&nbsp;{{ fingerBlood ? fingerBlood.total : ''  }}次</p>
                </div>
            </div>
            <div class="target">
                <div class="target-value">
                    <div class="target-value_item">
                        <div class="title">达标率</div>
                        <div class="number">
                            <span>
                                {{fingerBlood ? (fingerBlood.rate.split('.')[0] == 1 ? '100' : fingerBlood.rate.split('.')[1] ) : '' }}
                                <span class="unit">%</span>
                            </span>
                        </div>
                    </div>
                    <div class="target-value_item">
                        <div class="title">最低血糖值</div>
                        <div class="number">
                            <span>
                                {{fingerBlood ? Number(fingerBlood.lowBlood).toFixed(1) : '' }}
                                <span class="unit">mmol/L</span>
                            </span>
                        </div>
                    </div>
                    <div class="target-value_item">
                        <div class="title">最高血糖值</div>
                        <div class="number">
                            <span>
                                {{ fingerBlood ? Number(fingerBlood.highBlood).toFixed(1) : '' }}
                                <span class="unit">mmol/L</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="target-chart">
                    <div class="round-radius_chart flex">
                        <div class="chart pion-rel" v-if="fingerBlood">
                            <RoundPie :value="fingerBlood.tir" />
                            <div class="center pion-abs text-center">
                                <h4>平均值</h4>
                                <h1 class="font-weight">{{fingerBlood ? fingerBlood.ave_bloodsugar : '--' }}</h1>
                                <p class="font-12">mmol/L</p>
                            </div>
                        </div>
                        <div class="explain flex flex-wrap">
                            <p><span class="mr-10">{{ fingerBlood ? fingerBlood.tir.very_high : '' }}</span>非常高</p>
                            <p><span class="mr-10">{{ fingerBlood ? fingerBlood.tir.high : '' }}</span>高</p>
                            <p class="font-20"><span class="mr-5">{{ fingerBlood ? fingerBlood.tir.common : '' }}</span>正常</p>
                            <p><span class="mr-10">{{ fingerBlood ? fingerBlood.tir.low : '' }}</span>低</p>
                            <p><span class="mr-10">{{ fingerBlood ? fingerBlood.tir.very_low : ''}}</span>非常低</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="timesAVG solid radius-10 pt-15 pr-15 pb-15 pl-15">
                <h2 class="text-center">时间点平均值</h2>
                <div class="cards flex justify-between mt-10">
                    <template v-for="(item,avg) in fingerBlood.type_avg">
                        <div class="card mt-20 solid pt-10 pr-10 pb-10 pl-10 radius-5" v-if="avg < 5" :key="avg">
                            <p class="font-12">平均值</p>
                            <h4 class="font-16 font-weight mt-5">{{ avg == 1 ? '空腹' : avg == 2 ? '早2' : avg == 3 ? '午餐' : avg == 4 ? '午2' : avg == 5 ? '晚餐' : avg == 6 ? '晚2' : avg == 7 ? '睡前' : '凌晨' }}</h4>
                            <h1 class="font-22 font-weigh mt-5">{{ item }}<span class="font-12 ml-5">mmol/L</span></h1>
                            <div class="colorBox radius-50 mt-5" :class="[ item == 0 || item == '--' ? 'not' : Number(fingerBlood.target_low) > Number(item) ? 'low' : Number(fingerBlood.target_high) < Number(item) ? 'high': '' ]"></div>
                        </div>
                    </template>
                </div>
                <!--       凌晨 -->
                <div class="cards flex justify-between">
                    <template v-for="(item,avg) in fingerBlood.type_avg">
                        <div class="card mt-20 solid pt-10 pr-10 pb-10 pl-10 radius-5" v-if="avg >= 5" :key="avg">
                            <p class="font-12">平均值</p>
                            <h4 class="font-16 font-weight mt-5">{{ avg == 1 ? '空腹' : avg == 2 ? '早2' : avg == 3 ? '午餐' : avg == 4 ? '午2' : avg == 5 ? '晚餐' : avg == 6 ? '晚2' : avg == 7 ? '睡前' : '凌晨' }}</h4>
                            <h1 class="font-22 font-weigh mt-5">{{ item }}<span class="font-12 ml-5">mmol/L</span></h1>
                            <div class="colorBox radius-50 mt-5" :class="[ item == 0 || item == '--' ? 'not' : Number(fingerBlood.target_low) > Number(item) ? 'low' : Number(fingerBlood.target_high) < Number(item) ? 'high': '' ]"></div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="hot-chart" v-if="fingerBlood.eight">
                <div class="title">八点血糖</div>
                <div class="hot-box">
                    <div class="eightPoint">
                        <div class="left">
                            <div class="fixedbar">
                                <div class="item" v-for="(item,index) in textList" :key="index">{{item}}</div>
                            </div>
                            <div class="scrollBox">
                                <div class="item" v-for="(item,index) in data.val1" :key="index">
                                    <div class="date">{{item.date}}</div>
                                    <div class="block" v-for="(i,index) in item.list" :key="index">
                                        <div v-if="!i.blood_sugar" class="content grey">{{i.blood_sugar || '/'}}</div>
                                        <div v-else class="content font-14" :class="i.target_level == 0 ? 'green' : (i.target_level > 0 ? 'purple' : 'red' )">{{i.blood_sugar}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <div class="fixedbar">
                                <div class="item" v-for="(item,index) in textList" :key="index">{{item}}</div>
                            </div>
                            <div class="scrollBox">
                                <div class="item" v-for="(item,index) in data.val2" :key="index">
                                    <div class="date">{{item.date}}</div>
                                    <div class="block" v-for="(i,index) in item.list" :key="index">
                                        <div v-if="!i.blood_sugar" class="content grey">{{i.blood_sugar || '/'}}</div>
                                        <div v-else class="content font-14" :class="i.target_level == 0 ? 'green' : (i.target_level > 0 ? 'purple' : 'red' )">{{i.blood_sugar}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { RoundPie } from '_c/Charts/index';
    import { time } from '_js/Cache';
    export default {
        props:{
            fingerBlood: Object,
            Dark: Boolean,
            Tab: Array,
        },
        watch:{
            'fingerBlood.eight':{
                handler(val){
                    if(val != null && val != 'null' ){
                        this.splitTwoDate(val);
                    }
                },
                deep: true,
            },
            'Tab':{
                handler(){
                    this.dayType = 0;
                },
                deep: true
            }
        },
        components:{
            RoundPie
        },
        data(){
            return{
                dayType: 0,
                mon: new Date(),
                pickerMinDate:'',
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.pickerMinDate = minDate.getTime();
                        if (maxDate) {
                            this.pickerMinDate = '';
                        }
                    },
                    disabledDate: time => {
                        if (this.pickerMinDate != '') {
                            const day30 = (31 - 1) * 24 * 3600 * 1000;
                            // 只选后30天，minTime不用加day30,只选前30天，maxTime不用加day30
                            let maxTime = this.pickerMinDate + day30;
                            let minTime = this.pickerMinDate - day30;
                            if (maxTime > new Date()) {
                                maxTime = new Date();
                            }
                            return time.getTime() > maxTime || time.getTime() < minTime;
                        }
                        return time.getTime() > Date.now();
                    }
                },
                textList:['空腹','早2','午餐','午2','晚餐','晚2', '睡前', '凌晨'],
                data:{
                    val1:[],
                    val2:[],
                },
                loading: false,
            }
        },
        methods:{
            selectTimes(days){
                let start,end;
                switch(days){
                    case 7:
                        start = time( new Date(new Date().getTime() -  6 * 24 * 3600 * 1000),  'YYYY-MM-DD'),
                        end = time(new Date(), 'YYYY-MM-DD');
                        this.dayType = 0;
                        this.$emit('selectFingerTimes',start,end);
                    break;
                    case 14:
                        start = time( new Date(new Date().getTime() -  13 * 24 * 3600 * 1000),  'YYYY-MM-DD'),
                        end = time(new Date(), 'YYYY-MM-DD');
                        this.dayType = 1;
                        this.$emit('selectFingerTimes',start,end);
                    break;
                    case 30:
                        start = time( new Date(new Date().getTime() -  29 * 24 * 3600 * 1000),  'YYYY-MM-DD'),
                        end = time(new Date(), 'YYYY-MM-DD');
                        this.dayType = 2;
                        this.$emit('selectFingerTimes',start,end);
                    break;
                    default:
                        start = time(days[0], 'YYYY-MM-DD'),
                        end = time(days[1], 'YYYY-MM-DD');
                        this.dayType = 3;
                        this.$emit('selectFingerTimes',start,end);
                    break;
                }
                [this.startDay,this.endDay] = [start,end];
                this.loading = true;
            },
            splitTwoDate(val){
                this.data = {
                    val1:[],
                    val2:[],
                };
                let data = this.data;
                val.map( (item, index) => {
                    if(index % 2 == 0 ){
                        data.val1.push(item);
                    }else{
                        data.val2.push(item);
                    }
                })

                this.data = data,
                this.loading = false;
            }
        }
    }
</script>

<style lang="less" scoped>
    .fingerBlood{
        .timeSelect{
            & /deep/ .el-button{
                font-size: 13px;
                font-weight: unset;
                width: 125px;
                background: #eaedf1;
                color: #9F9F9F;
                border: unset;
                border-radius: 50px;
                &.on{
                    background: var(--defalutActiveColor) !important;
                    color: white !important;
                }
                &:hover{
                    background: var(--defalutActiveColor);
                    color: var(--white) !important;
                }
                &:focus{
                    background: var(--defalutActiveColor);
                    color: var(--white) !important;
                }
            }
            & /deep/ .days{
                width: 125px;
                height: 40px;
                top: 0px;
                left: 0px;
                cursor: pointer;
                background: unset;
                border: unset;

                & > i,
                & > span,
                & > input{
                    opacity: 0;
                    cursor: pointer;
                }
                .el-picker-panel{
                    margin-left: -260px;
                    .popper__arrow{
                        left: 315px !important;
                    }
                }
            }

            & /deep/ .el-date-editor{
                .available{
                    &.today{
                        span{
                            color: var(--defalutColor);
                        }
                    }
                    &.in-range{
                        &.start-date,
                        &.end-date{
                            span{
                                background-color: var(--defalutColor);
                                color: white;
                            }
                        }
                    }
                    &:hover{
                        color: var(--defalutColor);
                    }
                }
            }
        }
        
        .times{
            background: #f7f7f7;
            color: #242424;
        }

        .target {
            width: 100%;
            display: flex;
            margin-bottom: 40px;

            .target-value {
                flex: 1;
                display: flex;
                justify-content: space-between;
                margin-top: 80px;

                .target-value_item {
                    flex: 1;
                    text-align: center;
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    flex-wrap: wrap;

                    .title {
                        width: 100%;
                        font-size: 13px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #242424;
                        line-height: 18px;
                        margin-top: 20px;
                    }

                    .number {
                        font-size: 39px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 700;
                        color: #1f2e4d;
                        line-height: 20px;
                        margin-top: 20px;
                        text-align: center;
                    }

                    .unit {
                        font-size: 13px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #1f2e4d;
                        line-height: 18px;
                    }
                }
            }

            .target-chart {
                width: 33%;
                .round-radius_chart{
                    & /deep/ .roundBar{
                        left: -50px;
                    } 
                    .chart{
                        width: 50%;
                        height: 200px;
                        .center{
                            z-index: 99;
                            top: 95px;
                            left: 65px;
                            h1{
                                font-size: 38px;
                            }
                        }
                    }
                }
                .explain{
                    margin-top: 60px;
                    height: 180px;
                    align-content: space-around;
                    margin-left: 50px;
                    p{
                        width: 100%;
                        &:nth-of-type(1){
                            span{
                                color: #ffb700;
                            }
                        }
                        &:nth-of-type(2){
                            span{
                                color: #ffe452;
                            }
                        }
                        &:nth-of-type(3){
                            span{
                                color: #13be9b;
                            }
                        }
                        &:nth-of-type(4){
                            span{
                                color: #ff6e6e;
                            }
                        }
                        &:nth-of-type(5){
                            span{
                                color: #eb3f3f;
                            }
                        }
                    }
                }
            }
        }

        .timesAVG{
            margin-top: 80px;
            border-color: #e6e9f0;
            .cards{
                .card{
                    width: 24%;
                    border-color: #e6e9f0;
                    p{
                        color: #9F9F9F;
                    }
                    h1{
                        span{
                            color: #9F9F9F;
                        }
                    }
                    .colorBox{
                        width: 100%;
                        height: 15px;
                        background-color: #16cca6;
                        &.high{
                            background-color: #FFA901;
                        }
                        &.low{
                            background-color: #ED1B24;
                        }
                        &.not{
                            background-color: #E6E9F0;
                        }
                    }
                }
            }
        }

        .hot-chart {
            width: 100%;
            border-radius: 11px;
            border: 1px solid #e6e9f0;
            padding: 20px;
            margin-top: 20px;

            .title {
                width: 100%;
                font-size: 21px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #343434;
                line-height: 29px;
                text-align: center;
            }

            .hot-box {
                width: 100%;
                margin-top: 20px;

                .charts {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .eightPoint {
            width: 100%;
            display: flex;

            .left {
                width: 50%;
            }

            .right {
                width: 50%;
            }

            .fixedbar {
                box-sizing: border-box;
                padding-left: 60px;
                height: 35px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;

                .item {
                    width: 55px;
                    height: 35px;
                    line-height: 35px;
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #242424;
                }
            }

            .scrollBox {
                display: flex;
                flex-wrap: wrap;

                .item {
                    width: 100%;
                    height: 35px;
                    flex-shrink: 0;
                    display: flex;
                    justify-content: center;
                    margin-top: 8px;

                    .date {
                        width: 55px;
                        height: 35px;
                        flex-shrink: 0;
                        text-align: center;
                        font-size: 14px;
                        line-height: 35px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #242424;
                    }

                    .block {
                        width: 55px;
                        height: 35px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .content {
                            width: 35px;
                            height: 35px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: #16cca6;
                            border-radius: 4px;
                            color: #fff;
                        }

                        .red {
                            background: #ED1B24;
                        }

                        .purple {
                            background: #FFA901;
                        }

                        .grey {
                            background: #E6E9F0;
                        }
                    }
                }
            }
        }

        &.dark{

            .timeSelect{
                .el-button{
                    background: #0c0c0c;
                    color: #c1c1c1;
                    & /deep/ .el-date-editor{
                        input{
                            color: #c1c1c1 !important;
                        }
                        .el-picker-panel{
                            background: #202025;
                            border-color: #0c0c0c;
                            .el-picker-panel__icon-btn,
                            .el-date-picker__header-label{
                                color: #c1c1c1;
                            }
                            .el-date-picker__header{
                                border-color: #0c0c0c;
                            }
                            .el-date-table{
                                th{
                                    border-color: #0c0c0c;
                                }
                            }
                            .el-date-table__row{
                                .prev-month{
                                    span{
                                        color: #7f7f7f;
                                    }
                                }
                                .available{
                                    &.in-range{
                                        &.start-date,
                                        &.end-date{
                                            span{
                                                background-color: var(--defalutActiveColor);
                                                color: #0c0c0c;
                                            }
                                        }
                                        div{
                                            background-color: #4b5f7e;
                                        }
                                    }
                                    span{
                                        color: #c1c1c1;
                                    }
                                }
                                .normal.disabled,
                                .next-month.disabled,
                                .prev-month.disabled{
                                    div{
                                        background: #0c0c0c;
                                        color: #7f7f7f;
                                            &:hover{
                                                color: #7f7f7f;
                                            }
                                        }
                                }
                            }
                            .el-date-range-picker__content.is-left{
                                border-color: #0c0c0c;
                            }
                            .popper__arrow{
                                border-bottom-color: #0c0c0c;
                                &::after{
                                    border-bottom-color: #0c0c0c;
                                }
                            }
                        }
                    }
                }
            }
            .times{
                background: #0c0c0c;
                color: #c1c1c1;
                p{
                    color: #c1c1c1;
                }
            }
            .target{
                .target-value_item{
                    .title{
                        color: #7f7f7f !important;
                    }
                    .number{
                        color: #c1c1c1;
                        .unit{
                            color: #7f7f7f;
                        }
                    }
                }
                .target-chart{
                    .center{
                        h4,h1,p{
                            color: #c1c1c1;
                        }
                    }
                    .explain{
                        p{
                            color: #c1c1c1;
                        }
                    }
                }
            }

            .timesAVG{
                border-color: #2c2c2e;
                h2,h4,h1{
                    color: #c1c1c1;
                    span{
                        color: #7f7f7f;
                    }
                }
                .cards{
                    .card{
                        border-color: #2c2c2e;
                        .colorBox{
                            &.not{
                                background: #0c0c0c;
                            }
                        }
                    }
                }
            }



            .hot-chart{
                border-color: #2c2c2e !important;
                .fixedbar{
                    .item{
                        color: #c1c1c1;
                    }
                }
                .scrollBox{
                    .date{
                        color: #c1c1c1;
                    }
                    .grey{
                        background: #0c0c0c !important;
                        color: #717171 !important;
                    }
                }
                .title{
                    color: #c1c1c1;
                }
            }
        }
    }

    @media screen {
        @media (max-width: 1366px) and (min-width: 768px) {
            .fingerBlood{
                .target{
                    .target-chart{
                        .explain{
                            margin-left: 150px;
                        }
                    }
                }
            }
        }
    }
</style>
<template>
    <div class="BarChart" ref="dom"></div>
</template>

<script>
    import echarts from "echarts";
    import tdTheme from "./theme.json";
    import { on, off, getItem } from "_js/Cache";

    echarts.registerTheme("tdTheme", tdTheme);
    export default {
        name: 'BarChartRow',
        props: {
            value: Object || '',
            high: String,
            low: String,
        },
        watch:{
            value:{
                handler(val){
                    this.init(val, this.high, this.low);
                },
                deep: true,
            }
        },
        data () {
            return {
                dom: null,
                isDark: false
            }
        },
        methods:{
            resize () {
                this.dom.resize()
            },
            init(val,high,low){
                let xAxisData = val.x;
                let value = [];
                val.y ? val.y.map( i => {
                    let str;
                    if( Number(i) < low ){
                        str = {
                            value: Number(i),
                            itemStyle:{
                                color: '#E02020',
                                barBorderRadius : [ 4, 4, 0, 0],
                            },
                            label: {
                                // 柱图头部显示值
                                show: true,
                                position: "top",
                                color: "#E02020",
                                fontSize: "12px",
                                width: 20,
                                formatter: (params) => {
                                    return params.value[params.encode.x[0]];
                                },
                            },
                        };
                    }else if(Number(i) >= low && Number(i) < high ){
                        str = {
                            value: Number(i),
                            itemStyle: {
                                color:'#19be6b',
                                barBorderRadius : [ 4, 4, 0, 0],
                            },
                            label: {
                                // 柱图头部显示值
                                show: true,
                                position: "top",
                                color: "#19be6b",
                                fontSize: "12px",
                                width: 20,
                                formatter: (params) => {
                                    return params.value[params.encode.x[0]];
                                },
                            },
                        }
                    }else if( Number(i) > high ){
                        str = {
                            value: Number(i),
                            itemStyle: {
                                color:'#F7B500',
                                barBorderRadius : [ 4, 4, 0, 0],
                            },
                            label: {
                                // 柱图头部显示值
                                show: true,
                                position: "top",
                                color: "#F7B500",
                                fontSize: "12px",
                                width: 20,
                                formatter: (params) => {
                                    return params.value[params.encode.x[0]];
                                },
                            },
                        }
                    }
                    value.push(str);
                }) : '';

                let option = {
                    grid:{
                        width: '100%',
                        height: '78%',
                        top: '12px',
                        left: '0',
                        right: '0',
                        bottom: '0',
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                            shadowStyle:{
                                opacity: 0.5
                            }
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: xAxisData,
                        splitLine: {
                            show: true,
                            interval: 0,
                            lineStyle:{
                                color: this.isDark ? '#2c2c2e' : "#E9E9E9"
                            }
                        },
                        lineStyle:{
                            color: '#1f1f1f',
                        },
                        axisLine:{
                            show: false,
                        },
                        axisLabel: {
                            interval: 0,
                            textStyle: {
                                color: this.isDark ? '#c1c1c1' : "#333",
                            },
                            lineStyle:{
                                color: this.isDark ? '#2c2c2e' : "#E9E9E9"
                            }
                        },
                    },
                    yAxis: {
                        show: false,
                        type: 'value',
                        axisLine:{
                            show: false
                        },
                        splitLine:{
                            show: false
                        },
                    },
                    series: [{
                        data: value,
                        type: 'bar',
                        barWidth: '15px'
                    }]
                }

                this.dom = echarts.init(this.$refs.dom, 'tdTheme')
                this.dom.setOption(option)
                on(window, 'resize', this.resize)

            }
        },
        created(){
            this.isDark = getItem('dark') || false;
        },
        mounted(){
            setTimeout( () =>{
                this.init(this.value, this.high, this.low);
            },200)
        },
        beforeDestroy () {
            off(window, 'resize', this.resize)
        }
    }
</script>

<style scoped>
    .BarChart{
        width: 100%;
        height: 100%;
    }
</style>
<template>
    <div ref="dom" class="diet" style="width: 114px;height:150px"></div>
</template>

<script>
import echarts from "echarts";
import { on, off } from "_js/Cache";
export default {
    name: "nightCharts",
    props: {
        title: String
    },
    data() {
        return {
            
        };
    },
    methods: {
        resize() {
            this.dom.resize();
        },
        
        init() {
            let option = {
                title:{
                    text:this.title,
                    textAlign: 'center',
                    testStyle:{
                        width: '100%'
                    },
                    subtextStyle:{
                        width: '100%'
                    },
                    padding:[0,0,0,50]
                },
                color:['#F7B500','#fff','#E03520'],
                series: [
                    {
                        name: '访问来源',
                        type: 'pie',
                        radius: '70%',
                        
                        grid: {
                            width:'100%',
                            height:'100%',
                        },
                        data: [
                            {value: 350},
                            {value: 400},
                            {value: 350}
                        ],
                        
                        label: {
                            normal: {
                                show : false
                            }
                        },
                        itemStyle:{
                            borderColor: '#000'
                        }
                    },
                ]
            }
            this.dom = echarts.init(this.$refs.dom);
            this.dom.setOption(option);
            on(window, "resize", this.resize);
        },
    },
    mounted() {
        this.init()
    },
    beforeDestroy() {
        off(window, "resize", this.resize);
    }
}
</script>
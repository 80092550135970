<template>
    <div class="drugList" :class="[!Dark ? '' : 'dark']">
        <template v-if="drugList.list.length > 0">
            <div class="title pb-10 flex justify-between solid-bottom">
                <h1>处方列表</h1>
                <el-button class="add radius-50 addDrug" @click="showPrescription = !showPrescription">添加处方</el-button>
            </div>

            <div class="list mt-20 pt-20 pb-20 pl-20 pr-20 solid radius-10" v-for="(item,idx) in drugList.list" :key="idx">
                <div class="title solid-bottom pb-10 flex justify-between">
                    <div class="info">
                        <div class="time font-14 font-weight">
                            {{ returnTime(item.prescribe_time)}} 减药处方
                        </div>
                        <p class="font-12">{{ patientInfo.agency_nickname || patientInfo.nickname }} {{ patientInfo.sex_value }} {{ patientInfo.age }}</p>
                    </div>
                    <div class="btn">
                        <el-button class="editBtn radius-50" @click="editDrug(item.id,item)">编辑</el-button>
                        <el-button class="deleteBtn radius-50" @click="deleteDrug(item.id)">删除</el-button>
                    </div>
                </div>
                <div class="drug flex mt-20" v-for="(items,idxs) in item.reduce_medicine_record" :key="idxs">
                    <div class="drug">
                        <p class="font-12">
                            {{items.medicine_title}}
                        </p>
                        <div class="flex align-center">
                            <h4 class="font-20 font-weight">{{ items.start_medicine_num }} <span class="font-normal font-12">{{ items.unit_title}}</span></h4>
                            <span class="block radius-5 ml-10 mr-10"></span>
                            <h4 class="font-20 font-weight">{{ items.medicine_num }} <span class="font-12 font-normal">{{ items.unit_title}}</span></h4>
                        </div>
                    </div>
                    <div class="scale ml-20">
                        <p class="font-12 text-center">减药比例</p>
                        <h4 class="font-weight font-20 text-center" :class="{'high' : items.reduce_ratio < 0}">{{ items.reduce_ratio }} <span class="font-12 font-normal">%</span></h4>
                    </div>
                </div>
                <div class="drug flex mt-20" v-for="(items,idxs) in item.reduce_insulin_record" :key="idxs">
                    <div class="drug">
                        <p class="font-12">
                            {{items.medicine_title}}
                        </p>
                        <div class="flex align-center">
                            <h4 class="font-20 font-weight">{{ items.start_medicine_num }} <span class="font-normal font-12">{{ items.unit_title}}</span></h4>
                            <span class="block radius-5 ml-10 mr-10"></span>
                            <h4 class="font-20 font-weight">{{ items.medicine_num }} <span class="font-12 font-normal">{{ items.unit_title}}</span></h4>
                        </div>
                    </div>
                    <div class="scale ml-20">
                        <p class="font-12 text-center">减药比例</p>
                        <h4 class="font-weight font-20 text-center" :class="{'high' : items.reduce_ratio < 0}">{{ items.reduce_ratio }} <span class="font-12 font-normal">%</span></h4>
                    </div>
                </div>
                <p class="mt-15 font-12 solid-bottom pb-10 mb-10">处方内容：{{item.content}}</p>
                <div class="foot">
                    <p class="font-12">处方医生： {{ item.doctor_name }}</p>
                </div>
            </div>
        </template>
        <div class="flex justify-center align-center notData" v-else>
            <div class="center-box">
                <img src="@/assets/images/error/notdata.png" alt />
                <h3 class="text-center">暂无数据</h3>
                <el-button class="radius-50 mt-20 button block auto" @click="showPrescription = !showPrescription">添加处方</el-button>
            </div>
        </div>
        <AddDrug :showPrescription="showPrescription" :dataStr="dataStr" :id="patientInfo.id" :orderID="prescriptionID" @drugClose="drugClose" @upData="upData" />
    </div>
</template>

<script>
    import { time } from '_js/Cache';
    import { prescriptionDelete } from 'quest';
    import { AddDrug } from '_c/Model/index';
    export default {
        props:{
            patientInfo: Array || Object,
            drugList: Array || Object,
            Dark: Boolean
        },
        components:{
            AddDrug
        },
        data(){
            return{
                showPrescription: false,
                prescriptionID: '',
                id: '',
                dataStr: ''
            }
        },
        methods:{
            returnTime(t){
                const date = new Date(Math.abs(t * 1000));
                return time(date,'YYYY/MM/DD')
            },
            drugClose(){
                this.showPrescription = !this.showPrescription
                this.id = '';
                this.dataStr = '';
            },
            editDrug(id,item){
                this.prescriptionID = id;
                this.showPrescription = !this.showPrescription;
                let medicationList = [],insulinList = [];
                item.medicine_record.map( item =>{
                    medicationList.push({
                        id: item.id,
                        title: item.medicine_title,
                        num: item.medicine_num,
                        unit_list: item.unit_list,
                        unit: item.unit_title,
                        unit_id: item.unit_id,
                        medicine_id: item.medicine_id
                    })
                })
                item.insulin_record.map( item =>{
                    insulinList.push({
                        id: item.id,
                        title: item.medicine_title,
                        num: item.medicine_num,
                        unit_list: item.unit_list,
                        unit: item.unit_title,
                        unit_id: item.unit_id,
                        medicine_id: item.medicine_id
                    })
                })
                let data = {
                    tips: item.note,
                    content: item.content,
                    doctor_name: item.doctor_name,
                    time: time(new Date(item.prescribe_time * 1000),'年月日'),
                    timestamp: item.prescribe_time * 1000,
                    medicationList: medicationList,
                    insulinList: insulinList,
                }
                this.dataStr = JSON.stringify(data);
            },
            deleteDrug(id){
                this.$confirm('确定要删除该处方吗？','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then( () =>{
                    prescriptionDelete({id}).then( res =>{
                        if(res.data.code == 100000){
                            this.$message.success('删除成功！');
                            this.$emit('upDataDrug')
                        }else{
                            this.$message.warning('删除失败！请联系开发人员');
                        }
                    })
                })
            },
            upData(){
                this.dataStr = '';
                this.showPrescription = !this.showPrescription;
                this.$emit('upDataDrug')
            }
        }

    }
</script>

<style lang="less" scoped>
    .drugList{
        .notData{
            width: 100%;
            height: ~'calc(100vh - 395px)';
            .button{
                background-color: #304ECE;
                border-color: #304ECE !important;
                color: white;
            }
        }
        .title{
            border-color: #f2f2f2;
            h1{
                font-size: 1.5rem;
            }
            .addDrug{
                background-color: #304ECE;
                color: white;
                &:hover,
                &:focus{
                    background-color: #304ECE;
                    border-color: #304ECE !important;
                }
            }
        }
        .list{
            border-color: #f2f2f2;
            .title{
                p{
                    color: #999999;
                }
            }
            .editBtn{
                background-color: var(--defalutColor);
                color: white;
            }
            .deleteBtn{
                color: #f2f2f2 !important;
                background-color: #c2c2c2 !important;
                border: unset !important;
            }
            .drug{
                .drug{
                    width: 200px;
                }
                p{
                    color: #999;
                }
                .flex>span{
                    width: 35px;
                    height: 1px;
                    background-color: #000;
                }
                .scale{
                    h4{
                        color: var(--defalutColor);
                        span{
                            color: #484848;
                        }
                    }
                }
            }
            >p{
                border-color: #f2f2f2;
            }
            .foot{
                color: #999999;
            }
        }
        &.dark{
            button{
                border: unset !important;
            }
            .title{
                color: #c1c1c1;
                border-color: #2c2c2e !important;
            }
            .list{
                border-color: #2c2c2e !important;
                .drug{
                    h4{
                        color: #c1c1c1;
                        span{
                            color: #7f7f7f;
                        }
                    }
                    .flex{
                        >span{
                            background-color: #c1c1c1;
                        }
                    }
                }
                >p{
                    color: #7f7f7f;
                    border-color: #2c2c2e !important;
                }
            }
            &.dark{
                .addDrug,
                .add{
                    border-color: rgba(0,0,0,0) !important;
                }
                .content{
                    .title{
                        color: #c1c1c1 !important;
                    }
                    .edit{
                        border: unset !important;
                    }
                    .tips{
                        border-color: #2c2c2e !important;
                        color: #7f7f7f !important;
                    }
                    .list{
                        border-color: #2c2c2e !important;
                        .name{
                            color: #c1c1c1 !important;
                        }
                        .present,
                        .target{
                            p{
                                color: #7f7f7f;
                            }
                        }
                        .medicationList,
                        .insulinList{
                            h4{
                                color: #c1c1c1;
                            }
                            p{
                                color: #7f7f7f;
                            }
                            h1{
                                color: #c1c1c1;
                            }
                        }
                    }
                    .stageForList{
                        .info{
                            border-color: #2c2c2e !important;
                            .title{
                                color: #494949 !important;
                            }
                            .info1{
                                .title{
                                    background-color: #b8caa6;
                                }
                            }
                            .info2{
                                .title{
                                    background-color: #a7cca0;
                                }
                            }
                            .info3{
                                .title{
                                    background-color: #b6cad3;
                                }
                            }
                            .label,
                            .date{
                                color: #c1c1c1 !important;
                            }
                        }
                        .foodPlan{
                            border-color: #2c2c2e !important;
                            color: #c1c1c1;
                        }
                        .foodList{
                            color: #c1c1c1;
                            border-color: #2c2c2e;
                            .list{
                                .num{
                                    >span{
                                        background-color: #c1c1c1;
                                    }
                                }
                            }
                        }
                        .medication{
                            .list{
                                .num{
                                    >span{
                                        background-color: #c1c1c1;
                                    }
                                }
                            }
                            .scale{
                                h1{
                                    span{
                                        color: #7f7f7f;
                                    }
                                }
                            }
                        }
                        .edit{
                            .stageType{
                                p{
                                    color: #c1c1c1;
                                }
                            }
                            button{
                                border: unset !important;
                            }
                        }
                    }
                }
                /deep/ .el-dialog{
                    background: #202025 !important;
                    border-color: #2c2c2e !important;
                    .el-dialog__body{
                        .title,
                        .el-form-item__label{
                            color: #c1c1c1;
                        }
                        input,
                        textarea{
                            background-color: unset !important;
                            border-color: #2c2c2e !important;
                        }
                        .el-input-group__append{
                            background: #0d0e10 !important;
                            border-color: #2c2c2e !important;
                        }
                        button{
                            border: unset !important;
                        }
                        .selectDays{
                            background-color: #0d0e10;
                            border-color: #2c2c2e !important;
                            &:hover{
                                background-color: #000;
                            }
                        }
                    }
                }
            }
        }
    }
</style>

<style lang="less">
    .el-message-box{
        .el-button{
            &:hover,
            &:focus{
                color: unset !important;
                border-color: var(--defalutColor) !important;
            }
        }
        .el-button--primary{
            border-color: var(--defalutColor) !important;
            background-color: var(--defalutColor) !important;
            color: white !important;
            &:hover,
            &:focus{
                color: white !important;
                background-color: var(--defalutColor) !important;
            }
        }
    }
</style>
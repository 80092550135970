<template>
    <div class="statistics" :class="[!Dark ? '' : 'dark']">
        <div v-loading="!loading">
            <div class="timeSelect mb-20" v-if="!isDetails">
                <el-button :class="[dayType == 0 ? 'on' : '' ]" @click="selectTimes(7)">近7天</el-button>
                <el-button :class="[dayType == 1 ? 'on' : '' ]" @click="selectTimes(14)">近14天</el-button>
                <el-button :class="[dayType == 2 ? 'on' : '' ]" @click="selectTimes(30)">近1个月</el-button>
                <el-button :class="[dayType == 3 ? 'on' : '' ]" class="pion-rel">
                    自定义时间
                    <el-date-picker class="pion-abs days" v-model="mon" @change="selectTimes(mon)" :append-to-body="false" :picker-options="pickerOptions" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-button>
                <el-button class="float-right" @click="getDataExcel">数据导出</el-button>
                <el-button class="float-right" @click="goPrint(statistics.allData.bloodsugar_total)">数据报告</el-button>
            </div>
            <!-- 周期详情本头部 -->
            <div class="page-header" v-if="statistics && statistics.device_info && isDetails">
                <div class="image-logo">
                    <img src="@/assets/images/Patient/source1.png" v-if="Number(statistics.device_info.source) == 1" alt />
                    <img src="@/assets/images/Patient/source2.png" v-if="Number(statistics.device_info.source) == 2" alt />
                    <img src="@/assets/images/Patient/source3.png" v-if="Number(statistics.device_info.source) == 3" alt />
                    <img src="@/assets/images/Patient/source4.png" v-if="Number(statistics.device_info.source) == 4" alt />
                    <img src="@/assets/images/Patient/source5.png" v-if="Number(statistics.device_info.source) == 6" alt />
                    监测设备：{{statistics.device_info.device_name}}
                </div>
                <div class="title">
                    {{toTime(statistics.device_info.bind_time)}}-{{toTime(statistics.device_info.end_time)}} 已佩戴{{statistics.device_info.days}}天
                </div>
                <!-- 这个空div是为了让title剧中 -->
                <div class="title chart-process">
                    <el-button class="float-right" @click="getDataExcel">数据导出</el-button>
                    <el-button class="float-right" @click="goPrint(statistics.allData.bloodsugar_total)">数据报告</el-button>
                </div>
            </div>
            
            <!-- <div class="page-header flex selectMonth" style="justify-content: center">
                <el-date-picker v-model="mon" type="month" placeholder="选择月份" :append-to-body="false" :picker-options="pickerOptions" @change="selectMon" />
            </div> -->
            
            <div class="times flex justify-between pt-15 pb-15 pl-15 pr-15 font-12 radius-5" v-if="!isDetails">
                <div class="time flex">
                    <p>{{ startDay }}</p>
                    &nbsp;&nbsp;——&nbsp;&nbsp;
                    <p>{{ endDay }}</p>
                </div>
                <div class="number flex">
                    <p>覆盖时间：{{ statistics.allData ? statistics.allData.coverage : '' }}%</p>
                    <p class="ml-20">监测次数：{{ statistics.allData ? statistics.allData.bloodsugar_total : '' }}次</p>
                </div>
            </div>
            <div class="target">
                <div class="target-value">
                    <div class="target-value_item" v-if="statistics.allData.ave_bloodsugar||statistics.allData.ave_bloodsugar == 0">
                        <div class="title">平均血糖</div>
                        <div class="number">
                            <span>
                                {{Number(statistics.allData.ave_bloodsugar).toFixed(1)}}
                                <span class="unit">mmol/L</span>
                            </span>
                        </div>
                    </div>
                    <div class="target-value_item" v-if="statistics.allData.estimate_albumen||statistics.allData.estimate_albumen == 0">
                        <div class="title">模拟糖化血红蛋白</div>
                        <div class="number">
                            <span>
                                {{Number(statistics.allData.estimate_albumen).toFixed(1)}}
                                <span class="unit">%</span>
                            </span>
                        </div>
                    </div>
                    <!-- && (statistics.allData.rate.normal || statistics.allData.rate.normal == 0) -->
                    <div class="target-value_item" v-if="statistics.allData.rate">
                        <div class="title">达标率</div>
                        <div class="number">
                            <span>
                                {{statistics.allData.rate.normal ? Number(statistics.allData.rate.normal * 100).toFixed(0) : 0}}
                                <span class="unit">%</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="target-chart" v-if="statistics">
                    <Process :tir="statistics.tir" :flag="true" :Dark="Dark" />
                </div>
            </div>
            <div class="data-chart-area" v-if="statistics.envelope && statistics.envelope != ''">
                <ChartArea :value="statistics.envelope" :user_info="statistics.user_info" />
            </div>
            <template v-else>
                <div class="notDateCharts" v-if="deviceId != 0">
                    <div class="notDate">
                        <img src="@/assets/images/error/notCharts.png" alt="">
                        <p>超过2天连续监测，才可生成分析图表</p>
                    </div>
                    <div class="line">
                        <p>00:00</p>
                        <p>04:00</p>
                        <p>08:00</p>
                        <p>12:00</p>
                        <p>16:00</p>
                        <p>20:00</p>
                        <p>24:00</p>
                    </div>
                </div>
            </template>
            <!-- <div class="cards flex justify-between mt-20">
                <div class="card pt-20 pb-20 pl-20 pr-20 solid radius-5">
                    <h3 class="title text-center">葡萄糖暴露量特写</h3>
                    <div class="infos flex justify-center">
                        <div class="left text-center pion-rel">
                            <h4 class="font-14">日间</h4>
                            <h2 class="font-14">6AM-12AM</h2>
                            <p class="font-12">mmol/L</p>
                            <h1>6.7</h1>
                            <p class="font-12">4.9-6.7</p>
                        </div>
                        <div class="center text-center pion-rel">
                            <h4 class="font-14">睡眠</h4>
                            <h2 class="font-14">12AM-6AM</h2>
                            <p class="font-12">mmol/L</p>
                            <h1>4.3</h1>
                            <p class="font-12">4.9-6.7</p>
                        </div>
                        <div class="right text-center">
                            <h4 class="font-14">全天</h4>
                            <h2 class="font-14">24小时</h2>
                            <p class="font-12">%</p>
                            <h1>20.3</h1>
                            <p class="font-12">4.9-6.7</p>
                        </div>
                    </div>
                </div>
                <div class="card pt-20 pb-20 pl-20 pr-20 solid radius-5">
                    <h3 class="title text-center">血糖波动性</h3>
                    <div class="infos flex justify-center">
                        <div class="left text-center pion-rel">
                            <h4 class="font-14">MAGE</h4>
                            <p class="font-12">mmol/L</p>
                            <h1>7.2</h1>
                            <p class="font-12">&lt;3.9</p>
                        </div>
                        <div class="center text-center pion-rel">
                            <h4 class="font-14">SD</h4>
                            <p class="font-12">mmol/L</p>
                            <h1>4.3</h1>
                            <p class="font-12">0.5-1.4</p>
                        </div>
                        <div class="right text-center">
                            <h4 class="font-14">CV</h4>
                            <p class="font-12">%</p>
                            <h1>20.3</h1>
                            <p class="font-12">19-25%</p>
                        </div>
                    </div>
                </div>
                <div class="card pt-20 pb-20 pl-20 pr-20 solid radius-5">
                    <h3 class="title text-center">血糖波动性特写</h3>
                    <div class="infos flex justify-center">
                        <div class="left text-center pion-rel">
                            <h4 class="font-14">IQR</h4>
                            <p class="font-12">mmol/L</p>
                            <h1>7.2</h1>
                            <p class="font-12">0.7-1.6</p>
                        </div>
                        <div class="center text-center pion-rel">
                            <h4 class="font-14">MODD</h4>
                            <p class="font-12">mmol/L</p>
                            <h1>0.7</h1>
                            <p class="font-12">&lt;0.83</p>
                        </div>
                        <div class="right text-center">
                            <h4 class="font-14">稳定性</h4>
                            <p class="font-12">mmol/L/hr</p>
                            <h1>1.4</h1>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="cards flex justify-between mt-20">
                <div class="card pt-20 pb-20 pl-20 pr-20 solid radius-5">
                    <h3 class="title chart text-center">时间点平均血糖</h3>
                    <div class="center flex justify-center">
                        <div class="range pion-rel pl-20">
                            <p class="font-12">&lt;&nbsp;{{statistics.user_info.target_low}}</p>
                        </div>
                        <div class="range pion-rel pl-20 ml-20">
                            <p class="font-12">{{statistics.user_info.target_low}} - {{statistics.user_info.target_high}}</p>
                        </div>
                        <div class="range pion-rel pl-20 ml-20">
                            <p class="font-12">&gt;&nbsp;{{statistics.user_info.target_high}}</p>
                        </div>
                    </div>
                    <div class="bar" v-if=" ( statistics && statistics.timepoint ) || (statistics && statistics.allData)">
                        <BarChartRow :value="isDetails ? statistics.allData.timepoint : statistics.timepoint" :high="statistics.user_info.target_high" :low="statistics.user_info.target_low" />
                    </div>
                </div>
                <div class="card pt-20 pb-20 pl-20 pr-20 solid radius-5">
                    <h3 class="title chart text-center">每日平均血糖值</h3>
                    <div class="center flex justify-center">
                        <div class="range pion-rel pl-20">
                            <p class="font-12">&lt;&nbsp;{{statistics.user_info.target_low}}</p>
                        </div>
                        <div class="range pion-rel pl-20 ml-20">
                            <p class="font-12">{{statistics.user_info.target_low}} - {{statistics.user_info.target_high}}</p>
                        </div>
                        <div class="range pion-rel pl-20 ml-20">
                            <p class="font-12">&gt;&nbsp;{{statistics.user_info.target_high}}</p>
                        </div>
                    </div>
                    <div class="bar" v-if="(statistics && statistics.average) || (statistics && statistics.allData)">
                        <BarChartLine :value="isDetails ? statistics.allData.average : statistics.average" :high="statistics.user_info.target_high" :low="statistics.user_info.target_low" />
                    </div>
                </div>
                <div class="card pt-20 pb-20 pl-20 pr-20 solid radius-5">
                    <h3 class="title chart text-center">每日波动</h3>
                    <div class="center flex justify-center">
                        <div class="range pion-rel pl-20 ml-20 none">
                            
                        </div>
                        <div class="range pion-rel pl-20 ml-20">
                            <p class="font-12">&lt;=&nbsp;{{statistics.user_info.fluctuation}}</p>
                        </div>
                        <div class="range pion-rel pl-20 ml-20">
                            <p class="font-12">&gt;&nbsp;{{statistics.user_info.fluctuation}}</p>
                        </div>
                    </div>
                    <div class="bar" v-if="(statistics && statistics.wave) || (statistics && statistics.allData)">
                        <BarChartLine :value="isDetails ? statistics.allData.wave : statistics.wave" :type="'wave'" :range="statistics.user_info.fluctuation" />
                    </div>
                </div>
            </div>
            <div class="data-card">
                <div class="card-box" v-for="(itx, i) in 0" :key="i+ 100">
                    <div class="title">葡萄糖暴露量特写</div>
                    <div class="content">
                        <div class="con-item" v-for="(item, index) in 3" :key="index">
                            <div class="item-title-one">日间</div>
                            <div class="item-title-two">6AM-12AM</div>
                            <div class="item-title-unit">mmol/L</div>
                            <div class="item-title-number">6.7</div>
                            <div class="item-title-range">4.9-6.7</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="data-tir" v-if="statistics.day_tir && statistics.day_tir.length != 0">
                <div class="title">每日TIR缩略图</div>
                <div class="tir-card-box">
                    <div class="tir-card-item" v-for="(item, index) in statistics.day_tir" :key="index">
                        <div class="item-left">
                            <div class="date">{{item.date}}</div>
                            <div class="qualified">
                                <div class="standard">达标率</div>
                                <div class="number" v-if="item.common">{{item.common * 100 | tofixed}}%</div>
                                <div class="number" v-else>--</div>
                            </div>
                        </div>
                        <div class="tir-chart">
                            <Process :tir="item" :Dark="Dark" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart-big" v-if="statistics && statistics.allData.multi_line_x_arr">
                <div class="title">多日曲线</div>
                <ChartBig :x_arr="statistics.allData.multi_line_x_arr" :value="statistics.day_line" />
            </div>
            <div class="analysis" v-if="statistics.food||statistics.sports">
                <div class="analysis-box">
                    <div class="analysis-header">
                        <div class="header-left">饮食分析</div>
                    </div>
                    <div class="content" v-if="1">
                        <div class="analysis-title">
                            本周期已记录饮食
                            <span class="important">{{statistics.food.times||0}}</span> 次
                        </div>
                        <FoodLine :value="statistics.food.list" />
                    </div>
                    <div class="content" v-else>
                        <div class="empty">
                            <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                            <img src="@/assets/images/error/dark_empty.png" v-else alt />
                            <p>暂无数据</p>
                        </div>
                    </div>
                </div>
                <div class="analysis-box">
                    <div class="analysis-header">
                        <div class="header-left">用药分析</div>
                    </div>
                    <div class="content" v-if="0">
                        <!-- <ChartLine /> -->
                    </div>
                    <div class="content" v-else>
                        <div class="empty">
                        <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                        <img src="@/assets/images/error/dark_empty.png" v-else alt />
                        <p>暂无数据</p>
                        </div>
                    </div>
                </div>
                <div class="analysis-box">
                    <div class="analysis-header">
                        <div class="header-left">运动分析</div>
                    </div>
                    <div class="analysis-title">
                        本周期已记录运动
                        <span class="important">{{statistics.allData.sports.times||0}}</span> 次
                    </div>
                    <div class="content" v-if="statistics.sports.list&&statistics.sports.list.length">
                        <ChartBing :value="statistics.sports.list"></ChartBing>
                    </div>
                    <div class="content" v-else>
                        <div class="empty">
                            <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                            <img src="@/assets/images/error/dark_empty.png" v-else alt />
                            <p>暂无数据</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hot-chart" v-if="eight">
                <div class="title">八点血糖</div>
                <div class="hot-box">
                    <div class="eightPoint">
                        <div class="left">
                            <div class="fixedbar">
                                <div class="item" v-for="(item,index) in textList" :key="index">{{item}}</div>
                            </div>
                            <div class="scrollBox">
                                <div class="item" v-for="(item,index) in data.val1" :key="index">
                                    <div class="date">{{item.date}}</div>
                                    <div class="block" v-for="(i,index) in item.list" :key="index">
                                        <div v-if="!i.blood_sugar" class="content grey">{{i.blood_sugar || '/'}}</div>
                                        <div v-else class="content font-14" :class="i.target_level == 0 ? 'green' : (i.target_level > 0 ? 'purple' : 'red' )">{{i.blood_sugar}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <div class="fixedbar">
                                <div class="item" v-for="(item,index) in textList" :key="index">{{item}}</div>
                            </div>
                            <div class="scrollBox">
                                <div class="item" v-for="(item,index) in data.val2" :key="index">
                                    <div class="date">{{item.date}}</div>
                                    <div class="block" v-for="(i,index) in item.list" :key="index">
                                        <div v-if="!i.blood_sugar" class="content grey">{{i.blood_sugar || '/'}}</div>
                                        <div v-else class="content font-14" :class="i.target_level == 0 ? 'green' : (i.target_level > 0 ? 'purple' : 'red' )">{{i.blood_sugar}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Process } from '_c/Model/index';
    import { ChartArea, ChartBig, FoodLine, ChartBing, BarChartRow, BarChartLine } from '_c/Charts/index';
    import { time } from '_js/Cache';
    export default {
        components:{
            Process,
            ChartArea,
            ChartBig,
            FoodLine,
            ChartBing,
            BarChartRow,
            BarChartLine
        },
        props:{
            statistics: Object,
            eight: Array,
            deviceId: [String,Number],
            Dark: Boolean,
            Tab: String
        },

        watch:{
            eight:{
                handler(val){
                    if(val != null && val != 'null' ){
                        this.splitTwoDate(val);
                    }
                },
                deep: true,
            },
            'Tab':{
                handler(){
                    this.dayType = 0;
                },
                deep: true
            }
        },

        data(){
            return{
                loading: false,
                textList:['凌晨','空腹','早餐','早2','午餐','午2','晚餐','晚2','睡前'],
                data:{
                    val1:[],
                    val2:[],
                },
                dayType: 0,
                mon: new Date(),
                pickerMinDate:'',
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.pickerMinDate = minDate.getTime();
                        if (maxDate) {
                            this.pickerMinDate = '';
                        }
                    },
                    disabledDate: time => {
                        if (this.pickerMinDate != '') {
                            const day30 = (31 - 1) * 24 * 3600 * 1000;
                            // 只选后30天，minTime不用加day30,只选前30天，maxTime不用加day30
                            let maxTime = this.pickerMinDate + day30;
                            let minTime = this.pickerMinDate - day30;
                            if (maxTime > new Date()) {
                                maxTime = new Date();
                            }
                            return time.getTime() > maxTime || time.getTime() < minTime;
                        }
                        return time.getTime() > Date.now();
                    }
                },
                startDay: time( new Date(new Date().getTime() -  6 * 24 * 3600 * 1000),  'YYYY-MM-DD'),
                endDay: time(new Date(), 'YYYY-MM-DD'),
                isDetails: this.$route.query.new_page,
            }
        },
        filters:{
            tofixed(num){
                return Math.floor(num)
            }
        },
        methods:{
            toTime(temp){
                let date,
                    m,
                    d,
                    time;
                
                if(temp){
                    date = new Date(temp * 1000);
                    m = ( date.getMonth() + 1 ) < 10 ? '0' + (date.getMonth() + 1 ) : (date.getMonth() + 1),
                    d = (date.getDate()) < 10 ? '0' + date.getDate() : date.getDate(); 
                }else{
                    date = new Date();
                    m = ( date.getMonth() + 1 ) < 10 ? '0' + (date.getMonth() + 1 ) : (date.getMonth() + 1),
                    d = (date.getDate()) < 10 ? '0' + date.getDate() : date.getDate();
                }
                time = m + '月' + d + '日' ;
                return time;
            },
            splitTwoDate(val){
                this.data = {
                    val1:[],
                    val2:[],
                };
                let data = this.data;
                val.map( (item, index) => {
                    if(index % 2 == 0 ){
                        data.val1.push(item);
                    }else{
                        data.val2.push(item);
                    }
                })
                this.data = data,
                this.loading = true;
            },
            selectMon(val){
                this.$emit('selectMonth',val);
            },
            selectTimes(days){
                let start,end;
                switch(days){
                    case 7:
                        start = time( new Date(new Date().getTime() -  6 * 24 * 3600 * 1000),  'YYYY-MM-DD'),
                        end = time(new Date(), 'YYYY-MM-DD');
                        this.dayType = 0;
                        this.$emit('selectTimes',start,end);
                    break;
                    case 14:
                        start = time( new Date(new Date().getTime() -  13 * 24 * 3600 * 1000),  'YYYY-MM-DD'),
                        end = time(new Date(), 'YYYY-MM-DD');
                        this.dayType = 1;
                        this.$emit('selectTimes',start,end);
                    break;
                    case 30:
                        start = time( new Date(new Date().getTime() -  29 * 24 * 3600 * 1000),  'YYYY-MM-DD'),
                        end = time(new Date(), 'YYYY-MM-DD');
                        this.dayType = 2;
                        this.$emit('selectTimes',start,end);
                    break;
                    default:
                        start = time(days[0], 'YYYY-MM-DD'),
                        end = time(days[1], 'YYYY-MM-DD');
                        this.dayType = 3;
                        this.$emit('selectTimes',start,end);
                    break;
                }
                [this.startDay,this.endDay] = [start,end];
                this.loading = false;
            },

            goPrint(total){
                let data = this.isDetails ? {
                    id: this.$route.query.id,
                    from: 'hospital',
                    token: this.statistics.user_info.token,
                } : {
                    uid: this.$route.query.id,
                    from: 'hospital',
                    token: this.statistics.user_info.token,
                    start_day: this.startDay,
                    end_day: this.endDay
                };
                const { href } = this.$router.resolve({
                    path: total > 864 ? "/report" : '/report/notData',
                    [total > 864 ? 'query' : '']: total > 0 ? data : ''
                });
                window.open(href, '_blank');
            },
            getDataExcel(){
                this.$emit('getDataExcel', this.startDay, this.endDay);
            }
        }
    }
</script>

<style scoped lang="less">
    .size {
        width: 100%;
        height: 100%;
    }

    .empty {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        text-align: center;

        img {
            width: 50px;
        }

        p {
            width: 100%;
            font-size: 14px;
            line-height: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 500;
            color: #b8b8b8;
        }
    }

    .timeSelect{
        & /deep/ .el-button{
            font-size: 13px;
            font-weight: unset;
            width: 125px;
            background: #eaedf1;
            color: #9F9F9F;
            border: unset;
            border-radius: 50px;
            &.on{
                background: var(--defalutActiveColor) !important;
                color: white !important;
            }
            &:hover{
                background: var(--defalutActiveColor);
                color: var(--white) !important;
            }
            &:focus{
                background: var(--defalutActiveColor);
                color: var(--white) !important;
            }
        }
        & /deep/ .days{
            width: 125px;
            height: 40px;
            top: 0px;
            left: 0px;
            cursor: pointer;
            background: unset;
            border: unset;

            & > i,
            & > span,
            & > input{
                opacity: 0;
                cursor: pointer;
            }
            .el-picker-panel{
                margin-left: -260px;
                .popper__arrow{
                    left: 315px !important;
                }
            }
        }

        & /deep/ .el-date-editor{
            .available{
                &.today{
                    span{
                        color: var(--defalutColor);
                    }
                }
                &.in-range{
                    &.start-date,
                    &.end-date{
                        span{
                            background-color: var(--defalutColor);
                            color: white;
                        }
                    }
                }
                &:hover{
                    color: var(--defalutColor);
                }
            }
        }
    }

    .times{
        background: #f7f7f7;
        color: #242424;
    }

    .page-header {
        width: 100%;
        height: 43px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #f7f7f7;
        border-radius: 4px;

        &.selectMonth /deep/ .el-date-editor{
            width: 135px;
            input{
                background: unset;
                border: none;
                font-size: 18px;
                &::placeholder{
                    font-size: 18px;
                }
            }
        }

        .image-logo {
            margin-right: 10px;
            margin-left: 5px;
            display: flex;
            align-items: center;

            img {
                width: 28px;
                height: 28px;
                margin-right: 10px;
            }
        }

        .title {
            margin-right: 20px;
            font-size: 12px;
        }

        .chart-process {
            .float-right{
                font-size: 12px;
                font-weight: normal;
                padding: 8px 15px;
                background: none;
                border: none;
                margin: 0px;
                background-color: var(--defalutColor);
                color: white !important;
                border-radius: 50px;
                transition: .3s;
                &:nth-child(2){
                    margin-right: 10px;
                }
                &:hover,
                &:active{
                    transition: .3s;
                    background-color: var(--defalutActiveColor);
                    color: white !important;
                }
            }
        }
    }

    .target {
        width: 100%;
        display: flex;
        margin-bottom: 40px;

        .target-value {
            flex: 1;
            display: flex;
            justify-content: space-between;

            .target-value_item {
                flex: 1;
                text-align: center;
                display: flex;
                align-content: center;
                justify-content: center;
                flex-wrap: wrap;

                .title {
                    width: 100%;
                    font-size: 13px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #242424;
                    line-height: 18px;
                    margin-top: 20px;
                }

                .number {
                    font-size: 39px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 700;
                    color: #1f2e4d;
                    line-height: 20px;
                    margin-top: 20px;
                    text-align: center;
                }

                .unit {
                    font-size: 13px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #1f2e4d;
                    line-height: 18px;
                }
            }
        }

        .target-chart {
            width: 33%;
            height: 140px;
            margin-top: 30px;
        }
    }

    .data-chart-area {
        width: 100%;
        height: 300px;

        .charts {
            height: 100%;
        }
    }

    .notDateCharts{
        background: #F5F5F5;
        height: 100%;
        width: 100%;
        padding: 15px;

        .tip-line{
            text-align: right;
            padding-bottom: 5px;
            border-bottom: 1px solid #ededed;
            color: #a4a4a4;
        }

        .notDate{

            img{
                display: block;
                margin: 0px auto;
            }

            p{
                color: #464646;
                font-size: 14px;
                text-align: center;
            }
        }

        .line{
            padding-top: 15px;
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #ededed;
            color: #a4a4a4;
            font-size: 12px;
        }
    }

    .cards{
        .card{
            flex: 1;
            border-color: #e9ecf2;
            margin-right: 28px;
            &:last-child{
                margin-right: 0px;
            }
            .title{
                padding-bottom: 40px;
                &.chart{
                    padding-bottom: 5px;
                }
            }
            .infos{
                div{
                    width: 32%;
                    h2{
                        margin-top: -3px;
                    }
                    p{
                        color: #AAA;
                    }
                    &:nth-child(-n + 2){
                        &::after{
                            content: '';
                            position: absolute;
                            width: 1px;
                            height: 100%;
                            top: 0px;
                            right: 0px;
                            background: #E6E9F0;
                        }
                    }
                }
            }
            .center{
                .range{
                    &::after{
                        content: '';
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        border-radius: 10px;
                        top: ~'calc(50% - 4px)';
                        left: 5px;
                    }
                    &:nth-of-type(1)::after{
                        background: var(--bloodSugarLow);
                    }
                    &:nth-of-type(2)::after{
                        background: var(--defalutColor);
                    }
                    &:nth-of-type(3)::after{
                        background: var(--bloodSugarHigh);
                    }
                }
            }
            .bar{
                width: 100%;
                height: 150px;
            }

        }
    }

    .data-card {
        display: flex;
        margin-top: 40px;

        .title {
            font-size: 13px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #242424;
            line-height: 18px;
            margin-bottom: 20px;
        }

        .card-box {
            flex: 1;
            margin-right: 30px;
            background: #ffffff;
            border-radius: 7px;
            border: 1px solid #e6e9f0;
            text-align: center;
            padding: 10px;
        }

        .card-box:last-child {
            margin-right: 0px;
        }
    }

    .content {
        display: flex;
        flex-wrap: wrap;

        .con-item {
            flex: 1;
            border-right: 1px solid #e6e9f0;

            .item-title-one,
            .item-title-two {
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #242424;
                line-height: 19px;
            }

            .item-title-unit {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #aaaaaa;
                line-height: 13px;
            }

            .item-title-number {
                font-size: 22px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #242424;
                line-height: 32px;
            }

            .item-title-range {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #aaaaaa;
                line-height: 13px;
            }
        }

        .con-item:last-child {
            border: 0px;
        }
    }

    .data-tir {
        background: #ffffff;
        border-radius: 7px;
        border: 1px solid #e6e9f0;
        padding: 16px;
        margin-top: 18px;

        .title {
            width: 100%;
            text-align: center;
            font-size: 13px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #242424;
            line-height: 18px;
        }

        .tir-card-box {
            width: calc(~"100%");
            display: flex;
            // justify-content: space-around;
            flex-wrap: wrap;

            .tir-card-item {
                display: flex;
                width: 140px;
                height: 100px;
                border: 1px solid #ececec;
                padding: 5px 7px 10px;
                justify-content: space-between;
                margin-right: 16px;
                margin-top: 16px;
            }

            .tir-chart {
                flex: 1;
            }

            .item-left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-right: 10px;

                .item-left {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 17px;
                }

                .standard {
                    font-size: 10px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #1f2e4d;
                    line-height: 22px;
                }

                .number {
                    font-size: 15px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #1f2e4d;
                    line-height: 14px;
                }
            }
        }
    }

    .chart-big {
        width: 100%;
        height: 420px;
        margin-top: 10px;
        border-radius: 11px;
        border: 1px solid #e6e9f0;
        padding: 20px;

        .charts {
            width: 100%;
            height: 340px;
        }

        .title {
            font-size: 27px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #242424;
            height: 37px;
            line-height: 37px;
        }
    }

    .analysis {
        width: 100%;
        margin-top: 20px;
        display: flex;

        .analysis-box {
            flex: 1;
            border-radius: 11px;
            border: 1px solid #e6e9f0;
            padding: 20px;
            margin-right: 28px;

            .analysis-header {
                display: flex;
                justify-content: space-between;
                height: 40px;
                line-height: 40px;

                .header-left {
                    font-size: 21px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #242424;
                }

                .header-icon {
                    color: #afafaf;
                    font-size: 30px;
                    line-height: 40px;
                }
            }

            .analysis-title {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #aaaaaa;
                line-height: 13px;

                .important {
                    color: #16cca6;
                }
            }

            .content {
                width: 100%;
                height: 150px;
                margin-top: 10px;

                .charts {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .analysis-box:last-child {
            margin: 0;
        }
    }

    .data-analy {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .number {
            display: flex;
            align-items: center;

            .big {
                font-size: 64px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #242424;
                line-height: 89px;
                margin-right: 12px;
            }

            .unit {
                .arrow {
                    font-size: 32px;
                    color: #242424;
                    text-align: center;
                }

                .unit-s {
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #9f9f9f;
                    line-height: 23px;
                    position: relative;
                    top: -10px;
                }
            }
        }

        .classify {
            display: flex;

            .item {
                width: 100px;
                margin-left: 30px;
                text-align: center;

                .icon-title {
                    display: flex;
                    height: 30px;
                    align-items: center;
                    justify-content: center;

                    span {
                        display: inline-block;
                        margin-left: 3px;
                    }
                }

                .sum {
                    width: 100%;
                    text-align: center;
                    font-size: 22px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #242424;
                    line-height: 32px;
                }
            }
        }
    }

    .hot-chart {
        width: 100%;
        border-radius: 11px;
        border: 1px solid #e6e9f0;
        padding: 20px;
        margin-top: 20px;

        .title {
            width: 100%;
            font-size: 21px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #343434;
            line-height: 29px;
            text-align: center;
        }

        .hot-box {
            width: 100%;
            margin-top: 20px;

            .charts {
                width: 100%;
                height: 100%;
            }
        }
    }

    .eightPoint {
        width: 100%;
        display: flex;

        .left {
            width: 50%;
        }

        .right {
            width: 50%;
        }

        .fixedbar {
            box-sizing: border-box;
            padding-left: 60px;
            height: 35px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            .item {
                width: 55px;
                height: 35px;
                line-height: 35px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #242424;
            }
        }

        .scrollBox {
            display: flex;
            flex-wrap: wrap;

            .item {
                width: 100%;
                height: 35px;
                flex-shrink: 0;
                display: flex;
                justify-content: center;
                margin-top: 8px;

                .date {
                    width: 55px;
                    height: 35px;
                    flex-shrink: 0;
                    text-align: center;
                    font-size: 14px;
                    line-height: 35px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #242424;
                }

                .block {
                    width: 55px;
                    height: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .content {
                        width: 35px;
                        height: 35px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: #16cca6;
                        border-radius: 4px;
                        color: #fff;
                    }

                    .red {
                        background: #ED1B24;
                    }

                    .purple {
                        background: #FFA901;
                    }

                    .grey {
                        background: #E6E9F0;
                    }
                }
            }
        }
    }

    .statistics{
        &.dark{

            .page-header{
                background: #0c0c0c;
                &.selectMonth /deep/ .el-date-editor{
                    input{
                        color: #c1c1c1 !important;
                    }
                    .el-picker-panel{
                        background: #202025;
                        border-color: #0c0c0c;
                        .el-picker-panel__icon-btn,
                        .el-date-picker__header-label{
                            color: #c1c1c1;
                        }
                        .el-date-picker__header{
                            border-color: #0c0c0c;
                        }
                        .available{
                            .cell{
                                color: #c1c1c1;
                            }
                            &:hover{
                                .cell{
                                    color: var(--defalutActiveColor);
                                }
                            }
                            &.current.today{
                                .cell{
                                    color: var(--defalutActiveColor);
                                }
                            }
                            &.disabled{
                                .cell{
                                    background: #0c0c0c;
                                    color: #7f7f7f;
                                }
                                &:hover{
                                    color: #7f7f7f;
                                }
                            }
                        }
                        .el-month-table{
                            .cell{
                                color: #c1c1c1;
                                &:hover{
                                    color: var(--defalutActiveColor);
                                }
                            }
                            .current.today{
                                .cell{
                                    color: var(--defalutActiveColor);   
                                }
                            }
                            .disabled{
                                .cell{
                                    background: #0c0c0c;
                                    color: #7f7f7f;
                                }
                                &:hover{
                                    color: #7f7f7f;
                                }
                            }
                        }
                    }
                }
                .image-logo{
                    color: #c1c1c1;
                }
                .title{
                    color: #c1c1c1;
                }
                
            }
            .timeSelect{
                .el-button{
                    background: #0c0c0c;
                    color: #c1c1c1;
                    & /deep/ .el-date-editor{
                        input{
                            color: #c1c1c1 !important;
                        }
                        .el-picker-panel{
                            background: #202025;
                            border-color: #0c0c0c;
                            .el-picker-panel__icon-btn,
                            .el-date-picker__header-label{
                                color: #c1c1c1;
                            }
                            .el-date-picker__header{
                                border-color: #0c0c0c;
                            }
                            .el-date-table{
                                th{
                                    border-color: #0c0c0c;
                                }
                            }
                            .el-date-table__row{
                                .prev-month{
                                    span{
                                        color: #7f7f7f;
                                    }
                                }
                                .available{
                                    &.in-range{
                                        &.start-date,
                                        &.end-date{
                                            span{
                                                background-color: var(--defalutActiveColor);
                                                color: #0c0c0c;
                                            }
                                        }
                                        div{
                                            background-color: #4b5f7e;
                                        }
                                    }
                                    span{
                                        color: #c1c1c1;
                                    }
                                }
                                .normal.disabled,
                                .next-month.disabled,
                                .prev-month.disabled{
                                    div{
                                        background: #0c0c0c;
                                        color: #7f7f7f;
                                            &:hover{
                                                color: #7f7f7f;
                                            }
                                        }
                                }
                            }
                            .el-date-range-picker__content.is-left{
                                border-color: #0c0c0c;
                            }
                            .popper__arrow{
                                border-bottom-color: #0c0c0c;
                                &::after{
                                    border-bottom-color: #0c0c0c;
                                }
                            }
                        }
                    }
                }
            }
            .times{
                background: #0c0c0c;
                color: #c1c1c1;
                p{
                    color: #c1c1c1;
                }
            }
            .target-value_item{
                .title{
                    color: #7f7f7f !important;
                }
            }
            .tir-card-item{
                .date{
                    color: #c1c1c1 !important;
                }
                .standard{
                    color: #c1c1c1 !important;
                }
            }
            .cards{
                .card{
                    border-color: #2c2c2e;
                    color: #c1c1c1;
                    .infos{
                        color: #c1c1c1;
                        div:nth-child(-n + 2)::after{
                            background: #2c2c2e;
                        }
                    }
                }
            }
            .chart-big{
                border-color: #2c2c2e !important;
                .title{
                    color: #c1c1c1 !important;
                }
            }
            .notDateCharts{
                background: #0c0c0c !important;
                p{
                    color: #c1c1c1 !important;
                }
                .line{
                    color: #c1c1c1 !important;
                    border-color: #2c2c2e;
                }
            }
            .number,
            .unit{
                color: #c1c1c1 !important;
            }
            .hot-chart{
                border-color: #2c2c2e !important;
                .fixedbar{
                    .item{
                        color: #c1c1c1;
                    }
                }
                .title{
                    color: #c1c1c1;
                }
            }
            .scrollBox{
                .date{
                    color: #c1c1c1 !important;
                }
                .grey{
                    background: #0c0c0c !important;
                    color: #717171 !important;
                }
            }
            .analysis-box{
                border-color: #2c2c2e !important;
            }
            .data-tir{
                background: unset !important;
                border-color: #2c2c2e !important;
                .title{
                    color: #c1c1c1 !important;
                }
                .tir-card-item{
                    border-color: #2c2c2e !important;
                }
            }
            .analysis{
                .header-left{
                    color: #c1c1c1 !important;
                }
                .analysis-title{
                    color: #c1c1c1 !important;
                    span{
                        color: #0f947d !important;
                    }
                }
            }
        }
    }

</style>
<template>
    <div class="print">
        <div class="loading pion-fixed flex justify-center align-center" v-if="loading">
            <div class="loading-box" v-loading="loading">
                <p class="loading-text pion-abs text-center">{{$t('menu.list.Print.loading')}}</p>
            </div>
        </div>
        <div class="content pion-fixed" v-else>
            <div class="printing" v-loading="download" :style="{ 'overflow-y': !download ? 'scroll' : 'hidden', 'transform' : !download ? transform : '', 'height': !download ? height + '%' : '' }">
                <div class="A4-page">

                    <Home :download="download" :userInfo="user_info" :bindTime="bind_time" :endTime="end_time"/>

                    <PrintCycle 
                        :download="download"
                        :bindTime="bind_time"
                        :endTime="end_time"
                        :days="Number(days)"
                        :coverage="Number(coverage)"
                        :deviceBloodSugarTotal="device_bloodsugar_total"
                        :avgBloodSugar="Number(avg_bloodsugar)"
                        :estimateAlbumen="Number(estimate_albumen)"
                        :LAGE="Number( LAGE )"
                        :SD="Number(SD)"
                        :CV="Number(CV)"
                        :TIR="tir"
                        :dayAvgInsulin="Number(day_avg_insulin)"
                        :dayAvgLongInsulin="Number(day_avg_long_insulin)"
                        :dayAvgFoodInsulin="Number(day_avg_food_insulin)"
                        :dayAvgCarb="Number(day_avg_carb)"
                        :dayAvgCalorie="Number(day_avg_calorie)"
                        :envelope="envelope"
                        :userInfo="user_info"
                        :daysData="days_data"
                        :toDay="toDay"
                        :isDefaultUnit="isDefaultUnit"
                        :lang="lang"
                        :foodRecordRankLen="food_record_rank.length || 0"
                    />

                    <LineBox
                        :download="download"
                        :bindTime="bind_time"
                        :endTime="end_time"
                        :days="Number(days)"
                        :coverage="Number(coverage)"
                        :deviceBloodSugarTotal="device_bloodsugar_total"
                        :daysData="days_data"
                        :everyDaysDate="everyDaysDate"
                        :multiLineXArr="multi_line_x_arr"
                        :colorList="colorList"
                        :userInfo="user_info"
                        :BarLineVal="BarLineVal"
                        :toDay="toDay"
                        :foodRecordRankLen="food_record_rank.length || 0"
                        :isDefaultUnit="isDefaultUnit"
                        :lang="lang"
                        v-if="!cant"
                    />
                    <template  v-if="user_info.is_vip > 0 ">
                        <TIRAGP
                            :download="download"
                            :bindTime="bind_time"
                            :endTime="end_time"
                            :days="Number(days)"
                            :coverage="Number(coverage)"
                            :deviceBloodSugarTotal="device_bloodsugar_total"
                            :daysData="days_data"
                            :userInfo="user_info"
                            :toDay="toDay"
                            :lineMax="Number(lineMax)"
                            :foodRecordRankLen="food_record_rank.length || 0"
                            :isDefaultUnit="isDefaultUnit"
                            :lang="lang"
                            v-if="days > 8"
                        />

                        <Continuous
                            :download="download"
                            :bindTime="bind_time"
                            :endTime="end_time"
                            :days="Number(days)"
                            :coverage="Number(coverage)"
                            :deviceBloodSugarTotal="device_bloodsugar_total"
                            :daysData="days_data"
                            :userInfo="user_info"
                            :toDay="toDay"
                            :lineMax="Number(lineMax)"
                            :foodRecordRankLen="food_record_rank.length || 0"
                            :isDefaultUnit="isDefaultUnit"
                            :lang="lang"
                            v-if="days <= 8"
                        />

                        <Night
                            :download="download"
                            :bindTime="bind_time"
                            :endTime="end_time"
                            :days="Number(days)"
                            :coverage="Number(coverage)"
                            :deviceBloodSugarTotal="device_bloodsugar_total"
                            :toDay="toDay"
                            :nightDayData="nightDayData"
                            :nightChartValue="nightChartValue"
                            :nightLineX_Arr="nightLineX_arr"
                            :colorList="colorList"
                            :userInfo="user_info"
                            :foodRecordRankLen="food_record_rank.length || 0"
                            :daysLen="days_data.length || 0"
                            :isDefaultUnit="isDefaultUnit"
                            :lang="lang"
                            v-if="days < 16 && days > 8"
                        />

                        <ManyDay
                            :download="download"
                            :bindTime="bind_time"
                            :endTime="end_time"
                            :days="Number(days)"
                            :coverage="Number(coverage)"
                            :deviceBloodSugarTotal="device_bloodsugar_total"
                            :daysData="days_data"
                            :toDay="toDay"
                            :foodRecordRankLen="food_record_rank.length || 0"
                            :isDefaultUnit="isDefaultUnit"
                            :lang="lang"
                            :daysLen="days_data.length || 0"
                        />
                    </template>
                </div>
                <p class="printIngText pion-fixed text-center" v-if="download"><span>{{$t('menu.list.Print.wait')}}</span><br />{{ index }}</p>
            </div>
        </div>
        <!--  v-if="AppVersion != '' && AppVersion >= '4.6.0' " -->
        <div class="page-button pion-fixed">
            <div class="operate-button">
                <el-button class="pion-abs radius-50" @click="downLoadPDF" v-if="!download && Number(device_bloodsugar_total) > 864" :disabled="loading">{{$t('menu.list.Print.button')}}</el-button>
            </div>
        </div>
    </div>
</template>

<script>

    import {
        Home,
        PrintCycle,
        LineBox,
        TIRAGP,
        Continuous,
        Night,
        ManyDay
    } from './page/Mobile/index';

    import printPDF from '_js/mobile-toPDF.js';

    import { getReportDate } from "quest";

    import { deviceType, time, saveFileToPhone, setItem, getItem } from '_js/Cache';

    export default {
        components: {
            Home,
            PrintCycle,
            LineBox,
            TIRAGP,
            Continuous,
            Night,
            ManyDay,
        },
        data(){
            return{
                loading: true,
                download: false,
                bind_time: '',
                end_time: '',
                days: '',
                coverage: '',
                device_bloodsugar_total: '',
                id: 0,
                avg_bloodsugar: 0,
                estimate_albumen: 0,
                LAGE: 0,
                SD: 0,
                CV: 0,
                tir: {},
                day_avg_insulin: 0,
                day_avg_long_insulin: 0,
                day_avg_food_insulin: 0,
                day_avg_carb: 0,
                day_avg_calorie: 0,
                envelope: null,
                user_info: null,
                toDay:'',
                columns: [
                    {
                        title: 0,
                        slot: 'Sun',
                        align: "center",
                        chartLine:[]
                    },
                    {
                        title: 1,
                        slot:'Mon',
                        align: "center",
                        chartLine:[]
                    },
                    {
                        title: 2,
                        slot: 'Tue',
                        align: "center",
                        chartLine:[]
                    },
                    {
                        title: 3,
                        slot: 'Wed',
                        align: "center",
                        chartLine:[]
                    },
                    {
                        title: 4,
                        slot: 'Thu',
                        align: "center",
                        chartLine:[]
                    },
                    {
                        title: 5,
                        slot:'Fri',
                        align: "center",
                        chartLine:[]
                    },
                    {
                        title: 6,
                        slot: 'Sat',
                        align: "center",
                        chartLine:[]
                    }
                ],
                weekLineData: [],
                everyDaysDate:{
                    value: [],
                    x_arr: ['00:00','02:00','04:00','08:00','10:00','12:00','14:00','16:00','18:00','20:00','22:00','24:00']
                },
                days_data: {},
                manyDays: [],
                food_record_rank:[],
                // boxData: [],
                BarLineVal: null,
                colorList:['#A4C400','#6A00FF','#FA6800','#76608A','#AA00FF','#F0A30A','#A0522D','#008A00','#F472D0','#00ABA9','#1BA1E2','#A20025','#6D8764','#0050EF', '#17CCA6','#D4FF00','#5100C2','#FE8026','#C093EA','#520976','#BE820B','#F37134','#00F700','#F949C7','#02F2EE','#00AAFF','#F81F52','#8FF16E','#0239A7','#00FEC7','#A05017'],
                multi_line_x_arr:[],
                nightDayData: [],
                nightChartValue: [],
                nightLineX_arr:[],
                lineMax: 0,
                index: '',
                mobile: '',
                cant: false,
                transform: '',
                height: '',
                AppVersion: '',
                isDefaultUnit: true,
                lang: this.$route.query.language == 3 ? 'en' : this.$route.query.language == 2 ? 'tw' : 'zh'
            }
        },
        methods:{
            downLoadPDF(){
                let box = document.getElementsByClassName('printing')[0],
                    name = this.user_info.agency_nickname ? this.user_info.agency_nickname : this.user_info.nickname,
                    start = time(new Date(this.bind_time * 1000),'YYYY-MM-DD'),
                    end = time(new Date(this.end_time * 1000),'YYYY-MM-DD'),
                    fileData = {
                        name: name + ' ' + start + '—' + end + ' 血糖报告.pdf',
                        file: null
                    };
                box.scrollTo(0, 0);
                if(this.user_info.is_vip == 0){
                    this.cant = true;
                }
                setTimeout( () => {
                    const dome = document.getElementsByClassName('A4-page');
                    let FileName = fileData.name;
                    if(this.download){
                        this.$message({
                            message: this.$t('menu.list.Print.building'),
                            type: 'warning'
                        });
                    }else{
                        this.download = true;
                        printPDF.printPDF(dome[0], FileName,(file64) =>{
                            this.download = false;
                            this.cant = false;
                            fileData.file = file64,
                            fileData.fileLen = file64.length;
                            this.index = '';
                            saveFileToPhone('printBloodSugarReport',JSON.stringify(fileData));
                        }, (idx) =>{
                            typeof idx == 'number' ? (this.lang == 'en' ? this.index = this.$t('menu.list.Print.exporting') : this.index = this.$t('menu.list.Print.exporting') + idx + this.$t('menu.list.Print.exported')) : this.index = this.$t('menu.list.Print.merge');
                        });
                    }
                },1000)
            },
            getReportDate(id,report_sign,token,start,end){
                let data = {
                    [ this.$route.query.uid != '' && this.$route.query.uid != 'undefined' && this.$route.query.uid != undefined ? 'uid' : 'device_id' ]:id,
                    report_sign,
                    token,
                    start_day: start,
                    end_day: end
                }
                getReportDate(data).then( res =>{
                    let data = res.data,
                        report = res.data.data;
                    if( data.code == 100000 ){
                        // 每页报告公共数据处理
                        /*
                            起始时间
                            结束时间
                            总天数
                            覆盖时间
                            检测次数
                            底部ID编号
                        */

                        this.bind_time = report.device_info ? report.device_info.bind_time : new Date(start).getTime() / 1000,
                        this.end_time = report.device_info ? report.device_info.end_time : new Date(end).getTime() / 1000,
                        this.days = report.device_info ? report.device_info.days : (new Date(end).getTime() - new Date(start).getTime()) / 1000 / 24 / 3600 + 1,
                        this.coverage = report.coverage,
                        this.device_bloodsugar_total = report.device_bloodsugar_total,
                        this.id = report.device_info ? report.device_info.id : '';

                        // 第一页数据

                        /*
                            平均血糖值
                            预告糖化血红蛋白

                            血糖波动性
                                MAGE，
                                SD，
                                CV

                            右侧柱状图
                            注射胰岛素
                            长效胰岛素
                            每日摄入碳水
                            每日运动
                            AGP图谱曲线
                            用户信息（获取达标范围使用）
                            每日曲线图谱暂时先掠过

                            周期图谱
                            TIR+AGP连续图谱

                            BOX图谱

                            多日曲线X轴

                            夜间数据分析多日曲线数据
                            夜间数据分析关键点血糖值
                            夜间数据分析多日曲线X轴

                        */

                        this.avg_bloodsugar = report.avg_bloodsugar,
                        this.estimate_albumen = report.estimate_albumen,
                        this.LAGE = report.lage,
                        this.SD = report.sd,
                        this.CV = report.cv,
                        this.tir = report.tir,
                        this.day_avg_insulin = report.day_avg_insulin,
                        this.day_avg_long_insulin = report.day_avg_long_insulin,
                        this.day_avg_food_insulin = report.day_avg_food_insulin,
                        this.day_avg_carb = report.day_avg_carb,
                        this.day_avg_calorie = report.day_avg_calorie,
                        this.envelope = report.envelope_data;
                        this.user_info = report.user_info,
                        this.everyDaysDate.x_arr = report.envelope_data.x_arr || report.multi_line_x_arr;
                        this.isDefaultUnit = report.user_info.bs_unit;
                        report.days_data.map( item =>{
                            if(item.line){
                                this.everyDaysDate.value.push(item.line);
                            }
                        })
                        this.days_data = report.days_data,
                        this.food_record_rank = report.food_record_rank,
                        this.BarLineVal = report.box,
                        this.multi_line_x_arr = report.multi_line_x_arr;
                        this.nightDayData = report.night_time_points_list,
                        this.nightChartValue = report.night_line,
                        this.lineMax = report.max,
                        this.nightLineX_arr = report.night_line_x_arr;
                        // 数据请求完成，显示pdf
                        if(report.device_bloodsugar_total < 864){
                            this.$router.push({
                                path: '/report/notData',
                                query:''
                            })
                        }else{
                            this.loading = false;
                        }
                    }else{
                        this.$router.push({
                            path: '/report/notData',
                            query:{
                                title: data.msg
                            }
                        })
                    }
                }).catch( err =>{
                    console.log(err)
                })
            },
            initiatePay(){
                saveFileToPhone('openUserVip','')
            },
            setLang(lang){
                if(lang != getItem('lang')){
                    setItem('lang', lang)
                    window.location.reload();
                }
            }
        },
        created(){
            const data = new Date(),
                  md5 = require('js-md5');
            const id = this.$route.query.id || this.$route.query.device_id || this.$route.query.uid,
                  report_sign = md5(id + 'yja2019_fcz'),
                  token = this.$route.query.token || '',
                  start_day = this.$route.query.start_day || '',
                  end_day = this.$route.query.end_day || '';
            this.setLang(this.lang)
            this.getReportDate(id,report_sign,token,start_day,end_day);
            let year = data.getFullYear(),
                mon = data.getMonth() + 1 < 10 ? '0' + (data.getMonth() + 1) : data.getMonth() + 1,
                day = data.getDate() < 10 ? '0' + data.getDate() : data.getDate();
            this.toDay = year+'/'+mon+'/'+day;
            const type = deviceType();
            for(let key in type){
                if(type[key]){
                    this.mobile = key;
                }
            }
            if(type.iPhone == true || type.ios == true || type.mobile == true){
                let html = document.getElementsByTagName('html')[0],
                    body = document.getElementsByTagName('body')[0],
                    app = document.getElementById('app');
                html.style.overflowX = 'hidden';
                body.style.overflowX = 'hidden';
                html.style.minWidth = 'unset';
                body.style.minWidth = 'unset';
                app.style.minWidth = 'unset';
                this.transform = 'scale(' + (window.innerWidth / 1240).toFixed(2) +') translateX(-0px) translateY(-' + parseInt( 94 - ( 100 * (window.innerWidth / 1240)) ) + '%)',
                this.height = 107 + ( 100 * ( 1 - window.innerWidth / 1240 ) );
                if( type.ios || type.iPad || type.iPhone){
                    let self = this;
                    window["getAppVersion"] = function(msg){
                        self.AppVersion = msg;
                    };
                }else if(type.mobile || type.android){
                    this.AppVersion = window.vipBtn.getAppVersion();
                }
            }
        },
    }
</script>

<style scoped lang="less">

    .print{
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.5);
        z-index: 9999;
        margin: 0 auto;
    }

    .loading{
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;

        .loading-box{
            width: 100%;

            .loading-text{
                top: 40px;
                width: 100%;
                color: white;
            }
        }
    }

    .printing{
        width: 1239.5px;
        height: 100%;
        position: fixed;
        top: 0px;
        left: ~'calc( ( 100% - 1239.5px ) / 2 )';
        z-index: 0;
    }

    .A4-page{
        width: 1239.5px;
        height: 1754px;
        margin: 0 auto;
    }

    /deep/ .print-cont{
        width: 1239.5px;
        height: 1754px;
        padding: 40px 50px 40px 55px;
        box-sizing: border-box;
        border-radius: 5px;
        background: white;
        margin-top: 10px;
    }

    /deep/ .print-cont .tiptitle{
        display: flex;
        align-items: center;
        .title{
            width: 65%;
            h2{
                display: flex;
                font-size: 41px;
                color: #000;
                p{
                    margin-left: 20px;
                }
            }
            &>p{
                font-size: 19px;
                color: #8d8d8d;
                margin-top: 10px;
                span{
                    margin-left: 20px;
                    color: #8d8d8d;
                }
            }
        }
        &>h3{
            margin-bottom: auto;
            color: #000;
            font-size: 22px;
            margin-top: -5px;
        }
        .logo{
            width: 220px;
            object-fit: cover;
            position: absolute;
            top: 55px;
            right: 72px;
        }
    }

    /deep/ .print-cont .page-bottom{
        bottom: 50px;
        left: 55px;
        width: ~'calc(100% - 110px)';
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 0px 66px 0px 66px;
        border-top: 1px solid #c4c4c4;
        p{
            font-size: 16px;
            color: #8d8d8d;
        }
    }

    .printIngText{
        top: 55%;
        font-size: 22px;
        z-index: 9999;
        width: 1239.5px;
    }

    .page-button{
        bottom: 20px;
        right: 125px;
        .operate-button{
            button{
                width: 115px;
                height: 45px;
                bottom: 0px;
                padding: 0px;
                border: unset;
                background: var(--defalutColor);
                color: white;
                transition: .3s;
                &:hover{
                    background: var(--defalutActiveColor);
                    color: white !important;
                    transition: .3s;
                }
                &:focus{
                    background: var(--defalutActiveColor);
                    color: white !important;
                    transition: .3s;
                }
            }
        }
    }

    @media (max-width: 1000px ) {
        .loading{

            .loading-box{
                width: 100%;

                & /deep/ .circular{
                    width: 120px;
                    height: 120px;
                    margin-top: -100px;
                }

                .loading-text{
                    top: 120px;
                    font-size: 40px;
                    width: 100%;
                    margin-top: -100px;
                }

            }
        }

        /deep/ .printing .el-loading-mask{
            margin-top: -550px;
            svg{
                width: 200px;
                height: 200px;
            }
        }

        /deep/ .page-button{
            right: 40px;
            bottom: 40px;

            .operate-button{
                width: 300px;
                height: 100px;
                button{
                    width: 300px;
                    height: 100px;
                    font-size: 32px;
                }
            }
        }

        /deep/ .printIngText{
            font-size: 60px;
            margin-top: -100px;
        }

    }

</style>
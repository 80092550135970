<template>
    <div class="annal" :class="[!Dark ? '' : 'dark']" v-if="JSON.stringify(deviceList) != '{}' || deviceList != null">
        <template v-if="device && device.length">
            <div class="card-item" v-for="(item,index) in device" :key="index">
                <div class="header">
                    <div class="left">
                        <div>{{item.bind_time_value}}</div>
                        <div>设备ID:{{item.device_name}}</div>
                    </div>
                    <div class="right">
                        <div class="right-btn radius-12 text-center line-height-28 font-12 pt-2 pb-2 pl-15 pr-15" @click="toDetail(item.id)">查看详情</div>
                        <!-- { } -->
                        <div class="right-btn radius-12 text-center line-height-28 font-12 pt-2 pb-2 pl-15 pr-15 mt-2" @click="toPrint(item.total,item.id)">数据报告</div>
                    </div>
                </div>
                <div class="middle">
                    <div class="middle-box rate" :class="[ item.rate < 0.6 ? 'red' : item.rate >= 0.6 && item.rate < 0.8 ? 'yellow' : 'green' ]">
                        <div class="num">
                            {{Number(item.rate*100).toFixed(0)}}
                            <span>%</span>
                        </div>
                        <div class="hanyu">达标率</div>
                    </div>
                    <div class="middle-box ave_bloodsugar" :class="[ patientInfo.target_low > item.ave_bloodsugar ? 'red' : patientInfo.target_low < item.ave_bloodsugar && patientInfo.target_high < item.ave_bloodsugar ? 'green' : 'yellow' ]">
                        <div class="num">
                            {{item.ave_bloodsugar || empty}}
                            <span>mmol/L</span>
                        </div>
                        <div class="hanyu">平均血糖值</div>
                    </div>
                    <div class="middle-box estimate_albumen" :class="[ item.estimate_albumen > 6 ? 'red' : 'green' ]">
                        <div class="num">
                            {{item.estimate_albumen | empty}}
                            <span>%</span>
                        </div>
                        <div class="hanyu">模拟糖化</div>
                    </div>
                </div>
                <div class="bottom">
                    <SmallLine :value="item.envelope" v-if="item.envelope != '' " />
                    <div class="notDateCharts" v-else>
                        <div class="notDate">
                            <img src="@/assets/images/error/notCharts.png" alt="">
                            <p>超过2天连续监测，才可生成分析图表</p>
                        </div>
                        <div class="line">
                            <p>00:00</p>
                            <p>04:00</p>
                            <p>08:00</p>
                            <p>12:00</p>
                            <p>16:00</p>
                            <p>20:00</p>
                            <p>24:00</p>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div class="page flex justify-center">
            <el-pagination background layout="prev, pager, next, jumper" @current-change="selectPage" :current-page="page" prev-text="上一页" next-text="下一页" :hide-on-single-page="hiddenPage" :total="count" :page-size="12"></el-pagination>
        </div>
        <div class="empty" v-if="!device.length">
            <div class="center">
                <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                <img src="@/assets/images/error/dark_empty.png" v-else alt />
                <p>暂无数据</p>
            </div>
        </div>
    </div>
</template>

<script>
    import { SmallLine } from '_c/Charts/index';
    export default {
        props:{
            deviceList: Object,
            patientInfo: Object,
            Dark: Boolean
        },
        watch:{
            deviceList(val){
                this.device = val.list;
                this.count = val.count;
            }
        },
        components:{
            SmallLine
        },
        data(){
            return{
                device: [],
                count: 0,
                page: 1,
                hiddenPage: true,
            }
        },
        methods:{
            selectPage(page){
                this.page = page;
                this.$emit('changePage',page)
            },
            toDetail(id){
                const { href } = this.$router.resolve({
                    path: "/patient/cycle",
                    query: { 
                        id,
                        token: this.patientInfo.token,
                        new_page: true
                    }
                });
                window.open(href, '_blank');
            },
            toPrint(total,id){
                const { href } = this.$router.resolve({
                    path: total > 864 ? "/report" : '/report/notData',
                    [total > 864 ? 'query' : '']: total > 648 ? {
                        id,
                        from: 'hospital',
                        token: this.patientInfo.token,
                    } : ''
                });
                window.open(href, '_blank');
            }
        }
    }
</script>

<style scoped lang="less">
    .annal{
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .card-item {
            width: calc(~'(100% - 40px) / 3 ');
            height: auto;
            background: #ffffff;
            border-radius: 6px;
            border: 1px solid #e6e9f0;
            padding: 10px;
            margin: 0;

            &:nth-child(3n+2){
                margin-left: 20px;
            }

            &:nth-child(3n+3){
                margin-left: 20px;
            }

            &:nth-child(n+4){
                margin-top: 20px;
            }

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-right: 5px;

                .left {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    div {
                        font-size: 14px;
                        font-family: PingFang-SC-Regular, PingFang-SC;
                        font-weight: 400;
                        color: #242424;
                        line-height: 14px;
                        padding-top: 10px;
                        padding-left: 5px;
                        padding-bottom: 10px;
                    }
                }

                .right {
                    cursor: pointer;

                    .right-btn{
                        background: #16cca6;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                        &.disabled{
                            background: #e5e6eb;
                            color: #0c0c0c;
                        }
                    }
                }
            }

            .middle {
                display: flex;
                margin-top: 10px;
                border-top: 1px solid #e6e9f0;
                padding-top: 10px;
                padding-left: 5px;
                padding-right: 5px;

                .middle-box {
                    width: ~'calc( ( 100% - 20px ) / 3)';
                    border-radius: 7px;
                    padding: 15px 5px;
                    text-align: center;
                    .num {
                        display: flex;
                        justify-content: center;
                        align-items: baseline;
                        font-size: 18px;
                        height: 28px;
                        font-family: PingFang-SC-Heavy, PingFang-SC;
                        font-weight: 800;
                        color: #ffffff;
                        text-align: center;
                        margin-top: 5px;
                        line-height: 28px;

                        span {
                            font-size: 12px;
                            font-weight: 500;
                            margin-left: 5px;
                        }
                    }

                    .hanyu {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                    }
                }

                .middle-box.rate {
                    &.red{
                        // background: #d8d8d8 linear-gradient(135deg, #eb3f3f 70%, #ffb700 100%);
                        background: #eb3f3f;
                    }
                    &.yellow{
                        // background: #d8d8d8 linear-gradient(135deg, #ffb700 70%, #13be9b 100÷%);
                        background: #ffb700;
                    }
                    &.green{
                        // background: #d8d8d8 linear-gradient(135deg, #ff6e6e 30%, #13be9b 70%);
                        background: #13be9b;
                    }
                }

                .middle-box.ave_bloodsugar {
                    &.red{
                        // background: #d8d8d8 linear-gradient(135deg, #eb3f3f 70%, #ffb700 100%);
                        background: #eb3f3f;
                    }
                    &.yellow{
                        // background: #d8d8d8 linear-gradient(135deg, #ffb700 70%, #13be9b 100%);
                        background: #ffb700;
                    }
                    &.green{
                        // background: #d8d8d8 linear-gradient(135deg, #ff6e6e 30%, #13be9b 70%);
                        background: #13be9b;
                    }
                    margin: 0px 10px;
                }

                .middle-box.estimate_albumen {
                    &.red{
                        // background: #d8d8d8 linear-gradient(135deg, #eb3f3f 70%, #ffb700 100%);
                        background: #eb3f3f;
                    }
                    &.yellow{
                        // background: #d8d8d8 linear-gradient(135deg, #ffb700 70%, #13be9b 100%);
                        background: #ffb700;
                    }
                    &.green{
                        // background: #d8d8d8 linear-gradient(135deg, #ff6e6e 30%, #13be9b 70%);
                        background: #13be9b;
                    }
                }

                .middle-box:last-child {
                    margin-right: 0px;
                }
            }

            .bottom {
                margin-top: 20px;
                width: 100%;
                height: 180px;

                .charts {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .notDateCharts{
            background: #F5F5F5;
            height: 100%;
            width: 100%;
            padding: 15px;

            .tip-line{
                text-align: right;
                padding-bottom: 5px;
                border-bottom: 1px solid #ededed;
                color: #a4a4a4;
            }

            .notDate{

                img{
                    display: block;
                    margin: 0px auto;
                }

                p{
                    color: #464646;
                    font-size: 14px;
                    text-align: center;
                }
            }

            .line{
                padding-top: 15px;
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                border-top: 1px solid #ededed;
                color: #a4a4a4;
                font-size: 12px;
            }
        }

        & /deep/ .page{
            width: 100%;
            padding-top: 30px;
            .btn-prev,
            .btn-next{
                padding: 0px 20px;
                float: left;
                background: none;
                transition: .3s;
                &:hover{
                    color: var(--defalutColor);
                    transition: .3s;
                }
                &[disabled]{
                    color: #c0c4cc;
                }
            }
            .el-pager{
                float: left;
                li{
                    background-color: unset !important;
                    border: 1px solid #dcdee2;
                    transition: .3s;
                    &:hover{
                        border-color: var(--defalutColor);
                        color: var(--defalutColor);
                        transition: .3s;
                        cursor: pointer;
                    }
                    &.active{
                        border-color: var(--defalutColor);
                        color: var(--defalutColor);
                        transition: .3s;
                    }
                }
            }
            .el-input__inner:focus{
                border-color: var(--defalutColor);
            }
        }

        .empty{
            width: 100%;
            height: 70vh;
        }
        &.dark{
            .card-item{
                background: unset !important;
                border-color: #2c2c2e !important;
                .header{
                    .left>div{
                        color: #c1c1c1 !important;
                    }
                }
                .middle{
                    border-color: #2c2c2e;
                }
                .notDateCharts{
                    background: #0c0c0c !important;
                    p{
                        color: #c1c1c1 !important;
                    }
                    .line{
                        color: #c1c1c1 !important;
                        border-color: #2c2c2e;
                    }
                }
            }

            & /deep/ .page{
                .btn-prev{
                    color: #c1c1c1 !important;
                    &[disabled]{
                        color: #2c2c2e !important;
                        &:hover{
                            color: #2c2c2e !important;
                        }
                    }
                    &:hover{
                        color: var(--defalutColor) !important;
                    }
                }
                .number{
                    color: #c1c1c1 !important;
                    border-color: #2c2c2e !important;
                    &.active{
                        color: var(--defalutColor) !important;
                        border-color: var(--defalutColor)!important;
                    }
                }
                .btn-next{
                    color: #c1c1c1 !important;
                    &[disabled]{
                        color: #2c2c2e !important;
                        &:hover{
                            color: #2c2c2e !important;
                        }
                    }
                    &:hover{
                        color: var(--defalutColor) !important;
                    }
                }
                .el-pagination__jump{
                    color: #c1c1c1 !important;
                    input{
                        background-color: unset;
                        border-color: #2c2c22;
                        color: #c1c1c1;
                    }
                }
            }
        }
    }

    @media screen {
        @media (max-width: 1366px) and (min-width: 768px) {
            .annal{
                .card-item{
                    width: calc(~'(100% - 20px) / 2 ');
                    &:nth-child(even){
                        margin-left: 20px !important;
                    }

                    &:nth-child(3n+2){
                        margin-left: 0px;
                    }

                    &:nth-child(3n+3){
                        margin-left: 0px;
                    }

                    &:nth-child(n+3){
                        margin-top: 20px;
                    }
                }
            }
        }
    }

</style>
<template>
    <div ref="dom" class="charts chart-bar" v-if="value && user_info"></div>
</template>

<script>
    import echarts from "echarts";
    import tdTheme from "./theme.json";
    import { on, off, getItem } from '_js/Cache'

    echarts.registerTheme("tdTheme", tdTheme);
    export default {
        props: {
            value: Object,
            user_info: Object,
        },
        data() {
            return {
                dom: null,
                isDark: false,
            };
        },
        methods: {
            resize() {
                this.dom.resize();
            },
            hexToRgba(hex, opacity) {
                let rgbaColor = "";
                let reg = /^#[\da-f]{6}$/i;
                if (reg.test(hex)) {
                    rgbaColor = `rgba(${parseInt("0x" + hex.slice(1, 3))},${parseInt(
                    "0x" + hex.slice(3, 5)
                    )},${parseInt("0x" + hex.slice(5, 7))},${opacity})`;
                }
                return rgbaColor;
            },
            init() {
                let __self__ = this;
                let option = {
                    grid: {
                        left:'3%',
                        right: '3%',
                        y: 30,
                        y2: 30,
                    },
                    tooltip:{
                        trigger: "axis",
                        // 坐标轴指示器
                        // backgroundColor: "rgba(73,91,127,1)",
                        // color:'grey'
                    },
                    xAxis:[
                        {
                            type:'category',
                            boundaryGap: false,
                            data: this.value.x_arr,
                            axisLabel: {
                                interval: 1,
                                textStyle: {
                                    color: __self__.isDark ? '#c1c1c1' : "#333",
                                    fontSize: 10
                                }
                            },
                            axisLine:{
                                lineStyle:{
                                    color: __self__.isDark ? '#2c2c2e' : "#D9D9D9"
                                }
                            },
                            splitLine:{
                                lineStyle:{
                                    color: __self__.isDark ? '#2c2c2e' : "#D9D9D9"
                                }
                            },
                            zlevel: 99
                        }
                    ],
                    yAxis:{
                        show: true,
                        type: 'value',
                        axisLabel:{
                            show: true,
                            textStyle: {
                                color: __self__.isDark ? '#c1c1c1' : "#333",
                            }
                        },
                        axisLine:{
                            lineStyle:{
                                color: __self__.isDark ? '#2c2c2e' : "#D9D9D9"
                            }
                        },
                        splitLine:{
                            lineStyle:{
                                color: __self__.isDark ? '#2c2c2e' : "#D9D9D9"
                            }
                        },
                        zlevel: 99,
                        position: 'right',
                        interval:4,
                        min:0,
                        max(value){
                            const val = ( parseInt(value.max) + (4 - parseInt(value.max) % 4 ) ) > 16 ? parseInt(value.max) + (4 - parseInt(value.max) % 4 ) : 16;
                            return val;
                        },
                    },
                    series:[
                        {
                            name:'最高线',
                            type:'line',
                            data: __self__.value.device_max_bloodsugar_arr,
                            unit: 'mmol/L',
                            itemStyle: {
                                normal:{
                                    lineStyle: {
                                        width: 2,
                                        type: 'dashed',
                                        color: '#979797'
                                    }
                                }
                            },
                            symbol: 'none',
                        },
                        {
                            name: '90%线',
                            type: 'line',
                            unit: 'mmol/L',
                            data: __self__.value.device_90_bloodsugar_arr,
                            areaStyle: {
                                normal: {
                                    color: '#cde2f5',
                                    opacity: 1
                                }
                            },
                            lineStyle: {
                                normal: {
                                    color: '#cde2f5',
                                    width: 1
                                }
                            },
                            symbol: 'none',
                        },
                        {
                            name: '75%线',
                            type: 'line',
                            unit: 'mmol/L',
                            data: __self__.value.device_75_bloodsugar_arr,
                            areaStyle: {
                                normal: {
                                    color: '#a3d0f5',
                                    opacity: 1
                                }
                            },
                            lineStyle: {
                                normal: {
                                    color: '#a3d0f5',
                                    width: 1
                                }
                            },
                            symbol: 'none',
                        },
                        {
                            name: '平均线',
                            type: 'line',
                            unit: 'mmol/L',
                            data: __self__.value.device_avg_bloodsugar_arr,
                            markLine:{
                                symbol: ['none','none'],
                                label: {
                                    position: 'start'
                                },
                                animation: false,
                                silent: true,
                                lineStyle: {
                                    'type': 'solid',
                                    'width': 1,
                                }
                            },
                            lineStyle: {
                                normal: {
                                    color: '#515151',
                                    width: 3,
                                }
                            },
                            symbol: 'none',
                        },
                        {
                            name: '25%线',
                            type: 'line',
                            unit: 'mmol/L',
                            data: __self__.value.device_25_bloodsugar_arr,
                            areaStyle: {
                                normal: {
                                    color: '#cde2f5',
                                    opacity: 1
                                }
                            },
                            lineStyle: {
                                normal: {
                                    color: '#cde2f5',
                                    width: 1
                                }
                            },
                            symbol: 'none',
                        },
                        {
                            name: '10%线',
                            type: 'line',
                            unit: 'mmol/L',
                            data: __self__.value.device_10_bloodsugar_arr,
                            areaStyle: {
                                normal: {
                                    color: '#fff',
                                    opacity: 1
                                }
                            },
                            lineStyle: {
                                normal: {
                                    color: '#cde2f5',
                                    width: 1
                                }
                            },
                            symbol: 'none',
                        },
                        {
                            name: '最低线',
                            type: 'line',
                            unit: 'mmol/L',
                            data: __self__.value.device_min_bloodsugar_arr,
                            areaStyle: {
                                normal: {
                                    color: __self__.isDark ? '#202025' : 'white',
                                    opacity: 1
                                }
                            },
                            itemStyle: {
                                normal:{
                                    lineStyle: {
                                        width: 2,
                                        type: 'dashed',
                                        color: '#979797'
                                    }
                                }
                            },
                            symbol: 'none',
                        }
                    ],
                    visualMap: {
                        show: false,
                        precision: 1,
                        pieces: [
                            {
                                gte: 1,
                                lte: __self__.user_info.alert_low,
                                // color: '#c03434'
                                color: '#979797'
                            },
                            {
                                gte: __self__.user_info.alert_low,
                                lte: __self__.user_info.alert_high,
                                // color: '#16cca6'
                                color: '#979797'
                            },
                            {
                                gte: __self__.user_info.alert_high,
                                // color: '#ffaa29'
                                color: '#979797'
                            }
                        ]
                    }
                };
                __self__.dom = echarts.init(__self__.$refs.dom, "tdTheme");
                __self__.dom.setOption(option);
                on(window, "resize", __self__.resize);
            }
        },
        mounted() {
            this.init();
        },
        created(){
            this.isDark = getItem('dark') || false;
        },
        beforeDestroy() {
            off(window, "resize", this.resize);
        }
}
</script>

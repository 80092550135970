<template>
    <div class="BarChartLine" ref="dom" v-if="value"></div>
</template>

<script>
    import echarts from "echarts";
    import tdTheme from "./theme.json";
    import { on, off, getItem } from "_js/Cache";

    echarts.registerTheme("tdTheme", tdTheme);
    export default {
        name: 'BarChartLine',
        props: {
            value: Array,
            high: String || '',
            low: String || '',
            range: String || '',
            type: String || ''
        },
        data () {
            return {
                dom: null,
                isDark: false
            }
        },
        watch:{
            value:{
                handler(val){
                    setTimeout( ()=>{
                        this.init(val, this.high, this.low, this.range, this.type)
                    },200)
                },
                deep: true,
            }
        },
        methods:{
            resize () {
                this.dom.resize()
            },
            init(val, high, low, range, type){
                
                let xAxisData = val.x;

                let value = [];
                val.y.map( i => {

                    let str;
                    if(type == 'wave'){
                        if( Number(i) <= range ){
                            str = {
                                value: Number(i),
                                itemStyle: {
                                    color:'#19be6b',
                                    barBorderRadius : [ 10, 10, 10, 10],
                                }
                            }
                        }else if( Number(i) > range ){
                            str = {
                                value: Number(i),
                                itemStyle: {
                                    color:'#F7B500',
                                    barBorderRadius : [ 10, 10, 10, 10],
                                }
                            }
                        }
                    }else{
                        if( Number(i) < low ){
                            str = {
                                value: Number(i),
                                itemStyle:{
                                    color: '#E02020',
                                    barBorderRadius : [ 10, 10, 10, 10],
                                }
                            };
                        }else if( Number(i) >= low && Number(i) < high ){
                            str = {
                                value: Number(i),
                                itemStyle: {
                                    color:'#19be6b',
                                    barBorderRadius : [ 10, 10, 10, 10],
                                }
                            }
                        }else if( Number(i) > high ){
                            str = {
                                value: Number(i),
                                itemStyle: {
                                    color:'#F7B500',
                                    barBorderRadius : [ 10, 10, 10, 10],
                                }
                            }
                        }
                    }
                    value.push(str);
                })
                let option = {
                    grid:{
                        width: '100%',
                        height: '78%',
                        top: '12px',
                        left: '0',
                        right: '0',
                        bottom: '0',
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                            shadowStyle:{
                                opacity: 0.5
                            }
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: xAxisData,
                        splitLine: {
                            show: false,
                            interval: 0,
                            lineStyle:{
                                color: '#eeeeee'
                            }
                        },
                        axisLine:{
                            show: false,
                        },
                        lineStyle:{
                            color: '#1f1f1f',
                        },
                        axisLabel: {
                            interval: val.x.length > 15 ? 3 : 0,
                            textStyle: {
                                color: this.isDark ? '#c1c1c1' : "#333",
                            },
                            lineStyle:{
                                color: this.isDark ? '#2c2c2e' : "#333"
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        interval: 4,
                        min: 0,
                        max(value){
                            const val = type == 'wave' ? ( ( parseInt(value.max) + (4 - parseInt(value.max) % 4 ) ) > 16 ? parseInt(value.max) + (4 - parseInt(value.max) % 4 ) : 16 ) : ( ( parseInt(value.max) > 12 ? parseInt(value.max) + (4 - parseInt(value.max) % 4 ) : 12 ) )
                            return val;
                        },
                        splitLine: {
                            show: true,
                            lineStyle:{
                                color: this.isDark ? '#2c2c2e' : "#E9E9E9"
                            }
                        },
                        axisLine:{
                            show: false,
                        },
                        axisLabel: {
                            interval: 0,
                            textStyle: {
                                color: this.isDark ? '#c1c1c1' : "#333",
                            },
                            lineStyle:{
                                color: this.isDark ? '#2c2c2e' : "#333"
                            }
                        },
                    },
                    series: [{
                        data: value,
                        type: 'bar',
                        barWidth: '10px'
                    }]
                }

                this.dom = echarts.init(this.$refs.dom, 'tdTheme')
                this.dom.setOption(option)
                on(window, 'resize', this.resize)

            }
        },
        created(){
            this.isDark = getItem('dark') || false;
        },
        mounted(){
            setTimeout( () =>{
                this.init(this.value, this.high, this.low, this.range, this.type);
            },200)
        },
        beforeDestroy () {
            off(window, 'resize', this.resize)
        }
    }
</script>

<style scoped>
    .BarChartLine{
        width: 100%;
        height: 100%;
    }
</style>
// 创建vue
import Vue from 'vue'
// 创建router
import Router from 'vue-router'
// 引入router
import routers from './router'
// 引入获取token方法
import { getItem } from '_js/Cache'

Vue.use(Router)

const routes = routers

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const LOGIN_PAGE_NAME = 'login'

router.beforeEach( (to,from, next) => {
    const token = getItem('token');
    const roleId = getItem('userInfo')? getItem('userInfo').role_id : '';
    if (!token && to.name !== LOGIN_PAGE_NAME) {
        // 如果是打印页面的话
        if(to.name === 'report' ){
            next()
        }else{
            // 未登录且要跳转的页面不是登录页
            next({
                name: LOGIN_PAGE_NAME // 跳转到登录页
            })
        }
    } else if (!token && to.name === LOGIN_PAGE_NAME) {
        // 未登陆且要跳转的页面是登录页
        next() // 跳转
    }else if (token && to.name === LOGIN_PAGE_NAME) {
        // 已登录且要跳转的页面是登录页
        next({
            name: 'index'
        })
    }else if(to.name == null){
        next('/404');
    }else if(to.name == 'report'){
        next();
    }else{
        if(to.name === 'error' || to.name == 'test'){
            next();
        }else{
            if(roleId >= to.meta.roleId){
                next();
            }else{
                next('/401');
            }
        }
    }
})

export default router
<template>
    <div class="title pt-18" :class="[ !Dark ? '' : 'isDark' ]">
        <h2 class="font-normal font-20">{{ Title }}</h2>
    </div>
</template>

<script>
    export default {
        props:{
            Title: String,
            Dark: Boolean
        },
    }
</script>

<style scoped lang="less">
    .title{
        color: #212121;
        transition: .3s;
        &.isDark{
            transition: .3s;
            color: #fff;
        }
    }

    .title.isDark{
        color: #c1c1c1;
        transition: .3s;
    }
</style>
<template>
    <div class="process" :class="[!Dark ? '' : 'dark']">
        <div class="box" :class="[!flag?'small':'']" v-if="tir.length != 0">
            <div class="barList">
                <div class="barItem" :style="{'height': tir.very_high*100 + '%' }">
                    <div class="left-process" :style="{width: flag ? '48px' : '14px' }"></div>
                </div>
                <div class="barItem" :style="{'height': tir.high*100 + '%' }">
                    <div class="left-process" :style="{width: flag ? '48px' : '14px' }"></div>
                </div>
                <div class="barItem" :style="{'height': tir.common*100 + '%' }">
                    <div class="left-process" :style="{width: flag ? '48px' : '14px' }"></div>
                </div>
                <div class="barItem" :style="{'height': tir.low*100 + '%' }">
                    <div class="left-process" :style="{width: flag ? '48px' : '14px' }"></div>
                </div>
                <div class="barItem" :style="{'height': tir.very_low*100 + '%' }">
                    <div class="left-process" :style="{width: flag ? '48px' : '14px' }"></div>
                </div>
            </div>
            <div class="numberList">
                <div class="numItem">
                    {{tir.very_high*100 | tofixed }}%
                    <span v-if="flag != false">非常高</span>
                </div>
                <div class="numItem">
                    {{tir.high*100 | tofixed}}%
                    <span v-if="flag != false">高</span>
                </div>
                <div class="numItem">
                    {{tir.common*100 | tofixed}}%
                    <span v-if="flag != false">正常</span>
                </div>
                <div class="numItem">
                    {{tir.low*100 | tofixed }}%
                    <span v-if="flag != false">低</span>
                </div>
                <div class="numItem">
                    {{tir.very_low*100 | tofixed}}%
                    <span v-if="flag != false">非常低</span>
                </div>
            </div>
        </div>
        <div class="box flex" v-else>
            <div class="barList">
                <div class="barItem notData" :class="[flag == true ? '' : 'w-14']">
                    <div class="left-process"></div>
                </div>
                <div class="barItem notData" :class="[flag == true ? '' : 'w-14']">
                    <div class="left-process"></div>
                </div>
                <div class="barItem notData" :class="[flag == true ? '' : 'w-14']">
                    <div class="left-process"></div>
                </div>
                <div class="barItem notData" :class="[flag == true ? '' : 'w-14']">
                    <div class="left-process"></div>
                </div>
                <div class="barItem notData" :class="[flag == true ? '' : 'w-14']">
                    <div class="left-process"></div>
                </div>
            </div>
            <div class="numberList">
                <div class="numItem">
                    
                    <span v-if="flag != false">非常高</span>
                </div>
                <div class="numItem">
                    
                    <span v-if="flag != false">高</span>
                </div>
                <div class="numItem">
                    --
                    <span v-if="flag != false">正常</span>
                </div>
                <div class="numItem">
                    
                    <span v-if="flag != false">低</span>
                </div>
                <div class="numItem">
                    
                    <span v-if="flag != false">非常低</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        flag: Boolean,
        tir: Object,
        Dark: Boolean
    },
    filters: {
        tofixed(num) {
            return Math.floor(num);
        }
    },
    methods: {
        tofixed(num) {
            return Math.floor(num);
        }
    },

}
</script>

<style lang='less' scoped>

    .process {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;

        .box {
            height: calc(~"100%" - 10px);
            display: flex;
            overflow-y: visible;
        }

        .barList {
            height: 100%;

            .barItem {
                width: 100%;
                margin: 1px 0;
                &.notData{
                    &.w-14{
                        width: 14px;
                        .left-process{
                            width: 14px;
                        }
                    }
                    &:nth-of-type(3){
                        height: ~'calc(100% - 4px)';
                        .left-process{
                            height: 100%;
                        }
                    }
                }

                .left-process {
                    height: 100%;
                    width: 48px;
                    background: chocolate;
                    border-radius: 2px;
                    margin-top: 2px;
                    min-height: 1px;
                }
            }

            .barItem:nth-child(1) .left-process {
                background: #ffb700;
            }

            .barItem:nth-child(2) .left-process {
                background: #ffe452;
            }

            .barItem:nth-child(3) .left-process {
                background: #13be9b;
            }

            .barItem:nth-child(4) .left-process {
                background: #ff6e6e;
            }

            .barItem:nth-child(5) .left-process {
                background: #eb3f3f;
            }
        }

        .numberList{
            margin-left: 20px;
            font-size: 12px;
            font-weight: 500;
            color: #242424;
            line-height: 12px;

            .numItem{
                height: 20%;
                display: flex;
                align-items: center;
                margin-top: 2px;
            }

            .numItem:nth-child(3){
                font-size: 18px;
                line-height: 18px;
                font-weight: 600;
            }
        }

        .small {

            .numberList {
                margin-left: 5px;
            }
        }

        &.dark{
            .numberList{
                color: #c1c1c1 !important;
            }
        }
    }

</style>
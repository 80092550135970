<template>
    <div ref="dom" class="charts roundBar pion-abs">

    </div>
</template>

<script>
    import echarts from "echarts";
    import tdTheme from "./theme.json";
    import { on, off, getItem } from '_js/Cache'
    echarts.registerTheme("tdTheme", tdTheme);
    export default {
        name: 'roundPie',
        props:{
            value: Object
        },
        methods:{
            resize() {
                this.dom.resize();
            },
            init(val){
                let {common,high,low,very_high,very_low} = val,
                    sum = common+high+low+very_high+very_low;
                let option = {
                    angleAxis: {
                        clockwise: false, // 刻度增长是否按顺时针，默认顺时针(true)。
                        axisLine: {
                            show: false
                        },
                        axisLabel: {
                            show: false
                        },
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        min: 0,
                        max: sum == 0 ? 1 : sum, //一圈的刻度值
                        startAngle: 0 //初始角度
                    },
                    radiusAxis: {
                        type: 'category',
                        data: ['1', '2', '3', '4', '5', '6'], // 极坐标径向轴中的类目，这边有几个数，
                        // 就代表径向轴分了几份，和series中的data对应，这样就可以撑开圆环
                        z: 10,
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false
                        },
                        splitLine:{
                            show: false,
                        }
                    },
                    polar: {
                    },
                    series: [{
                        type: 'bar',
                        data: [0, 0, 0, very_low],
                        coordinateSystem: 'polar',
                        stack: 'a',
                        roundCap: true,
                        itemStyle: {
                            color: '#eb3f3f',
                        },
                        barWidth: 20
                    }, {
                        type: 'bar',
                        data: [0, 0, 0, very_high], // 前面的0，累计还是0，这样径向轴上的对应的分区总数就是0，不会显示圆环
                        coordinateSystem: 'polar',
                        stack: 'a',
                        roundCap: true,
                        itemStyle: {
                            color: '#ffb700'
                        }
                    }, {
                        type: 'bar',
                        data: [0, 0, 0, high],
                        coordinateSystem: 'polar',
                        stack: 'a',
                        roundCap: true,
                        itemStyle: {
                            color: '#ffe452'
                        }
                    }, {
                        type: 'bar',
                        data: [0, 0, 0, sum == 0 ? 1 : common],
                        coordinateSystem: 'polar',
                        stack: 'a',
                        roundCap: true,
                        itemStyle: {
                            color: '#13be9b'
                        }
                    }, {
                        type: 'bar',
                        data: [0, 0, 0, low],
                        coordinateSystem: 'polar',
                        stack: 'a',
                        roundCap: true,
                        itemStyle: {
                            color: '#ff6e6e'
                        }
                    }],
                    legend: {
                        show: true,
                        data: ['A', 'B', 'C']
                    }
                };

                this.dom = echarts.init(this.$refs.dom, "tdTheme");
                this.dom.setOption(option);
                on(window, "resize", this.resize);
            }
        },
        mounted(){
            this.init(this.value);
        },
        created(){
            this.isDark = getItem('dark') || false;
        },
        beforeDestroy() {
            off(window, "resize", this.resize);
        }
    }
</script>

<style lang="less" scoped>
    .roundBar{
        width: 280px;
        height: 280px;
    }
</style>
<template>
    <div class="addPrescription">
        <el-dialog width="400" :visible.sync="showPrescription" :modal-append-to-body="false" top="10vh" :before-close="drugClose">
            <div class="title">
                <p>添加处方</p>
            </div>
            <el-form :model="prescription" ref="prescriptionForm">
                <el-form-item prop="content" class="mb-10 flex textarea" :rules="{required: true, message: '请输入处方内容', trigger: 'blur'}" label="处方内容">
                    <el-input type="textarea" :rows="3" v-model="prescription.content" placeholder="处方内容"></el-input>
                </el-form-item>
                <el-form-item prop="content" class="mb-10 name pt-10" :rules="{required: true, message: '医生姓名', trigger: 'blur'}" label="医生姓名">
                    <el-input v-model="prescription.doctor_name" placeholder="医生姓名"></el-input>
                </el-form-item>
                <el-form-item class="time pion-rel mb-10 pt-10" prop="time" :rules="{required: true, message: '请选择时间', trigger: 'blur'}" label="选择时间">
                        <el-input placeholder="选择日期" suffix-icon="el-icon-date" v-model="prescription.time"></el-input>
                        <el-date-picker :append-to-body="false" class="pion-abs" v-model="mon" align="right" type="date" @change="selectTime"></el-date-picker>
                </el-form-item>
                <div class="list flex mt-20 pt-10" v-for="(item,idx) in prescription.medicationList" :key="item.key">
                    <el-form-item class="flex mb-10" label="口服药名称">
                        <el-autocomplete class="inputs" :popper-append-to-body="false" :fetch-suggestions="(queryString,cb)=>{addDrug(queryString,cb,1)}" v-model="item.title" placeholder="请输入内容" @select="(item) => {addPrescription(item,idx,0)}">
                            <template slot-scope="{ item }">
                                <div>{{ item.value = item.title }}</div>
                            </template>
                        </el-autocomplete>
                    </el-form-item>
                    <el-form-item class="flex unit ml-20 mb-10" :rules="{required: true}" label="剂量">
                        <el-input class="inputs" v-model="item.num">
                            <template slot="append" >
                                <el-dropdown @command="(command) =>{changeUnitDrug(command,idx,0)}">
                                    <span class="el-dropdown-link">
                                        {{ item.unit_list.length == 0 ? '片' : (isNaN(item.unit) ? item.unit : item.unit_list[Number(item.unit) - 1].title) }}/天
                                    </span>
                                    <el-dropdown-menu slot="dropdown" :append-to-body="false">
                                        <el-dropdown-item v-for="(items,idxs) in item.unit_list" :command="idxs" :key="idxs">{{items.title}}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-input>
                    </el-form-item>
                    <i class="el-icon-remove-outline ml-10 mt-10" @click="deleteDrugItem(idx,0)"></i>
                </div>
                <el-button class="radius-50 mb-20 addMedication" size="small" @click="addDrugMedication(0)">添加口服药</el-button>
                <div class="list flex" v-for="(item,idx) in prescription.insulinList" :key="item.key">
                    <el-form-item class="flex mb-10" label="胰岛素名称">
                        <el-autocomplete class="inputs" :popper-append-to-body="false" :fetch-suggestions="(queryString,cb)=>{addDrug(queryString,cb,2)}" v-model="item.title" placeholder="请输入内容" @select="(item) => {addPrescription(item,idx,1)}">
                            <template slot-scope="{ item }">
                                <div>{{ item.value = item.title }}</div>
                            </template>
                        </el-autocomplete>
                    </el-form-item>
                    <el-form-item class="flex unit ml-20 mb-10" :rules="{required: true}" label="剂量">
                        <el-input class="inputs" v-model="item.num">
                            <template slot="append">
                                <el-dropdown @command="(command) =>{changeUnitDrug(command,idx,1)}">
                                    <span class="el-dropdown-link">
                                        {{ item.unit_list.length == 0 ? '片' : (isNaN(item.unit) ? item.unit : item.unit_list[Number(item.unit) - 1].title) }}/天
                                    </span>
                                    <el-dropdown-menu slot="dropdown" :append-to-body="false">
                                        <el-dropdown-item v-for="(items,idxs) in item.unit_list" :command="idxs" :key="idxs">{{items.title}}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-input>
                    </el-form-item>
                    
                    <i class="el-icon-remove-outline mt-10 ml-10" @click="deleteDrugItem(idx,1)"></i>
                </div>
                <el-button class="radius-50 addMedication" size="small" @click="addDrugMedication(1)">添加胰岛素</el-button>
                <el-form-item class="flex justify-center mb-5">
                    <el-button class="submit mt-20 radius-50" @click="savePrescription('prescriptionForm')">保存</el-button>
                </el-form-item>
                <!-- <el-form-item prop="tips" :rules="{required: true, message: '请输入药方说明', trigger: 'blur'}" label="药方说明">
                    <el-input class="mt-20" type="textarea" :rows="3" v-model="prescription.tips" placeholder="药方说明"></el-input>
                </el-form-item> -->
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import { time } from '_js/Cache';
    import {medicineSearch, prescriptionSave} from 'quest';
    export default {
        props:{
            showPrescription: Boolean,
            id: Number,
            orderID: String || Number,
            dataStr: String,
            from: String,
            content: String,
            hospital_prescription_id: Number
        },
        watch:{
            dataStr:{
                deep: true,
                handler(val){
                    this.prescriptionID = this.orderID;
                    this.prescription = JSON.parse(val);
                    if(JSON.parse(val).insulinList.length == 0){
                        this.prescription.insulinList = [
                            {
                                title:'',
                                num:'',
                                unit_list:[],
                            }
                        ]
                    }else if(JSON.parse(val).medicationList.length == 0){
                        this.prescription.medicationList = [
                            {
                                title:'',
                                num:'',
                                unit_list:[],
                            }
                        ]
                    }
                    this.mon = new Date(JSON.parse(val).timestamp);
                }
            },
            content(val){
                this.prescription.content = val;
            },
        },
        data(){
            return{
                prescription:{
                    // tips: '',
                    content: '',
                    doctor_name: '',
                    time: '',
                    medicationList:[
                        {
                        title:'',
                        num:'',
                        unit_list:[],
                    }],
                    insulinList:[
                        {
                            title:'',
                            num:'',
                            unit_list:[],
                        }
                    ],
                },
                prescriptionID: '',
                mon: new Date(),
            }
        },
        methods:{
            drugClose(){
                this.prescription = {
                    // tips: '',
                    content: '',
                    doctor_name: '',
                    time: '',
                    medicationList:[
                        {
                        title:'',
                        num:'',
                        unit_list:[],
                    }],
                    insulinList:[
                        {
                        title:'',
                        num:'',
                        unit_list:[],
                    }],
                },
                this.$emit('drugClose')
            },
            selectTime(t){
                this.prescription.time = time(t,'年月日');
            },
            addDrug(title,cb,type){
                medicineSearch({
                    title,
                    type
                }).then( res =>{
                    cb(res.data.data);
                })
            },
            addPrescription(info,idx,t){
                t == 0 ? this.prescription.medicationList[idx] = info : this.prescription.insulinList[idx] = info;
            },
            changeUnitDrug(idxs,idx,t){
                t == 0 ? this.prescription.medicationList[idx].unit = idxs + 1 : this.prescription.insulinList[idx].unit = idxs + 1;
            },
            deleteDrugItem(idx,t){
                t == 0 ? this.prescription.medicationList.splice(idx,1) : this.prescription.insulinList.splice(idx,1);
            },
            addDrugMedication(t){
                t == 0 ? this.prescription.medicationList.push({
                    title:'',
                    num:'',
                    unit_list:[]
                }) : this.prescription.insulinList.push({
                    title:'',
                    num:'',
                    unit_list:[]
                })
            },
            savePrescription(e){
                this.$refs[e].validate((valid) => {
                    if (valid) {
                        let medicationList = [];
                        this.prescription.medicationList.map( item =>{
                            medicationList.push({
                                [ item.id ? 'id' : '' ] : item.id ? item.id : '',
                                num: item.num,
                                unit_id: isNaN(item.unit) ? item.unit_id : item.unit_list[item.unit - 1].id,
                                unit_title: isNaN(item.unit) ? item.unit : item.unit_list[item.unit - 1].title,
                                title: item.title,
                                medicine_id: item.medicine_id
                            })
                        })
                        this.prescription.insulinList.map( item =>{
                            medicationList.push({
                                [ item.id ? 'id' : '' ] : item.id ? item.id : '',
                                num: item.num,
                                unit_id: isNaN(item.unit) ? item.unit_id : item.unit_list[item.unit - 1].id,
                                unit_title: isNaN(item.unit) ? item.unit : item.unit_list[item.unit - 1].title,
                                title: item.title,
                                medicine_id: item.medicine_id
                            })
                        });
                        let data = {
                            uid : this.id,
                            id: this.prescriptionID,
                            // note: this.prescription.tips,
                            content: this.prescription.content,
                            doctor_name: this.prescription.doctor_name,
                            prescribe_time: this.mon.getTime() / 1000,
                            medicine_record_detail: JSON.stringify(medicationList),
                            [this.from == 'hospital' ? 'hospital_prescription_id' : '' ] : this.hospital_prescription_id,
                        }
                        prescriptionSave(data).then( res =>{
                            if(res.data.code == 100000){
                                this.$message({
                                    message: '保存成功！',
                                    type: 'success',
                                })
                                this.showPrescription = false;
                                setTimeout( () =>{
                                    this.prescription = {
                                        // tips: '',
                                        content: '',
                                        doctor_name: '',
                                        time: '',
                                        medicationList:[
                                            {
                                            title:'',
                                            num:'',
                                            unit_list:[],
                                        }],
                                        insulinList:[
                                            {
                                            title:'',
                                            num:'',
                                            unit_list:[],
                                        }],
                                    },
                                    this.$emit('upData')
                                },2000)
                            }else{
                                this.$message({
                                    message: res.data.msg,
                                    type: 'error',
                                })
                            }
                        })
                    }else{
                        this.$message({
                            message: '请填写必填字段！！',
                            type: 'error',
                        })
                    }
                })
            },
        }
    }
</script>
<style lang="less" scoped>
    .addPrescription /deep/ .el-dialog{
        border-radius: 6px;
        padding: 40px;
        width: 800px;
        .el-dialog__body{
            padding-bottom: 0px;
            .title{
                position: absolute;
                top: 55px;
                font-size: 16px;
                p{
                    font-size: 20px;
                }
            }
            .textarea{
                .el-form-item__content{
                    max-width: 80%;
                    width: 100%;
                }
            }
            .name,
            .time{
                .el-input{
                    width: 300px;
                }
                .el-date-editor{
                    width: 300px;
                    left: 80px;
                    >input,
                    >span{
                        opacity: 0;
                    }
                }
            }
            .list{
                i{
                    font-size: 22px;
                }
            }
            .addMedication{
                color: white;
                background: var(--bloodSugarHigh);
                &:hover,
                &:focus,
                &:active{
                    border-color: var(--bloodSugarHigh) !important;
                }
            }
            .submit{
                width: 350px;
                height: 55px;
                background-color: var(--defalutColor);
                color: white;
            }
        }
    }
</style>

<style lang="less">
    .dark .addPrescription .el-dialog{
        .el-picker-panel{
            background: #202025;
            border-color: #0c0c0c;
            .el-picker-panel__icon-btn,
            .el-date-picker__header-label{
                color: #c1c1c1;
            }
            .el-date-table th{
                color: #c1c1c1;
                border-color: #0c0c0c;
            }
            .prev-month span{
                color: #c1c1c1;
            }
            .available{
                span{
                    color: #fff;
                }
                &.current{
                    span{
                        background-color: var(--defalutActiveColor);
                    }
                }
            }
            .normal.disabled div,
            .next-month.disabled div{
                background: #0c0c0c;
                color: #7f7f7f;
            }
            .popper__arrow{
                border-bottom-color: #202025;
                &::after{
                    border-bottom-color: #202025;
                }
            }
        }
        .el-autocomplete-suggestion{
            background-color: #0d0e10;
            border-color: #2c2c2e;
            .popper__arrow{
                border-bottom-color: #0d0e10;
                border-top-color: #0d0e10;
                &::after{
                    border-top-color: #0d0e10;
                    border-bottom-color: #0d0e10;
                }
            }
            li{
                color: #c1c1c1;
                &:hover{
                    background-color: unset;
                    color: var(--defalutActiveColor);
                }
            }
        }
        .el-dropdown-menu{
            background-color: #0d0e10;
            border-color: #2c2c2e;
        }
    }
</style>




/* eslint-disable */
//不使用JQuery版的
import html2canvas from 'html2canvas';
// import domToImage from 'dom-to-image';
import JsPDF from 'jspdf';


/**
 * @param  ele          要生成 pdf 的DOM元素（容器）
 * @param  padfName     PDF文件生成后的文件名字
 * */

function printPDF(ele, pdfName, fun,printIng){

    let eleW = ele.offsetWidth;// 获得该容器的宽
    let eleH = ele.offsetHeight;// 获得该容器的高
    let eleOffsetTop = ele.offsetTop;  // 获得该容器到文档顶部的距离
    let eleOffsetLeft = ele.offsetLeft; // 获得该容器到文档最左的距离

    var canvas = document.createElement("canvas");
    var abs = 0;

    let win_in = document.documentElement.clientWidth || document.body.clientWidth; // 获得当前可视窗口的宽度（不包含滚动条）
    let win_out = window.innerWidth; // 获得当前窗口的宽度（包含滚动条）

    if(win_out>win_in){
        abs = (win_out - win_in)/2;    // 获得滚动条宽度的一半
    }
    canvas.width = eleW * 2;    // 将画布宽&&高放大两倍
    canvas.height = eleH * 2;

    var context = canvas.getContext("2d");
    context.scale(2, 2);
    context.translate(-eleOffsetLeft -abs, -eleOffsetTop);    // 这里默认横向没有滚动条的情况，因为offset.left(),有无滚动条的时候存在差值

    let doms = ele.getElementsByClassName('print-cont'),
        contentWidth = 0,
        contentHeight = 0,
        pdfArr = new Array(),
        index = 0,
        //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        imgWidth = 595.28,
        imgHeight = 0;

    // 按照顺序将页面内容转为base64格式图片；
    function imageBase64(){
        html2canvas( doms[index], {
            dpi: 192, // 设置打印清晰度
        } ).then( (canvas)=>{
            contentWidth = canvas.width, // 每页宽度
            contentHeight = canvas.height, // 每页高度
            imgHeight = 595.28 / contentWidth * contentHeight; // 等比高度
            let pageData = canvas.toDataURL('image/jpeg', 1.0); // 转为base64
            newPromise(pageData);
        })
    }

    function newPromise(pageData){
        new Promise( (res,rej) =>{
            if(index == doms.length){
                res();
            }else{
                pdfArr.push(pageData);
                index += 1;
                setTimeout( () =>{
                    res();
                    imageBase64();
                },100)
            }
        }).then( ()=>{
            if(index != doms.length){
                printIng(index,pdfArr);
            }else{
                printIng(index);
                savePDF();
            }
        })
    }

    function savePDF(){
        setTimeout( () =>{
            let pdf = new JsPDF('p', 'pt', 'a4',true);
            for(let i = 0; i < doms.length; i++){
                // addImage 将转换为base64的页面塞入每一页pdf中，通过addPage控制当前PDF增加页面。 如果没有addPage方法，将只导出1页pdf。
                pdf.addImage(pdfArr[i], 'JPEG', 0, 0, imgWidth, imgHeight);
                if(i < doms.length - 1){
                    pdf.addPage();
                }
            }

            if( index == doms.length){
                pdf.save(pdfName)
                fun();
            }
        },1000)
    }

    imageBase64();
}
export default {
    printPDF
}
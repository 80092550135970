<template>
    <div class="baseLine" :class="[!isDark ? '' : 'dark']">
        <div class="Base flex mb-20 mt-20 pt-20 pl-10">
            <p>时间：{{ time }}</p>
            <p class="ml-20">基础率：{{ base }} U/H</p>
            <p class="ml-20">百分比：{{ ratio }}%</p>
        </div>
        <div class="baseLine" ref="dom"></div>
    </div>
</template>

<script>
    import echarts from "echarts";
    import tdTheme from "./theme.json";
    import { on, off, getItem, time } from "_js/Cache";

    echarts.registerTheme("tdTheme", tdTheme);
    export default {
        name: 'BaseLine',
        props: {
            value: Array
        },
        watch:{
            value(val){
                let xData = []; // 初始化为空数组
                let yData = []; // 初始化为空数组
                val.map(item => {
                    xData.push(item.value);
                    yData.push(time(new Date(item.time * 1000),'h:m'));
                });
                this.init(xData,yData);
            } 
        },
        data () {
            return {
                dom: null,
                index:0,
                time: '',
                base: '',
                ratio: 0,
                isDark: false
            }
        },
        methods:{
            resize () {
                this.dom.resize()
            },
            mouseOutHandler() {
                try {
                    if (this.dom) {
                    this.initializeChart();
                    } else {
                    console.warn('this.dom is undefined');
                    }
                } catch (error) {
                    console.error('Error in mouseOutHandler:', error);
                }
            },
            init(xData,yData){

                let self = this;
                // 获取数据中的最大值
                let maxValue = Math.max(...this.value.map(item => item.value));
                // 动态计算Y轴的刻度间隔和最大值
                let interval = maxValue / 3;  // 将最大值除以3来计算间隔，确保4个刻度
                let yAxisMax = interval * 3;  // Y轴最大值为间隔的3倍
                // 在 mounted 生命周期钩子中获取元素宽度
                const elementWidth = this.$refs.dom.offsetWidth - 35;
                
                let option = {
                    grid: {
                        top: '10%',
                        bottom: 20,
                        left: 15,
                        right: 12,
                        width: elementWidth,
                    },
                    tooltip: {
                        trigger: 'axis',
                        formatter: function (params) {
                            const index = params[0].dataIndex;  // 当前点的下标
                            self.time = yData[index];
                            self.base = xData[index];
                            self.ratio = self.value[index].ratio;
                            self.index = index
                        }
                    },
                    xAxis: {
                        type: 'category',  // X 轴使用时间轴
                        axisLine: {
                            show: false,  // 隐藏 X 轴的轴线
                            lineStyle: {
                                color: self.isDark ? '#c1c1c1' : "#D9D9D9"
                            }
                        },
                        splitLine: {
                            show: false,  // 隐藏 X 轴的网格线
                            lineStyle: {
                                color: self.isDark ? '#2c2c2e' : "#E9E9E9"
                            }
                        },
                        axisLabel: {
                            interval: 15, // 自动间隔
                            textStyle:{
                                color: self.isDark ? '#c1c1c1' : "#333",
                                fontSize: 10
                            }
                        },
                        data: yData
                    },
                    yAxis: {
                        type: 'value',
                        min: 0,  // Y 轴最小值为0
                        interval,  // 设置Y轴刻度的增量为0.2
                        max: yAxisMax,
                        offset: -10,
                        position: 'right',  // 将Y轴放到右侧
                        axisLabel: {
                            textStyle:{
                                color: self.isDark ? '#c1c1c1' : "#333",
                                fontSize: 10
                            },
                            formatter: function (value) {
                                return value.toFixed(1);  // 保留一位小数
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: self.isDark ? '#2c2c2e' : "#E9E9E9"
                            }
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: self.isDark ? '#c1c1c1' : "#D9D9D9"
                            }
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    series: [
                        {
                            name: 'Data',
                            type: 'line',
                            step: 'end',  // 阶梯状折线
                            smooth: false,  // 禁用平滑线条
                            showSymbol: false, // 关闭小圆点显示
                            symbol: 'none', // 确保小圆点完全隐藏
                            symbolSize: 2,
                            data: xData,
                            areaStyle: {  // 增加区域阴影效果
                                color: '#16cca6'  // 阴影颜色，可自定义
                            },
                            lineStyle: {
                                color: '#16cca6',  // 线条颜色设置为红色
                                width: 1  // 线条宽度
                            },
                            itemStyle: {
                                color: '#16cca6'  // 数据点颜色设置为红色
                            },
                        }
                    ]
                };

                self.dom = echarts.init(this.$refs.dom, 'tdTheme')
                self.dom.setOption(option)
                on(window, 'resize', self.resize)
                // 监听 mouseout 事件
                if (!this.mouseOutHandlerAdded) {
                    on(window, 'mouseout', this.mouseOutHandler);
                    this.mouseOutHandlerAdded = true; // 添加标识
                }
            },
            initializeChart(){
                setTimeout( () =>{
                    let xData = []; // 初始化为空数组
                    let yData = []; // 初始化为空数组
                    // 假设 this.value 中的数据格式为 [{time: 1728835200, value: 1.7000}]
                    let currentTime = Math.floor(Date.now() / 1000); // 获取当前时间的 Unix 时间戳（秒）
                    // 找到距离当前时间最近的且不超过当前时间的时间点
                    let closestData = null;
                    this.value.map(item => {
                        if (item.time <= currentTime) {
                            if (!closestData || (currentTime - item.time < currentTime - closestData.time)) {
                                closestData = item; // 记录最近的数据
                            }
                        }
                        xData.push(item.value); // 保存数据的 value
                        yData.push(time(new Date(item.time * 1000), 'h:m')); // 保存数据的时间，转换为 h:m 格式
                    });
                    // 处理最近的时间点
                    if (closestData) {
                        this.time = time(new Date(closestData.time * 1000), 'h:m'); // 存储最近的数据的时间
                        this.base = closestData.value;
                        this.ratio = closestData.ratio;
                    } else {
                        this.time = "No valid data"; // 如果没有找到合适的数据
                    }

                    this.init(xData, yData); // 初始化图表数据
                },200)
            }
        },
        created(){
            this.isDark = getItem('dark') || false;
        },
        mounted(){
            this.initializeChart();
            
        },
        beforeDestroy () {
            off(window, 'resize', this.resize)
        }
    }
</script>

<style lang="less" scoped>
    .baseLine{
        width: 100%;
        height: 120px;
        &.dark{
            .Base{
                color: #c1c1c1;
            }
        }
    }
</style>
<template>
    <div class="BarLine">
        <div ref="dom" v-if="imgData == ''"></div>
        <img :src="imgData" alt="" v-else>
    </div>
</template>

<script>
    import echarts from "echarts";
    import tdTheme from "../Charts/theme.json";
    import { on, off } from '_js/Cache';
    let dom = null;

    echarts.registerTheme("tdTheme", tdTheme);
    export default {
        name: 'BarLine',
        props:{
            data: Object,
            userInfo: Object,
            isDefaultUnit: Number
        },
        data(){
            return{
                imgData: ''
            }
        },
        methods:{
            resize() {
                dom.resize();
            },
            init(val,low,high) {
                var ser = [];
                for(let i in val){
                    if(i != 'x_arr'){
                        ser.push({
                            type: i != 'avg_line' ? 'bar' : "line", //柱状图
                            [i != 'avg_line' ? 'stack' : '' ]: i != 'avg_line' ? 'barChart' : '' ,
                            data: i != 'box_3' ? val[i] : [
                                val.box_3.map( (item,idx) =>{
                                    return {
                                        value: item,
                                        itemStyle:{
                                            barBorderRadius: val.box_2[idx] > 0 && val.box_4[idx] > 0 ? 0 : val.box_2[idx] > 0 ? [50,50,0,0] : val.box_4[idx] > 0 ? [0,0,50,50] : 50
                                        }
                                    }
                                })
                            ][0],
                            itemStyle:{
                                color: i == 'box_1' ? '#fff' :  i == 'box_2' ? '#E03520' : i == 'box_3' ? '#C4C4C4': '#F7B500',
                                opacity: i == 'box_1' ? 0 : 1,
                                [i != 'avg_line' && i != 'box_3' ? 'barBorderRadius' : '' ]: i == 'box_1' ? [0,0,0,0] :  i == 'box_2' ? [0,0,50,50] : [50,50,0,0],
                                [i == 'avg_line' ? 'normal' :  '']:i == 'avg_line' ? {
                                    lineStyle: {
                                        type: "dashed",
                                        color: '#000'
                                    }
                                } : '',
                            },
                            symbol: 'none',
                            barWidth: '30px',
                            markLine:  i == 'box_1' ? {
                                silent: true,
                                symbolSize: 1,
                                data: [
                                    {
                                        yAxis: this.isDefaultUnit == 0 ? low : Number(low * 18).toFixed(0)
                                    }
                                ],
                                label: {
                                    fontSize: 22,
                                    color: '#fff',
                                    position: 'start',
                                    padding: 5,
                                    borderRadius: 5,
                                    backgroundColor: '#E03520'
                                },
                                lineStyle: {
                                    normal: {
                                        width: 3,
                                        color: "#E03520",
                                        type: "solid"
                                    }
                                }
                            } : {
                                silent: true,
                                symbolSize: 1,
                                data: [
                                    {
                                        yAxis: this.isDefaultUnit == 0 ? high : Number(high * 18).toFixed(0)
                                    }
                                ],
                                label: {
                                    fontSize: 22,
                                    color: '#fff',
                                    position: 'start',
                                    padding: 5,
                                    borderRadius: 5,
                                    backgroundColor: '#F7B500'
                                },
                                lineStyle: {
                                    normal: {
                                        width: 3,
                                        color: "#F7B500",
                                        type: "solid",

                                    }
                                }
                            }
                        })
                    }
                }
                let option = {
                    animation: false,
                    title: {
                        text: this.text,
                        x: '4%',
                        y: '40px',
                        textStyle: {
                            fontSize: 21,
                            color: '#333',
                            fontFamily: 'PingFangSC-Regular, PingFang SC'
                        }
                    },
                    grid:{
                        width: '90%',
                        height: '70%',
                        left: '5%',
                        top: 90,
                    },
                    tooltip: {
                        trigger: 'axis',
                        show: false,
                        axisPointer: {
                            type: 'shadow',
                            shadowStyle:{
                                opacity: 0.5
                            }
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: val.x_arr,
                        splitLine:{
                            show: false,
                        },
                        axisLabel: {
                            textStyle: {
                                color: "#333",
                            },
                            lineStyle:{
                                color: "#D9D9D9"
                            }
                        },
                        axisLine:{
                            lineStyle:{
                                color: "#D9D9D9"
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        axisLine:{
                            show: false
                        },
                        splitLine:{
                            lineStyle:{
                                color: "#D9D9D9"
                            }
                        },
                        axisLabel: {
                            show: false
                        }
                    },
                    series: ser
                };
                dom = echarts.init(this.$refs.dom, "tdTheme");
                dom.setOption(option);
                this.imgData = dom.getDataURL({
                    pixelRatio: 2
                });
                dom.dispose();
                on(window, "resize", this.resize);
            }
        },
        mounted() {
            this.init(this.data,this.userInfo.target_low,this.userInfo.target_high);
        },
        beforeDestroy() {
            off(window, "resize", this.resize);
        }
    }
</script>

<style lang="less" scoped>
    .BarLine{
        width: 100%;
        height: 100%;
        div{
            width: 100%;
            height: 100%;
        }
        img{
            width: 100%;
            height: 100%;
        }
    }
</style>
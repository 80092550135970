import Vue from 'vue'
// 引入elementUI
import ElementUI from 'element-ui';

// 引入elementUI样式
import 'element-ui/lib/theme-chalk/index.css';

// 引入多语言
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale';
// 引入语言切换
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import tcLocale from 'element-ui/lib/locale/lang/zh-TW'
import zh from './i18n/zh-CN';
import en from './i18n/en';
import tw from './i18n/tc';
// 引入vue核心及路由
import App from './App.vue'
import router from './router'
// 引入全局公用样式
import './main.css';

Vue.config.productionTip = false

Vue.config.devtools = true;

Vue.use(VueI18n);
Vue.use(ElementUI);


 
const messages = {
    en: {
        ...en,
        ...enLocale
      },
      zh: {
        ...zh,
        ...zhLocale
      },
      tw: {
        ...tw,
        ...tcLocale
      }
}

const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'zh',//从localStorage中拿到用户的语言选择，如果没有，那默认中文。
    messages,
    silentTranslationWarn: true // 忽略翻译警告
})
locale.i18n((key, value) => i18n.t(key, value)) //为了实现element插件的多语言切换

// 修改顶部标题配置
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }else{
        document.title = '糖尿病数据化管理平台';
    }
    next()
})


new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')

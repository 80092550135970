<template>
    <div class="charts chart-bar">
        <div ref="dom" v-if="imgData == ''"></div>
        <img :src="imgData" alt="" v-else>
    </div>
</template>

<script>
    import echarts from 'echarts'
    import tdTheme from '../Charts/theme.json'
    import { on, off } from "_js/Cache";
    echarts.registerTheme('tdTheme', tdTheme)
    let dom = null;

    export default {
        name: 'EveryDaysLine',
        props: {
            value: Array || Object,
            x_arr: Array,
            color: Array,
            isNight: Boolean,
            target_low: String || '',
            target_high: String || '',
            isDefaultUnit: Number|| ''
        },
        data () {
            return {
                imgData: ''
            }
        },
        methods: {
            resize () {
                dom.resize()
            },
            hexToRgba (hex, opacity) {
                let rgbaColor = ''
                let reg = /^#[\da-f]{6}$/i
                if (reg.test(hex)) {
                    rgbaColor = `rgba(${parseInt('0x' + hex.slice(1, 3))},${parseInt(
                    '0x' + hex.slice(3, 5)
                    )},${parseInt('0x' + hex.slice(5, 7))},${opacity})`
                }
                return rgbaColor
            }
        },
        mounted () {
            let series = [{
                type: 'line',
                markArea:{
                    itemStyle:{
                        color: this.hexToRgba('#5EFF72',0.1)
                    },
                    data: [
                        [{
                            yAxis: this.isDefaultUnit == 1 ? this.target_low * 18 : this.target_low ? this.target_low : '0'
                        },
                        {
                            yAxis: this.isDefaultUnit == 1 ? this.target_high * 18 : this.target_high ? this.target_high : '0'
                        }]
                    ]
                }
            }]
            let dateList = this.value,
                data;
            if(this.isNight == true){
                dateList.map( (item,idx) =>{
                    data = item.value;
                    series.push({
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        sampling: 'average',
                        connectNulls: true,
                        itemStyle: {
                            color: this.color[idx]
                        },
                        lineStyle: {
                            opacity: 0.5,
                            width:1.5,
                        },
                        data
                    })
                })
            }else if( this.isNight == false){

                for(let i in dateList){
                    data = dateList[i].map( item =>{
                        return item;
                    })
                    series.push({
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        sampling: 'average',
                        connectNulls: true,
                        itemStyle: {
                            color: this.color[i]
                        },
                        lineStyle: {
                            opacity: 0.5,
                            width:1.5,
                        },
                        data
                    })
                }
            }else{
                dateList.forEach( (item,index) => {
                    data = item.map( items =>{
                        return this.isNight ? items : items.blood_sugar;
                    })
                    series.push({
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        sampling: 'average',
                        connectNulls: true,
                        itemStyle: {
                            color: this.color[index]
                        },
                        lineStyle: {
                            opacity: 0.5,
                            width:1.5,
                        },
                        data
                    })
                })
            }
            let that = this;
            this.$nextTick(() => {
                let option = {
                    animation: false,
                    legend: {
                        type:'scroll',
                        top: '6%',
                        height: '90%',
                        right: '0',
                        show: true,
                        textStyle: {
                            color: '#ACCFFF',
                            fontSize: 14
                        }
                    },
                    grid: {
                        left: '0%',
                        right: '1%',
                        top: '20%',
                        bottom: '6%',
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: this.x_arr,
                        splitLine:{
                            show: this.isDownload ? false : true ,
                        }
                    },
                    yAxis: {
                        position: "right",
                        interval: that.isDefaultUnit == 0 ? 4 : 72,
                        min:0,
                        max(value){
                            const val = that.isDefaultUnit == 0 ? ( ( parseInt(value.max) + (4 - parseInt(value.max) % 4 ) ) > 16 ? parseInt(value.max) + (4 - parseInt(value.max) % 4 ) : 16 ) : ( ( parseInt(value.max) + (32 - parseInt(value.max) % 32 ) ) > 288 ? parseInt(value.max) + (32 - parseInt(value.max) % 32 ) : 288 );
                            return val;
                        },
                        type: 'value', 
                        boundaryGap: [0, '100%'],
                        splitLine: {
                            show: true ,
                            splitLine :{
                                lineStyle: {
                                    color: "#e1e1e1",
                                    width:0.5
                                },
                            }
                        }
                    },
                    series: series
                }
                dom = echarts.init(this.$refs.dom, 'tdTheme')
                dom.setOption(option)
                this.imgData = dom.getDataURL({
                    pixelRatio: 2
                });
                dom.dispose();
                on(window, 'resize', this.resize)
            })
        },
        beforeDestroy () {
            off(window, 'resize', this.resize)
        }
    }
</script>

<style lang="less" scoped>
    .charts{
        width: 100%;
        height: 100%;
        div{
            width: 100%;
            height: 100%;
        }
        img{
            width: 100%;
            height: 100%;
        }
    }
</style>
<template>
    <div class="header b-pad-20 pion-fixed" :class="[!isDark ? '' : 'isDark']">
        <div class="logo flex align-center">
            <template v-if="logo && logo != ''">
                <img :src="logo" alt="">
            </template>
            <h4 class="ml-15" v-if="!collapsed">糖尿病数字化管理工作平台</h4>
        </div>
        <!-- 手动展开/隐藏右侧导航菜单 -->
        <!-- <div class="menu-select ml-20">
            <div class="PC pion-rel" @mouseover="hover = true" @mouseleave="hover = false" @click="menuSelect">
                <transition name="el-zoom-in-center" v-if="collapsed">
                    <i v-if="hover" class="el-icon-remove pion-abs"></i>
                 </transition>
                <transition name="el-zoom-in-center" v-if="collapsed">
                    <i v-if="!hover" class="el-icon-remove-outline pion-abs" ></i>
                 </transition>
                <transition name="el-zoom-in-center" v-if="!collapsed">
                    <i v-if="hover" class="el-icon-circle-plus pion-abs"></i>
                 </transition>
                <transition name="el-zoom-in-center" v-if="!collapsed">
                    <i v-if="!hover" class="el-icon-circle-plus-outline pion-abs"></i>
                 </transition>
            </div>
        </div> -->
        <div class="setting flex align-center">
            <div class="dark flex align-center justify-center pointer pson-r-50 mr-10" :class="[isDark ? 'is' : '' ]" @click="dark">
                <i :class="[!isDark ? 'el-icon-sunny' : 'el-icon-sunrise' ]"></i>
            </div>
            <el-dropdown @command="loginOut" modal-append-to-body>
                <span class="el-dropdown-link">
                    {{ userName ? userName : '用户' }}<i class="el-icon-caret-bottom el-icon--right"></i>
                </span>
                <el-dropdown-menu class="dropdown index" :class="[!isDark ? '' : 'isDark']" slot="dropdown">
                    <el-dropdown-item>退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <!-- <el-radio-group v-model="lang" size="mini" @input="changLang">
                <el-radio-button label="中文" name="zh-CN"></el-radio-button>
                <el-radio-button label="英文" name="EN"></el-radio-button>
                <el-radio-button label="繁体" name="TC"></el-radio-button>
            </el-radio-group> -->
        </div>
    </div>
</template>

<script>
import {setItem, getItem, removeItem} from '_js/Cache';
export default {
    props:{
        isDark: Boolean,
        collapsed: Boolean
    },
    data(){
        return{
            hover: false,
            userName: getItem('userInfo').nick,
            logo: getItem('agencyInfo').logo || require('@/assets/images/error/top_logo.jpg'),
            lang: getItem('lang') == 'zh' ? '中文' : getItem('lang') == 'en' ? '英文' : '繁体'
        }
    },
    methods:{
        menuSelect(){
            this.collapsed = !this.collapsed;
            setItem('menuType',this.collapsed);
            this.$emit('hideMenu',this.collapsed);
        },
        loginOut(){
            removeItem('token');
            removeItem('userInfo');
            removeItem('agencyInfo');
            this.$message.success('退出成功！');
            setTimeout( () => {
                this.$router.push({
                    path: '/login'
                })
            },1500)
        },
        dark(){
            this.$emit('dark')
        },
        changLang(e){
            let lang = e == '中文' ? 'zh' : e == '英文' ? 'en' : 'tw';
            setItem('lang', lang);
            this.$i18n.locale = lang;
            window.location.reload();
        }
    }
}
</script>

<style scoped lang="less">

    .header{
        display: flex;
        width: 100%;
        height: 64px;
        background: var(--white);
        align-items: center;
        box-shadow: 0px 3px 6px 0px rgba(230, 233, 240, 0.3);
        z-index: 99;
        transition: .3s;
        .logo{
            img{
                width: 180px;
                height: 45px;
                object-fit: contain;
            }
            h4{
                font-size: 22px;
                color: var(--black);
            }
        }
        .menu-select{
            width: 18px;
            height: 18px;
            .PC{
                top: 0px;
                left: 0px;
                width: 18px;
                height: 18px;
            }
            i{
                top: 0px;
                left: 0px;
                font-size: 18px;
                &:hover{
                    color: var(--defalutColor);
                }
            }
        }

        .setting /deep/ {
            margin-left: auto;
            padding-right: 20px;

            .el-dropdown-menu{
                width: 120px;
            }

            .dark{
                width: 25px;
                height: 25px;
                background: #e5e6eb;
                color: #242424;
                transition: .3s;
            }

            .dark.is{
                background: #3e3e48;
                color: white;
                transition: .3s;
            }

            .el-dropdown-link {
                height: 14px;
                font-size: 14px;
                font-family: PingFangSC-Medium,PingFang SC;
                font-weight: 500;
                color: #242424;
                line-height: 14px;
                cursor: pointer;
            }

            .el-icon-arrow-down {
                font-size: 12px;
            }
        }
        &.isDark{
            background: #202025 !important;
            transition: .3s;
            box-shadow: 0px 3px 6px 0px rgba(17, 17, 17, 0.5);
            h4{
                color: #c1c1c1;
            }
            .setting /deep/ {
                .el-dropdown-link {
                    color: #c1c1c1;
                }
            }
        }
    }

</style>

<style lang="less">

    .el-dropdown-menu.isDark{
        background-color: #202025;
        border-color: #0c0c0c;
        overflow: unset;
        li:hover{
            color: #c1c1c1 !important;
        }
        .popper__arrow{
            border-bottom-color: #0c0c0c !important;
            &::after{
                border-bottom-color: #202025 !important;
            }
        }
    }
        
    .dropdown.index{
        width: 100px;
    }
</style>
<template>
  <div class="el-collapse">
    <el-collapse class="pl-20">
        <el-collapse-item v-for="(item, index) in list" :key="index">
            <template slot="title">
                <div class="list fath line-height-40 flex justify-between align-center solid-bottom" @click="selectCard(item.id)">
                    <p class="font-14" @click.stop="showDrawer(item)">
                        <i class="el-icon-arrow-right" :style="{ 'transform' : item.showChild ? 'rotate(90deg)' : '' }" v-if="item.childs && item.childs.length > 0"></i>
                        {{item.title}}
                    </p>
                    <div class="father icons none">
                        <i class="el-icon-edit font-16" @click.stop="editDep(item.id,item)"></i>
                        <i class="el-icon-delete-solid font-16" @click.stop="delDep(item.id)"></i>
                    </div>
                </div>
            </template>
            <Tree :list="item.childs" @selectCard="selectCard" @showDrawer="showDrawer" @editDep="editDep" @delDep="delDep" />
        </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
    export default {
        name: "Tree",
        props: {
            list: Array,

        },
        methods:{
            selectCard(id){
                this.$emit('selectCard',id);
            },

            showDrawer(item){
                this.$emit('showDrawer',item);
            },

            editDep(id,item){
                this.$emit('editDep',id,item)
            },

            delDep(id){
                this.$emit('delDep',id)
            }
        }
    };
</script>

<style lang="less" scopeds>

    .el-collapse{
        border-top: unset;
        border-bottom: unset;

        .fath:hover{
            .father{
                display: block;
            }
        }

        .sec:hover{
            .section{
                display: block;
            }
        }

        .chi:hover{
            .children{
                display: block;
            }
        }

        .el-collapse-item__wrap{
            border-bottom: unset;
        }

        .el-collapse-item__content{
            padding-bottom: 0px;
        }

        .el-collapse-item__header{
            border-bottom: unset;
            // position: relative;
            // padding-left: 20px;

            & > .el-icon-arrow-right{
                display: none;
                // position: absolute;
                // left: 0px;
            }
        }

        .list{
            width: 100%;
            border-color: #dcdee2;
            cursor: pointer;

            &:nth-last-child(1){
                border: none;
            }

            p{
                color: #666;
            }

            .icons{
                i{
                    margin-right: 30px;
                    color: #979797;
                }
            }
        }
    }

</style>

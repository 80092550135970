<template>
    <div class="AddPatient">
        <el-dialog :title=" !Edit ? '添加患者' : '编辑患者' " class="AddPatientDialog" :class="[!Dark ? '' : 'dark']" :top="isIpad ? '8%' : '5%' " :visible.sync="Show" :append-to-body="true" :before-close="close">
            <el-form label-position="right" ref="form" :rules="rules" label-width="125px" :model="form">
                <div class="left pion-rel float-left">
                    <h2 class="title font-14 font-weight">
                        基本信息
                    </h2>
                    <el-form-item prop="mobile" label="手机号：">
                        <div class="flex">
                            <el-input class="mr-20" maxlength="11" v-model="form.mobile"></el-input>
                            <el-button v-if="!Edit" @click="sendCode(form.mobile)">{{typeof countdown == 'string' ? countdown : countdown + 's' }}</el-button>
                        </div>
                    </el-form-item>
                    <el-form-item label="验证码：" v-if="!Edit">
                        <div class="flex">
                            <el-input class="mr-20" v-model="code"></el-input>
                            <el-button @click="checkCode(code)">验证验证码</el-button>
                        </div>
                    </el-form-item>
                    <el-form-item prop="agency_nickname" label="姓名：">
                        <el-input v-model="form.agency_nickname"></el-input>
                    </el-form-item>
                    <el-form-item class="date" prop="birthday" label="生日：">
                        <el-date-picker v-model="form.birthday" :append-to-body="false" type="date" placeholder="选择日期" />
                    </el-form-item>
                    <el-form-item prop="sex" label="性别：">
                        <el-select :popper-append-to-body="false" v-model="form.sex" placeholder="请选择">
                            <el-option v-for="item in [{ value:'1', label: '男' }, { value: '2', label: '女' }]" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="suffer_type" label="患病类型：">
                        <el-select :popper-append-to-body="false" v-model="form.suffer_type" placeholder="请选择">
                            <el-option v-for="item in [{value:'0',label:'非糖尿病'},{value:'1',label:'1型糖尿病'},{value:'2',label:'2型糖尿病'},{value:'3',label:'妊娠糖尿病'},{value:'4',label:'其他类型糖尿病'}]" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item class="date" prop="suffer_time" label="患病时间：">
                        <el-date-picker v-model="form.suffer_time" format="yyyy-MM-dd" :append-to-body="false" type="date" placeholder="选择日期" />
                    </el-form-item>
                    <el-form-item prop="height" label="身高：">
                        <el-input v-model="form.height">
                            <template slot="append">厘米</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="weight" label="体重：">
                        <el-input v-model="form.weight">
                            <template slot="append">公斤</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="treatment_plan" label="治疗方式：">
                        <el-select :popper-append-to-body="false" v-model="form.treatment_plan" placeholder="请选择">
                            <el-option v-for="item in [{value:'1',label:'无治疗方式'},{value:'2',label:'饮食运动'},{value:'3',label:'口服药'},{value:'4',label:'胰岛素打针'},{value:'5',label:'胰岛素戴泵'}]" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="digest_level" label="消化水平：">
                        <el-select :popper-append-to-body="false" v-model="form.digest_level" placeholder="请选择">
                            <el-option v-for="item in [{value:'1',label:'正常'},{value:'2',label:'胃轻瘫'}]" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="physical_level" label="运动习惯：">
                        <el-select :popper-append-to-body="false" v-model="form.physical_level" placeholder="请选择">
                            <el-option v-for="item in [{value:'1',label:'卧床休息（几乎不运动）'},{value:'2',label:'轻体力活动（办公室职员、售货员）'},{value:'3',label:'中等体力运动（学生、司机、医生、电工）'},{value:'4',label:'重体力劳动（建筑搬运工、农民、舞蹈演员）'}]" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="main_doctor_id" label="医生：">
                        <el-input v-model="form.main_doctor_id" class="none" />
                        <div class="workerItem" v-if="form.main_doctor_id">
                            <div class="avatar pion-rel">
                                <img class="radius-50" :src="doctor_avatar" onerror="this.src='https://webimg.ziroom.com/1c853bc6-21fc-4e32-8a6f-18ec2c6fef32.png'" />
                                <i class="el-icon-error pion-abs" @click.stop="clearDoctor" />
                            </div>
                            <p class="name font-12">{{doctor_name}}</p>
                        </div>
                        <i v-else class="el-icon-circle-plus-outline addUsers" @click="SystemUserShow = true" />
                    </el-form-item>
                    <el-form-item label="参与人员：">
                        <el-input v-model="form.other_doctor_ids" class="none" />
                        <div class="flex">
                            <div class="workerItem mr-20" v-for="(item,index) in health" :key="index">
                                <div class="avatar pion-rel">
                                    <img class="radius-50" :src="item.avatar" onerror="this.src='https://webimg.ziroom.com/1c853bc6-21fc-4e32-8a6f-18ec2c6fef32.png'" />
                                    <i class="el-icon-error pion-abs" @click="deleteOther(index)" />
                                </div>
                                <p class="name font-12">{{ item.name }}</p>
                            </div>
                            <i class="el-icon-circle-plus-outline addUsers" @click="SystemUserShow = true, isAddAide = true" />
                        </div>
                    </el-form-item>
                </div>
                <div class="right float-left">
                    <h2 class="font-14 font-weight">上传体检报告：</h2>
                    <el-form-item>
                        <el-upload :action="uploadImgUrl" :data="params" :on-success="upLoadImg" :file-list="fileList" :before-remove="delImg" accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG" list-type="picture-card">
                            <i class="el-icon-upload"></i>
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                    </el-form-item>
                    <h2 class="font-14 font-weight">健康数据（选填）</h2>
                    <el-form-item label="腰围：">
                        <el-input v-model="form.waistline">
                            <template slot="append">厘米</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="臀围：">
                        <el-input v-model="form.hipline">
                            <template slot="append">厘米</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="体脂率：">
                        <el-input v-model="form.fatrate">
                            <template slot="append">%</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="心率：">
                        <el-input v-model="form.heartrate">
                            <template slot="append">次/分</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="糖化血红蛋白：">
                        <el-input v-model="form.hba1c">
                            <template slot="append">%</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="总胆固醇：">
                        <el-input v-model="form.tc">
                            <template slot="append">mmol/L</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="甘油三酯：">
                        <el-input v-model="form.glycerate">
                            <template slot="append">mmol/L</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="尿酸：">
                        <el-input v-model="form.uric_acid">
                            <template slot="append">umol/L</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="舒张压（低压）：">
                        <el-input v-model="form.hypotension">
                            <template slot="append">mmHg</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="收缩压（高压）：">
                        <el-input v-model="form.hypertension">
                            <template slot="append">mmHg</template>
                        </el-input>
                    </el-form-item>
                    <h2 class="font-14 font-weight">病史资料（选填）</h2>
                    <el-form-item label="活动性质：">
                        <el-checkbox-group v-model="form.complication">
                            <el-checkbox :label="item.value" border v-for="item in [{value:'1',label:'无并发症'},{value:'2',label:'糖尿病心血管病变'},{value:'3',label:'糖尿病视网膜病变'},{value:'4',label:'糖尿病肾病'},{value:'5',label:'糖尿病足'},{value:'6',label:'糖尿病神经病变'},{value:'7',label:'其他并发症'}]" :key="item.value">{{ item.label }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="过往病史：">
                        <el-checkbox-group v-model="form.medical_history">
                            <el-checkbox :label="item.value" border v-for="item in [{value:'1',label:'精神疾病'},{value:'2',label:'恶性肿瘤'},{value:'3',label:'关节问题'},{value:'4',label:'肾病'},{value:'5',label:'甲状腺疾病'},{value:'6',label:'高血脂'},{value:'7',label:'高血压'},{value:'8',label:'其他'},{value:'9',label:'没有疾病史'}]" :key="item.value">{{ item.label }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="糖尿病家族史：">
                        <el-select :popper-append-to-body="false" v-model="form.family_history" placeholder="请选择">
                            <el-option v-for="item in [{value:'1',label:'有'},{value:'2',label:'无'},{value:'3',label:'不知道'}]" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="目前症状：">
                        <el-select :popper-append-to-body="false" v-model="form.symptom" placeholder="请选择">
                            <el-option v-for="item in [{value:'1',label:'有明显症状'},{value:'2',label:'无明显症状'}]" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <h2 class="font-14 font-weight">生活习惯（选填）</h2>
                    <el-form-item label="是否吸烟：">
                        <el-select :popper-append-to-body="false" v-model="form.is_smoke" placeholder="请选择">
                            <el-option v-for="item in [{value:'1',label:'频繁'},{value:'2',label:'偶尔'},{value:'3',label:'不吸烟'}]" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否饮酒：">
                        <el-select :popper-append-to-body="false" v-model="form.is_drink" placeholder="请选择">
                            <el-option v-for="item in [{value:'1',label:'频繁'},{value:'2',label:'偶尔'},{value:'3',label:'不饮酒'}]" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="口味偏好：">
                        <el-checkbox-group v-model="form.flavor">
                            <el-checkbox :label="item.value" border v-for="item in [{value:'11',label:'偏酸'},{value:'12',label:'偏甜'},{value:'13',label:'香辣/麻辣'},{value:'14',label:'咸香'},{value:'15',label:'清淡'}]" :key="item.value">{{ item.label }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="主食偏好：">
                        <el-checkbox-group v-model="form.staple_food">
                            <el-checkbox :label="item.value" border v-for="item in [{value:'21',label:'水果代替'},{value:'22',label:'不吃主食'},{value:'23',label:'有粗粮'},{value:'24',label:'精米白面'}]" :key="item.value">{{ item.label }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="用餐场所：">
                        <el-checkbox-group v-model="form.dinner_place">
                            <el-checkbox :label="item.value" border v-for="item in [{value:'31',label:'餐厅'},{value:'32',label:'食堂'},{value:'33',label:'外面'},{value:'34',label:'在家'}]" :key="item.value">{{ item.label }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="每日餐次：">
                        <el-checkbox-group v-model="form.daily_times">
                            <el-checkbox :label="item.value" border v-for="item in [{value:'41',label:'无规律'},{value:'42',label:'三-五餐'},{value:'43',label:'三餐'},{value:'44',label:'两餐'}]" :key="item.value">{{ item.label }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <h2 class="font-14 font-weight">备注（选填）</h2>
                    <el-form-item>
                        <el-input type="textarea" :rows="4" placeholder="请输入备注" v-model="form.doctor_note" />
                    </el-form-item>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="resetForm">清空</el-button>
                <el-button @click="submit">确定</el-button>
            </div>
        </el-dialog>
        <SystemUser v-if="SystemUserShow" :Dark="Dark" :Show="SystemUserShow" :UserList="doctorList" :isAddAide="isAddAide" :health="health" @addClose="addClose" @select="addUsers" />
    </div>
</template>

<script>
    import { getDoctorList, sendCode, checkCode, addEditUser, userSave } from 'quest';
    import { upLoadImage, getItem } from '@/assets/js/Cache';

    export default {
        props:{
            Edit: Boolean,
            Show: Boolean,
            patientInfo: Object,
            Dark: Boolean
        },
        components: {
            'SystemUser': () => import('./SystemUser')
        },
        watch:{
            patientInfo(newv){
                let {
                    birthday,
                    agency_nickname,
                    nickname,
                    sex,
                    suffer_type,
                    suffer_time,
                    height,
                    weight,
                    physical_level,
                    treatment_plan,
                    digest_level,
                    main_doctor_id,
                    doctor_name,
                    doctor_avatar,
                    other_doctor_ids,
                    health_manager_name,
                    health_manager_avatar,
                    sports_manager_name,
                    sports_manager_avatar,
                    note,
                    waistline,
                    hipline,
                    fatrate,
                    heartrate,
                    hba1c,
                    tc,
                    glycerate,
                    uric_acid,
                    hypotension,
                    hypertension,
                    treatment,
                    complication,
                    family_history,
                    symptom,
                    is_smoke,
                    is_drink,
                    flavor,
                    staple_food,
                    dinner_place,
                    daily_times,
                    medical_report,
                    doctor_note
                } = newv
                this.uid = newv.id;
                this.doctor_name = doctor_name;
                this.doctor_avatar = doctor_avatar;
                this.health_manager_name = health_manager_name;
                this.health_manager_avatar = health_manager_avatar;
                this.sports_manager_name = sports_manager_name;
                this.sports_manager_avatar = sports_manager_avatar;
                this.form = {
                    birthday: birthday == 0 || birthday == '' ? '' : new Date(birthday*1000) ,
                    agency_nickname: this.isCodeGet ? nickname : agency_nickname,
                    mobile: newv.mobile_show,
                    sex: sex != '' && sex != null && sex != 0 ? sex : '',
                    suffer_type: suffer_type != '' && suffer_type != null ? suffer_type : '',
                    suffer_time: suffer_time > 0 ? new Date(suffer_time * 1000) : '',
                    height,
                    weight,
                    physical_level,
                    treatment_plan: treatment_plan != '' && treatment_plan != null && treatment_plan != 0 ? treatment_plan : '',
                    digest_level: digest_level != '' && digest_level != null && digest_level != 0 ? digest_level : '',
                    main_doctor_id,
                    other_doctor_ids,
                    note,
                    waistline,
                    hipline,
                    fatrate,
                    heartrate,
                    hba1c,
                    tc,
                    glycerate,
                    uric_acid,
                    hypotension,
                    hypertension,
                    treatment,
                    complication: complication||[],
                    medical_history: complication||[],
                    family_history,
                    symptom,
                    is_smoke,
                    is_drink,
                    flavor: flavor||[],
                    staple_food: staple_food||[],
                    dinner_place: dinner_place||[],
                    daily_times: daily_times||[],
                    medical_report: medical_report||[],
                    doctor_note: doctor_note || ''
                }
                if(this.isEdit || this.isCodeGet || this.Edit){
                    this.health = newv.other_doctor_list;
                    this.form.main_doctor_id = newv.main_doctor_info.id != 0 && !( Array.isArray(newv.main_doctor_info) ) ? newv.main_doctor_info.id.toString() : '';
                    this.doctor_avatar = newv.main_doctor_info.avatar;
                    this.doctor_name = newv.main_doctor_info.name;
                    newv.other_doctor_list.map( item => {
                        this.other_doctor_ids.push(item.id)
                    })
                    let fileList = [];
                    medical_report.map( item => {
                        let str = {};
                        str.url = item;
                        fileList.push(str);
                    })
                    this.fileList = fileList;
                    this.other_doctor_ids = [...new Set(this.other_doctor_ids)]
                    this.form.other_doctor_ids = String(this.other_doctor_ids);
                }
            },
        },
        data(){
            return{
                form: {
                    mobile: '',
                    agency_nickname: '',
                    birthday: '',
                    sex: '',
                    suffer_type: '',
                    suffer_time: '',
                    height: '',
                    weight: '',
                    treatment_plan: '',
                    digest_level: '',
                    physical_level: '',
                    main_doctor_id: '',
                    other_doctor_ids: '',
                    medical_report:[],
                    waistline: '',
                    hipline: '',
                    fatrate: '',
                    heartrate: '',
                    hba1c: '',
                    tc: '',
                    glycerate: '',
                    uric_acid: '',
                    hypotension: '',
                    complication: [],
                    medical_history: [],
                    family_history: '',
                    symptom: '',
                    is_smoke: '',
                    is_drink: '',
                    flavor: [],
                    staple_food: [],
                    dinner_place: [],
                    daily_times: [],

                },
                code: '',
                countdown: '发送验证码',
                isEdit: false,
                SystemUserShow: false,
                doctorList: [],
                health: [],
                doctor_avatar: '',
                doctor_name: '',
                isAddAide: false,
                uid: null,
                rules: {
                    mobile:[
                        {
                            required: true,
                            message: '请输入手机号',
                            trigger: 'blur'
                        }
                    ],
                    agency_nickname:[
                        {
                            required: true,
                            message: '请输入姓名',
                            trigger: 'blur'
                        }
                    ],
                    birthday:[
                        {
                            required: true,
                            message: '请选择生日',
                            trigger: 'change'
                        }
                    ],
                    sex:[
                        {
                            required: true,
                            message: '请选择性别',
                            trigger: 'change'
                        }
                    ],
                    suffer_type:[
                        {
                            required: true,
                            message: '请选择患病类型',
                            trigger: 'change'
                        }
                    ],
                    suffer_time:[
                        {
                            required: true,
                            message: '请选择患病时间',
                            trigger: 'change'
                        }
                    ],
                    height:[
                        {
                            required: true,
                            message: '请输入身高',
                            trigger: 'blur'
                        }
                    ],
                    weight:[
                        {
                            required: true,
                            message: '请输入体重',
                            trigger: 'blur'
                        }
                    ],
                    treatment_plan:[
                        {
                            required: true,
                            message: '请选择治疗方式',
                            trigger: 'change'
                        }
                    ],
                    digest_level:[
                        {
                            required: true,
                            message: '请选择消化水平',
                            trigger: 'change'
                        }
                    ],
                    physical_level:[
                        {
                            required: true,
                            message: '请选择运动习惯',
                            trigger: 'change'
                        }
                    ],
                    main_doctor_id:[
                        {
                            required: true,
                            message: '请选择医生',
                            trigger: 'blur'
                        }
                    ],
                },
                isCodeGet: false,
                params: {},
                other_doctor_ids: [],
                uploadImgUrl: '',
                dialogImageUrl: '',
                fileList:[],
                dialogVisible: false,
                isIpad: false
            }
        },
        methods:{
            close(){
                this.$refs.form.resetFields();
                this.code = '';
                this.$emit('close', false);
            },

            getDoctorList(){
                getDoctorList({
                    department_id: getItem('userInfo').department_id || '',
                    page:1,
                    limit:999
                }).then( res => {
                    if(res.data.code == 100000){
                        this.doctorList = res.data.data.list;
                    }
                })
            },

            sendCode(num){
                if(num.length == 11 ){
                    if(typeof this.countdown == 'string'){
                        sendCode({
                            mobile: num
                        }).then( res =>{
                            if(res.data.code == 100000){
                                this.countdown = 59;
                                let timer = setInterval( () => {
                                    this.countdown--;
                                    if(this.countdown == 0 ){
                                        clearInterval(timer);
                                        this.countdown = '重新发送';
                                    }
                                },1000)
                                this.$message.success('验证码发送成功');
                            }else{
                                this.$message.error(res.data.msg);
                            }
                        })
                    }
                }else{
                    this.$message.error('手机号有误！');
                }
            },

            checkCode(val){
                checkCode({
                    mobile: this.form.mobile,
                    verify_code: val,
                    from: 4
                }).then( res => {
                    if(res.data.code == 100000){
                        let uid = res.data.data.uid;
                        if(uid != 0 ){
                            this.isEdit = true;
                            addEditUser({uid}).then( res =>{
                                if(res.data.code == 100000){
                                    this.isCodeGet = true;
                                    this.patientInfo = res.data.data;
                                    setTimeout( () => {
                                        this.$refs.form.validate();
                                    },200)
                                }
                            })
                        }else{
                            this.uid = '';
                        }
                        this.$message.success('验证成功！');
                    }else{
                        this.$message.error('验证码错误！');
                    }
                })
            },

            addClose(){
                this.SystemUserShow = false;
                this.isAddAide = false;
            },

            addUsers(value){
                if(!this.isAddAide){
                    this.form.main_doctor_id = value.id,
                    this.doctor_avatar = value.avatar,
                    this.doctor_name = value.name;
                }else{
                    this.health.push(value);
                    this.health.map( item => {
                        this.other_doctor_ids.push(item.id)
                    })
                    this.other_doctor_ids = [...new Set(this.other_doctor_ids)]
                    this.form.other_doctor_ids = String(this.other_doctor_ids);
                }
                this.isAddAide = false;

                this.$refs.form.validate(() => {})
            },

            clearDoctor(){
                this.form.main_doctor_id = '',
                this.doctor_avatar = '',
                this.doctor_name = '';
            },

            deleteOther(idx){
                this.health.splice(idx,1);
                this.other_doctor_ids = [];
                this.health.map( item => {
                    this.other_doctor_ids.push(item.id)
                })
                this.other_doctor_ids = [...new Set(this.other_doctor_ids)]
                this.form.other_doctor_ids = String(this.other_doctor_ids);
            },

            upLoadImg(res){
                if(res.code == 100000){
                    this.form.medical_report.push(res.data.file);
                }
            },

            delImg(val,list){
                if(list.length > 0 ){
                    let lists = [];
                    setTimeout(() =>{
                        list.map(item => {
                            lists.push(item.url);
                        })
                    },200)
                    this.form.medical_report = lists;
                }else{
                    this.form.medical_report = [];
                }
            },

            resetForm(){
                this.$refs.form.resetFields();
                this.code = '';
            },

            submit(){
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        let date = this.form.suffer_time,
                            suffer_time = date.getTime() / 1000,
                            birthday = new Date(this.form.birthday).getTime() / 1000;
                        this.form.suffer_time = suffer_time;
                        this.form.birthday = birthday;
                        delete this.form['note']
                        userSave({
                            uid: this.uid,
                            ...this.form,
                        }).then(res=>{
                            if(res.data.code == 100000){
                                this.$message.success(res.data.msg);
                                this.$refs.form.resetFields();
                                this.uid = '';
                                this.code = '';
                                this.$emit('ok')
                            }else{
                                this.$message.error(res.data.msg);
                            }
                        }).catch( () =>{
                            this.$message.error('接口异常');
                        })
                    } else {
                        this.$message.error('填写信息或者选择信息有误！');
                    }
                })
            }
        },
        mounted(){
            this.isEdit = this.Edit;
            let data = upLoadImage();
            this.uploadImgUrl = data.url,
            this.params = data.params;
            this.getDoctorList();
            this.isIpad = window.innerWidth >= 1024 && window.innerWidth <= 1366;
        }
    }
</script>


<style lang="less">

    .AddPatientDialog{
        overflow: hidden;

        .el-dialog{
            width: 80%;
            height: 80%;
            border-radius: 10px;
            position: fixed;
            margin: 0px !important;
            top: 10%;
            left: 10%;
        }

        .el-dialog__header{
            border-bottom: 1px solid #dcdfe6;
            padding: 14px 16px;
            span{
                font-size: 14px;
                color: #17233d;
            }
            button{
                margin-top: 4px;
            }
        }
        .el-dialog__body{
            height: ~'calc( 100% - 125px)';
            overflow: hidden;
            padding-bottom: 20px;

            .el-form{
                height: 100%;
                overflow: hidden;

                .left,
                .right{
                    width: 50%;
                    height: 100%;
                    overflow-y: scroll;
                    padding-left: 40px;

                    &::before{
                        content: '';
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        width: 1px;
                        height: 1000px;
                        background: #f4f4f4;
                    }

                    h2{
                        padding: 23px 30px 30px;
                        color: #9f9f9f;
                    }

                    .el-input{
                        width: 245px;
                    }

                    .workerItem{

                        .avatar{
                            width: 35px;

                            img{
                                width: 35px;
                                height: 35px;
                            }

                            i{
                                font-size: 23px;
                                bottom: 4px;
                                right: -12px;
                                color: var(--bloodSugarLow);
                                cursor: pointer;
                            }
                        }

                        .name{
                            color: #9f9f9f;
                            margin-top: -10px;
                        }
                    }

                    .addUsers{
                        font-size: 38px;
                        color: rgb(216, 216, 216);
                        font-weight: 300;
                        cursor: pointer;
                    }
                }

                .left{
                    .el-form-item{
                        .el-form-item__content{
                            max-width: 62%;
                            .el-input,
                            .el-select{
                                width: 100% !important;
                            }
                        }
                        &:nth-child( -n + 3){
                            .el-form-item__content{
                                max-width: 70%;
                                .el-input{
                                    width: 50% !important;
                                }
                            }
                        }
                    }
                }

                .right{

                    &::before{
                        content: unset !important;
                    }

                    h2{
                        padding-left: 0px;
                    }

                    .formItem{

                        .ivu-form-item-label{
                            font-size: 12px;
                            color: #515a6e;
                        }

                    }

                    .el-form-item{
                        .el-form-item__content{
                            max-width: 62%;
                            .el-input{
                                width: 100% !important;
                            }
                        }
                        &:nth-child( -n + 3){
                            .el-form-item__content{
                                max-width: 70%;
                                .el-input{
                                    width: 50% !important;
                                }
                            }
                        }
                    }

                    .el-upload{
                        width: 60px;
                        height: 60px;
                        line-height: 70px;
                        border-color: #dcdee2;
                        transition: .5s;
                        background-color: unset;

                        &:hover{
                            border-color: var(--defalutActiveColor);
                            transition: .5s;
                        }
                    }

                    .el-upload-list__item{
                        width: 60px;
                        height: 60px;
                    }

                    .el-checkbox.is-bordered{
                        margin: 0px;
                        margin-right: 8px;
                        margin-bottom: 8px;
                        padding: 0px 15px;
                        height: 32px;
                        line-height: 32px;
                        .el-checkbox__label{
                            font-size: 12px;
                        }
                    }

                    .el-input{
                        width: 350px;
                    }
                }
            }
        }

        .el-dialog__footer{
            border-top: 1px solid #dcdfe6;
            padding-top: 15px;

            button{
                padding: 5px 15px 6px;
                font-size: 12px;
                border-radius: 4px;
                line-height: 1.6;
                font-weight: normal;
                transition: .3s;

                span{
                    display: block;
                    padding-top: 2px;
                }
                &:nth-of-type(2){
                    transition: .3s;
                    background: var(--defalutColor);
                    border-color: var(--defalutColor);
                    color: white;

                    &:hover{
                        background-color: var(--defalutActiveColor);
                        border-color: var(--defalutActiveColor);
                        transition: .3s;
                    }
                }
            }
        }
    }

    .AddPatientDialog.dark{
        .el-dialog,
        .el-dialog__header,
        .el-dialog__body,
        .el-dialog__footer{
            background: #202025 !important;
            border-color: #2c2c2e !important;
            .el-form{
                .left{
                    &::before{
                        background: #2c2c2e !important;
                    }
                }
                .right{
                    .el-upload{
                        border-color: #2c2c2e;
                    }
                    .el-checkbox{
                        border-color: #2c2c2e;
                        .el-checkbox__inner{
                            background-color: #2c2c2e;
                        }
                    }
                }
            }
            .el-dialog__title,
            .el-dialog__close{
                color: #c1c1c1;
            }
            h2{
                color: #c1c1c1 !important;
            }
            .el-form-item__label{
                color: #c1c1c1 !important;
            }
            .el-input__inner{
                background-color: unset;
                color: #c1c1c1;
                border-color: #2c2c2e !important;
                &::placeholder{
                    color: #c1c1c1 !important;
                    border-color: #2c2c2e !important;
                }
            }
            .el-checkbox__label{
                color: #c1c1c1;
            }
            .el-form-item__content{
                .el-input-group__append{
                    background-color: #0c0c0c !important;
                    color: #c1c1c1 !important;
                    border-color: #2c2c2e !important;
                }
            }
            .el-button{
                background-color: unset;
                color: #c1c1c1;
                border-color: #2c2c2e;
            }
            .dialog-footer{
                button:nth-of-type(2){
                    background: var(--defalutActiveColor) !important;
                    border-color: var(--defalutActiveColor) !important;
                }
            }
            .addUsers{
                color: #c1c1c1 !important;
            }
            .el-textarea{
                textarea{
                    background-color: unset;
                    border-color: #2c2c2e;
                    &::placeholder{
                        color: #c1c1c1 !important;
                    }
                }
            }
        }
        .el-picker-panel{
            background: #202025;
            border-color: #0c0c0c;
            .el-picker-panel__icon-btn,
            .el-date-picker__header-label{
                color: #c1c1c1;
            }
            .el-date-table th{
                color: #c1c1c1;
                border-color: #0c0c0c;
            }
            .prev-month span{
                color: #c1c1c1;
            }
            .available{
                span{
                    color: #fff;
                }
                &.current{
                    span{
                        background-color: var(--defalutActiveColor);
                    }
                }
            }
            .normal.disabled div,
            .next-month.disabled div{
                background: #0c0c0c;
                color: #7f7f7f;
            }
            .popper__arrow{
                border-bottom-color: #202025;
                &::after{
                    border-bottom-color: #202025;
                }
            }
        }
        .el-select-dropdown{
            background-color: #202025;
            border-color: #0c0c0c;
            .hover{
                background-color: #0c0c0c;
            }
            .el-select-dropdown__item{
                color: #c1c1c1;
            }
            .popper__arrow{
                border-bottom-color: #0c0c0c;
                &::after{
                    border-bottom-color: #0c0c0c;
                }
            }
        }
    }

</style>
<template>
    <el-table class="listTable mt-20" :data="tableDate" :class="[!Dark ? '' : 'dark']" style="width: 100%">
            <el-table-column fixed label="头像" align="center" header-align="center" width="150">
                <template slot-scope="{ row }">
                    <img class="header radius-50" :src="row.avatar != '' && row.avatar != null ? row.avatar : notDate" alt="">
                </template>
            </el-table-column>
            <el-table-column fixed prop="agency_nickname" align="center" header-align="center" label="用户姓名" />
            <el-table-column fixed prop="age" sortable align="center" header-align="center" label="年龄" />
            <template v-if="type != 'HighAndLow' ">
                <el-table-column fixed prop="sex_value" align="center" header-align="center" label="性别" />
            </template>
            <el-table-column fixed prop="suffer_type_value" align="center" header-align="center" label="类型" />
            <template v-if="type != 'HighAndLow' ">
                <el-table-column fixed prop="agency_nickname" align="center" header-align="center" label="状态">
                    <template slot-scope="{ row }">
                        {{row.device_id ? '动态监测' : '指尖监测'}}
                    </template>
                </el-table-column>
                <el-table-column fixed prop="agency_nickname" align="center" header-align="center" label="监测设备">
                    <template slot-scope="{ row }">
                        {{row.device_type ? row.device_type : '--'}}
                        <br>
                        {{row.device_name}}
                    </template>
                </el-table-column>
                <el-table-column fixed label="最新血糖" align="center" header-align="center">
                    <template slot-scope="{ row }">
                        <template v-if="row.last_blood_sugar != ''">  
                            {{ row.last_blood_sugar_time }}
                            <div class="newbood_sugar" :class="row.last_blood_sugar_status=='normal'?'normal':(row.last_blood_sugar_status=='high' ? 'high' : 'low' )">
                                {{row.last_blood_sugar}}
                                <span>mmol/L</span>
                            </div>
                        </template>
                        <template v-else>
                            --
                        </template>
                    </template>
                </el-table-column>
                <el-table-column fixed sortable align="center" header-align="center" label="达标率">
                    <template slot-scope="{ row }">
                        {{ row.common_rate +  '%' }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" align="center" header-align="center" label="操作" width="150">
                    <template slot-scope="{ row }">
                        <el-popover placement="bottom" :append-to-body="false" width="100" trigger="hover">
                            <div class="content popover pt-10">
                                <h4 class="text-center font-16">更多菜单</h4>
                                <el-divider class="line"></el-divider>
                                <div class="pop-button text-center">
                                    <el-button class="font-14" @click.stop="toDetail(row.id)" type="text">查看详情</el-button>
                                    <el-button class="font-14" @click.stop="deleteUser(row.id)" type="text">删除成员</el-button>
                                </div>
                            </div>
                            <i class="el-icon-more font-weight font-20" slot="reference"></i>
                        </el-popover>
                    </template>
                </el-table-column>
            </template>
            <template v-else>
                <el-table-column fixed prop="device_type" align="center" header-align="center" label="监测设备">
                    <template slot-scope="{ row }">
                        {{row.device_type ? row.device_type : '/' }}
                    </template>
                </el-table-column>
                <el-table-column fixed prop="notice_blood_sugar_time" sortable align="center" header-align="center" label="预警时间" />
                <el-table-column fixed prop="notice_blood_sugar" sortable align="center" header-align="center" label="预警血糖">
                    <template slot-scope="{ row }">
                        <div class="newbood_sugar" :class="[tab == '5' ? 'high' : 'low' ]">
                            {{row.notice_blood_sugar}}
                            <span>mmol/L</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" align="center" header-align="center" label="操作" width="100">
                    <template slot-scope="{ row }">
                        <el-popover placement="bottom" :append-to-body="false" width="100" trigger="hover">
                            <div class="content popover pt-10">
                                <h4 class="text-center font-16">更多菜单</h4>
                                <el-divider class="line"></el-divider>
                                <div class="pop-button text-center">
                                    <el-button class="font-14" @click.stop="toDetail(row.id)" type="text">查看详情</el-button>
                                </div>
                            </div>
                            <i class="el-icon-more font-weight font-20" slot="reference"></i>
                        </el-popover>
                    </template>
                </el-table-column>
            </template>
    </el-table>
</template>

<script>
    export default {
        props:{
            data: Array,
            type: String,
            tab: String,
            Dark: Boolean,
        },
        watch:{
            data(val){
                this.tableDate = val;
            },
        },
        data(){
            return{
                tableDate:[],
                notDate: require('@/assets/images/error/notheader.png'),
            }
        },
        methods:{
            toDetail(id){
                this.$emit('to',id);
            },
            deleteUser(id){
                this.$emit('del',id);
            }
        },
        mounted(){
            if(this.type == 'HighAndLow'){
                this.tableDate = this.data;
            }
        }
        
    }
</script>

<style scoped lang="less">
    .listTable{

        &::before,
        .el-table__fixed::before{
            content: unset;
        }

        th.el-table__cell{
            background-color: #fcfdff;
            height: 52px;
            border: none;
        }

        /deep/ tr.hover-row>td.el-table__cell{
            cursor: pointer;
            background-color: #ebf7ff;
        }

        .el-table__row{

            .el-table__cell{
                border: none;
                height: 84px;

                .normal{
                    color: var(--defalutColor);
                    span{
                        color: #606266;
                    }
                }

                .high{
                    color: var(--bloodSugarHigh);
                    span{
                        color: #606266;
                    }
                }

                .low{
                    color: var(--bloodSugarLow);
                    span{
                        color: #606266;
                    }
                }
            }
        }

        .header{
            width: 37px;
            height: 37px;
        }
        &.dark{
            /deep/ .el-popover{
                background-color: #202025;
                border-color: #0c0c0c;
                h4{
                    color: #c1c1c1;
                }
                .el-divider{
                    background-color: #0c0c0c;
                }

                .popper__arrow{
                    border-bottom-color: #0c0c0c !important;
                    &::after{
                        border-bottom-color: #202025 !important;
                    }
                }
            }
        }
    }
</style>
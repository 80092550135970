// 登录
import Login from '@/pages/Login/index'
// 左侧导航+右侧栏
import Home from '@/components/main/index'
// 首页
import Index from '@/pages/Index/index'
// 患者列表首页
import Patient from '@/pages/Patient/index'
// 患者详情页
import PatientDetails from '@/pages/Patient/Details/index'
// 周期详情
import PatientCycle from '@/pages/Patient/Details/cycle';
// 高低血糖列表
import PatientList from '@/pages/Patient/List/index'
// 任务列表
import Task from '@/pages/Task/index'
// 方案模版
import Stencil from '@/pages/Stencil/index'
// 医院处方
import HospitalList from '@/pages/Hospital/index'
// 中央系统
import CenterSystem from '@/pages/CenterSystem/index'
// 组织管理
import Organization from '@/pages/Organization/index'
//账号设置
import Setting from '@/pages/Setting/index'

// 打印报告
import Print from '@/pages/Print/index'
// 手机打印报告
import MobilePrint from '@/pages/Print/Mobile'
// 报告暂无数据
import NotData from '@/pages/Print/NotData'

export default [
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta:{
            title: '登录'
        }
    },
    {
        path:'/',
        name:'Home',
        component: Home,
        children:[
            {
                path: '/',
                name: 'index',
                component: Index,
                meta:{
                    icon: 'el-icon-s-home',
                    title: '监控中心',
                    pageIndex: 0,
                    roleId: 5,
                }
            },
            {
                path: '/patient',
                name: 'patient',
                component: Patient,
                meta:{
                    icon: 'el-icon-menu',
                    title: '患者列表',
                    pageIndex: 1,
                    roleId: 5,
                },
            },
            {
                path: '/patient/detail',
                name: 'PatientDetails',
                component: PatientDetails,
                meta:{
                    title:'患者详情',
                    pageIndex: 1,
                    roleId: 5
                }
            },
            {
                path: '/patient/cycle',
                name: 'PatientCycle',
                component: PatientCycle,
                meta:{
                    title:'周期详情',
                    pageIndex: 1,
                    roleId: 5
                }
            },
            {
                path: '/patient/list',
                name: 'PatientList',
                component: PatientList,
                meta:{
                    title:'血糖预警列表',
                    pageIndex: 1,
                    roleId: 5
                }
            },
            {
                path: 'task',
                name: 'task',
                component: Task,
                meta:{
                    icon: 'el-icon-s-order',
                    title: '任务中心',
                    pageIndex: 2,
                    roleId: 5,
                }
            },
            {
                path: 'stencil',
                name: 'stencil',
                component: Stencil,
                meta:{
                    icon: 'el-icon-s-claim',
                    title: '方案模版',
                    pageIndex: 3,
                    roleId: 5,
                }
            },
            {
                path: 'hospital',
                name: 'HospitalList',
                component: HospitalList,
                meta:{
                    icon: 'el-icon-s-ticket',
                    title: '医院处方',
                    pageIndex: 4,
                    roleId: 5,
                }
            },
            {
                path: 'organization',
                name: 'organization',
                component: Organization,
                meta:{
                    icon: 'el-icon-s-cooperation',
                    title: '组织架构',
                    pageIndex: 6,
                    roleId: 5,
                }
            },
            {
                path: 'setting',
                name: 'setting',
                component: Setting,
                meta:{
                    icon: 'el-icon-s-custom',
                    title: '账号设置',
                    pageIndex: 7,
                    roleId: 5,
                }
            },
        ]
    },
    {
        path: '/monitor',
        name: 'CenterSystem',
        component: CenterSystem,
        meta:{
            icon: 'el-icon-message-solid',
            title: '中央监护',
            pageIndex: 6,
            roleId: 5,
        }
    },
    {
        path: '/report',
        name: 'report',
        component: Print,
        meta:{
            title: '血糖报告',
        }
    },
    {
        path: '/report/mobile',
        name: 'report',
        component: MobilePrint,
        meta:{
            title: '血糖报告',
        }
    },
    {
        path: '/report/notData',
        name: 'report',
        component: NotData,
        meta:{
            title: '暂无数据',
        }
    },
    {
        path: '/404',
        name: 'error',
        component: () => import('@/pages/Error/404.vue'),
        meta:{
            title: '404'
        }
    },
    {
        path: '/401',
        name: 'error',
        component: () => import('@/pages/Error/401.vue'),
        meta:{
            title: '401'
        }
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('@/pages/Error/error.vue'),
        meta:{
            title:'权限不足'
        }
    },
    {
        path: '/test',
        name: 'test',
        component: () => import('@/pages/TEST/test.vue'),
        meta:{
            title:'测试专用叶面'
        }
    }
]
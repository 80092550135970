<template>
    <div class="print-cont food-efficacy pion-rel" :style="{'margin-top' : !download ? '10px' : '0px' }">
        
        <div class="tiptitle">
            <div class="title">
                <h2>{{$t('menu.list.Print.page7.title')}}</h2>
                <p>{{ timeGet(bindTime,endTime) }}（{{days}}{{lang == 'en' ?  'day': '天' }}）
                    <span>{{$t('menu.list.Print.page7.times')}}：{{ coverage }}%</span>
                    <span>{{$t('menu.list.Print.page7.number')}}：{{ deviceBloodSugarTotal}} {{$t('menu.list.Print.page7.titUnit')}}</span>
                </p>
            </div>
            <img class="logo" :src="logo" alt="">
        </div>

        <div class="efficacy-conts solid-top">
            <div class="tiptitle flex align-end">
                <h1>{{$t('menu.list.Print.page7.titles')}}</h1>
            </div>
            <div class="feeicacy-list">
                <div class="feeicacy flex solid pt-15 pr-15 pb-15" v-for="( item,index) in foodRecordRank" :key="index">
                    <h2 class="text-center pson-r-50">{{index + 1}}</h2>
                    <div class="food">
                        <h4 class="font-20 mt-5">{{ item.title ? item.title : '--' }}</h4>
                        <div class="vice-food flex">
                            <p class="flex justify-between font-20 mr-20 align-center">{{$t('menu.list.Print.page5.carbohydrate')}}：{{ item.carbohydrate ? item.carbohydrate : 0}}克<span class="font-16">|</span></p>
                            <p class="flex justify-between font-20 mr-20 align-center">{{$t('menu.list.Print.page7.insulin')}}：{{ item.insulin ? item.insulin : 0 }}U<span class="font-16">|</span></p>
                            <p class="flex justify-between font-20 mr-20">{{ timeGet(item.food_time) }}&nbsp;&nbsp; {{ item.type == 0 ? $t('menu.list.Print.page7.type1') : item.type == 1 ? $t('menu.list.Print.page7.type2') : item.type == 2 ? $t('menu.list.Print.page7.type3') : $t('menu.list.Print.page7.type4') }}</p>
                        </div>
                    </div>
                    <div class="glycemic-fluctuation flex">
                        <div class="glycemic-number radius-15 text-center" :class="[item.food_range > 3.9 ? 'isHigh' : '']">
                            <p class="font-16 mt-10">{{ $t('menu.list.Print.page7.titles2') }}</p>
                            <h4>{{ changUnit(isDefaultUnit,item.food_range).val.toFixed(0) }}</h4>
                            <span class="block font-16">{{ changUnit(isDefaultUnit).unit }}</span>
                        </div>
                        <div class="fluctuation-number radius-15 text-center ml-20" :class="[ item.food_fluctuate > 2.2 ? 'isHigh' : '' ]">
                            <p class="font-16 mt-10">{{ $t('menu.list.Print.page7.unit') }}</p>
                            <h4>{{ changUnit(isDefaultUnit,item.food_fluctuate).val.toFixed(0) }}</h4>
                            <span class="block font-16">{{ changUnit(isDefaultUnit).unit }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-bottom pion-abs">
            <p>{{$t('menu.list.Print.page7.date')}}：{{toDay}}</p>
            <p>{{$t('menu.list.Print.page2.form')}}：{{ lang == 'en' ? '' : '糖动' }}CGMCARE</p>
            <!-- {{ 4 + ( foodRecordRankLen > 0 ? 1 : 0 ) + Math.ceil((daysLen  / 16 )) + Math.ceil(daysLen  / 4 ) }}/{{ 4 + ( foodRecordRankLen > 0 ? 1 : 0 ) + Math.ceil((daysLen  / 16 )) + Math.ceil(daysLen / 4) + ( Math.ceil(daysLen / 16) > 1 ? 2 : 1 ) + Number(pageAdd) }} -->
            <p>{{$t('menu.list.Print.page7.page')}}：{{ pageNum }}/{{ sum }}</p>
        </div>
    </div>
</template>

<script>
    import { time, getItem, changUnit } from '_js/Cache';
    export default {
        props:{
            download: Boolean,
            logo: String,
            bindTime: Number,
            endTime: Number,
            days: Number,
            coverage: Number,
            deviceBloodSugarTotal: Number,
            foodRecordRank: Array,
            toDay: String,
            foodRecordRankLen: Number,
            daysLen: Number,
            pageAdd: Number || String,
            isDefaultUnit: Number
        },
        data(){
            return{
                sum: 0,
                pageNum: 5,
                lang: getItem('lang')
            }
        },
        watch:{
            parintList:{
                handler(){
                    this.getPageSum();
                },
                deep: true
            },
            pageAdd:{
                handler(){
                    this.getPageSum();
                },
                deep: true
            }
        },
        methods:{
            timeGet(start,end){
                return end ? time(new Date(start * 1000 ),this.lang == 'en' ? 'YYYY/MM/DD' : '年月日' ) + '—' + time(new Date(end * 1000 ),this.lang == 'en' ? 'MM/DD' : '月日') : time(new Date(start),'MM/DD');
            },
            getPageSum(){
                setTimeout(() =>{
                    let doms = document.getElementsByClassName('print-cont'),
                        listArr = Array.from(doms);
                    this.sum = doms.length;

                    listArr.map((item,index) =>{
                        item.className.split(' ').map(items =>{
                            if(items == 'food-efficacy'){
                                this.pageNum = index + 1;
                            }
                        })
                    })
                })
            },
            changUnit(t,v){
                return changUnit(t,v);
            }
        },
        mounted(){
            this.getPageSum();
        }
    }
</script>

<style lang="less" scoped>

    .food-efficacy{
        .efficacy-conts{
            padding-top: 34px;
            margin-top: 43px;
            border-color: #e5e5e5;
            .tiptitle{
                h1{
                    font-size: 37px;
                    color: #000;
                }
            }
            .feeicacy-list{
                margin-top: 30px;
                .feeicacy{
                    border-color: #e5e5e5;
                    margin: 30px 0px;
                    padding-left: 22px;
                    h2{
                        width: 37px;
                        height: 37px;
                        line-height: 37px;
                        color: white;
                        background: #7B7B7B;
                    }
                    .food{
                        margin-left: 22px;
                        h4{
                            color: #000;
                        }
                        .vice-food{
                            margin-top: 40px;
                            p{
                                width: 160px;
                                color: #979797;
                            }
                        }
                    }
                    .glycemic-fluctuation{
                        margin-left: auto;
                        &>div{
                            width: 105px;
                            height: 105px;
                            color: white;
                            h4{
                                margin-top: -5px;
                                font-size: 38px;
                            }
                            span{
                                margin-top: -10px;
                            }

                        }
                        .glycemic-number{
                            background: #26C3A8;
                        }
                        .fluctuation-number{
                            background: #26C3A8;
                        }

                        .high{
                            background: #F7B500 !important;
                        }
                        .isHigh{
                            background: #E56555 !important;
                        }
                    }
                }

                .feeicacy:nth-of-type(1){
                    h2{
                        background: #E03520 !important;
                    }
                }

                .feeicacy:nth-of-type(2){
                    h2{
                        background: #E56555 !important;
                    }
                }

                .feeicacy:nth-of-type(3){
                    h2{
                        background: #FFA800 !important;
                    }
                }
            }
        }
    }
</style>
<template>
    <div class="addRole" :class="[!Dark ? '' : 'dark']">

        <el-dialog title="添加角色" width="1240" :visible.sync="show" :modal-append-to-body="false" :before-close="close">
            <el-form label-position="top" label-width="140px">
                 <el-form-item label="角色名称">
                    <el-input v-model="name" placeholder="请输入角色名称"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="close">取消</el-button>
                <el-button @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { addRole } from 'quest';
    export default {
        props:{
            show: Boolean,
            info: Object,
            Dark: Boolean
        },
        data(){
            return{
                name: ''
            }
        },
        watch:{
            info(val){
                this.name = val.title;
            }
        },
        methods:{
            close(){
                this.$emit('close', false);
                this.name = '';
            },
            submit(){
                if(!this.name){
                    this.$message({
                        message: '请填写角色名称!',
                        type: 'warning',
                    });
                }else{
                    addRole({title: this.name,[this.info.id ? 'id' : '' ]: this.info.id ? this.info.id : '' }).then( res => {
                        if(res.data.code == 100000){
                            this.$message({
                                message: '保存成功！',
                                type: 'success',
                            });
                            this.name = '';
                        }else{
                            this.$message({
                                message: '保存失败！请联系管理员查看具体原因',
                                type: 'error',
                            });
                        }
                        this.$emit('save', true);
                    })
                }
            }
        }
    }
</script>

<style lang="less" scoped>

    .addRole /deep/ .el-dialog{
        width: 20vw;
        overflow: hidden;
        border-radius: 10px;

        .el-dialog__header{
            border-bottom: 1px solid #dcdfe6;
            padding: 14px 16px;
            span{
                font-size: 14px;
                color: #17233d;
            }
            button{
                margin-top: 4px;
            }
        }

        .el-select{
            width: 100%;
        }

        .el-form-item__label{
            font-size: 12px;
            padding-bottom: 0px;
            line-height: unset;
        }

        input{
            font-size: 12px;
            height: 32px;
            line-height: 1;

            &::placeholder{
                font-size: 12px;
            }
        }

        .addIcon{
            font-size: 32px;
            color: #16CCA6;
            cursor: pointer;
            margin-top: 10px;
        }

        .el-dialog__footer{
            border-top: 1px solid #dcdfe6;
            padding-top: 15px;

            button{
                padding: 5px 15px 6px;
                font-size: 12px;
                border-radius: 4px;
                line-height: 1.6;
                font-weight: normal;
                transition: .3s;

                span{
                    display: block;
                    padding-top: 2px;
                }
                &:nth-of-type(2){
                    transition: .3s;
                    background: var(--defalutColor);
                    border-color: var(--defalutColor);
                    color: white;

                    &:hover{
                        background-color: var(--defalutActiveColor);
                        border-color: var(--defalutActiveColor);
                        transition: .3s;
                    }
                }
            }
        }

        .user{
            width: 35px;
            cursor: pointer;

            img{
                width: 35px;
                height: 35px;
            }

            p{
                margin-top: -20px;
            }
        }
    }

    .addRole.dark /deep/ .el-dialog{

        .el-dialog__header,
        .el-dialog__body,
        .el-dialog__footer{
            background: #202025 !important;
            border-color: #2c2c2e !important;
            .el-dialog__title,
            .el-dialog__close{
                color: #c1c1c1;
            }
            .el-form-item__label{
                color: #c1c1c1 !important;
            }
            .el-input__inner{
                color: #c1c1c1;
                background-color: unset;
                border-color: #2c2c2e;
                &::placeholder{
                    color: #c1c1c1 !important;
                    border-color: #2c2c2e !important;
                }
            }
            .el-button{
                background-color: unset;
                color: #c1c1c1;
                border-color: #2c2c2e;
            }
            .dialog-footer{
                button:nth-of-type(2){
                    background: var(--defalutActiveColor) !important;
                    border-color: var(--defalutActiveColor) !important;
                }
            }
        }
    }

    @media screen{
        @media (max-width: 1366px) {
            
            .addRole /deep/ .el-dialog{
                width: 25%;
                margin-top: 20% !important;
            }
        }
    }
</style>
<template>
    <div class="Stencil pages pl-18 pr-18 mt-20" :class="[!Dark ? '' : 'dark', collapsed ? 'hide' : '' ]">
        <div class="scroll pt-20 pb-20 pl-20 pr-20 radius-10" v-loading="loading">
            <template v-if="!loading">
                <el-tabs class="pion-rel" v-model="tab">
                    <el-tab-pane label="我的模版" name="0" />
                    <el-tab-pane label="全部模版" name="1" />
                    <div class="buttons pion-abs flex">
                        <el-button class="line-height-32 font-12 ml-15 font-normal" plain @click="showAdd">添加模版</el-button>
                    </div>
                </el-tabs>
                <div class="list-temp flex flex-wrap" v-if="templateList && templateList.length">
                    <div class="list float-left pt-15 pb-15 pl-18 pr-18 radius-10 ml-10 mr-10 mt-10 mb-10 solid" v-for="(item,index) in templateList" :key="index" @click="showLable(true,item.id)">
                        <div class="tip-list flex align-center">
                            <img class="block pson-r-50 clear" :src="item.dcotor_avatar" onerror="this.src='https://webimg.ziroom.com/1c853bc6-21fc-4e32-8a6f-18ec2c6fef32.png'" alt="">
                            <h4 class="font-16 ml-10">{{item.doctor_name}}</h4>
                            <p class="ml-10 font-14 mt-2">创建</p>
                            <el-tooltip class="deletePlan" effect="dark" content="删除" placement="bottom" v-if="tab == '0'">
                                <el-button class="none" @click.stop="deletePlan(item.id)">
                                    <i class="el-icon-delete-solid"></i>
                                </el-button>
                            </el-tooltip>
                        </div>
                        <div class="body mt-20">
                            <h1 class="font-18">{{item.title}}</h1>
                            <p class="font-14 mt-5">{{item.agency_title}}</p>
                        </div>
                        <div class="foot">
                            <p class="font-16">平均每日摄入</p>
                            <div class="info mt-5 flex flex-wrap">
                                <p class="font-12 mr-15">碳水：<span>{{item.avg_carbohydrate}}克</span></p>
                                <p class="font-12 mr-15" v-if="!isIpad">脂肪：<span>{{item.avg_protein}}克</span></p>
                                <p class="font-12 mr-15" v-if="!isIpad">蛋白质：<span>{{item.avg_fat}}克</span></p>
                                <p class="font-12 mr-15">运动：<span>{{item.avg_calorie}}千卡</span></p>
                                <p class="font-12 mr-15">口服药：<span>{{item.avg_medicine}}次</span></p>
                                <p class="font-12 mr-15">胰岛素：<span>{{item.avg_insulin}}U</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="empty" v-else>
                    <div class="box-center">
                        <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                        <img src="@/assets/images/error/dark_empty.png" v-else alt />
                        <p>暂无数据</p>
                    </div>
                </div>
                <div class="pages flex justify-center">
                    <el-pagination background layout="prev, pager, next, jumper" @current-change="selectPage" :current-page="page" prev-text="上一页" next-text="下一页" :total="count" :page-size="16"></el-pagination>
                </div>
            </template>
        </div>
        <AddPlan :parameter="parameter" :Dark="Dark" @closePlan="close" @getTempList="getTempList" />
    </div>
</template>

<script>
    import { getDoctorPlanList, doctorPlanDelete } from 'quest';
    import { AddPlan } from '_c/Model/index';
    export default {
        props:{
            Dark: Boolean,
            collapsed: Boolean
        },
        data(){
            return{
                loading: true,
                templateList: [],
                tab: '0',
                isIpad: window.innerWidth >= 1024 && window.innerWidth <= 1366,
                page: 1,
                isAll: false,
                count: 0,
                // notDate: require('@/assets/images/error/notheader.png'),
                parameter:{
                    from:'programme',
                    show: false,
                    id: 0,
                    isAddTemp: false
                },
            }
        },
        watch:{
            tab(val){
                if(val == '1'){
                    this.isAll = true;
                }else{
                    this.isAll = false;
                }
                this.getTempList();
            },

            'page'(){
                this.loading = true;
                this.getTempList();
            }
        },
        components:{
            AddPlan
        },
        methods:{
            getTempList(){
                getDoctorPlanList({
                    page: this.page,
                    [this.isAll ? 'scope' : ''] : this.isAll ? 'all' : ''
                }).then( res =>{
                    if(res.data.code == 100000){
                        this.templateList = res.data.data.list,
                        this.count = res.data.data.count;
                        this.loading = false
                    }
                })
            },
            selectPage(page){
                this.page = page
            },
            showLable(type,id){
                this.parameter.show = type;
                this.parameter.isAddTemp = false;
                this.parameter.id = id;
            },
            showAdd(){
                this.parameter.show = true,
                this.parameter.isAddTemp = true;
                this.id = 0;
            },
            deletePlan(id){
                this.$confirm('此操作将永久删除该方案, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    doctorPlanDelete({
                        id
                    }).then( res =>{
                        if(res.data.code == 100000){
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            setTimeout(() =>{
                                this.getTempList();
                            },200)
                        }else{
                            this.$message({
                                type: 'error',
                                message: '删除失败' + res.msg
                            });
                        }
                    })
                })
            },
            close(val){
                window.isCloseHint = val;
                this.parameter.show = val;
                this.parameter.id = 0;
                if(!val){
                    for(let i = 0; i < 7 ; i++ ){
                        localStorage.removeItem('day' + i);
                    }
                }
            },

        },
        mounted(){
            this.getTempList();
        }
    }
</script>

<style scoped lang="less">

    .Stencil{
        height: ~'calc(100% - 70px)';
        .scroll{
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            background: var(--white);

            .list-temp{

                .list{
                    width: ~'calc( 25%  - 20px)';
                    max-height: 245px;
                    border-color: #ebebeb;

                    .tip-list{
                        
                        img{
                            width: 32px;
                            height: 32px;
                            object-fit: cover;
                        }
                        h4{
                            color: #737373;
                        }
                        .drop{
                            margin-left: auto;
                        }
                        .deletePlan{
                            margin-left: auto;
                            border: none;
                            padding: 0px;
                            cursor: pointer;
                            i{
                                transition: .3s;
                                color: #9F9F9F;
                            }
                            &:hover{
                                i{
                                    transition: .3s;
                                    color: #202025;
                                }
                            }
                        }
                    }

                    .tip-list /deep/ .dropDown{
                        margin-left: auto !important;
                    }

                    .body{
                        h1{
                            color: black;
                        }
                        p{
                            color: #737373;
                        }
                    }

                    .foot{
                        margin-top: 30px;
                        p{
                            color: black;
                        }
                        .info{

                            p{
                                color: #9f9f9f;
                            }
                        }
                    }

                    &:hover{
                        .deletePlan{
                            display: block;
                        }
                    }
                }
            }
        }

        .scroll /deep/ .el-tabs{

            .el-tabs__nav-wrap:after{
                height: 1px;
                background-color: #dcdee2;
            }

            .el-tabs__nav-scroll{
                padding-left: 20px;
            }

            .el-tabs__active-bar{
                height: 2px;
                background-color: var(--defalutColor);
            }
            
            .el-tabs__item.is-active{
                font-weight: 100;
                color: var(--defalutColor);
            }
            
            .el-tabs__item{
                transition: .3s;
            }
            
            .el-tabs__item:hover{
                transition: .3s;
                color: var(--defalutColor);
            }

            .el-tabs__content{
                overflow: visible;
            }

            .buttons{
                top: -60px;
                right: 0px;
                z-index: 999;

                button{
                    height: 32px;
                    width: 120px;
                    padding: 0px 10px;
                    transition: .3s;
                    line-height: 7px;

                    &:hover{
                        border-color: var(--defalutActiveColor) !important;
                        color: var(--defalutActiveColor) !important;
                        transition: .3s;
                    }
                    &:focus{
                        background: none;
                        border-color: var(--defalutActiveColor) !important;
                        color: var(--defalutActiveColor) !important;
                    }
                }
            }
        }

        .scroll /deep/ .pages{
            .btn-prev,
            .btn-next{
                padding: 0px 20px;
                float: left;
                background: none;
                transition: .3s;
                &:hover{
                    color: var(--defalutColor);
                    transition: .3s;
                }
                &[disabled]{
                    color: #c0c4cc;
                }
            }
            .el-pager{
                float: left;
                li{
                    background: none;
                    border: 1px solid #dcdee2;
                    transition: .3s;
                    &:hover{
                        border-color: var(--defalutColor);
                        color: var(--defalutColor);
                        transition: .3s;
                        cursor: pointer;
                    }
                    &.active{
                        border-color: var(--defalutColor);
                        color: var(--defalutColor);
                        transition: .3s;
                    }
                }
            }
            .el-input__inner:focus{
                border-color: var(--defalutColor);
            }
        }

        &.dark{
            .scroll{
                background: #202025 !important;
                .list{
                    border-color: #2c2c2c !important;
                    h4,
                    .body h1,
                    .foot p{
                        color: #c1c1c1 !important;
                    }
                    .tip-list p,
                    .body p,
                    .info p{
                        color: #9f9f9f !important;
                    }
                }
            }

            .scroll /deep/ .el-tabs{

                .el-tabs__item{
                    color: #c1c1c1 !important;
                    &.is-active{
                       color: var(--defalutColor) !important; 
                    }
                }

                .el-tabs__nav-wrap:after{
                    background-color: #2c2c2c;
                }

                .buttons{
                    button{
                        background: unset;
                        color: #c1c1c1 !important;
                        border-color: #2c2c2c;
                    }
                }
            }

            & /deep/ .pages{
                .btn-prev{
                    color: #c1c1c1 !important;
                    &[disabled]{
                        color: #2c2c2e !important;
                        &:hover{
                            color: #2c2c2e !important;
                        }
                    }
                    &:hover{
                        color: var(--defalutColor) !important;
                    }
                }
                .number{
                    color: #c1c1c1 !important;
                    border-color: #2c2c2e !important;
                    &.active{
                        color: var(--defalutColor) !important;
                        border-color: var(--defalutColor)!important;
                    }
                }
                .btn-next{
                    color: #c1c1c1 !important;
                    &[disabled]{
                        color: #2c2c2e !important;
                        &:hover{
                            color: #2c2c2e !important;
                        }
                    }
                    &:hover{
                        color: var(--defalutColor) !important;
                    }
                }
                .el-pagination__jump{
                    color: #c1c1c1 !important;
                    input{
                        background-color: unset;
                        border-color: #2c2c22;
                        color: #c1c1c1;
                    }
                }
            }
        }
    }

    @media screen {
        @media (max-width: 1366px) and (min-width: 768px) {
            .Stencil{
                .list-temp{
                    .list{
                        width: ~'calc( 100% / 3  - 20px)' !important;
                    }
                }
            }
        }
    }
</style>
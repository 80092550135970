<template>
    <div class="bar " ref="dom" v-if="value"></div>
</template>

<script>

    import echarts from 'echarts'
    import tdTheme from './theme.json'
    import { on, off, getItem } from '_js/Cache'
    echarts.registerTheme('tdTheme', tdTheme)
    export default {
        props:{
            value: Array,
        },
        watch:{
            'value':{
                handler(val){
                    if(val.length != 0 ){
                        this.init();
                    }
                },
                deep: true,
            }
        },
        data () {
            return {
                dom: null,
                isDark: false
            }
        },
        methods:{
            resize () {
                this.dom.resize()
            },

            init(){
                let lineOption = {
                    lineStyle: {
                        color: this.isDark ? '#2c2c2e' : 'rgba(151,151,151,0.5)'
                    }
                }
                let fontColor = '#666'
                this.$nextTick(() => {
                    let xAxisData = ["1-10","10-20","20-30","30-40","40-50","50-60","60-70",">80"]
                    let seriesData = this.value
                    let option = {
                        title: {
                            x: 'left',
                            textStyle: {
                            fontSize: '12px',
                            color: '#333'
                            }
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                            type: 'shadow',
                            shadowStyle:{
                                opacity: 0.5
                            }
                            },
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: xAxisData,
                                axisLine: {
                                lineStyle: {
                                    color: 'rgba(151,151,151,0)',
                                    type: 'dashed'
                                }
                            },
                            splitLine: {
                                lineStyle: {
                                    color: 'rgba(151,151,151,0)',
                                    type: 'dashed'
                                }
                            },
                            axisTick: {
                                show: false
                            },
                            axisLabel: {
                                // margin: 10,
                                color: this.isDark ? '#c1c1c1' : fontColor,
                                textStyle: {
                                    fontSize: 12
                                }
                            }
                        }],
                        yAxis: [{
                            axisLabel: {
                                formatter: '{value}',
                                color: this.isDark ? '#c1c1c1' : fontColor
                            },
                            axisTick: {
                                show: false
                            },
                            axisLine: lineOption,
                            splitLine: lineOption
                        }],
                        series: [{
                            data: seriesData,
                            barWidth: '80%',
                            type: 'bar',
                            itemStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: '#00BD89' // 0% 处的颜色
                                        }, {
                                        offset: 1,
                                        color: '#C9F9E1' // 100% 处的颜色
                                    }], false)
                                }
                            }
                        }]
                    }
                    this.dom = echarts.init(this.$refs.dom, 'tdTheme')
                    this.dom.setOption(option)
                    on(window, 'resize', this.resize)
                })
            }
        },
        created(){
            this.isDark = getItem('dark') || false;
        },
        beforeDestroy () {
            off(window, 'resize', this.resize)
        }
    }
</script>

<style scoped>
    .bar{
        width: 90%;
        margin: 0 auto;
        height: 300px;
    }
</style>
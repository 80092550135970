<template>
    <div class="Login clear pion-fixed">
        <div class="login-from">
            <el-card class="card" shadow="never">
                <h4 class="font-weight">糖尿病数字化管理平台</h4>
                <el-form :model="login" :rules="loginRules" ref="login">
                    <el-form-item prop="username">
                        <el-input placeholder="请输入账号" v-model="login.username"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input type="password" placeholder="请输入密码" @focus="change = true" @blur="change = false" v-model="login.password" @keyup.enter.native="onLogin"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-checkbox v-model="checked">记住密码</el-checkbox>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="mt-25 font-18" type="primary" @click="onLogin" v-loading.fullscreen.lock="loading">登录</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </div>
        <div class="foot">
            <div class="logo">
                <img src="@/assets/images/icons/foot_logo.png" alt="">
            </div>
            <div class="rightText text-right">
                <p>
                    北京华云慧医科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;京ICP备2021017834号-1&nbsp;&nbsp;&nbsp;&nbsp;增值电信业务经营许可证：京B2-20221892
                </p>
                <p class="mt-5">
                    (京)网药械信息备字(2022)第00589号&nbsp;&nbsp;Copyright @2017-2021 Beijing Huayuan WITMED Tech Co.Ltd All RIghts Reserved
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import { login, getUserInfo, agencyInfo } from 'quest';
    import { getItem, setItem, removeItem } from '_js/Cache';
    export default {
        watch:{
            login:{
                handler(){
                    this.checked = false;
                },
                deep: true,
            }
        },
        data(){
            return{
                login:{
                    username:'',
                    password:'',
                },
                loading: true,
                checked: false,
                loginRules:{
                    username:[
                        {
                            required: true,
                            message: '账号不能为空',
                            trigger: 'blur'
                        },
                    ],
                    password:[
                        {
                            required: true,
                            message: '密码不能为空',
                            trigger: 'blur'
                        }
                    ]
                },
                change: false
            }
        },
        methods:{


            
            onLogin(){
                let data = this.login;
                login(data).then( res =>{
                    if(res.data.code === 100000){
                        if(this.checked){
                            setItem('user', JSON.stringify(this.login));
                        }
                        setItem('token',res.data.data.token);
                        this.loading = true;
                        getUserInfo().then( res =>{
                            if(res.data.code === 100000){
                                setTimeout(() => {
                                    this.loading = false;
                                    this.$router.push({
                                        path: '/'
                                    })
                                },1000)
                                setItem('userInfo',res.data.data);
                                agencyInfo().then(res => {
                                    if(res.data.code == 100000) {
                                        this.$message({
                                            message: '登录成功！',
                                            type: 'success'
                                        });
                                        setTimeout(() => {
                                            this.loading = false;
                                            this.$router.push({
                                                path: '/'
                                            })
                                        },1000)
                                        setItem('agencyInfo',res.data.data);
                                    }else{
                                        this.$message.error(res.data.msg);
                                        removeItem('token');
                                    }
                                })
                            }else{
                                this.$message.error(res.data.msg);
                                removeItem('token');
                            }
                        })
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        mounted(){
            if(getItem('user') != null){
                this.login = getItem('user');
                setTimeout( () => {
                    this.loading = false;
                    this.checked = true;
                },200)
            }else{
                this.loading = false;
            }
            document.onkeydown = () => {
                let _key = window.event.keyCode;
                if (_key === 13 && this.change == false) {
                    this.onLogin();
                    document.onkeydown = null;
                }
            }

        }
    }
</script>

<style lang="less" scoped>
    .wh100{
        width: 100%;
        height: 100%;
    }
    .Login{
        .wh100;
        background-image: url('../../assets/images/Login/background.png');
        background-size: cover;
        background-position: center;
        .login-from{
            width: 540px;
            height: 486px;
            background: white;
            position: fixed;
            top: ~'calc(50% - 205px)';
            left: ~'calc(50% - 260px)';
            border-radius: 10px;
        }
        .login-from /deep/ .el-card{
            .wh100;
            .el-card__body{
                .wh100;
                h4{
                    padding-top: 25px;
                    color: var(--defalutColor);
                    font-size: ~'calc(10px + 2vmin)';
                    font-family: PingFang SC, PingFang SC-Semibold;
                    text-align: center;
                }
                .el-form{
                    width: ~'calc(100% - 80px)';
                    margin: 0 auto;
                    margin-top: 40px;
                    input{
                        width: 100%;
                        height: 64px;                
                        border: 1px solid #c4cac6;
                        border-radius: 4px;
                        padding: 0px 18px;
                        outline: none;
                        transition: .3s;
                    }
                    input::placeholder{
                        font-size: 16px;
                        color: #9f9f9f;
                        letter-spacing: 0.5px;
                    }

                    .is-checked{
                        .el-checkbox__inner{
                            background-color: var(--defalutColor);
                        }
                        span{
                            color: var(--defalutColor);
                            border-color: var(--defalutColor);
                        }
                    }

                    .el-checkbox__inner:hover{
                        border-color: var(--defalutColor);
                    }

                    .is-focus{
                        border-color: var(--defalutColor);
                    }

                    input:hover{
                        border-color: var(--defalutColor);
                    }
                    input:focus{
                        transition: .3s;
                        border-color: var(--defalutColor);
                    }
                    button{
                        width: 100%;
                        height: 64px;
                        background: var(--defalutColor);
                        cursor: pointer;
                        color: white;
                        transition: .3s;
                        border-color: var(--defalutColor);
                        &:active{
                            transition: .3s;
                            background: var(--defalutActiveColor);
                        }
                    }
                }
            }
        }
        .foot{
            position: fixed;
            bottom: 0px;
            width: 100%;
            height: 66px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 20px;
            background-color: #fff;
            opacity: .3;
            .logo{
                display: flex;
                align-items: center;
                img{
                    height: 28px;
                    object-fit: cover;
                }
            }
            .rightText{
                p{
                    font-size: 14px;
                    color: #727272;
                }
            }
        }
    }
</style>
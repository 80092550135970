<template>
    <div class="chartsHistogram">
        <div ref="dom" v-if="imgData == ''"></div>
        <img :src="imgData" alt="" v-else>
    </div>
</template>

<script>
import echarts from "echarts";
import tdTheme from "../Charts/theme.json";
import { on, off } from "_js/Cache";
let dom = null;

echarts.registerTheme("tdTheme", tdTheme);
export default {
    props:{
        data: Object
    },
    data(){
        return{
            imgData: ''
        }
    },
    methods:{
        init(){
            let low = this.data.low,
                normal = this.data.normal,
                high = this.data.high,
                xArr = this.data.x_arr.map( item =>{
                    return item.split('-')[1] + '-' + item.split('-')[2];
                });
            let option = {
                animation: false,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    show: false,
                },
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: xArr
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        interval: 25,
                        max: 125
                    },
                ],
                series: [
                    {
                        name: '',
                        type: 'bar',
                        stack: 'Ad',
                        emphasis: {
                            focus: 'series'
                        },
                        itemStyle:{
                            color: '#E03520',
                            barBorderRadius:[0, 0, 50, 50]
                        },
                        zlevel: 1,
                        data: low
                    },
                    {
                        type: 'bar',
                        stack: 'Ad',
                        emphasis: {
                            focus: 'series'
                        },
                        itemStyle:{
                            color: '#C4C4C4',
                            barBorderRadius:[0, 0, 0, 0]
                        },
                        zlevel: 1,
                        barWidth : 30,
                        data: normal
                    },
                    {
                        type: 'bar',
                        stack: 'Ad',
                        emphasis: {
                            focus: 'series'
                        },
                        itemStyle:{
                            color: '#F7B500',
                            barBorderRadius:[50, 50, 0, 0]
                        },
                        zlevel: 1,
                        data: high
                    }
                ]
            };
            dom = echarts.init(this.$refs.dom, "tdTheme");
            dom.setOption(option);
            this.imgData = dom.getDataURL({
                pixelRatio: 2
            });
            dom.dispose();
            on(window, "resize", this.resize);
        }
    },

    mounted(){
        this.init();
    },

    beforeDestroy() {
        off(window, "resize", this.resize);
    }

}
</script>

<style lang="less" scoped>
    .chartsHistogram{
        width: 100%;
        height: 100%;
        div{
            width: 100%;
            height: 100%;
        }
        img{
            width: 100%;
            height: 100%;
        }
    }
</style>
<template>
    <div class="lists pages pl-18 pr-18 mt-20" :class="[!Dark ? '' : 'dark', collapsed ? 'hide' : '' ]">
        <div class="scroll pt-20 pb-20 pl-20 pr-20 radius-10" v-loading="loading">
            <el-tabs class="pion-rel" v-model="tab">
                <el-tab-pane label="高血糖事件" name="5" />
                <el-tab-pane label="低血糖事件" name="1" />
            </el-tabs>
            <Table :data="tableDate" :type="'HighAndLow'" :tab="tab" @to="toDetail" v-if="tableDate.length > 0" />
            <div class="empty" v-else>
                <div class="box-center">
                    <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                    <img src="@/assets/images/error/dark_empty.png" v-else alt />
                    <p>暂无数据</p>
                </div>
            </div>
            <div class="pages flex justify-center">
                <el-pagination background layout="prev, pager, next, jumper" @current-change="selectPage" :current-page="page" prev-text="上一页" next-text="下一页" :hide-on-single-page="hiddenPage" :total="count" :page-size="20"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import { Table } from '_c/Model/index';
    import { getBloodSugarNoticeList } from 'quest'
    export default {
        props:{
            Dark: Boolean,
            collapsed: Boolean
        },
        data(){
            return{
                loading: true,
                tab: '5',
                tableDate: [],
                count: 0,
                page: 1,
                hiddenPage: true,
            }
        },
        components:{
            Table,
        },
        watch:{
            tab(){
                this.getBloodSugarNoticeList();
            }
        },
        methods:{
            getBloodSugarNoticeList(){
                this.loading = true;
                getBloodSugarNoticeList({
                    type: this.tab,
                    page: this.page,
                    limit: 20
                }).then( res =>{
                    if(res.data.code == 100000){
                        this.tableDate = res.data.data.list;
                        this.count = res.data.data.count;
                        this.loading = false;
                    }
                })
            },
            selectPage(page){
                this.page = page;
                this.getBloodSugarNoticeList();
            },
            toDetail(id){
                this.$router.push({
                    name: 'PatientDetails',
                    query:{
                        id: id,
                    }
                })
            }
        },
        mounted(){
            if(JSON.parse(this.$route.query.isHigh) == true){
                this.tab = '5';
            }else{
                this.tab = '1';
            }
            this.getBloodSugarNoticeList();
        }
    }
</script>

<style scoped lang="less">
    .lists{
        height: ~'calc(100% - 70px)';
        .scroll{
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            background: var(--white);
        }

        .scroll /deep/ .el-tabs{

            .el-tabs__nav-wrap:after{
                height: 1px;
                background-color: #dcdee2;
            }

            .el-tabs__nav-scroll{
                padding-left: 20px;
            }

            .el-tabs__active-bar{
                height: 2px;
                background-color: var(--defalutColor);
            }
            
            .el-tabs__item.is-active{
                font-weight: 100;
                color: var(--defalutColor);
            }
            
            .el-tabs__item{
                transition: .3s;
            }
            
            .el-tabs__item:hover{
                transition: .3s;
                color: var(--defalutColor);
            }

            .el-tabs__content{
                overflow: visible;
            }
        }
        
        .scroll /deep/ .listTable{

            &::before,
            .el-table__fixed::before{
                content: unset;
            }

            .el-table__fixed-right:before{
                content:  unset;
            }

            th.el-table__cell{
                background-color: #fcfdff;
                height: 52px;
                border: none;
            }

            .el-table__row{

                &:hover,
                &.hover-row{
                    background-color: #ebf7ff;
                    cursor: pointer;
                }

                .el-table__cell{
                    border: none;
                    height: 84px;
                    background-color: unset;

                    .normal{
                        color: var(--defalutColor);
                        span{
                            color: #606266;
                        }
                    }

                    .high{
                        color: var(--bloodSugarHigh);
                        span{
                            color: #606266;
                        }
                    }

                    .low{
                        color: var(--bloodSugarLow);
                        span{
                            color: #606266;
                        }
                    }
                }
            }

            .header{
                width: 37px;
                height: 37px;
            }
        }

        .scroll /deep/ .pages{
            .btn-prev,
            .btn-next{
                padding: 0px 20px;
                float: left;
                background: none;
                transition: .3s;
                &:hover{
                    color: var(--defalutColor);
                    transition: .3s;
                }
                &[disabled]{
                    color: #c0c4cc;
                }
            }
            .el-pager{
                float: left;
                li{
                    background: none;
                    border: 1px solid #dcdee2;
                    transition: .3s;
                    &:hover{
                        border-color: var(--defalutColor);
                        color: var(--defalutColor);
                        transition: .3s;
                        cursor: pointer;
                    }
                    &.active{
                        border-color: var(--defalutColor);
                        color: var(--defalutColor);
                        transition: .3s;
                    }
                }
            }
            .el-input__inner:focus{
                border-color: var(--defalutColor);
            }
        }
        &.dark{
            .scroll{
                background: #202025 !important;
            }

            .scroll /deep/ .el-tabs{

                .el-tabs__item{
                    color: #c1c1c1 !important;
                    &.is-active{
                       color: var(--defalutColor) !important; 
                    }
                }

                .el-tabs__nav-wrap:after{
                    background-color: #2c2c2e;
                }

                .buttons{
                    input{
                        background: unset;
                        border-color: #2c2c2e;

                        &::placeholder{
                            color: #e8e8e8;
                        }
                    }
                    button{
                        background: unset;
                        color: white !important;
                        border-color: #2c2c2e;
                    }
                }
            }

            .scroll /deep/ .listTable{
                background-color: unset;

                thead{
                    color: #c1c1c1;
                }

                th.el-table__cell{
                    background-color: black !important;
                    &>div{
                        color: #c1c1c1;
                    }
                }

                tr{
                    background: unset;
                }

                .el-table__row{

                    td>div{
                        color: #c1c1c1 !important;
                    }

                    &:hover,
                    &.hover-row{
                        background-color: #585858;
                    }

                    .newbood_sugar{
                        span{
                            color: #ccc !important;
                        }
                    }
                }
            }

            & /deep/ .pages{
                .btn-prev{
                    color: #c1c1c1 !important;
                    &[disabled]{
                        color: #2c2c2e !important;
                        &:hover{
                            color: #2c2c2e !important;
                        }
                    }
                    &:hover{
                        color: var(--defalutColor) !important;
                    }
                }
                .number{
                    color: #c1c1c1 !important;
                    border-color: #2c2c2e !important;
                    &.active{
                        color: var(--defalutColor) !important;
                        border-color: var(--defalutColor)!important;
                    }
                }
                .btn-next{
                    color: #c1c1c1 !important;
                    &[disabled]{
                        color: #2c2c2e !important;
                        &:hover{
                            color: #2c2c2e !important;
                        }
                    }
                    &:hover{
                        color: var(--defalutColor) !important;
                    }
                }
                .el-pagination__jump{
                    color: #c1c1c1 !important;
                    input{
                        background-color: unset;
                        border-color: #2c2c22;
                        color: #c1c1c1;
                    }
                }
            }

        }
    }

</style>
export default {
    menu: {
        index: {
            title: '监控中心'
        },
        list: {
            title: '患者列表',
            Print: {
                loading: '正在生成报告',
                button: '导出',
                page1: {
                    title: '动态血糖数据分析报告',
                    name: '用户姓名',

                },
                page2: {
                    title: '报告预览',
                    times: '覆盖时间',
                    number: '检测次数',
                    titUnit: '次',
                    avg: '平均血糖值',
                    hemoglobin: '预估糖化血红蛋白',
                    volatility: '血糖波动性',
                    tir: {
                        vhigh: '非常高',
                        high: '高',
                        normal: '正常',
                        low: '低',
                        vlow: '非常低'
                    },
                    insulin: '注射胰岛素',
                    days: '每天',
                    longInsulin: '长效胰岛素',
                    MealInsulin: '餐食胰岛素',
                    carbohydrate: '每日摄入碳水',
                    unit: '克/每天',
                    sport: '每日运动',
                    sunit: '卡/每天',
                    AGP: 'AGP图谱',
                    minmax: '最小值/最大值',
                    footTitle: '每日曲线图谱',
                    date: '打印日期',
                    form: '数据来源',
                    page: '页码'
                },
                page3:{
                    title: '周期图谱',
                    times: '覆盖时间',
                    number: '检测次数',
                    titUnit: '次',
                    lineTitle: '多日曲线',
                    footTitle: 'BOX图谱',
                    high: '高于达标范围',
                    low: '低于达标范围',
                    scope: '达标范围',
                    avg: '平均值',
                    date: '打印日期',
                    form: '数据来源',
                    page: '页码',
                    notVIP: '开通会员查看全部专享内容',
                },
                page4:{
                    title: '连续图谱',
                    times: '覆盖时间',
                    number: '检测次数',
                    titUnit: '次',
                    notData: '暂无数据',
                    footTitle: '平均血糖值',
                    date: '打印日期',
                    form: '数据来源',
                    page: '页码',
                },
                page5:{
                    title: '每日报告',
                    times: '覆盖时间',
                    number: '检测次数',
                    titUnit: '次',
                    year: '年',
                    mon: '月',
                    day: '日',
                    long: '长效',
                    meal: '餐食',
                    carbohydrate: '碳水化合物',
                    unit: '克',
                    type: '运动',
                    units: '千卡',
                    date: '打印日期',
                    form: '数据来源',
                    page: '页码',
                },
                page6: {
                    title: '夜间数据',
                    times: '覆盖时间',
                    number: '检测次数',
                    titUnit: '次',
                    titles: '夜间关键点血糖值',
                    high: '高于达标范围',
                    low: '低于达标范围',
                    unit: '点',
                    date: '打印日期',
                    form: '数据来源',
                    page: '页码',
                },
                page7: {
                    title: '食药分析',
                    times: '覆盖时间',
                    number: '检测次数',
                    titUnit: '次',
                    titles: '升糖指数排行',
                    insulin: '胰岛素',
                    type1: '加餐',
                    type2: '早饭',
                    type3: '午饭',
                    type4: '晚饭',
                    titles2: '升糖幅度',
                    unit: '波动值',
                    date: '打印日期',
                    form: '数据来源',
                    page: '页码',
                },
                page8: {
                    title: '多日时段',
                    times: '覆盖时间',
                    number: '检测次数',
                    titUnit: '次',
                    titles1: '时间段',
                    titles2: 'TAR 高于目标范围占比',
                    titles3: 'TIR 目标范围时间占比',
                    titles4: 'TBR 低于目标范围时间占比',
                    titles5: '探头值数量',
                    titles6: '上限',
                    titles7: '下限',
                    titles8: 'MG 平均葡萄糖',
                    titles9: 'SD 葡萄糖标准差',
                    titles10: 'CV 变异系数',
                    PS1: 'TIR：葡萄糖目标范围内时间（狭义TIR是指葡萄糖在目标范国內时间百分比，广义上也指监测期间葡萄糖在不同范国内（低值、目标范围、高值）的时间所占百分比 ，可更全面反映患者的血糖控制水平）。',
                    PS2: 'MD：平均葡萄糖值（月期内所有葡萄糖测定值的平均值，用于评价整体血糖水平）。',
                    PS3: 'SD：葡萄糖标准差（日期内所有葡萄糖测定值的标准差，用于评价总体偏离平均血糖值的程度，即血糖波动）。',
                    PS4: 'CV：变异系数（日期内所有葡萄糖测定值的离散程度，用于评价血糖波动）。',
                    PS5: '注：为保证展示效果，对部分数据进行了适度平滑处理。',
                    date: '打印日期',
                    form: '数据来源',
                    page: '页码',
                },
                page9: {
                    title: '报告解读',
                    times: '覆盖时间',
                    number: '检测次数',
                    titUnit: '次',
                    signature: '医生签字',
                    date: '打印日期',
                    form: '数据来源',
                    page: '页码',
                },
                wait: '报告生成约30秒，请耐心等待',
                merge: '正在合并报告',
                exporting: '正在生成第',
                exported: '页报告',
                building: '正在打包中...请勿重复下载！',
                over: '下载成功！'
            }
        },
        Task: {
            title: '任务中心',
        },
        plan: {
            title: '方案模版'
        },
        hospital: {
            title: '医院处方'
        },
        Custody: {
            title: '中央监护'
        },
        Organization: {
            title: '组织架构'
        },
        setting: {
            title: '账号设置'
        }
    }
}
<template>
    <div class="print-cont Interpret pion-rel" :style="{'margin-top' : !download ? '10px' : '0px' }">
        <div class="tiptitle">
            <div class="title">
                <h2>{{$t('menu.list.Print.page9.title')}}</h2>
                <p>{{ timeGet(bindTime,endTime) }}（{{days}}{{lang == 'en' ?  'day': '天' }}）
                    <span>{{$t('menu.list.Print.page9.times')}}：{{ coverage }}%</span>
                    <span>{{$t('menu.list.Print.page9.number')}}：{{ deviceBloodSugarTotal}} {{$t('menu.list.Print.page9.titUnit')}}</span>
                </p>
            </div>
            <img class="logo" :src="logo" alt="">
        </div>
        <div class="picture mt-20 solid-top" v-html="interpretText"></div>
        <div class="signImg flex align-center">
            <p>{{$t('menu.list.Print.page9.signature')}}：</p>
            <img :src="signImg" alt="">
        </div>
        <div class="page-bottom pion-abs">
            <p>{{$t('menu.list.Print.page9.date')}}：{{toDay}}</p>
            <p>{{$t('menu.list.Print.page2.form')}}：{{ lang == 'en' ? '' : '糖动' }}CGMCARE</p>
            <!-- {{ 4 + ( foodRecordRankLen > 0 ? 1 : 0 ) + Math.ceil((daysLen / 16 )) + Math.ceil(daysLen / 4 ) + Math.ceil((Object.keys(daysData).length  / 16 )) + Number(pageAdd) }}/{{ 4 + ( foodRecordRankLen > 0 ? 1 : 0 ) + Math.ceil((daysData.length  / 16 )) + Math.ceil(daysData.length / 4 ) + ( Math.ceil(daysData.length / 16) > 1 ? 2 : 1 ) + Number(pageAdd) }} -->
            <p>{{$t('menu.list.Print.page9.page')}}：{{ pageNum }}/{{sum}}</p>
        </div>
    </div>
</template>

<script>
    import { time, getItem } from '_js/Cache';

    export default {
        props:{
            download: Boolean,
            logo: String,
            bindTime: Number,
            endTime: Number,
            days: Number,
            coverage: Number,
            deviceBloodSugarTotal: Number,
            interpretText: String,
            daysData: Array || Object,
            toDay: String,
            foodRecordRankLen: Number,
            daysLen: Number,
            parintList: Array,
            signImg: String,
        },
        data(){
            return{
                sum: 0,
                pageNum: 7,
                lang: getItem('lang')
            }
        },
        watch:{
            parintList:{
                handler(){
                    this.getPageSum();
                },
                deep: true
            }
        },
        methods:{
            timeGet(start,end){
                return end ? time(new Date(start * 1000 ),this.lang == 'en' ? 'YYYY/MM/DD' : '年月日' ) + '—' + time(new Date(end * 1000 ),this.lang == 'en' ? 'MM/DD' : '月日') : time(new Date(start),'MM/DD');
            },
            getPageSum(){
                setTimeout(() =>{
                    let doms = document.getElementsByClassName('print-cont'),
                        listArr = Array.from(doms);
                    this.sum = doms.length;

                    listArr.map((item,index) =>{
                        item.className.split(' ').map(items =>{
                            if(items == 'Interpret'){
                                this.pageNum = index + 1;
                            }
                        })
                    })
                })
            }
        },
        mounted(){
            this.getPageSum();
        }
    }
</script>

<style lang="less" scoped>

    .picture{
        padding-top: 50px;
        border-color: #e5e5e5;
        & /deep/ p{
            margin: 15px 0px;
        }
    }

    .signImg{
        margin-top: 50px;
        justify-content: flex-end;
    }

</style>
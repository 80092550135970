<template>
    <div class="department" :class="[!Dark ? '' : 'dark']">
        <el-dialog title="添加部门" :visible.sync="Show" :modal-append-to-body="false" :before-close="close">
            <el-form label-position="top" ref="form" label-width="140px" :model="form">
                 <el-form-item label="部门名称">
                    <el-input v-model="form.title" placeholder="请输入部门名称"></el-input>
                </el-form-item>
                <el-form-item label="上级部门：">
                    <el-select :popper-append-to-body="false" v-model="form.pid" placeholder="请选择">
                        <el-option v-for="item in list" :label="item.title" :value="item.id" :key="item.id" />
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="部门负责人">
                    <div class="user text-center mt-10" v-if="form.system_user_id != '' && form.system_user_id != undefined " @click="selectUser">
                        <img class="radius-50" :src="systemUserInfo.avatar && systemUserInfo.avatar != '' ? systemUserInfo.avatar : notDate" alt="">
                        <p class="font-12 text-cut">{{ systemUserInfo.title != '' ? systemUserInfo.name : systemUserInfo.department_title }}</p>
                    </div>
                    <i class="el-icon-plus addIcon font-weight" v-else @click="selectUser"></i>
                </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="close">取消</el-button>
                <el-button @click="submit">确定</el-button>
            </div>
        </el-dialog>
        <SystemUser v-if="SystemUserShow" :Show="SystemUserShow" :UserList="doctorList" :isAddAide="isAddAide" :health="health" @addClose="addClose" @select="addUsers" />
    </div>
</template>

<script>
    import { addDepartment, getDoctorList, departmentSave } from 'quest'
    export default {
        props:{
            Show: Boolean,
            id: Number,
            Dark: Boolean
        },
        components: {
            'SystemUser': () => import('./SystemUser')
        },
        watch:{
            Show(val){
                if(val){
                    this.addDepartment();
                    this.getDoctorList();
                }
            }
        },
        data(){
            return{
                form:{
                    title: '',
                    pid: '',
                    system_user_id: ''
                },
                list: [],
                doctorList: [],
                SystemUserShow: false,
                isAddAide: false,
                systemUserInfo: [],
                notDate: require('@/assets/images/error/notheader.png'),
            }
        },
        methods:{
            getDoctorList(){
                getDoctorList({
                    department_id: this.id || '',
                    page:1,
                    limit:999
                }).then( res => {
                    if(res.data.code == 100000){
                        this.doctorList = res.data.data.list;
                    }
                })
            },

            addDepartment(){
                addDepartment({
                    [this.id != 0 && this.id != '' ? 'id' : '' ]: this.id || '',
                }).then( res => {
                    if(res.data.code == 100000){
                        this.form.title = res.data.data.title || '',
                        this.form.pid = Number(res.data.data.pid) == 0 ? '' : res.data.data.pid,
                        this.list = res.data.data.department_list || '',
                        this.form.system_user_id = Number(res.data.data.system_user_id) == 0 ? '' : res.data.data.system_user_id;
                        this.$refs.form.resetFields();
                    }
                })
            },

            selectUser(){
                this.SystemUserShow = true;
            },

            addClose(){
                this.SystemUserShow = false;
                this.isAddAide = false;
                this.$refs.form.resetFields();
            },

            addUsers(value){
                this.systemUserInfo = value;
                this.form.system_user_id = value.id;
                this.isAddAide = false;
            },

            close(){
                this.$emit('close', false);
            },

            submit(){
              let {title,pid,system_user_id} = this.form;
                if(!title){
                    this.$message({
                        message: '请填写部门名称！',
                        type: 'warning',
                    });
                }else{
                    departmentSave({
                        id: this.id||'',
                        title,
                        pid,
                        system_user_id
                    }).then( res => {
                        if(res.data.code == 100000){
                            this.$message({
                                message: '添加成功！',
                                type: 'success',
                            });
                        }else{
                            this.$message({
                                message: '添加失败！请联系管理员查看具体原因',
                                type: 'error',
                            });
                        }
                        this.$emit('save', false);
                    })
                }
            }
        },
    }
</script>

<style lang="less" scoped>

    .department /deep/ .el-dialog{
        width: 20vw;
        overflow: hidden;
        border-radius: 10px;

        .el-dialog__header{
            border-bottom: 1px solid #dcdfe6;
            padding: 14px 16px;
            span{
                font-size: 14px;
                color: #17233d;
            }
            button{
                margin-top: 4px;
            }
        }

        .el-select{
            width: 100%;
        }

        .el-form-item__label{
            font-size: 12px;
            padding-bottom: 0px;
            line-height: unset;
        }

        input{
            font-size: 12px;
            height: 32px;
            line-height: 1;

            &::placeholder{
                font-size: 12px;
            }
        }

        .addIcon{
            font-size: 32px;
            color: #16CCA6;
            cursor: pointer;
            margin-top: 10px;
        }

        .el-dialog__footer{
            border-top: 1px solid #dcdfe6;
            padding-top: 15px;

            button{
                padding: 5px 15px 6px;
                font-size: 12px;
                border-radius: 4px;
                line-height: 1.6;
                font-weight: normal;
                transition: .3s;

                span{
                    display: block;
                    padding-top: 2px;
                }
                &:nth-of-type(2){
                    transition: .3s;
                    background: var(--defalutColor);
                    border-color: var(--defalutColor);
                    color: white;

                    &:hover{
                        background-color: var(--defalutActiveColor);
                        border-color: var(--defalutActiveColor);
                        transition: .3s;
                    }
                }
            }
        }

        .user{
            width: 35px;
            cursor: pointer;

            img{
                width: 35px;
                height: 35px;
            }

            p{
                margin-top: -20px;
            }
        }
    }

    .department.dark /deep/ .el-dialog{
        .el-dialog__header,
        .el-dialog__body,
        .el-dialog__footer{
            background: #202025 !important;
            border-color: #2c2c2e !important;

            .el-select-dropdown{
                background-color: #202025;
                border-color: #0c0c0c;
                .hover{
                    background-color: #0c0c0c;
                }
                .el-select-dropdown__item{
                    color: #c1c1c1;
                }
                .popper__arrow{
                    border-bottom-color: #0c0c0c;
                    &::after{
                        border-bottom-color: #0c0c0c;
                    }
                }
            }
            .el-dialog__title,
            .el-dialog__close{
                color: #c1c1c1;
            }
            .el-form-item__label{
                color: #c1c1c1 !important;
            }
            .el-input__inner{
                color: #c1c1c1;
                background-color: unset;
                border-color: #2c2c2e;
                &::placeholder{
                    color: #c1c1c1 !important;
                    border-color: #2c2c2e !important;
                }
            }
            .el-button{
                background-color: unset;
                color: #c1c1c1;
                border-color: #e6e9f0;
            }
            .dialog-footer{
                button:nth-of-type(2){
                    background: var(--defalutActiveColor) !important;
                    border-color: var(--defalutActiveColor) !important;
                }
            }
        }
    }


    @media screen{
        @media (max-width: 1366px) {
            
            .department /deep/ .el-dialog{
                width: 25%;
                margin-top: 20% !important;
            }
        }
    }

</style>
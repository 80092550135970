<template>
    <div class="print">
        <div class="loading pion-fixed flex justify-center align-center" v-if="loading">
            <div class="loading-box" v-loading="loading">
                <p class="loading-text pion-abs text-center">{{$t('menu.list.Print.loading')}}</p>
            </div>
        </div>
        <div class="content pion-fixed" v-else>
            <div class="printing" :style="{ 'overflow-y': !download ? 'scroll' : '' }" v-loading="download">
                <div class="A4-page">
                    <Home :download="download" :userInfo="user_info" :bindTime="bind_time" :endTime="end_time" :logo="logo"/>

                    <PrintCycle 
                        :download="download"
                        :logo="logo"
                        :bindTime="bind_time"
                        :endTime="end_time"
                        :days="Number(days)"
                        :coverage="Number(coverage)"
                        :deviceBloodSugarTotal="device_bloodsugar_total"
                        :avgBloodSugar="Number(avg_bloodsugar)"
                        :estimateAlbumen="Number(estimate_albumen)"
                        :LAGE="Number( LAGE )"
                        :SD="Number(SD)"
                        :CV="Number(CV)"
                        :TIR="tir"
                        :dayAvgInsulin="Number(day_avg_insulin)"
                        :dayAvgLongInsulin="Number(day_avg_long_insulin)"
                        :dayAvgFoodInsulin="Number(day_avg_food_insulin)"
                        :dayAvgCarb="Number(day_avg_carb)"
                        :dayAvgCalorie="Number(day_avg_calorie)"
                        :envelope="envelope"
                        :userInfo="user_info"
                        :daysData="days_data"
                        :toDay="toDay"
                        :parintList="parintList"
                        :pageAdd="[ interpretText == '' ? 0 : 1 ]"
                        :isDefaultUnit="isDefaultUnit"
                        v-if="parintList[0].check"
                    />

                    <LineBox
                        :download="download"
                        :logo="logo"
                        :bindTime="bind_time"
                        :endTime="end_time"
                        :days="Number(days)"
                        :coverage="Number(coverage)"
                        :deviceBloodSugarTotal="device_bloodsugar_total"
                        :daysData="days_data"
                        :everyDaysDate="everyDaysDate"
                        :multiLineXArr="multi_line_x_arr"
                        :colorList="colorList"
                        :userInfo="user_info"
                        :BarLineVal="BarLineVal"
                        :toDay="toDay"
                        :parintList="parintList"
                        :pageAdd="[ interpretText == '' ? 0 : 1 ]"
                        :isDefaultUnit="isDefaultUnit"
                        v-if="parintList[1].check"
                    />

                    <template  v-if="user_info.is_vip > 0 ">
                        <TIRAGP
                            :download="download"
                            :logo="logo"
                            :bindTime="bind_time"
                            :endTime="end_time"
                            :days="Number(days)"
                            :coverage="Number(coverage)"
                            :deviceBloodSugarTotal="device_bloodsugar_total"
                            :daysData="days_data"
                            :userInfo="user_info"
                            :toDay="toDay"
                            :lineMax="Number(lineMax)"
                            :parintList="parintList"
                            :pageAdd="[ interpretText == '' ? 0 : 1 ]"
                            :isDefaultUnit="isDefaultUnit"
                            v-if="parintList[2].check"
                        />

                        <Continuous
                            :download="download"
                            :logo="logo"
                            :bindTime="bind_time"
                            :endTime="end_time"
                            :days="Number(days)"
                            :coverage="Number(coverage)"
                            :deviceBloodSugarTotal="device_bloodsugar_total"
                            :daysData="days_data"
                            :userInfo="user_info"
                            :toDay="toDay"
                            :lineMax="Number(lineMax)"
                            :parintList="parintList"
                            :pageAdd="[ interpretText == '' ? 0 : 1 ]"
                            :isDefaultUnit="isDefaultUnit"
                            v-if="parintList[3].check"
                        />

                        <Night
                            :download="download"
                            :logo="logo"
                            :bindTime="bind_time"
                            :endTime="end_time"
                            :days="Number(days)"
                            :coverage="Number(coverage)"
                            :deviceBloodSugarTotal="device_bloodsugar_total"
                            :toDay="toDay"
                            :nightDayData="nightDayData"
                            :nightChartValue="nightChartValue"
                            :nightLineX_Arr="nightLineX_arr"
                            :colorList="colorList"
                            :userInfo="user_info"
                            :daysLen="days_data.length || 0"
                            :parintList="parintList"
                            :pageAdd="[ interpretText == '' ? 0 : 1 ]"
                            :isDefaultUnit="isDefaultUnit"
                            v-if="parintList[4].check"
                        />

                        <FoodEfficacy
                            :download="download"
                            :logo="logo"
                            :bindTime="bind_time"
                            :endTime="end_time"
                            :days="Number(days)"
                            :coverage="Number(coverage)"
                            :deviceBloodSugarTotal="device_bloodsugar_total"
                            :foodRecordRank="food_record_rank"
                            :userInfo="user_info"
                            :toDay="toDay"
                            :daysLen="days_data.length || 0"
                            :parintList="parintList"
                            :pageAdd="[ interpretText == '' ? 0 : 1 ]"
                            :isDefaultUnit="isDefaultUnit"
                            v-if="food_record_rank > 0"
                        />

                        <ManyDay
                            :download="download"
                            :logo="logo"
                            :bindTime="bind_time"
                            :endTime="end_time"
                            :days="Number(days)"
                            :coverage="Number(coverage)"
                            :deviceBloodSugarTotal="device_bloodsugar_total"
                            :daysData="days_data"
                            :toDay="toDay"
                            :daysLen="days_data.length || 0"
                            :parintList="parintList"
                            :pageAdd="[ interpretText == '' ? 0 : 1 ]"
                            :isDefaultUnit="isDefaultUnit"
                            v-if="parintList[5].check"
                        />
                    </template>
                    <Interpret
                        v-if="interpretText != ''"
                        :download="download"
                        :logo="logo"
                        :bindTime="bind_time"
                        :endTime="end_time"
                        :days="Number(days)"
                        :coverage="Number(coverage)"
                        :deviceBloodSugarTotal="device_bloodsugar_total"
                        :daysData="days_data"
                        :toDay="toDay"
                        :daysLen="days_data.length || 0"
                        :interpretText="interpretText"
                        :parintList="parintList"
                        :signImg="resultImg"
                    />
                </div>
                <p class="printIngText pion-fixed text-center" v-if="download"><span>{{$t('menu.list.Print.wait')}}</span><br />{{ index }}</p>
            </div>
        </div>
        <el-dialog title="报告解读" class="interpretBox" :visible.sync="showInterpret" width="60%" height="50%" :modal-append-to-body="false" :before-close="true">
            <div class="html">
                <Toolbar :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
                <Editor class="editorBox" style="height: 280px; overflow-y: hidden;" v-model="interpretText" :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" />
            </div>
            <div class="sign pion-rel flex align-center mt-20">
                <span>医生签字：</span>
                <VueEsign ref="esign" style="border: 1px solid #c2c1c1; border-radius: 5px" :width="260" :height="100" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor" />
                <svg t="1688107752354" class="icon pion-abs" viewBox="0 0 1024 1024" @click="handleReset" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1461" width="22" height="22"><path d="M416.256 946.176c-49.664 0-99.84-17.92-138.752-54.784l-266.24-248.32L572.928 46.08 988.16 433.152l-422.4 448.512c-40.448 43.008-94.72 64.512-149.504 64.512z m-96.768-99.328c57.344 53.76 147.968 50.176 201.216-7.168L901.12 436.224l-325.632-303.616-477.696 508.416 221.696 205.824z" p-id="1462" fill="#c2c1c1"></path><path d="M233.71776 480.44032l41.69216-44.42624 344.22784 323.03104-41.69216 44.43136zM423.936 885.248H1003.52v60.928H423.936z" p-id="1463" fill="#c2c1c1"></path></svg>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showInterpret = false">取消</el-button>
                <el-button type="primary" @click="saveInterpret">确定</el-button>
            </span>
        </el-dialog>
        <div class="page-button pion-fixed">
            <div class="operate-button">
                <el-button class="pion-abs interpret radius-50" @click="showInterpret = true" v-if="!download && Number(device_bloodsugar_total) > 864" :disabled="loading">报告解读</el-button>
                <el-button class="pion-abs radius-50" @click="downLoadPDF" v-if="!download && Number(device_bloodsugar_total) > 864" :disabled="loading">导出报告</el-button>
            </div>
        </div>
        
        <el-dialog title="选择导出内容" class="interpretBox" :visible.sync="showSelecter" width="40%" height="50%" :close-on-click-modal="false" :close-on-press-escape="false"  :modal-append-to-body="false" :show-close="false">
            <div class="html printCent">
                <div class="forItem" v-for="(item,index) in parintList" :key="index">
                    <el-checkbox v-model="item.check" :label="item.title" border></el-checkbox>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="defaultSelect">取消</el-button>
                <el-button type="primary" @click="printTo">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

    import {
        Home,
        PrintCycle,
        LineBox,
        TIRAGP,
        Continuous,
        Night,
        FoodEfficacy,
        ManyDay,
        Interpret
    } from './page/PC/index';

    import printPDF from '_js/print-toPDF.js';

    import { getReportDate } from "quest";

    import { deviceType, time, saveFileToPhone, setItem, getItem, removeItem } from '_js/Cache';

    import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
    import VueEsign from 'vue-esign'
    import '@wangeditor/editor/dist/css/style.css'

    export default {
        components: {
            VueEsign,
            Editor,
            Toolbar,
            Home,
            PrintCycle,
            LineBox,
            TIRAGP,
            Continuous,
            Night,
            FoodEfficacy,
            ManyDay,
            Interpret
        },
        data(){
            return{
                loading: true,
                download: false,
                logo: getItem('lang') == 'en' ? require('@/assets/images/print/en-logo.jpg') : this.$route.query.from == 'admin' ? require('@/assets/images/print/pring-logo.png') : getItem('agencyInfo') && getItem('agencyInfo').logo_base64 && getItem('agencyInfo').logo_base64 != '' ? ('data:image/png;base64,' + getItem('agencyInfo').logo_base64) : require('@/assets/images/print/pring-logo.png'),
                bind_time: '',
                end_time: '',
                days: '',
                coverage: '',
                device_bloodsugar_total: '',
                id: 0,
                avg_bloodsugar: 0,
                estimate_albumen: 0,
                LAGE: 0,
                SD: 0,
                CV: 0,
                tir: {},
                day_avg_insulin: 0,
                day_avg_long_insulin: 0,
                day_avg_food_insulin: 0,
                day_avg_carb: 0,
                day_avg_calorie: 0,
                envelope: null,
                user_info: null,
                toDay:'',
                columns: [
                    {
                        title: 0,
                        slot: 'Sun',
                        align: "center",
                        chartLine:[]
                    },
                    {
                        title: 1,
                        slot:'Mon',
                        align: "center",
                        chartLine:[]
                    },
                    {
                        title: 2,
                        slot: 'Tue',
                        align: "center",
                        chartLine:[]
                    },
                    {
                        title: 3,
                        slot: 'Wed',
                        align: "center",
                        chartLine:[]
                    },
                    {
                        title: 4,
                        slot: 'Thu',
                        align: "center",
                        chartLine:[]
                    },
                    {
                        title: 5,
                        slot:'Fri',
                        align: "center",
                        chartLine:[]
                    },
                    {
                        title: 6,
                        slot: 'Sat',
                        align: "center",
                        chartLine:[]
                    }
                ],
                weekLineData: [],
                everyDaysDate:{
                    value: [],
                    x_arr: ['00:00','02:00','04:00','08:00','10:00','12:00','14:00','16:00','18:00','20:00','22:00','24:00']
                },
                days_data: {},
                food_record_rank:[],
                BarLineVal: null,
                colorList:['#A4C400','#6A00FF','#FA6800','#76608A','#AA00FF','#F0A30A','#A0522D','#008A00','#F472D0','#00ABA9','#1BA1E2','#A20025','#6D8764','#0050EF', '#17CCA6','#D4FF00','#5100C2','#FE8026','#C093EA','#520976','#BE820B','#F37134','#00F700','#F949C7','#02F2EE','#00AAFF','#F81F52','#8FF16E','#0239A7','#00FEC7','#A05017'],
                multi_line_x_arr:[],
                nightDayData: [],
                nightChartValue: [],
                nightLineX_arr:[],
                lineMax: 0,
                index: '正在生成第1页报告',
                mobile: '',
                cant: false,
                transform: '',
                height: '',
                showInterpret: false,
                interpretText: '',
                beforeUnload: '',
                Handler: '',
                editor: null,
                toolbarConfig: { },
                editorConfig: { placeholder: '请输入内容...' },
                mode: 'default', // or 'simple'
                lineWidth: 6,
                lineColor: "#000000",
                bgColor: "",
                resultImg: "",
                isCrop: false,
                showSelecter: false,
                parintList:[
                    {
                        title: '报告预览',
                        check: true
                    },
                    {
                        title: '周期图谱',
                        check: true
                    },
                    {
                        title: '连续图谱',
                        check: true
                    },
                    {
                        title: '每日报告',
                        check: true
                    },
                    {
                        title: '夜间数据',
                        check: true
                    },
                    {
                        title: '多日时段',
                        check: true
                    },
                ],
                isDefaultUnit: 0,
            }
        },

        methods:{
            downLoadPDF(){
                this.showSelecter = true;
            },
            printTo(){
                this.showSelecter = false;
                let box = document.getElementsByClassName('printing')[0],
                    name = this.user_info.agency_nickname ? this.user_info.agency_nickname : this.user_info.nickname,
                    start = time(new Date(this.bind_time * 1000),'YYYY-MM-DD'),
                    end = time(new Date(this.end_time * 1000),'YYYY-MM-DD'),
                    fileData = {
                        name: name + ' ' + start + '—' + end + ' 血糖报告.pdf',
                        file: null
                    };
                box.scrollTo(0, 0);
                if(this.user_info.is_vip == 0){
                    this.cant = true;
                }
                setTimeout( () => {
                    const dome = document.getElementsByClassName('A4-page');
                    let FileName = fileData.name;
                    if(this.download){
                        this.$message({
                            message: this.$t('menu.list.Print.building'),
                            type: 'warning'
                        });
                    }else{
                        this.download = true;
                        printPDF.printPDF(dome[0], FileName,() =>{
                            this.download = false;
                            this.cant = false;
                            this.$message({
                                message: this.$t('menu.list.Print.over'),
                                type: 'success'
                            });
                            this.defaultSelect();
                        }, (idx) =>{
                            typeof idx == 'number' ? (this.lang == 3 ? this.index = this.$t('menu.list.Print.exporting') + idx : this.index = this.$t('menu.list.Print.exporting') + idx + this.$t('menu.list.Print.exported')) : this.index = this.$t('menu.list.Print.merge');
                        });
                    }
                },1000)
            },
            getReportDate(id,from,report_sign,token,start,end){
                let data = {
                    [ this.$route.query.uid != '' && this.$route.query.uid != 'undefined' && this.$route.query.uid != undefined ? 'uid' : 'device_id' ]:id,
                    from: from ? from : '',
                    report_sign,
                    token,
                    start_day: start,
                    end_day: end,
                }
               getReportDate(data).then( res =>{
                    let data = res.data,
                        report = res.data.data;
                    if( data.code == 100000 ){
                        // 每页报告公共数据处理
                        /*
                            起始时间
                            结束时间
                            总天数
                            覆盖时间
                            检测次数
               f             底部ID编号
                        */

                        this.bind_time = report.device_info ? report.device_info.bind_time : new Date(start).getTime() / 1000,
                        this.end_time = report.device_info ? report.device_info.end_time : new Date(end).getTime() / 1000,
                        this.days = report.device_info ? report.device_info.days : (new Date(end).getTime() - new Date(start).getTime()) / 1000 / 24 / 3600 + 1,
                        this.coverage = report.coverage,
                        this.device_bloodsugar_total = report.device_bloodsugar_total,
                        this.id = report.device_info ? report.device_info.id : '';

                        // 第一页数据

                        /*
                            平均血糖值
                            预告糖化血红蛋白

                            血糖波动性
                                MAGE，
                                SD，
                                CV

                            右侧柱状图
                            注射胰岛素
                            长效胰岛素
                            每日摄入碳水
                            每日运动
                            AGP图谱曲线
                            用户信息（获取达标范围使用）
                            每日曲线图谱暂时先掠过

                            周期图谱
                            TIR+AGP连续图谱

                            BOX图谱

                            多日曲线X轴

                            夜间数据分析多日曲线数据
                            夜间数据分析关键点血糖值
                            夜间数据分析多日曲线X轴

                        */

                        this.avg_bloodsugar = report.avg_bloodsugar,
                        this.estimate_albumen = report.estimate_albumen,
                        this.LAGE = report.lage,
                        this.SD = report.sd,
                        this.CV = report.cv,
                        this.tir = report.tir,
                        this.day_avg_insulin = report.day_avg_insulin,
                        this.day_avg_long_insulin = report.day_avg_long_insulin,
                        this.day_avg_food_insulin = report.day_avg_food_insulin,
                        this.day_avg_carb = report.day_avg_carb,
                        this.day_avg_calorie = report.day_avg_calorie,
                        this.envelope = report.envelope_data;
                        this.user_info = report.user_info,
                        this.everyDaysDate.x_arr = report.envelope_data.x_arr || report.multi_line_x_arr;
                        report.days_data.map( item =>{
                            if(item.line){
                                this.everyDaysDate.value.push(item.line);
                            }
                        })
                        this.days_data = report.days_data,
                        this.food_record_rank = report.food_record_rank,

                        this.BarLineVal = report.box,

                        this.multi_line_x_arr = report.multi_line_x_arr;

                        this.nightDayData = report.night_time_points_list,
                        this.nightChartValue = report.night_line,
                        this.lineMax = report.max,
                        this.nightLineX_arr = report.night_line_x_arr;
                        this.isDefaultUnit = report.user_info.bs_unit;
                        let signInfo = getItem('interpret');
                        if(signInfo != null && report.user_info.id == signInfo.id){
                            this.interpretText = signInfo.text;
                            this.resultImg = signInfo.resultImg;
                        }
                        // 数据请求完成，显示pdf
                        if(report.device_bloodsugar_total < 864){
                            this.$router.replace({
                                path: '/report/notData',
                                query:''
                            })
                        }else{
                            this.loading = false;
                        }
                    }
                }).catch( err =>{
                    console.log(err);
                })
            },
            initiatePay(){
                saveFileToPhone('openUserVip','')
            },
            saveInterpret(){
                if(this.interpretText != ''){
                    this.$refs.esign.generate().then(res => {
                    this.showInterpret = false;
                        this.resultImg = res;
                        const data = {
                            id: this.user_info.id,
                            text: this.interpretText,
                            resultImg: res
                        }
                        setItem('interpret', JSON.stringify(data));
                    }).catch( () =>{
                        this.$message.error("请医生签字！");
                    })
                }
            },
            beforeunloadHandler(){
                this.beforeUnload=new Date().getTime();
            },
            unloadHandler(){
                this.Handler = new Date().getTime()-this.beforeUnload;
                if(this.Handler<=5){
                    removeItem('interpret')
                }
            },
            onCreated(editor) {
                this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
            },
            //清空画板..
            handleReset() {
                this.$refs.esign.reset();
                this.resultImg = "";
            },
            defaultSelect(){
                this.parintList.map(item =>{
                    item.check = true;
                })
                this.showSelecter = false;
            },
        },
        created(){
            const data = new Date(),
                  md5 = require('js-md5');
            const id = this.$route.query.id || this.$route.query.device_id || this.$route.query.uid,
                  from = this.$route.query.from,
                  report_sign = md5(id + 'yja2019_fcz'),
                  token = this.$route.query.token || '',
                  start_day = this.$route.query.start_day || '',
                  end_day = this.$route.query.end_day || '';
            this.getReportDate(id,from,report_sign,token,start_day,end_day);
            let year = data.getFullYear(),
                mon = data.getMonth() + 1 < 10 ? '0' + (data.getMonth() + 1) : data.getMonth() + 1,
                day = data.getDate() < 10 ? '0' + data.getDate() : data.getDate();
            this.toDay = year+'/'+mon+'/'+day;
            const type = deviceType();
            for(let key in type){
                if(type[key]){
                    this.mobile = key;
                }
            }
            if(type.iPhone == true || type.ios == true || type.mobile == true){
                this.$router.replace({
                    path: '/report/mobile',
                    query: this.$route.query
                })
            }

        },
        mounted() {
            window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
            window.addEventListener('unload', e => this.unloadHandler(e))
        },
        destroyed() {
            window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
            window.removeEventListener('unload', e => this.unloadHandler(e))
        },
        beforeDestroy() {
            const editor = this.editor
            if (editor == null) return
            editor.destroy() // 组件销毁时，及时销毁编辑器
        }
    }
</script>

<style scoped lang="less">

    .print{
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.5);
        z-index: 9999;
        margin: 0 auto;
    }

    .loading{
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        z-index: 99999999;

        .loading-box{
            width: 100%;

            .loading-text{
                top: 40px;
                width: 100%;
                color: white;
            }
        }

    }

    .content{
        width: 100%;
        height: 100vh;
        .notData{
            width: 100%;
            height: 100%;
            background-color: white;
            img{
                width: 350px;
            }
            p{
                font-size: 28px;
            }
        }
    }

    .printing{
        width: 1239.5px;
        height: 100%;
        position: fixed;
        top: 0px;
        left: ~'calc( ( 100% - 1239.5px ) / 2 )';
        z-index: 0;
    }

    .A4-page{
        width: 1239.5px;
        height: auto;
        margin: 0 auto;
    }


    /deep/ .print-cont{
        width: 100%;
        height: 1754px;
        padding: 40px 50px 40px 55px;
        box-sizing: border-box;
        border-radius: 5px;
        background: white;
        margin-top: 10px;
    }

    /deep/ .print-cont .tiptitle{
        display: flex;
        align-items: center;
        .title{
            // width: 65%;
            max-width: 80%;
            h2{
                display: flex;
                font-size: 41px;
                color: #000;
                p{
                    margin-left: 20px;
                }
            }
            &>p{
                font-size: 19px;
                color: #8d8d8d;
                margin-top: 10px;
                span{
                    margin-left: 20px;
                    color: #8d8d8d;
                }
            }
        }
        &>h3{
            margin-bottom: auto;
            color: #000;
            font-size: 22px;
            margin-top: -5px;
        }
        .logo{
            width: 220px;
            object-fit: cover;
            position: absolute;
            top: 55px;
            right: 72px;
        }
    }

    /deep/ .print-cont .page-bottom{
        bottom: 50px;
        left: 55px;
        width: ~'calc(100% - 110px)';
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 0px 66px 0px 66px;
        border-top: 1px solid #c4c4c4;
        p{
            font-size: 16px;
            color: #8d8d8d;
        }
    }

    .printIngText{
        top: 55%;
        font-size: 22px;
        z-index: 9999;
        width: 1239.5px;
    }

    

    .page-button{
        bottom: 20px;
        right: 125px;
        .operate-button{
            button{
                width: 115px;
                height: 45px;
                bottom: 0px;
                padding: 0px;
                border: unset;
                background: var(--defalutColor);
                color: white;
                transition: .3s;
                &.interpret{
                    bottom: 55px;
                    margin-left: 10px;
                    background: #6795ea;
                    &:hover,
                    &:focus{
                        background: #4b7dd8;
                    }
                }

                &:hover{
                    background: var(--defalutActiveColor);
                    color: white !important;
                    transition: .3s;
                }
                &:focus{
                    background: var(--defalutActiveColor);
                    color: white !important;
                    transition: .3s;
                }
            }
        }
    }


</style>

<style lang="less">
    .interpretBox{
        .el-dialog{
            border-radius: 10px;
            .el-dialog__header{
                border-bottom: 1px solid #dcdfe6;
                padding: 14px 16px;
                span{
                    font-size: 14px;
                    color: #17233d;
                }
                button{
                    margin-top: 4px;
                }
            }

            .html{
                .w-e-text-container{
                    border: 1px solid #c2c1c1;
                    border-radius: 5px;
                }
                &.printCent{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    .forItem{
                        margin: 10px;
                    }
                }
            }

            .sign{
                justify-content: flex-end;
                margin-top: 30px;
                svg{
                    top: -20px;
                    right: 3px;
                    font-size: 22px;
                    cursor: pointer;
                }
            }

            .el-dialog__footer{
                border-top: 1px solid #dcdfe6;
                padding-top: 15px;

                button{
                    padding: 5px 15px 6px;
                    font-size: 12px;
                    border-radius: 4px;
                    line-height: 1.6;
                    font-weight: normal;
                    transition: .3s;

                    span{
                        display: block;
                        padding-top: 2px;
                    }
                    &:nth-of-type(2){
                        transition: .3s;
                        background: var(--defalutColor);
                        border-color: var(--defalutColor);
                        color: white;

                        &:hover{
                            background-color: var(--defalutActiveColor);
                            border-color: var(--defalutActiveColor);
                            transition: .3s;
                        }
                    }
                }
            }
        }
    }
</style>
<template>
    <div class="TIR-AGP">
        <div class="print-cont pion-rel" :style="{'margin-top' : !download ? '10px' : '0px' }" v-for="index in Math.ceil((Object.keys(daysData).length  / 16 )) " :key="index">
            <div class="tiptitle">
                <div class="title">
                    <h2>{{$t('menu.list.Print.page4.title')}}</h2>
                    <p>{{ timeGet(bindTime,endTime) }}（{{days}}{{lang == 'en' ?  'days': '天' }}）
                        <span>{{$t('menu.list.Print.page4.times')}}：{{ coverage }}%</span>
                        <span>{{$t('menu.list.Print.page4.number')}}：{{ deviceBloodSugarTotal}} {{$t('menu.list.Print.page4.titUnit')}}</span>
                    </p>
                </div>
                <img class="logo" :src="logo" alt="">
            </div>

            <div class="continuous-list flex flex-wrap pt-20">
                <div class="continuous" v-for="(item,idx) in daysData" :key="idx">
                    <template v-if=" idx < ( index * 16 ) && idx >= ( ( index - 1 ) * 16) ">
                        <div class="week flex solid-left align-center pion-rel">
                            <div class="week-data pl-20" :style="{'border-right': ( idx + 1 ) == daysData.length ? '1px solid #e5e5e5' : '' }">
                                <h4 class="mt-7 font-20">{{ lang == 'en' ? item.week_en : item.week }}</h4>
                                <p class="font-14">{{ item.date }}</p>
                            </div>
                        </div>
                        <div class="week-chat">
                            <template v-if="item.line && userInfo != '' ">
                                <ChartsLine 
                                    :value="item.line"
                                    :target_low="userInfo.target_low" 
                                    :target_high="userInfo.target_high"
                                    :isPDF="true"
                                    :index="idx"
                                    :longPass="true"
                                    :lineMax="lineMax"
                                    :isDefaultUnit="isDefaultUnit"
                                />
                            </template>
                            <template v-else>
                                <div class="notData flex justify-center align-center">
                                    <div class="center">
                                        <img src="@/assets/images/error/empty.png" alt="">
                                        <p class="font-14">{{$t('menu.list.Print.page4.notData')}}</p>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="week-date mb-5 pt-10 pb-10 pl-20 pr-10">
                            <div class="week-cont flex">
                                <div class="left">
                                    <h4 class="font-14">{{$t('menu.list.Print.page4.footTitle')}}</h4>
                                    <h1>{{ item.avg ? ( isDefaultUnit == 1 ? Number(changUnit(isDefaultUnit,item.avg).val).toFixed(0) : Number(changUnit(isDefaultUnit,item.avg).val).toFixed(1)) : '--' }} </h1>
                                    <p class="font-weight">SD&nbsp;{{ item.sd ? ( isDefaultUnit == 1 ? Number(changUnit(isDefaultUnit,item.sd).val).toFixed(0) : Number(changUnit(isDefaultUnit,item.sd).val).toFixed(1) ) : '--' }}</p>
                                    <p class="font-weight">CV&nbsp;{{ item.cv ? item.cv + '%' : '--' }}</p>
                                </div>
                                <div class="right flex">
                                    <div class="chat" v-if="item.tir">
                                        <div class="vh radius-2" :style="{ 'height': item.tir.very_high * 100 + '%' }"></div>
                                        <div class="h mt-5 radius-2" :style="{ 'height': item.tir.high * 100 + '%' }"></div>
                                        <div class="nomal mt-5 radius-2" :style="{ 'height': item.tir.common * 100 + '%' }"></div>
                                        <div class="l mt-5 radius-2" :style="{ 'height': item.tir.low * 100 + '%' }"></div>
                                        <div class="vl mt-5 radius-2" :style="{ 'height': item.tir.very_low * 100 + '%' }"></div>
                                    </div>
                                    <div class="chat-info ml-15" v-if="item.tir">
                                        <p class="font-12">{{ item.tir.very_high != 0 ? parseInt(item.tir.very_high * 100).toFixed(0) : 0 }}% {{$t('menu.list.Print.page2.tir.vhigh')}}</p>
                                        <p class="font-14 mt-5">{{ item.tir.high != 0 ? parseInt(item.tir.high * 100).toFixed(0) : 0 }}% {{$t('menu.list.Print.page2.tir.high')}}</p>
                                        <h4 class="font-15 mt-5">{{ item.tir.common != 0 ? parseInt(item.tir.common * 100).toFixed(0) : 0 }}% {{$t('menu.list.Print.page2.tir.normal')}}</h4>
                                        <p class="font-14 mt-5">{{ item.tir.low != 0 ? parseInt(item.tir.low * 100).toFixed(0) : 0 }}% {{$t('menu.list.Print.page2.tir.low')}}</p>
                                        <p class="font-12 mt-5">{{ item.tir.very_low != 0 ? parseInt(item.tir.very_low * 100).toFixed(0) : 0 }}% {{$t('menu.list.Print.page2.tir.vlow')}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <div class="page-bottom pion-abs">
                <p>{{$t('menu.list.Print.page4.date')}}：{{toDay}}</p>
                <p>{{$t('menu.list.Print.page2.form')}}：{{ lang == 'en' ? '' : '糖动' }}CGMCARE</p>
                <!-- {{ index + 3 }}/{{ 4 + ( foodRecordRankLen > 0 ? 1 : 0 ) + Math.ceil((daysData.length  / 16 )) + Math.ceil(daysData.length / 4 ) + ( Math.ceil(daysData.length / 16) > 1 ? 2 : 1 ) + Number(pageAdd) }} -->
                <p>{{$t('menu.list.Print.page4.page')}}：{{ index + pageNum }}/{{ sum }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        ChartsLine
    } from "_c/print";
    import { time, getItem, changUnit } from '_js/Cache';
    export default {
        name: 'TIRAGP',
        components: {
            ChartsLine
        },
        props: {
            download: Boolean,
            logo: String,
            bindTime: Number,
            endTime: Number,
            days: Number,
            coverage: Number,
            deviceBloodSugarTotal: Number,
            daysData: Array || Object,
            userInfo: Object,
            toDay: String,
            foodRecordRankLen: Number,
            lineMax: Number,
            parintList: Array,
            pageAdd: Number || String,
            isDefaultUnit: Number,
        },
        data(){
            return{
                sum: 0,
                pageNum: 3,
                index: null,
                lang: getItem('lang')
            }
        },
        watch:{
            parintList:{
                handler(){
                    this.getPageSum();
                },
                deep: true
            },
            pageAdd:{
                handler(){
                    this.getPageSum();
                },
                deep: true
            }
        },
        
        methods:{
            timeGet(start,end){
                return end ? time(new Date(start * 1000 ),this.lang == 'en' ? 'YYYY/MM/DD' : '年月日' ) + '—' + time(new Date(end * 1000 ),this.lang == 'en' ? 'MM/DD' : '月日') : time(new Date(start),'MM/DD');
            },
            getPageSum(){
                setTimeout(() =>{
                    let doms = document.getElementsByClassName('print-cont'),
                        listArr = Array.from(doms),
                        cu = 0;
                    this.sum = doms.length;
                    listArr.map((item,index) =>{
                        if(item.parentNode.className == 'TIR-AGP'){
                            if(cu == 0 ){
                                this.pageNum = index;
                            }
                            cu += 1;
                        }
                    })
                })
            },
            changUnit(t,v){
                return changUnit(t,v);
            }
        },
        mounted(){
            this.getPageSum();
        }
    }
</script>

<style lang="less" scoped>
    .continuous-list{
        .continuous{
            width: 25%;

            .week{
                border-color: #e5e5e5;
                height: 60px;
                z-index: 999999;
                .week-data{
                    flex: 1;
                    height: 100%;
                    color: #000;
                }
            }

            .week-chat{
                width: 100%;
                height: 190px;
                margin-top: -30px;

                & /deep/ .ChartsLine{
                    width: 100%;
                    height: 190px;
                }

                .notData{
                    img{
                        width: 55px;
                        height: 55px;
                        margin-top: 55px;
                    }
                }
            }

            .week-date{
                .week-cont{
                    flex: 1;
                    color: #000;
                    border-color: #e5e5e5;
                    .left{
                        max-width: 40%;
                        h1{
                            font-size: 41px;
                        }
                        h1.isHig{
                            color: #E03520;
                        }
                    }
                    .right{
                        margin-left: auto;
                        .chat{
                            width: 45px;
                            height: 100px;
                            &>div{
                                flex: 1;
                                min-height: 1px;
                            }
                            .vh{
                                background: #F5B940;
                                height: 5px;
                            }
                            .h{
                                background: #FBE46C;
                                height: 10px;
                            }
                            .nomal{
                                height: calc(~' 100% - 60px');
                                background: #57BB9D;
                                height: 67px;
                            }
                            .l{
                                background: #EE7373;
                                height: 5px;
                            }

                            .vl{
                                background: #EE7373;
                                height: 10px;
                            }
                        }
                        .chat-info{
                            padding-top: 2px;
                        }
                    }
                }
                .week-cont:nth-last-child(1){
                    border-right: none;
                }
            }
            &:nth-child(1){
                margin-top: 0px;
            }
        }
    }
</style>
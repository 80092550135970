<template>
    <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
    import echarts from "echarts";
    import tdTheme from "./theme.json";
    import { on, off, getItem } from '_js/Cache'
    echarts.registerTheme("tdTheme", tdTheme);
    export default {
        name: "FoodLine",
        props: {
            value: Object
        },
        data() {
            return {
                dom: null,
                isDark: false,
            };
        },
        methods: {
            resize() {
                this.dom.resize();
            },
            hexToRgba(hex, opacity) {
                let rgbaColor = "";
                let reg = /^#[\da-f]{6}$/i;
                if (reg.test(hex)) {
                    rgbaColor = `rgba(${parseInt("0x" + hex.slice(1, 3))},${parseInt(
                        "0x" + hex.slice(3, 5)
                    )},${parseInt("0x" + hex.slice(5, 7))},${opacity})`;
                }
                return rgbaColor;
            }
        },
        mounted() {
            this.$nextTick(() => {
                let option = {
                    tooltip: {
                        trigger: "axis"
                    },
                    legend: {
                        type: "scroll",
                        top: "2%",
                        right: "0",
                        // itemGap: 50,
                        textStyle: {
                            color: "#ACCFFF",
                            fontSize: 10
                        }
                    },
                    grid: {
                        left: "0%",
                        right: "10%",
                        top: "25%",
                        bottom: "2%",
                        containLabel: true
                    },
                    xAxis: {
                        type: "category",
                        boundaryGap: false,
                        data: this.value.x_arr,
                        axisLabel: {
                            fontSize: 10,
                            color: this.isDark ? '#c1c1c1' : "#333",
                        },
                        axisLine:{
                            lineStyle:{
                                color: this.isDark ? '#2c2c2e' : '333'
                            }
                        },
                        splitLine:{
                            lineStyle:{
                                color: this.isDark ? '#2c2c2e' : '#D9D9D9'
                            }
                        }
                    },
                    yAxis: {
                        interval: 4,
                        min: 0,
                        max: 16,
                        type: "value",
                        boundaryGap: [0, "100%"],
                        axisLabel: {
                            fontSize: 10,
                                color: this.isDark ? '#c1c1c1' : "#333",
                        },
                        axisLine:{
                            lineStyle:{
                                color: this.isDark ? '#2c2c2e' : ''
                            }
                        },
                        splitLine:{
                            lineStyle:{
                                color: this.isDark ? '#2c2c2e' : '#D9D9D9'
                            }
                        }
                    },
                    series: [
                        {
                            name: "早餐2",
                            type: "line",
                            smooth: true,
                            symbol: "none",
                            sampling: "average",
                            lineStyle: {
                                opacity: 0.5,
                                width: 1.5
                            },
                            data: this.value.b_list,
                            markLine: {
                                silent: true,
                                symbolSize: 1,
                                data: [
                                    {
                                        yAxis: 3.9
                                    }
                                ],
                                label: {
                                    fontSize: 10
                                },
                                lineStyle: {
                                    normal: {
                                        color: "#FA6400",
                                        type: "dashed"
                                    }
                                }
                            }
                        },
                        {
                            name: "午餐2",
                            type: "line",
                            smooth: true,
                            symbol: "none",
                            sampling: "average",
                            lineStyle: {
                                opacity: 0.5,
                                width: 1.5
                            },
                            data: this.value.l_list,
                            markLine: {
                                silent: true,
                                symbolSize: 1,
                                data: [
                                    {
                                    yAxis: 7.8
                                    }
                                ],
                                label: {
                                    fontSize: 10
                                },
                                lineStyle: {
                                    normal: {
                                        color: "#FA6400",
                                        type: "dashed"
                                    }
                                }
                            }
                        },
                        {
                            name: "晚餐2",
                            type: "line",
                            smooth: true,
                            symbol: "none",
                            sampling: "average",
                            lineStyle: {
                                opacity: 0.5,
                                width: 1.5
                            },
                            data: this.value.d_list,
                        }
                    ]
                };
                setTimeout( () =>{
                    this.dom = echarts.init(this.$refs.dom, "tdTheme");
                    this.dom.setOption(option);
                },200)
                on(window, "resize", this.resize);
            });
        },
        created(){
            this.isDark = getItem('dark') || false;
        },
        beforeDestroy() {
            off(window, "resize", this.resize);
        }
    };
</script>

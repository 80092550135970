<template>
    <div class="insulinPie">
        <div ref="dom" v-if="imgData == ''"></div>
        <img :src="imgData" alt="" v-else>
    </div>
</template>

<script>
import echarts from "echarts";
import { on, off } from "_js/Cache";
let dom = null;

export default {
    name: 'insulinPie',
    props:{
        dayAvgLongInsulin: Number,
        dayAvgFoodInsulin: Number,
    },
    data(){
        return{
            imgData: '',
        }
    },
    methods:{

        resize() {
            dom.resize();
        },
        init() {
            let option = {
                animation: false,
                legend: {
                    orient: 'vertical',
                    left: 'left'
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: '95%',
                        data: [
                            { value: this.dayAvgLongInsulin },
                            { value: this.dayAvgFoodInsulin },
                        ],
                        hoverAnimation: false,
                        label: {
                            normal: {
                                show: false,
                                position: 'inside',
                                formatter:"{b}:{d}%"
                            },
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };
            dom = echarts.init(this.$refs.dom, "tdTheme");
            dom.setOption(option);
            this.imgData = dom.getDataURL({
                pixelRatio: 2
            });
            dom.dispose();
            on(window, "resize", this.resize);
        }
    },
    mounted(){
        this.init();
    },
    beforeDestroy() {
        off(window, "resize", this.resize);
    }
}
</script>

<style lang="less" scoped>
    .insulinPie{
        width: 50%;
        height: 100%;
        margin-left: 20px;
        div{
            width: 100%;
            height: 100%;
        }
        img{
            width: 100%;
            height: 100%;
        }
    }
</style>
<template>
    <div class="ACGChratLine">
        <div ref="dom" v-if="imgData == ''"></div>
        <img :src="imgData" alt="" v-else>
    </div>
</template>

<script>
import echarts from "echarts";
import tdTheme from "../Charts/theme.json";
import { on, off } from "_js/Cache";
let dom = null;

echarts.registerTheme("tdTheme", tdTheme);
export default {
    name: "ACGChratLine",
    props: {
        value: Object,
        userInfo: Object,
        isDefaultUnit: Number
    },
    data() {
        return {
            imgData: '',
        }
    },
    methods:{
        resize() {
            dom.resize();
        },
        hexToRgba (hex, opacity) {
            let rgbaColor = ''
            let reg = /^#[\da-f]{6}$/i
            if (reg.test(hex)) {
                rgbaColor = `rgba(${parseInt('0x' + hex.slice(1, 3))},${parseInt(
                '0x' + hex.slice(3, 5)
                )},${parseInt('0x' + hex.slice(5, 7))},${opacity})`
            }
            return rgbaColor
        },
        init() {
            let that = this;
            let option = {
                animation: false,
                grid: {
                    left:'3%',
                    right: '3%',
                    y: 30,
                    y2: 30,
                },
                xAxis:[
                    {
                        type:'category',
                        boundaryGap: false,
                        data: that.value.x_arr,
                        axisLabel: {
                            interval: 1
                        },
                        zlevel: 99
                    }
                ],
                yAxis:{
                    show: true,
                    type: 'value',
                    axisLabel:{
                        show: true,
                    },
                    zlevel: 99,
                    position: 'left',
                    interval: that.isDefaultUnit == 0 ? 4 : 72,
                    min:0,
                    max(value){
                        const val = that.isDefaultUnit == 0 ? ( ( parseInt(value.max) + (4 - parseInt(value.max) % 4 ) ) > 16 ? parseInt(value.max) + (4 - parseInt(value.max) % 4 ) : 16 ) : ( ( parseInt(value.max) + (32 - parseInt(value.max) % 32 ) ) > 288 ? parseInt(value.max) + (32 - parseInt(value.max) % 32 ) : 288 );
                        return val;
                    },
                },
                min: 0,
                max(value){
                    const val = that.isDefaultUnit == 0 ? ( ( parseInt(value.max) + (4 - parseInt(value.max) % 4 ) ) > 16 ? parseInt(value.max) + (4 - parseInt(value.max) % 4 ) : 16 ) : ( ( parseInt(value.max) + (32 - parseInt(value.max) % 32 ) ) > 288 ? parseInt(value.max) + (32 - parseInt(value.max) % 32 ) : 288 );
                    return  that.isDefaultUnit == 0 ? val : val * 18;
                },
                series:[
                    {
                        name: '90%线',
                        type: 'line',
                        unit: that.isDefaultUnit == 0 ? 'mmol/L' : 'mg/dL',
                        data: that.value.device_90_bloodsugar_arr,
                        areaStyle: {
                            normal: {
                                color: '#cde2f5',
                                opacity: 1
                            }
                        },
                        // markArea: {
                        //     itemStyle:{
                        //         color: '#E4E4E4'
                        //     },
                        //     silent: true,
                        //     data: [
                        //         [{
                        //             yAxis:  this.userInfo.target_low || 0
                        //         }, {
                        //             yAxis: this.userInfo.target_high || 0
                        //         }]
                        //     ]
                        // },
                        lineStyle: {
                            normal: {
                                color: '#cde2f5',
                                width: 2
                            }
                        },
                        smooth: true,
                        symbol: 'none',
                    },
                    {
                        name: '75%线',
                        type: 'line',
                        unit: that.isDefaultUnit == 0 ? 'mmol/L' : 'mg/dL',
                        data: that.value.device_75_bloodsugar_arr,
                        areaStyle: {
                            normal: {
                                color: '#a3d0f5',
                                opacity: 1
                            }
                        },
                        lineStyle: {
                            normal: {
                                color: '#a3d0f5',
                                width: 1
                            }
                        },
                        smooth: true,
                        symbol: 'none',
                    },
                    {
                        name: '平均线',
                        type: 'line',
                        unit: that.isDefaultUnit == 0 ? 'mmol/L' : 'mg/dL',
                        data: that.value.device_avg_bloodsugar_arr,
                        markLine:{
                            symbol: ['none','none'],
                            label: {
                                position: 'start'
                            },
                            animation: false,
                            silent: true,
                            lineStyle: {
                                'type': 'solid',
                                'width': 1,
                            }
                        },
                        lineStyle: {
                            normal: {
                                color: '#515151',
                                width: 3,
                            }
                        },
                        smooth: true,
                        symbol: 'none',
                    },
                    {
                        name: '25%线',
                        type: 'line',
                        unit: that.isDefaultUnit == 0 ? 'mmol/L' : 'mg/dL',
                        data: that.value.device_25_bloodsugar_arr,
                        areaStyle: {
                            normal: {
                                color: '#cde2f5',
                                opacity: 1
                            }
                        },
                        lineStyle: {
                            normal: {
                                color: '#cde2f5',
                                width: 1
                            }
                        },
                        smooth: true,
                        symbol: 'none',
                    },
                    {
                        name: '10%线',
                        type: 'line',
                        unit: that.isDefaultUnit == 0 ? 'mmol/L' : 'mg/dL',
                        data: that.value.device_10_bloodsugar_arr,
                        areaStyle: {
                            normal: {
                                color: 'white',
                                opacity: 1
                            }
                        },
                        lineStyle: {
                            normal: {
                                color: '#cde2f5',
                                width: 2
                            }
                        },
                        smooth: true,
                        symbol: 'none',
                    },
                    {
                        name:'最高线',
                        type:'line',
                        data: that.value.device_max_bloodsugar_arr,
                        unit: that.isDefaultUnit == 0 ? 'mmol/L' : 'mg/dL',
                        itemStyle: {
                            normal:{
                                lineStyle: {
                                    width: 2,
                                    type: 'dashed',
                                    color: '#979797'
                                }
                            }
                        },
                        smooth: true,
                        symbol: 'none',
                    },
                    {
                        name: '最低线',
                        type: 'line',
                        unit: that.isDefaultUnit == 0 ? 'mmol/L' : 'mg/dL',
                        data: that.value.device_min_bloodsugar_arr,
                        areaStyle: {
                            normal: {
                                color: 'white',
                                opacity: 1
                            }
                        },
                        itemStyle: {
                            normal:{
                                lineStyle: {
                                    width: 2,
                                    type: 'dashed',
                                    color: '#979797'
                                }
                            }
                        },
                        smooth: true,
                        symbol: 'none',
                    }
                ],
                visualMap: {
                    show: false,
                    precision: 1,
                    pieces: [
                        {
                            gte: 1,
                            lte: that.isDefaultUnit == 0 ? that.userInfo.alert_low : that.userInfo.alert_low * 18 ,
                            color: '#ffcc00'
                        },
                        {
                            gte: that.isDefaultUnit == 0 ? that.userInfo.alert_low : that.userInfo.alert_low * 18,
                            lte: that.isDefaultUnit == 0 ? that.userInfo.alert_high : that.userInfo.alert_high * 18,
                            color: '#18d4B1'
                        },
                        {
                            gte: that.isDefaultUnit == 0 ? that.userInfo.alert_high : that.userInfo.alert_high * 18,
                            color: '#cc0033'
                        }
                    ]
                },
            };
            dom = echarts.init(that.$refs.dom, "tdTheme");
            dom.setOption(option);
            that.imgData = dom.getDataURL({
                pixelRatio: 2
            });
            dom.dispose();
            on(window, "resize", that.resize);
        }
    },
    mounted(){
        this.init();
    },
    beforeDestroy() {
        off(window, "resize", this.resize);
    }
}
</script>

<style lang="less" scoped>
    .ACGChratLine{
        width: 100%;
        height: 100%;
        opacity: .8;
        div{
            width: 100%;
            height: 100%;
        }
        img{
            width: 100%;
            height: 100%;
        }
    }
</style>
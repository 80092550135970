<template>
    <div class="pie" ref="dom" v-if="value"></div>
</template>

<script>
    import echarts from "echarts";
    import tdTheme from "./theme.json";
    import { on, off, getItem } from "_js/Cache";

    echarts.registerTheme("tdTheme", tdTheme);
    export default {
        name: 'ChartPie',
        props:{
            value: Array,
            text: String
        },
        watch:{
            value:{
                handler(){
                    this.init();
                },
                deep: true
            }
        },
        data () {
            return {
                dom: null,
                isDark: false
            }
        },
        methods:{
            init(){
                let isIpad = window.innerWidth >= 1024 && window.innerWidth <= 1366;
                this.$nextTick(() => {
                    let option = {
                        title: {
                            text: this.text,
                            x: 'center',
                            textStyle: {
                                fontSize: 16,
                                color: this.isDark ? '#c1c1c1' : '#999',
                                lineHeight: 20
                            },
                            textAlign: 'center',
                            left: '48%',
                            top: '45%'
                        },  
                        tooltip: {
                            trigger: 'item',
                            formatter: '{b} : {d}%'
                        },
                        series: [
                            {
                                type: 'pie',
                                center: ['50%', '50%'],
                                radius: isIpad ? [60, 80] : [60, 90],
                                    label: {
                                        show: false
                                    },
                                    labelLine: {
                                        show: false
                                    },
                                    data: this.value,
                                    itemStyle: {
                                    emphasis: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                            }
                        ]
                    }
                    this.dom = echarts.init(this.$refs.dom, 'tdTheme')
                    this.dom.setOption(option)
                    on(window, 'resize', this.resize)
                })
            }
        },
        created(){
            this.isDark = getItem('dark') || false;
        },
        beforeDestroy () {
            off(window, 'resize', this.resize)
        }
    }
</script>

<style scoped>
    .pie{
        width: 361px;
        height: 300px;
    }
</style>
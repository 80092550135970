<template>
    <div class="histogram float-left" ref="dom" v-if="value"></div>
</template>

<script>
    import echarts from "echarts";
    import tdTheme from "./theme.json";
    import { on, off, getItem } from "_js/Cache";

    echarts.registerTheme("tdTheme", tdTheme);
    export default {
        name: 'ChartHistogram',
        props: {
            value: Array
        },
        watch:{
            value:{
                handler(){
                    this.init();
                },
                deep: true
            }
        },
        data () {
            return {
                dom: null,
                isDark: false
            }
        },
        methods:{
            resize () {
                this.dom.resize()
            },
            init(){

                let xAxisData = this.value.map( i => {
                    return i.time
                })

                let value = [];
                this.value.map( i => {

                    let str;
                    if(i.value > 75 ){
                        str = {
                            value: i.value,
                            itemStyle:{
                                color: '#19be6b',
                                barBorderRadius : [ 4, 4, 0, 0],
                            }
                        };
                    }else if(i.value <= 75 && i.value > 50 ){
                        str = {
                            value: i.value,
                            itemStyle: {
                                color:'#F7B500',
                                barBorderRadius : [ 4, 4, 0, 0],
                            }
                        }
                    }else if(i.value < 50 ){
                        str = {
                            value: i.value,
                            itemStyle: {
                                color:'#E02020',
                                barBorderRadius : [ 4, 4, 0, 0],
                            }
                        }
                    }
                    value.push(str);
                })

                this.$nextTick(() => {
                    let option = {
                        title: {
                            text: this.text,
                            x: '4%',
                            y: '40px',
                            textStyle: {
                                fontSize: 21,
                                color: '#333',
                                fontFamily: 'PingFangSC-Regular, PingFang SC'
                            }
                        },
                        grid:{
                            width: '90%',
                            height: '200px',
                            left: '5%',
                            top: 90,
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow',
                                shadowStyle:{
                                    opacity: 0.5
                                }
                            }
                        },
                        xAxis: {
                            type: 'category',
                            data: xAxisData,
                            splitLine:{
                                show: false
                            },
                            axisLabel: {
                                textStyle: {
                                    color: this.isDark ? '#c1c1c1' : "#333",
                                },
                                lineStyle:{
                                    color: this.isDark ? '#2c2c2e' : "#D9D9D9"
                                }
                            },
                            axisLine:{
                                lineStyle:{
                                    color: this.isDark ? '#2c2c2e' : "#D9D9D9"
                                }
                            },
                        },
                        yAxis: {
                            type: 'value',
                            axisLine:{
                                show: false
                            },
                            interval: 25,
                            max(){
                                return 100
                            },
                            nameStyle:{
                                color:'rgba(0,0,0,0.45)',
                                fontSize:14,
                            },
                            splitLine:{
                                lineStyle:{
                                    color: this.isDark ? '#2c2c2e' : "#D9D9D9"
                                }
                            },
                            axisLabel: {
                                textStyle: {
                                    color: this.isDark ? '#c1c1c1' : "#333",
                                },
                                lineStyle:{
                                    color: this.isDark ? '#2c2c2e' : "#333"
                                }
                            },
                        },
                        series: [{
                            data: value,
                            type: 'bar',
                            barWidth: '70%'
                        }]
                    }

                    this.dom = echarts.init(this.$refs.dom, 'tdTheme')
                    this.dom.setOption(option)
                    on(window, 'resize', this.resize)
                })
            }
        },
        created(){
            this.isDark = getItem('dark') || false;
        },
        beforeDestroy () {
            off(window, 'resize', this.resize)
        }
    }
</script>

<style scoped>
    .histogram{
        width: calc(100% - 265px);
        height: 100%;
        padding-left: 25px;
    }
</style>
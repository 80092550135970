<template>
    <div class="SectionLine radius-5 clear">
        <div ref="dom" v-if="imgData == ''"></div>
        <img :src="imgData" alt="" v-else>
    </div>
</template>

<script>
    import echarts from "echarts";
    import { on, off, dataGolay } from "_js/Cache";
    let dom = null;

    export default {
        name: "SectionLine",
        props:{
            value: Object,
            target_low: String,
            target_high: String,
            isDefaultUnit: Boolean
        },
        data(){
            return{
                imgData: ''
            }
        },
        methods: {
            resize() {
                dom.resize();
            },

            init(){
                let t = this;
                let yAxisData,yAxisData1,yAxisData2,
                    values = dataGolay(t.value.value);

                yAxisData = t.value.time.map( (item,index) => {
                    return [
                        item, values[index]
                    ];
                });

                yAxisData1 = t.value.time.map( (item) => {
                    return [
                        item, t.isDefaultUnit == 1 ? t.target_low * 18 : t.target_low
                    ];
                });
                yAxisData2 = t.value.time.map( (item) => {
                    return [
                        item, Number(t.isDefaultUnit == 1 ? t.target_high * 18 : t.target_high) - Number(t.isDefaultUnit == 1 ? t.target_low * 18 : t.target_low)
                    ];
                });

                let option = {
                    animation: false,
                    xAxis: {
                        show: false,
                        type: 'time',
                        // boundaryGap: false,
                        // data: xAxisData
                    },
                    yAxis: {
                        show: false,
                        type: 'value',
                        interval: t.isDefaultUnit == 1 ? 72 : 4,
                        min:0,
                        max(value){
                            const val = t.isDefaultUnit == 0 ? ( ( parseInt(value.max) + (4 - parseInt(value.max) % 4 ) ) > 16 ? parseInt(value.max) + (4 - parseInt(value.max) % 4 ) : 16 ) : ( ( parseInt(value.max) + (32 - parseInt(value.max) % 32 ) ) > 288 ? parseInt(value.max) + (32 - parseInt(value.max) % 32 ) : 288 );
                            return val;
                        },
                    },
                    grid:{
                        width: '100%',
                        height: '80%',
                        left: 0,
                        bottom: 0,
                    },
                    series: [
                        {
                            data: yAxisData1,
                            stack:'a',
                            type: 'line',
                            symbol: "none",
                            silent: true,
                            lineStyle:{
                                color: 'gray',
                                width: 0
                            },
                            areaStyle: {
                                color: '#E03520',
                                opacity: 1
                            }
                        },
                        {
                            data: yAxisData2,
                            stack: 'a',
                            type: 'line',
                            silent: true,
                            symbol: "none",
                            areaStyle: {
                                color: '#DEDEDE',
                                opacity: 1
                            },
                            lineStyle:{
                                width: 0
                            }
                        },
                        {
                            data: yAxisData,
                            type: 'line',
                            smooth: true,
                            silent: true,
                            symbol: "none",
                            connectNulls: true,
                            areaStyle: {
                                opacity: 1
                            },
                            lineStyle: {
                                width: 1,
                                color: 'black'
                            },
                            markArea: {
                                itemStyle:{
                                    color: '#DEDEDE'
                                },
                                silent: true,
                                data: [
                                    [{
                                        yAxis: t.isDefaultUnit == 1 ? t.target_low * 18 : t.target_low || 0
                                    }, {
                                        yAxis: t.isDefaultUnit == 1 ? t.target_high * 18 : t.target_high || 0
                                    }]
                                ]
                            },
                        },
                    ],
                    visualMap: {
                        show: false,
                        precision: 1,
                        backgroundColor: 'white',
                        pieces: [
                            {
                                lte: Number( t.isDefaultUnit == 1 ? t.target_low * 18 : t.target_low),
                                color: 'white',
                            },
                            {
                                gte: Number(t.isDefaultUnit == 1 ? t.target_high * 18 : t.target_high),
                                color: '#F7B500'
                            }
                        ]
                    }

                }
                dom = echarts.init(t.$refs.dom,null);
                dom.setOption(option);
                t.imgData = dom.getDataURL({
                    pixelRatio: 2
                });
                dom.dispose();
                on(window, "resize", t.resize);
            }
        },
        mounted(){
            this.init();
        },
        beforeDestroy() {
            off(window, "resize", this.resize);
        }
    }
</script>

<style lang="less" scoped>
    .SectionLine{
        width: 100%;
        height: 100%;
        div{
            width: 100%;
            height: 100%;
        }
        img{
            width: 100%;
            height: 100%;
        }
    }
</style>
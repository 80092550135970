<template>
    <div class="charts charts-finget" v-if="value" ref="dom"></div>
</template>

<script>
    import echarts from "echarts";
    import tdTheme from "./theme.json";
    import { on, off, getItem } from "_js/Cache";

    echarts.registerTheme("tdTheme", tdTheme);
    export default {
        props: {
            value: {
                type: Array,
                default: () => {
                    return [];
                },
                required: false
            },
            target_high: {
                default: null
            },
            target_low: {
                default: null
            },
            
        },
        data() {
            return {
                dom: null,
                isDark: false,
            };
        },
        methods: {
            resize() {
                this.dom.resize();
            },
            hexToRgba(hex, opacity) {
                let rgbaColor = "";
                let reg = /^#[\da-f]{6}$/i;
                if (reg.test(hex)) {
                    rgbaColor = `rgba(${parseInt("0x" + hex.slice(1, 3))},${parseInt(
                    "0x" + hex.slice(3, 5)
                    )},${parseInt("0x" + hex.slice(5, 7))},${opacity})`;
                }
                return rgbaColor;
            },
            init() {
                let __self__ = this;
                let xAxisData = this.value.map(i => {
                    return i.time;
                });

                let yAxisData1 = this.value.map(i => {
                    return i.value;
                });
                let color = ["#16CCA6", "#00CA69"];
                let option = {
                    color: color,
                    tooltip: {
                        trigger: "axis",
                    },
                    visualMap: {
                        top: 0,
                        left: 0,
                        precision:1,
                        show: false,
                        orient:'horizontal',
                        pieces: [
                            {
                                gte: 0,
                                lte: Number(__self__.target_low),
                                color: '#ED1B24'
                            },
                            {
                                gte: Number(__self__.target_low),
                                lte: Number(__self__.target_high),
                                color: '#16cca6'
                            },
                            {
                                gte: Number(__self__.target_high),
                                color: '#FFA901'
                            }
                        ],
                    },
                    grid: {
                        top: 10,
                        bottom: 20,
                        left: 20,
                        right: 10
                    },
                    xAxis: [
                        {
                            type: "category",
                            offset: -1,
                            boundaryGap: false,
                            show: true,
                            axisLabel: {
                                formatter(val) {
                                    return val;
                                },
                                interval: 24,
                                textStyle: {
                                    color: __self__.isDark ? '#c1c1c1' : "#333",
                                    fontSize: 12
                                }
                            },
                            splitLine: {
                                show: __self__.showYLine ? true : false,
                                lineStyle: {
                                    type: __self__.showYLine ? "solid" : "dashed",
                                    color: "#E9E9E9"
                                }
                            },
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: __self__.isDark ? '#c1c1c1' : "#D9D9D9"
                                }
                            },
                            data: xAxisData
                        }
                    ],
                    yAxis: [
                        {
                            show: true,
                            position: "right",
                            offset: -20,
                            type: "value",
                            interval: 4,
                            min: 0,
                            max(value) {
                                const val =
                                    parseInt(value.max) + (4 - (parseInt(value.max) % 4)) > 16
                                    ? parseInt(value.max) + (4 - (parseInt(value.max) % 4))
                                    : 16;
                                return val;
                            },
                            axisLabel: {
                                textStyle: {
                                    color: __self__.isDark ? '#c1c1c1' : "#666",
                                    fontSize: 12
                                }
                            },
                            nameTextStyle: {
                                color: "#666",
                                fontSize: 12,
                                lineHeight: 40
                            },
                            // 分割线
                            splitLine: {
                                lineStyle: {
                                    color: __self__.isDark ? '#2c2c2e' : "#E9E9E9"
                                }
                            },
                            axisLine: {
                                show: false
                            },
                            axisTick: {
                                show: false
                            }
                        }
                    ],
                    series: [
                        {
                            name: "血糖",
                            type: "scatter",
                            smooth: true,
                            symbolSize: 10,
                            zlevel: 3,
                            symbol: "circle", // 数据交叉点样式
                            emphasis:{
                                scale: false,
                                itemStyle:{
                                    color: '#f6b603', //颜色
                                    borderColor: '#f6b603', //图形的描边颜色
                                    borderWidth: 0, // 描边的线宽
                                    shadowColor: '#f6b603', // 图形的阴影颜色
                                }
                            },
                            markArea: {
                                itemStyle: {
                                    color: __self__.hexToRgba("#5EFF72", 0.1)
                                },

                                data: [
                                    [
                                        {
                                            yAxis: __self__.target_low || 0
                                        },
                                        {
                                            yAxis: __self__.target_high || 0
                                        }
                                    ]
                                ],
                                animation: true
                            },
                            markPoint: {
                                data: __self__.specialArr
                            },
                            data: yAxisData1
                        }
                    ]
                };
                this.dom = echarts.init(this.$refs.dom, "tdTheme");
                this.dom.setOption(option);
                on(window, "resize", this.resize);
            }
        },
        created(){
            this.isDark = getItem('dark') || false;
        },
        mounted() {
            setTimeout( () =>{
                this.init();
            },200)
        },
        beforeDestroy() {
            off(window, "resize", this.resize);
        }
    };
</script>

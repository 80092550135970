<template>
    <div class="menu pion-abs" :class="[!Dark ? '' : 'isDark', collapsed ? 'hide' : '' ]">
        <div class="menu-list">
            <template v-for="(item,index) in menu">
                <div class="list flex align-center pl-20" :class="[ pageIndex == index ? 'on' : '' ]" :key="index" v-if="(item.roleId >= roleId) && (item.router != '/hospital' || is_reverse > 0)" @click="go(index,item.router)" >
                    <!-- $t('route.index') -->
                    <i class="ml-5 mr-12 font-16" :class="item.icon"></i>
                    <p class="font-16" v-if="!collapsed">{{ item.title }}</p>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { getItem } from '_js/Cache';
    export default {
        props:{
            Dark: Boolean,
            collapsed: Boolean
        },
        data(){
            return{
                menu: [
                    {
                        icon: 'el-icon-s-home',
                        title: this.$t('menu.index.title'),
                        router: '/',
                        roleId: 500,
                    },
                    {
                        icon: 'el-icon-menu',
                        title: this.$t('menu.list.title'),
                        router: '/patient',
                        roleId: 500,
                    },
                    {
                        icon: 'el-icon-s-order',
                        title: this.$t('menu.Task.title'),
                        router: '/task',
                        roleId: 500,
                    },
                    {
                        icon: 'el-icon-s-claim',
                        title: this.$t('menu.plan.title'),
                        router: '/stencil',
                        roleId: 500,
                    },
                    {
                        icon: 'el-icon-s-ticket',
                        title: this.$t('menu.hospital.title'),
                        router: '/hospital',
                        roleId: 500,
                    },
                    {
                        icon: 'el-icon-message-solid',
                        title: this.$t('menu.Custody.title'),
                        router: '/monitor',
                        roleId: 500,
                    },
                    {
                        icon: 'el-icon-s-cooperation',
                        title: this.$t('menu.Organization.title'),
                        router: '/organization',
                        roleId: 5,
                    },
                    {
                        icon: 'el-icon-s-custom',
                        title: this.$t('menu.setting.title'),
                        router: '/setting',
                        roleId: 500,
                    }
                ],
                pageIndex: this.$route.meta.pageIndex,
                roleId: getItem('userInfo').role_id,
                is_reverse: getItem('agencyInfo').is_reverse
            }
        },
        watch:{
            $route(){
                this.pageIndex = this.$route.meta.pageIndex
            }
        },
        methods:{
            go(idx,page){
                this.pageIndex = idx;
                if(this.pageIndex == 5){
                    const { href } = this.$router.resolve({
                        path: page
                    });
                    window.open(href, '_blank');
                }else{
                    this.$router.push({
                        path: page
                    })
                }
            }
        },
    }
</script>

<style scoped lang="less">
    .menu{
        left: 0px;
        width: 200px;
        height: 100%;
        background: var(--white);
        z-index: 9;
        transition: .3s;
        &.hide{
            width: 65px;
        }
        .list{
            width: 100%;
            height: 52px;
            cursor: pointer;
            transition: .3s;
            &:hover,
            &.on{
                background: var(--defalutColor);
                color: white;
                transition: .3s;
            }
        }
        &.isDark{
            background: #202025;
            transition: .3s;
            .list{
                color: #c1c1c1;
                &:hover,
                &.on{
                    background: unset;
                    color: var(--defalutColor);
                    p{
                        color: var(--defalutColor);
                        transition: .3s;
                    }
                }
            }
        }
    }

</style>
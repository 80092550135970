<template>
    <div ref="dom" class="charts chart-bar" v-if="value"></div>
</template>

<script>
    import echarts from "echarts";
    import tdTheme from "./theme.json";
    import { on, off, getItem } from '_js/Cache'
    echarts.registerTheme("tdTheme", tdTheme);

    export default {
        props: {
            value: Object,
        },
        data() {
            return {
                dom: null,
                isIpad: false,
                isDark: false,
            };
        },
        methods: {
            resize() {
                this.dom.resize();
            },
            hexToRgba(hex, opacity) {
                let rgbaColor = "";
                let reg = /^#[\da-f]{6}$/i;
                if (reg.test(hex)) {
                    rgbaColor = `rgba(${parseInt("0x" + hex.slice(1, 3))},${parseInt(
                    "0x" + hex.slice(3, 5)
                    )},${parseInt("0x" + hex.slice(5, 7))},${opacity})`;
                }
                return rgbaColor;
            },
            init() {
                let option = {
                    grid: {
                        left:'5%',
                        right: '8%',
                        y: 30,
                        y2: 30,
                        top: '5%',
                        // width: '100%',
                        height: this.isIpad ? '70%' : '84%'
                    },
                    tooltip:{
                        trigger: "axis",
                        // 坐标轴指示器
                        backgroundColor: "rgba(73,91,127,1)"
                    },
                    xAxis:[
                        {
                            type:'category',
                            boundaryGap: false,
                            data: this.value.x_arr,
                            axisLabel: {
                                interval: this.isIpad ? 24 : 3,
                                textStyle: {
                                    color: this.isDark ? '#c1c1c1' : "#333",
                                }
                            },
                            axisLine:{
                                lineStyle:{
                                    color: this.isDark ? '#2c2c2e' : "#D9D9D9"
                                }
                            },
                            splitLine:{
                                lineStyle:{
                                    color: this.isDark ? '#2c2c2e' : "#D9D9D9"
                                }
                            },
                            zlevel: 99,
                            offset: 0
                        }
                    ],
                    yAxis:{
                        show: true,
                        type: 'value',
                        zlevel: 99,
                        position: 'right',
                        interval:4,
                        min:0,
                        offset: 0,
                        max(value){
                            const val = ( parseInt(value.max) + (4 - parseInt(value.max) % 4 ) ) > 16 ? parseInt(value.max) + (4 - parseInt(value.max) % 4 ) : 16;
                            // __self__.addYaxis(val);
                            return val;
                        },
                        axisLabel:{
                            show: true,
                            textStyle: {
                                color: this.isDark ? '#c1c1c1' : "#333",
                            }
                        },
                        axisLine:{
                            lineStyle:{
                                color: this.isDark ? '#2c2c2e' : "#D9D9D9"
                            }
                        },
                        splitLine:{
                            lineStyle:{
                                color: this.isDark ? '#2c2c2e' : "#D9D9D9"
                            }
                        },
                    },
                    series:[
                        {
                            name:'最高线',
                            type:'line',
                            data: this.value.device_max_bloodsugar_arr,
                            unit: 'mmol/L',
                            marikLine:{
                                symbol: ['none','none'],
                                lable:{
                                    show: false,
                                    position: 'start'
                                },
                                data: [
                                    {
                                        lineStyle: {
                                            color: '#A9A9A9'
                                        },
                                        label:{
                                            show: true,
                                            position: 'start'
                                        }
                                    },
                                    {
                                        lineStyle:{
                                            color: '#a9a9a9',
                                        },
                                        label:{
                                            show: true,
                                            position: 'start'
                                        }
                                    },
                                ],
                                animation: false,
                                silent: true,
                                lineStyle:{
                                    'type': 'solid',
                                    'width': 1,
                                }
                            },
                            areaStyle: {
                                normal: {
                                    color: '#75c4f8'
                                }
                            },
                            itemStyle: {
                                normal:{
                                    lineStyle: {
                                    width: 1,
                                    type: 'dashed',
                                    color: '#979797'
                                    }
                                }
                            },
                            symbol: 'none',
                        },
                        {
                            name: '平均线',
                            type: 'line',
                            unit: 'mmol/L',
                            data: this.value.device_avg_bloodsugar_arr,
                            markLine:{
                                symbol: ['none','none'],
                                label: {
                                    position: 'start'
                                },
                                animation: false,
                                silent: true,
                                lineStyle: {
                                    'type': 'solid',
                                    'width': 1,
                                }
                            },
                            lineStyle: {
                                normal: {
                                    color: '#74848e',
                                    width: 2,
                                }
                            },
                            symbol: 'none',
                        },
                        {
                            name: '最低线',
                            type: 'line',
                            unit: 'mmol/L',
                            data: this.value.device_min_bloodsugar_arr,
                            areaStyle: {
                                normal: {
                                    color: this.isDark ? '#202025' : 'white',
                                    opacity: 1
                                }
                            },
                            itemStyle: {
                                normal:{
                                    lineStyle: {
                                        width: 1,
                                        type: 'dashed',
                                        color: '#979797'
                                    }
                                }
                            },
                            symbol: 'none',
                        }
                    ],
                    visualMap: {
                        show: false,
                        precision: 1,
                    }
                };
                this.dom = echarts.init(this.$refs.dom, "tdTheme");
                this.dom.setOption(option);
                on(window, "resize", this.resize);
            }
        },
        mounted() {
            this.init()
            this.isIpad = window.innerWidth >= 1024 && window.innerWidth <= 1366;
        },
        created(){
            this.isDark = getItem('dark') || false;
        },
        beforeDestroy() {
            off(window, "resize", this.resize);
        }
    }
</script>

<template>
    <div class="print-cont night pion-rel" :style="{'margin-top' : !download ? '10px' : '0px' }">

        <div class="tiptitle">
            <div class="title">
                <h2>{{$t('menu.list.Print.page6.title')}}</h2>
                <p>{{ timeGet(bindTime,endTime) }}（{{days}}{{lang == 'en' ?  'days': '天' }}）
                    <span>{{$t('menu.list.Print.page6.times')}}：{{ coverage }}%</span>
                    <span>{{$t('menu.list.Print.page6.number')}}：{{ deviceBloodSugarTotal}} {{$t('menu.list.Print.page6.titUnit')}}</span>
                </p>
            </div>
            <img class="logo" :src="logo" alt="">
        </div>

        <div class="night-conts">
            <div class="curve pt-20 mt-20">
                <div class="color pt-20 mt-5 solid-top flex flex-wrap">
                    <div class="list flex align-center mt-10 mb-10" v-for="(item, index) in nightDayData" :key="index">
                        <p class="font-16">{{ timeGet(item.date) }}</p>
                        <hr class="ml-15 radius-5">
                    </div>
                </div>
            </div>
            <div class="night-charts" v-if="nightChartValue != null && nightLineX_Arr != null">
                <EveryDaysLine :x_arr="nightLineX_Arr" :value="nightChartValue" :isNight="false" :color="colorList" :target_low="userInfo.target_low" :target_high="userInfo.target_high" :isDefaultUnit="isDefaultUnit" />
            </div>
            <div class="night-table">
                <div class="table-title flex">
                    <h4>{{$t('menu.list.Print.page6.titles')}}</h4>
                    <div class="scope font-20 flex align-center">{{$t('menu.list.Print.page6.high')}}<span class="block radius-50 ml-10"></span></div>
                    <div class="scope font-20 flex align-center">{{$t('menu.list.Print.page6.low')}}<span class="block radius-50 ml-10"></span></div>
                </div>
            </div>
            <div class="table mt-5 pt-20" v-if="days < 16">
                <div class="tip-time flex solid-top solid-bottom solid-left">
                    <p class="solid-right"></p>
                    <p class="text-center solid-right font-16">23-0</p>
                    <p class="text-center solid-right font-16">0-1{{$t('menu.list.Print.page6.unit')}}</p>
                    <p class="text-center solid-right font-16">1-2{{$t('menu.list.Print.page6.unit')}}</p>
                    <p class="text-center solid-right font-16">2-3{{$t('menu.list.Print.page6.unit')}}</p>
                    <p class="text-center solid-right font-16">3-4{{$t('menu.list.Print.page6.unit')}}</p>
                    <p class="text-center solid-right font-16">4-5{{$t('menu.list.Print.page6.unit')}}</p>
                    <p class="text-center solid-right font-16">5-6{{$t('menu.list.Print.page6.unit')}}</p>
                    <p class="text-center solid-right font-16">6-7{{$t('menu.list.Print.page6.unit')}}</p>
                </div>
                <div class="date-value">

                <!-- eslint-disable-next-line -->
                    <div class="list flex solid-left solid-bottom" v-for="(item,index) in nightDayData" v-if="index < 20" :key="index">
                        <p class="date font-14 text-center solid-right">{{item.date}}</p>
                        <p class="value font-14 text-center solid-right" v-for="(items, idxs) in item.list" :key="idxs" :class="[ Number(items) < userInfo.target_low ? 'IPE' : Number(items) > userInfo.target_high ? 'high' : '' ]">{{ items == '--' ? '--' : isDefaultUnit == 1 ? Number(changUnit(isDefaultUnit,items).val).toFixed(0) : Number(changUnit(isDefaultUnit,items).val).toFixed(1) }}</p>
                    </div>
                </div>
            </div>
            <div class="table mt-5 pt-20" v-else>
                <div class="monDays flex" v-for="(item,idx) in Math.ceil(nightDayData.length / 15)" :key="idx" :class="[idx > 0 ? 'mt-20' : '']">
                    <template v-for="(item,index) in nightDayData">
                        <!-- eslint-disable-next-line -->
                        <div class="tip-time solid-top solid-bottom solid-left" v-if=" ( idx == 0 && index == 0 ) || ( idx == 1 && index == 16 ) ">
                            <p class="solid-right solid-bottom"></p>
                            <p class="text-center solid-right solid-bottom font-16">23-0{{$t('menu.list.Print.page6.unit')}}</p>
                            <p class="text-center solid-right solid-bottom font-16">0-1{{$t('menu.list.Print.page6.unit')}}</p>
                            <p class="text-center solid-right solid-bottom font-16">1-2{{$t('menu.list.Print.page6.unit')}}</p>
                            <p class="text-center solid-right solid-bottom font-16">2-3{{$t('menu.list.Print.page6.unit')}}</p>
                            <p class="text-center solid-right solid-bottom font-16">3-4{{$t('menu.list.Print.page6.unit')}}</p>
                            <p class="text-center solid-right solid-bottom font-16">4-5{{$t('menu.list.Print.page6.unit')}}</p>
                            <p class="text-center solid-right solid-bottom font-16">5-6{{$t('menu.list.Print.page6.unit')}}</p>
                            <p class="text-center solid-right font-16">6-7{{$t('menu.list.Print.page6.unit')}}</p>
                        </div>
                        <!-- eslint-disable-next-line -->
                        <div class="date-value" v-if=" ( idx == 0 && index < 16 ) || ( idx == 1 && index >= 16 ) ">
                            <div class="list solid-top">
                                <p class="date font-14 text-center solid-right solid-bottom">{{notYears(item.date)}}</p>
                                <p class="value font-14 text-center solid-right solid-bottom" v-for="(items, idxs) in item.list" :key="idxs" :class="[ Number(items) < userInfo.target_low ? 'IPE' : Number(items) > userInfo.target_high ? 'high' : '' ]">{{items == '--' ? '--' : isDefaultUnit == 1 ? Number(changUnit(isDefaultUnit,items).val).toFixed(0) : Number(changUnit(isDefaultUnit,items).val).toFixed(1) }}</p>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div class="page-bottom pion-abs">
            <p>{{$t('menu.list.Print.page5.date')}}：{{toDay}}</p>
            <p>{{$t('menu.list.Print.page2.form')}}：{{ lang == 'en' ? '' : '糖动' }}CGMCARE</p>
            <!-- {{ 4 + ( foodRecordRankLen > 0 ? 1 : 0 ) + Math.ceil((daysLen  / 16 )) + Math.ceil(daysLen  / 4 ) }}/{{ 4 + ( foodRecordRankLen > 0 ? 1 : 0 ) + Math.ceil((daysLen  / 16 )) + Math.ceil(daysLen / 4) + ( Math.ceil(daysLen / 16) > 1 ? 2 : 1 ) + Number(pageAdd) }} -->
            <p>{{$t('menu.list.Print.page5.page')}}：{{ pageNum }}/{{ sum }}</p>
        </div>

    </div>
</template>

<script>
    import { time, getItem, changUnit } from '_js/Cache';
    import { EveryDaysLine } from '_c/print';
    export default {
        components:{
            EveryDaysLine
        },
        props:{
            download: Boolean,
            logo: String,
            bindTime: Number,
            endTime: Number,
            days: Number,
            coverage: Number,
            deviceBloodSugarTotal: Number,
            nightDayData: Array,
            nightChartValue: Array,
            nightLineX_Arr: Array,
            colorList: Array,
            userInfo: Object,
            toDay: String,
            foodRecordRankLen: Number,
            daysLen: Number,
            parintList: Array,
            pageAdd: Number || String,
            isDefaultUnit: Number
        },
        data(){
            return{
                sum: 0,
                pageNum: 4,
                lang: getItem('lang')
            }
        },
        watch:{
            parintList:{
                handler(){
                    this.getPageSum();
                },
                deep: true
            },
            pageAdd:{
                handler(){
                    this.getPageSum();
                },
                deep: true
            }
        },
        methods:{
            notYears(days){
                return time( new Date(days), 'MM-DD');
            },
            timeGet(start,end){
                return end ? time(new Date(start * 1000 ),this.lang == 'en' ? 'YYYY/MM/DD' : '年月日' ) + '—' + time(new Date(end * 1000 ),this.lang == 'en' ? 'MM/DD' : '月日') : time(new Date(start),'MM/DD');
            },
            getPageSum(){
                setTimeout(() =>{
                    let doms = document.getElementsByClassName('print-cont'),
                        listArr = Array.from(doms);
                    this.sum = doms.length;

                    listArr.map((item,index) =>{
                        item.className.split(' ').map(items =>{
                            if(items == 'night'){
                                this.pageNum = index + 1;
                            }
                        })
                    })
                })
            },
            changUnit(t,v){
                return changUnit(t,v);
            }
        },
        mounted(){
            this.getPageSum();
        }
    }
</script>

<style lang="less" scoped>

    .night-conts{
        .curve{
            .color{
                border-color: #e5e5e5;
                .list{
                    width: calc(~'100% / 8');
                    p{
                        color: #000;
                    }
                    hr{
                        width: 35px;
                        height: 4px;
                    }
                }
                &>.list:nth-of-type(1) hr{
                    background: #A4C400;
                }
                &>.list:nth-of-type(2) hr{
                    background: #6A00FF;
                }
                &>.list:nth-of-type(3) hr{
                    background: #FA6800;
                }
                &>.list:nth-of-type(4) hr{
                    background: #76608A;
                }
                &>.list:nth-of-type(5) hr{
                    background: #AA00FF;
                }
                &>.list:nth-of-type(6) hr{
                    background: #F0A30A;
                }
                &>.list:nth-of-type(7) hr{
                    background: #A0522D;
                }
                &>.list:nth-of-type(8) hr{
                    background: #008A00;
                }
                &>.list:nth-of-type(9) hr{
                    background: #F472D0;
                }
                &>.list:nth-of-type(10) hr{
                    background: #00ABA9;
                }
                &>.list:nth-of-type(11) hr{
                    background: #1BA1E2;
                }
                &>.list:nth-of-type(12) hr{
                    background: #A20025;
                }
                &>.list:nth-of-type(13) hr{
                    background: #6D8764;
                }
                &>.list:nth-of-type(14) hr{
                    background: #0050EF;
                }
                &>.list:nth-of-type(15) hr{
                    background: #17CCA6;
                }
                &>.list:nth-of-type(16) hr{
                    background: #D4FF00;
                }
                &>.list:nth-of-type(17) hr{
                    background: #5100C2;
                }
                &>.list:nth-of-type(18) hr{
                    background: #FE8026;
                }
                &>.list:nth-of-type(19) hr{
                    background: #C093EA;
                }
                &>.list:nth-of-type(20) hr{
                    background: #520976;
                }
                &>.list:nth-of-type(21) hr{
                    background: #BE820B;
                }
                &>.list:nth-of-type(22) hr{
                    background: #f37134;
                }
                &>.list:nth-of-type(23) hr{
                    background: #00F700;
                }
                &>.list:nth-of-type(24) hr{
                    background: #F949C7;
                }
                &>.list:nth-of-type(25) hr{
                    background: #02F2EE;
                }
                &>.list:nth-of-type(26) hr{
                    background: #00aaFF;
                }
                &>.list:nth-of-type(27) hr{
                    background: #F81F52;
                }
                &>.list:nth-of-type(28) hr{
                    background: #8FF16E;
                }
                &>.list:nth-of-type(29) hr{
                    background: #0239A7;
                }
                &>.list:nth-of-type(30) hr{
                    background: #00FEC7;
                }
                &>.list:nth-of-type(31) hr{
                    background: #A05017;
                }
            }
        }
        .night-charts{
            width: 100%;
            height: 400px;
        }
        .night-table{
            margin-top: 55px;
            .table-title{
                h4{
                    font-size: 31px;
                    color: #000;
                }
                .scope{
                    span{
                        width: 12px;
                        height: 12px;
                        background: #F7B500;
                    }
                }
                .scope:nth-of-type(1){
                    margin-left: auto;
                }
                .scope:nth-of-type(2){
                    margin-left: 40px;
                    span{
                        background: #E03520;
                    }
                }
            }
        }

        .table{
            .tip-time{
                border-color: #e5e5e5;
                p{
                    flex: 1;
                    height: 50px;
                    line-height: 50px;
                    border-color: #e5e5e5;
                    color: #5a5a5a;
                }
            }
            .date-value{
                .list{
                    border-color: #e5e5e5;
                    p{
                        flex: 1;
                        height: 40px;
                        line-height: 40px;
                        border-color: #e5e5e5;
                    }

                    .IPE{
                        background: #E03520 !important;
                        color: white;
                    }
                    
                    .high{
                        background: #F7B500 !important;
                        color: white;
                    }
                    
                }
                .list:nth-of-type(odd){
                    p{
                        background: #eee;
                    }
                }
            }

            .monDays{
                & > div{
                    flex: 1;
                }
                .tip-time{
                    min-width: 80px;
                    max-width: 80px;
                    p{
                        height: 38px;
                        line-height: 38px;
                        &:nth-of-type(even){
                            background: #eee;
                        }
                        &:nth-last-of-type(1){
                            margin-top: -1px;
                        }
                    }
                }
                .date-value{
                    max-width: 66px;
                    .list{
                        p{
                            height: 38px;
                            line-height: 38px;
                            &:nth-of-type(odd){
                                background: white;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
<template>
    <div class="Index pages pl-18 pr-18 mt-20" :class="[!Dark ? '' : 'dark', collapsed ? 'hide' : '' ]">
        <div class="scroll pb-18" v-loading="loading">
            <div v-show="loading == false">
                <el-col :span="8" class="card" :class="[ index == 2 || index == 5 ? '' : 'pr-20' ]" v-for="(item,index) in list" :key="index">
                    <div class="cont radius-10 pt-15 pr-15 pb-15 pl-15">
                        <LiteTit :tip="item" :index="index" :Dark="Dark" @add="showADd" @go="go" />
                        <div class="cont flex justify-evenly pt-10 pb-10" v-if="index == 0">
                            <div class="num text-center mt-5 pointer" @click="to('/patient?tab=1')">
                                <h4 class="font-14 font-normal">佩戴中用户</h4>
                                <p class="font-20">{{ wearing }}</p>
                            </div>
                            <div class="num text-center mt-5 pointer" @click="to('/patient')">
                                <h4 class="font-14 font-normal">用户总数</h4>
                                <p class="font-20">{{ allUser }}</p>
                            </div>
                            <div class="num text-center mt-5 pointer" @click="to('/patient')">
                                <h4 class="font-14 font-normal">今日新增用户</h4>
                                <p class="font-20">{{ todayNewUsers }}</p>
                            </div>
                        </div>
                        <div class="cont flex justify-evenly pt-10 pb-10" v-if="index == 1">
                            <div v-if="todoReportNum  && todoReportNum  != 0" @click="to('/task?report=true')">
                                <el-badge :value="todoReportNum" class="task text-center pointer">
                                    <img src="@/assets/images/Index/icon01.png" alt="">
                                    <p class="font-12">每日报告</p>
                                </el-badge>
                            </div>
                            <div class="task text-center pointer" @click="to('/task?report=true')" v-else>
                                <img src="@/assets/images/Index/icon01.png" alt="">
                                <p class="font-12">每日报告</p>
                            </div>
                            <div @click="to('/task?plan=true')" v-if="todoPlanNum && todoPlanNum != 0">
                                <el-badge :value="todoPlanNum" class="task text-center pointer">
                                    <img src="@/assets/images/Index/icon02.png" alt="">
                                    <p class="font-12">糖控计划</p>
                                </el-badge>
                            </div>
                            <div class="task text-center pointer" @click="to('/task?plan=true')" v-else>
                                <img src="@/assets/images/Index/icon02.png" alt="">
                                <p class="font-12">糖控计划</p>
                            </div>
                        </div>
                        <div class="cont flex padding0 justify-evenly pt-10" v-if="index == 2">
                            <div class="doctorList text-center" v-for="(item,index) in doctorList" :key="index">
                                <img :src="item.avatar" class="radius-50" :onerror="notHeaders" alt="">
                                <h4 class="font-14">{{ item.name }}</h4>
                                <p class="font-12">{{ item.role_title }}</p>
                            </div>
                        </div>
                        <div class="cont pt-10" v-if="index == 3">
                            <div class="BloodSugar flex justify-between line-height-45 mb-14 pl-10 pr-10 radius-5 pointer" v-for="(item,index) in highBloodSugarNoticeList" @click="go('/patient/detail', {id: item.uid})" :key="index">
                                <h4 class="font-16 font-normal text-cut">{{ item.agency_nickname }}</h4>
                                <div class="info flex">
                                    <p class="font-16 ">{{ item.blood_sugar_time }}</p>
                                    <p class="font-16 ml-15">{{collapsed ? '' : '高血糖预警：'}}<span class="font-16 high">&nbsp;{{ item.blood_sugar }}&nbsp;</span>mmol/L</p>
                                </div>
                            </div>
                            <div class="content avgHeight empty" v-if="highBloodSugarNoticeList.length == 0 && loading == false">
                                <div class="center">
                                    <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                                    <img src="@/assets/images/error/dark_empty.png" v-else alt />
                                    <p>暂无数据</p>
                                </div>
                            </div>
                        </div>
                        <div class="cont pt-10" v-if="index == 4">
                            <div class="BloodSugar flex justify-between line-height-45 mb-14 pl-10 pr-10 radius-5" v-for="(item,index) in lowBloodSugarNoticeList" @click="go('/patient/detail', {id: item.uid})" :key="index">
                                <h4 class="font-16 font-normal text-cut">{{ item.agency_nickname }}</h4>
                                <div class="info flex">
                                    <p class="font-16">{{ item.blood_sugar_time }}</p>
                                    <p class="font-16 ml-15">{{ collapsed ? '' : '低血糖预警：'}}<span class="font-16 low">&nbsp;{{ item.blood_sugar }}&nbsp;</span>mmol/L</p>
                                </div>
                            </div>
                            <div class="content avgHeight empty" v-if="lowBloodSugarNoticeList.length == 0 && loading == false">
                                <div class="center">
                                    <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                                    <img src="@/assets/images/error/dark_empty.png" v-else alt />
                                    <p>暂无数据</p>
                                </div>
                            </div>
                        </div>
                        <div class="cont pt-10 padding0" v-if="index == 5">
                            <div class="BloodSugar common flex justify-between line-height-45 mb-14 pl-10 pr-10 radius-5" v-for="(item,index) in uncommonUserList"  @click="go('/patient/detail', {id: item.uid})" :key="index">
                                <h4 class="font-16 font-normal text-cut">{{ item.agency_nickname }}</h4>
                                <div class="info flex">
                                    <p class="font-16">{{ item.date }}</p>
                                    <p class="font-16 ml-15 flex justify-between">达标率：<span class="font-16" :class="{ 'notStandard' : item.common_rate < 60 && item.common_rate > 50 , 'severeStandard' : item.common_rate <= 50 }">&nbsp;{{ item.common_rate }}%&nbsp;</span></p>
                                </div>
                            </div>
                            <div class="content avgHeight empty" v-if="uncommonUserList.length == 0 && loading == false">
                                <div class="center">
                                    <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                                    <img src="@/assets/images/error/dark_empty.png" v-else alt />
                                    <p>暂无数据</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col class="histogramChat mt-20 radius-10 clear" :span="24">
                    <div class="analyze float-left pion-rel">
                        <div class="upToStandard flex align-center pt-20">
                            <div class="center text-center">
                                <h4 class="font-18">平均达标率</h4>
                                <p class="font-18 mt-5">%</p>
                                <h1>{{ Standard }}</h1>
                            </div>
                        </div>
                        <div class="average flex align-center pt-20">
                            <div class="center text-center">
                                <h4>平均血糖值</h4>
                                <p>mmol/L</p>
                                <h1>{{ BloodSugar }}</h1>
                            </div>
                        </div>
                    </div>
                    <ChartHistogram :value="HistogramVal" />
                </el-col>
                <el-col class="ageStatistics pr-10 mt-20" :span="12">
                    <div class="cont radius-10 pt-15 pr-15 pb-15 pl-15">
                        <LiteTit :tip="{title: '年龄统计', go: ''}" />
                        <ChartBar :value="ChartBarVal" />
                    </div>
                </el-col>
                <el-col class="typeStatistics pl-10 mt-20" :span="12">
                    <div class="cont radius-10 pt-15 pr-15 pb-15 pl-15">
                        <LiteTit :tip="{title: '类型统计', go: ''}" />
                        <div class="pieChart flex justify-evenly">
                            <ChartPie :value="ChartSexVal" :text="'性别比例'" />
                            <ChartPie :value="ChartTypeVal" :text="'患病类型'" />
                        </div>
                    </div>
                </el-col>
            </div>
        </div>
        <AddPatient :Edit="false" :Show="showADdPatient" :Dark="Dark" @ok="submit" @close="showADdPatient = false" />
        <AddDoctor :show="addDoctors" @close="addDoctors = false" :Dark="Dark" @save="addDoctors = false" />
    </div>
</template>

<script>
    import { LiteTit } from '_c/Public/Public';
    import { getIndexDate } from 'quest';
    import { ChartHistogram, ChartBar, ChartPie } from '_c/Charts/index';
    import { AddPatient, AddDoctor } from '_c/Model/index';
    export default {
        props:{
            Dark: Boolean,
            collapsed: Boolean
        },
        components:{
            LiteTit,
            ChartHistogram,
            ChartBar,
            ChartPie,
            AddPatient,
            AddDoctor
        },
        data(){
            return{
                list:[
                    {
                        title:'我的患者',
                        isAdd: true,
                        go: '/patient',
                        roleId: 500
                    },
                    {
                        title:'我的任务',
                        isAdd: false,
                        go: '/task',
                        roleId: 500
                    },
                    {
                        title:'我的团队',
                        isAdd: true,
                        go: '/organization',
                        roleId: 5
                    },
                    {
                        title:'高血糖事件',
                        isAdd: false,
                        go: '/patient/list?isHigh=true',
                        roleId: 500
                    },
                    {
                        title:'低血糖事件',
                        isAdd: false,
                        go: '/patient/list?isHigh=false',
                        roleId: 500
                    },
                    {
                        title:'未达标患者',
                        isAdd: false,
                        // /patient?standard=true
                        go: '',
                        roleId: 500
                    },
                ],
                loading: true,
                wearing: 0,
                allUser: 0,
                todayNewUsers: 0,
                todoReportNum: 0,
                todoPlanNum: 0,
                doctorList:[],
                highBloodSugarNoticeList: [],
                lowBloodSugarNoticeList: [],
                uncommonUserList: [],
                Standard: 0,
                BloodSugar: 0,
                HistogramVal: [],
                ChartBarVal: [],
                ChartSexVal:[],
                ChartTypeVal: [],
                showADdPatient: false,
                addDoctors: false,
                notHeaders: 'this.src="' + require('@/assets/images/error/notheader.png') + '"'
            }
        },
        methods:{
            getIndexDate(){
                getIndexDate().then( res => {
                    let indexDate = res.data.data;
                    if(res.data.code === 100000){
                        this.loading = false,
                        this.wearing = indexDate.binding_count || 0,
                        this.allUser = indexDate.user_count || 0,
                        this.todayNewUsers = indexDate.today_new_user_count || 0,
                        this.todoReportNum = indexDate.todo_report_num,
                        this.todoPlanNum = indexDate.todo_plan_num,
                        this.doctorList = indexDate.team_list,
                        this.highBloodSugarNoticeList = indexDate.high_bloodsugar_notice_list,
                        this.lowBloodSugarNoticeList = indexDate.low_bloodsugar_notice_list,
                        this.uncommonUserList = indexDate.uncommon_user_list,
                        this.HistogramVal = indexDate.day_rate,
                        this.ChartBarVal = indexDate.user_age_count,
                        this.ChartSexVal = indexDate.user_sex_count,
                        this.ChartTypeVal = indexDate.user_type_count,
                        this.Standard = indexDate.month_common_rate,
                        this.BloodSugar = indexDate.month_avg_bs;
                        this.indexDate = indexDate
                    }
                })
            },
            showADd(index){
                switch(index){
                    case 0:
                        this.showADdPatient = true;
                    break;
                    case 2:
                        this.addDoctors = true;
                    break;
                }
            },
            to(path){
                this.$router.push({
                    path: path
                })
            },
            go(path,query){
                this.$router.push({
                    path: path,
                    query: query
                })
            },
            submit(){
                this.showADdPatient = false;
            }
        },
        created(){
            this.getIndexDate();
        }
    }
</script>

<style scoped lang="less">
    .Index{
        height: ~'calc(100% - 70px)';
        .scroll{
            width: 100%;
            height: 100%;
            border-radius: 20px;
            overflow-y: scroll;
            .card{
                .cont{
                    background: var(--white);
                    transition: .3s;
                    .task{
                        img{
                            width: 33px;
                            height: 33px;
                        }
                        p{
                            color: #9f9f9f;
                        }
                    }
                    .doctorList{
                        img{
                            width: 30px;
                            height: 30px;
                        }
                        h4{
                            margin-top: -4px;
                        }
                        p{
                            margin-top: -4px;
                            color: #9f9f9f;
                        }
                    }
                    .BloodSugar{
                        transition: .3s;
                        cursor: pointer;
                        &:hover{
                            background: #f4f4f4;
                            transition: .3s;
                        }
                        &:nth-last-of-type(1){
                            margin-bottom: 0px;
                        }
                        h4{
                            max-width: 75px;
                            color: #242424;
                        }
                        .info{
                            p{
                                color: #242424;
                                .high{
                                    color: var(--bloodSugarHigh);
                                }
                                .low{
                                    color: var(--bloodSugarLow);
                                }
                                .severeStandard{
                                    color: var(--bloodSugarLow);
                                }
                            }
                        }
                    }
                    .common{
                        p{
                            &:nth-of-type(2){
                                width: 110px;
                            }
                        }
                    }
                    .avgHeight{
                        min-height: 615px;  
                    }
                }
                &:nth-of-type(-n + 3){
                    height: 140px;
                }
                &:nth-of-type(n + 4){
                    margin-top: 20px;
                }
            }
            .padding0{
                padding-right: 0px;
            }

            .histogramChat{
                width: 100%;
                height: 360px;
                background: var(--white);
                .analyze{
                    width: 265px;
                    height: 100%;
                    &::before{
                        content: "";
                        position: absolute;
                        width: 1px;
                        height: 333px;
                        top: 13px;
                        right: 0px;
                        background: #f6f6f6;
                    }
                    &>div{
                        width: 100%;
                        height: 50%;
                        &>*{
                            width: 100%;
                        }
                        p{
                            color: #B3B3B3;
                        }
                        h1{
                            font-size: 48px;
                            margin-top: -10px;
                        }
                    }
                }
            }

            .ageStatistics,
            .typeStatistics{
                .cont{
                    background: var(--white);
                }
            }
        }
    }

    .Index.dark{
        .cont{
            background: #202025 !important;
            transition: .3s;
            color: #9f9f9f !important;
            .title{
                border-color: #2c2c2e;
            }
            
            .num{
                h4{
                    color: #9f9f9f !important;
                }
            }
            .doctorList{
                P{
                    color: #7f7f7f;
                }
            }
            .BloodSugar{
                color: white !important;
                &:hover{
                    background: var(--black) !important;
                }
                h4{
                    color: #9f9f9f !important;
                }
                p{
                    color: #7f7f7f !important;
                }
            }
        }
        .analyze{
            &::before{
                background: #2c2c2e !important;
            }
            h4{
                color: #9f9f9f !important;
            }
            p{
                color: #7f7f7f !important;
            }
            h1{
                color: #9f9f9f !important;
            }
        }
        .histogramChat{
            background: #202025 !important;
            color: white !important;
        }
    }

</style>
export default {
    menu: {
        index: {
            title: '監控中心'
        },
        list: {
            title: '患者列表',
            Print: {
                loading: '正在生成報告',
                button: '匯出',
                page1: {
                    title: '動態血糖數據分析報告',
                    name: '用戶姓名',

                },
                page2: {
                    title: '報告預覽',
                    times: '覆蓋時間',
                    number: '檢測次數',
                    titUnit: '次',
                    avg: '平均血糖值',
                    hemoglobin: '預估糖化血紅蛋白',
                    volatility: '血糖波動性',
                    tir: {
                        vhigh: '非常高',
                        high: '高',
                        normal: '正常',
                        low: '低',
                        vlow: '非常低'
                    },
                    insulin: '註射胰島素',
                    days: '每天',
                    longInsulin: '長效胰島素',
                    MealInsulin: '餐食胰島素',
                    carbohydrate: '每日攝入碳水',
                    unit: '克/每天',
                    sport: '每日運動',
                    sunit: '卡/每天',
                    AGP: 'AGP圖譜',
                    minmax: '最小值/最大值',
                    footTitle: '每日曲線圖譜',
                    date: '打印日期',
                    form: '數據來源',
                    page: '頁碼'
                },
                page3:{
                    title: '周期圖譜',
                    times: '覆蓋時間',
                    number: '檢測次數',
                    titUnit: '次',
                    lineTitle: '多日曲線',
                    footTitle: 'BOX圖譜',
                    high: '高於達標範圍',
                    low: '低於達標範圍',
                    scope: '達標範圍',
                    avg: '平均值',
                    date: '打印日期',
                    form: '數據來源',
                    page: '頁碼',
                    notVIP: '開通會員查看全部專享內容',
                },
                page4:{
                    title: '連續圖譜',
                    times: '覆蓋時間',
                    number: '檢測次數',
                    titUnit: '次',
                    notData: '暫無數據',
                    footTitle: '平均血糖值',
                    date: '打印日期',
                    form: '數據來源',
                    page: '頁碼',
                },
                page5:{
                    title: '每日報告',
                    times: '覆蓋時間',
                    number: '檢測次數',
                    titUnit: '次',
                    year: '年',
                    mon: '月',
                    day: '日',
                    long: '長效',
                    meal: '餐食',
                    carbohydrate: '碳水化合物',
                    unit: '克',
                    type: '運動',
                    units: '千卡',
                    date: '打印日期',
                    form: '數據來源',
                    page: '頁碼',
                },
                page6: {
                    title: '夜間數據',
                    times: '覆蓋時間',
                    number: '檢測次數',
                    titUnit: '次',
                    titles: '夜間關鍵點血糖值',
                    high: '高於達標範圍',
                    low: '低於達標範圍',
                    unit: '點',
                    date: '打印日期',
                    form: '數據來源',
                    page: '頁碼',
                },
                page7: {
                    title: '食藥分析',
                    times: '覆蓋時間',
                    number: '檢測次數',
                    titUnit: '次',
                    titles: '升糖指數排行',
                    insulin: '胰島素',
                    type1: '加餐',
                    type2: '早飯',
                    type3: '午飯',
                    type4: '晚飯',
                    titles2: '升糖幅度',
                    unit: '波動值',
                    date: '打印日期',
                    form: '數據來源',
                    page: '頁碼',
                },
                page8: {
                    title: '多日時段',
                    times: '覆蓋時間',
                    number: '檢測次數',
                    titUnit: '次',
                    titles1: '時間段',
                    titles2: 'TAR 高於目標範圍占比',
                    titles3: 'TIR 目標範圍時間占比',
                    titles4: 'TBR 低於目標範圍時間占比',
                    titles5: '探頭值數量',
                    titles6: '上限',
                    titles7: '下限',
                    titles8: 'MG 平均葡萄糖',
                    titles9: 'SD 葡萄糖標準差',
                    titles10: 'CV 變異系數',
                    PS1: 'TIR：葡萄糖目標範圍內時間（狹義TIR是指葡萄糖在目標範國內時間百分比，廣義上也指監測期間葡萄糖在不同範國內（低值、目標範圍、高值）的時間所占百分比 ，可更全面反映患者的血糖控製水平）。',
                    PS2: 'MD：平均葡萄糖值（月期內所有葡萄糖測定值的平均值，用於評價整體血糖水平）。',
                    PS3: 'SD：葡萄糖標準差（日期內所有葡萄糖測定值的標準差，用於評價總體偏離平均血糖值的程度，即血糖波動）。',
                    PS4: 'CV：變異系數（日期內所有葡萄糖測定值的離散程度，用於評價血糖波動）。',
                    PS5: '註：為保證展示效果，對部分數據進行了適度平滑處理。',
                    date: '打印日期',
                    form: '數據來源',
                    page: '頁碼',
                },
                page9: {
                    title: '報告解讀',
                    times: '覆蓋時間',
                    number: '檢測次數',
                    titUnit: '次',
                    signature: '醫生簽字',
                    date: '打印日期',
                    form: '數據來源',
                    page: '頁碼',
                },
                wait: '報告生成約30秒，請耐心等待',
                merge: '正在合併報告',
                exporting: '正在產生第',
                exported: '頁報告',
                building: '正在打包...請勿重複下載！',
                over: '下載成功！',
            }
        },
        Task: {
            title: '任務中心',
        },
        plan: {
            title: '方案模版'
        },
        hospital: {
            title: '醫院處方'
        },
        Custody: {
            title: '中央監護'
        },
        Organization: {
            title: '組織架構'
        },
        setting: {
            title: '賬號設置'
        }
    }
}
<template>
    <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
    import echarts from "echarts";
    import tdTheme from './theme.json'
    import { on, off } from '_js/Cache'
    echarts.registerTheme('tdTheme', tdTheme)
    export default {
        name: "ChartBar",
        props: {
            value: Array
        },
        data() {
            return {
                dom: null
            };
        },
        methods: {
            resize() {
                this.dom.resize();
            },
            init() {
                this.$nextTick(() => {
                    let option = {
                        tooltip: {
                            trigger: "item",
                            formatter:function(params){
                                return params.name + params.value/60 + '分钟'
                            }
                        },
                    legend: {
                        type: "scroll",
                        orient: "vertical",
                        left: "0",
                        top: "center",
                        itemGap: 10,
                        selectedMode: true,
                        itemWidth: 5,
                        itemHeight: 5,
                        icon: "circle",
                        textStyle: {
                            color: "#77899c",
                            rich: {
                                uname: {
                                    width: 100
                                },
                                unum: {
                                    color: "#4ed139",
                                    width: 10,
                                    align: "right"
                                }
                            }
                        },
                        // formatter: function(name) {
                        //     // 获取legend显示内容
                        //     let data = option.series[0].data;
                        //     let total = 0;
                        //     let tarValue = 0;
                        //     for (let i = 0, l = data.length; i < l; i++) {
                        //         total += data[i].value;
                        //         if (data[i].name == name) {
                        //             tarValue = data[i].value;
                        //         }
                        //     }
                        //     let p = ((tarValue / total) * 100).toFixed(0);
                        //     return name + p + "%";
                        // }
                    },
                    series: [
                        {
                            name: "名称",
                            type: "pie",
                            radius: [0, 60],
                            center: ["70%", "50%"],
                            label: {
                                show: false
                            },
                            labelLine: {
                                show: false
                            },
                            data: this.value
                        }
                    ]
                    };
                    this.dom = echarts.init(this.$refs.dom, "tdTheme");
                    this.dom.setOption(option);
                    on(window, "resize", this.resize);
                });
            }
        },
        mounted() {
            this.init();
        },
        beforeDestroy() {
            off(window, "resize", this.resize);
        }
    };
</script>

<template>
    <div class="ChartsLine" v-if="value" ref="chartLine"></div>
</template>

<script>
    import echarts from "echarts";
    import tdTheme from "./theme.json";
    import { on, off, getItem, time } from "_js/Cache";
    echarts.registerTheme("tdTheme", tdTheme);
    
    export default {
        props:{
            value: Array,
            specialArr: Array,
            type:Boolean,
            target_high:{
                default:null
            },
            target_low:{
                default:null
            },
            longPass: Boolean,
        },
        watch:{
            'value':{
                handler(){
                    this.init();
                },
                deep: true,
            }
        },
        data() {
            return {
                chartLine: null,
                isDark: false,
            };
        },
        methods:{
            resize () {
                this.chartLine.resize()
                const elementWidth = this.$refs.chartLine.offsetWidth;
                    // 重新设置 grid 宽度
                this.chartLine.setOption({
                    grid: {
                        width: elementWidth - 35, // 使用动态计算的宽度
                    },
                });
            },
            hexToRgba(hex, opacity) {
                let rgbaColor = "";
                let reg = /^#[\da-f]{6}$/i;
                if (reg.test(hex)) {
                    rgbaColor = `rgba(${parseInt("0x" + hex.slice(1, 3))},${parseInt(
                    "0x" + hex.slice(3, 5)
                    )},${parseInt("0x" + hex.slice(5, 7))},${opacity})`;
                }
                return rgbaColor;
            },
            init() {
                let __self__ = this;
                let xAxisData = __self__.value.map(i => {
                    return i.time;
                });
                let yAxisData1 = __self__.value.map( i => {
                    return i.value;
                });
                let color = ["#16CCA6", "#00CA69"];
                // 在 mounted 生命周期钩子中获取元素宽度
                const elementWidth = __self__.$refs.chartLine.offsetWidth;
                let option = {
                    color: color,
                    tooltip: {
                        trigger: "axis",
                    },
                    grid: {
                        top: __self__.longPass == null ? 5 : 40,
                        bottom: 20,
                        left:  __self__.longPass == false ? 14 : __self__.longPass == true ? 0 : 15,
                        right: (__self__.index + 1 ) % 4 != 0 ? 0 : __self__.longPass ? 20 : 15,
                        width: elementWidth - 35,
                        height: __self__.longPass == null ? '70%' : '',
                    },

                    visualMap:{
                        top: 0,
                        left: 0,
                        precision:1,
                        show: false,
                        orient:'horizontal',
                        pieces: [
                            {
                                gte: 0,
                                lte: Number(__self__.isDefaultUnit == 1 ? __self__.target_low * 18 : __self__.target_low),
                                color: '#ED1B24'
                            },
                            {
                                gte: Number(__self__.isDefaultUnit == 1 ? __self__.target_low * 18 : __self__.target_low),
                                lte: Number(__self__.isDefaultUnit == 1 ? __self__.target_high * 18 : __self__.target_high),
                                color: '#16cca6'
                            },
                            {
                                gte: Number(__self__.isDefaultUnit == 1 ? __self__.target_high * 18 : __self__.target_high),
                                color: '#FFA901'
                            }
                        ],
                    },
                    xAxis: [
                        {
                            show: true,
                            type: "category",
                            offset: -1,
                            boundaryGap: false,
                            axisLabel: {
                                interval: __self__.type ? 5 : 15,
                                formatter: __self__.isPDF == true ? (val) =>{
                                    return time(new Date(val),'h:m')
                                } : '{value}',
                                textStyle: {
                                    color: __self__.isDark ? '#c1c1c1' : "#333",
                                    fontSize: 10
                                }
                            },
                            splitLine: {
                                show: false,
                                lineStyle: {
                                    type: "dashed",
                                    color: "#E9E9E9"
                                }
                            },
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: __self__.isDark ? '#c1c1c1' : "#D9D9D9"
                                }
                            },
                            data: xAxisData
                        }
                    ],
                    yAxis: [
                        {
                            show: __self__.hideXY ? false : true,
                            position: "right",
                            type: "value",
                            interval: 4,
                            min:0,
                            max(value){
                                const val = ( parseInt(value.max) + (4 - parseInt(value.max) % 4 ) ) > 16 ? parseInt(value.max) + (4 - parseInt(value.max) % 4 ) : 16;
                                 return __self__.isPDF ? ( parseInt( __self__.lineMax) + (4 - parseInt( __self__.lineMax) % 4 ) ) : val;
                            },

                            axisLabel: {
                                textStyle: {
                                    color: __self__.isDark ? '#c1c1c1' : "#666",
                                    fontSize: 10
                                }
                            },
                            nameTextStyle: {
                                color: "#666",
                                fontSize: 10,
                                lineHeight: 40
                            },
                            // 分割线
                            splitLine: {
                                lineStyle: {
                                    color: __self__.isDark && !__self__.isPDF ? '#2c2c2e' : "#E9E9E9"
                                }
                            },
                            axisLine: {
                                show: false
                            },
                            axisTick: {
                                show: false
                            }
                        }
                    ],
                    series: [
                        {
                            name: __self__.isPDF ? '' : "血糖",
                            type: "line",
                            smooth: true,
                            connectNulls: true,
                            symbolSize: 2,
                            zlevel: 3,
                            symbol: "circle", // 数据交叉点样式
                            markArea: {
                                itemStyle:{
                                    color: __self__.hideXY ? '#DEDEDE' : __self__.hexToRgba('#5EFF72',0.1)
                                },
                                data: [
                                    [{
                                    yAxis:__self__.target_low||0
                                    }, {
                                    yAxis:__self__.target_high||0
                                    }]
                                ],
                                animation: true
                            },
                            itemStyle: __self__.hideXY ? {
                                color: "#000"
                            } : {},
                            lineStyle: __self__.hideXY ? {
                                normal:{
                                    color: '#000'
                                }
                            } : {},
                            markPoint: {
                                data : __self__.specialArr,
                            },
                            data: yAxisData1
                        },
                    ]
                };
                setTimeout( ()=>{
                    __self__.chartLine = echarts.init(__self__.$refs.chartLine, "tdTheme");
                    __self__.chartLine.setOption(option);
                    on(window, 'resize', this.resize)
                },200)
            }
        },
        created(){
            this.isDark = getItem('dark') || false;
        },
        mounted(){
            this.init();
        },
        beforeDestroy() {
            off(window, "resize", this.resize());
        }
    }
</script>
export default{
    // 登录
    login: '/system_user/login',
    // 获取登陆用户信息
    userInfo: '/system_user/getuserinfo',
    // 获取机构信息
    agencyInfo: 'agency/detail',
    // 获取首页全部内容
    getIndexDate: '/stat/index',
    // 获取患者列表信息
    getUsersList: '/user/getList',
    // 获取医生列表
    getDoctorList: '/doctor/getList',
    // 发送验证码
    sendCode: '/sms/send',
    // 验证验证码
    checkCode: '/sms/check',
    // 添加患者/编辑患者
    addUser: '/user/add',
    // 保存患者
    userSave: '/user/save',
    // 删除患者
    deleteUser: '/user/delete',
    // 获取患者详细信息
    getPatientInfo: '/user/getuserinfo',
    // 编辑用户姓名
    editUserName: '/user/updateAgencyName',
    // 编辑用户备注
    editUserNote: '/user/updateDoctorNote',
    // 新增备注
    addNote: '/life_record/note',
    // 编辑每日数据解读
    editInterpret: '/bloodsugar/dayAnalysis',
    // 获取用户信息
    getUserHealthData: '/user/getUserHealthData',
    // 获取指定天数的信息
    getDataByDate: 'bloodsugar/getDataByDate',
    // 获取指定天数饮食信息
    getUserLifeRecordByDay: 'life_record/getDayList',
    // 获取任务列表
    getTaskList: '/task/getTaskList',
    // 方案模版列表
    getDoctorPlanList: '/doctor_plan/getList',
    // 获取用户方案
    getUserPlan: '/user_plan/getUserPlan',
    // 获取模版方案
    templateGetList: '/doctor_plan/getList',
    // 保存用户方案
    planSave: '/user_plan/save',
    // 添加饮食方案（单条）
    foodRecordAdd: '/food_record_new/add',
    // 添加胰岛素使用方案（单条）
    medicineRecordAdd: '/medicine_record/add',
    // 添加运动方案（单条）
    sportsRecordAdd: '/sports_record/add',
    // 删除食物方案（单条）
    foodRecordDel: '/food_record_new/delete',
    // 删除胰岛素使用方案（单条）
    medicineRecordDel: '/medicine_record/delete',
    // 删除运动方案（单条）
    sportsRecordDel: '/sports_record/delete',
    // 保存方案模版
    planSaveTemp: '/doctor_plan/save',
    // 方案详情
    templateGetInfo: '/doctor_plan/getDoctorPlan',
    // 获取医院方案
    getHospitalPrescriptionList: 'reverse_plan/getHospitalPrescriptionList',
    // 获取数据统计(已过期)
    getDeviceStatByDeviceId: '/device/getDeviceStatByDeviceId',
    // 数据统计最新接口
    getDeviceStatByDays: '/device/getDeviceStatByDays',
    // 获取数据统计[ 指尖血 数据]
    getStatByDays: 'pure_finger_blood/getStatByDays',
    // 佩戴记录列表
    getUserDeviceList: '/device/getUserDeviceList',
    // 搜索食物
    foodSearch: '/food/search',
    // 搜索药物
    medicineSearch: '/medicine/search',
    // 搜索运动
    sportsSearch: '/sports/search',
    // 删除用户方案
    userPlanDelete: 'user_plan/delete',
    // 删除模版方案
    doctorPlanDelete: 'doctor_plan/delete',
    // 获取高低血糖列表
    getBloodSugarNoticeList: '/bloodsugarnoticequeue/getBloodSugarNoticeList',
    // 获取部门列表
    getDepartment: '/department/index',
    // 添加部门
    addDepartment: '/department/add',
    // 保存部门
    departmentSave: '/department/save',
    // 删除部门
    departmentDelete: '/department/delete',
    // 获取角色列表
    getRoleList: '/agency_role/index',
    // 添加角色
    addRole: '/agency_role/save',
    // 删除角色
    deleteRole: '/agency_role/delete',
    // 添加成员
    addDoctor: '/system_user/add',
    // 保存成员
    saveDoctor: '/system_user/save',
    // 删除成员
    deleteDoctor: '/system_user/delete',
    // 修改用户信息
    upDateInfo: '/system_user/updateInfo',
    // 修改密码
    upDatePwd: '/system_user/updatePwd',
    // 保存机构logo
    upLoadLogo: '/agency/save',
    // 获取报告数据
    getReportDate: '/device/report',
    // 导出数据
    bloodsugarExport: '/bloodsugar/export',
    // 获取逆转方案详情
    getUserReversePlan: 'reverse_plan/getUserReversePlan',
    // 保存逆转方案
    reversePlanSave: '/reverse_plan/save',
    // 阶段方案保存
    reversePlanPeriodSave: 'reverse_plan/periodSave',
    // 获取 处方列表
    getPrescriptionList: '/reverse_plan/getPrescriptionList',
    // 处方药保存
    prescriptionSave: '/reverse_plan/prescriptionSave',
    // 删除处方
    prescriptionDelete: 'reverse_plan/prescriptionDelete',
    // 添加医生点评
    periodNoteSave: 'reverse_plan/periodNoteSave',
    // 方案总结
    planConclusionSave: 'reverse_plan/conclusionSave'
}
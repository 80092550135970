<template>
    <div class="print-cont pion-rel" :style="{'margin-top' : !download ? '10px' : '0px' }">
        <div class="tiptitle">
            <div class="title">
                <h2>{{$t('menu.list.Print.page3.title')}}</h2>
                <p>{{ timeGet(bindTime,endTime) }}（{{days}}{{lang == 'en' ?  'days': '天' }}）
                    <span>{{$t('menu.list.Print.page3.times')}}：{{ coverage }}%</span>
                    <span>{{$t('menu.list.Print.page3.number')}}：{{ deviceBloodSugarTotal}} {{$t('menu.list.Print.page3.titUnit')}}</span>
                </p>
            </div>
            <img class="logo" :src="logo" alt="">
        </div>
        <div class="picture mt-20 solid-top">
            <div class="curve">
                <h4>{{$t('menu.list.Print.page3.lineTitle')}}</h4>
                <div class="color flex flex-wrap">
                    <div class="list flex align-center mt-10 mb-10" v-for="(item, index) in daysData" :key="index">
                        <p class="font-16">{{ timeGet(item.date) }}</p>
                        <hr class="ml-15 radius-5">
                    </div>
                </div>
                <div class="echarts" v-if="everyDaysDate.value != null && everyDaysDate.x_arr != null">
                    <EveryDaysLine :x_arr="multiLineXArr" :value="everyDaysDate.value" :isNight="true" :color="colorList" :target_low="userInfo.target_low" :target_high="userInfo.target_high" :isDefaultUnit="isDefaultUnit" />
                </div>
            </div>
            <div class="atlas">
                <div class="tiptitle flex align-start">
                    <h4>{{$t('menu.list.Print.page3.footTitle')}}</h4>
                    <div class="colorBox flex justify-between pt-10">
                        <div>
                            <p class="font-14">{{$t('menu.list.Print.page3.high')}}</p>
                            <hr class="radius-5 auto mt-5">
                        </div>
                        <div>
                            <p class="font-14">{{$t('menu.list.Print.page3.low')}}</p>
                            <hr class="radius-5 auto mt-5">
                        </div>
                        <div>
                            <p class="font-14">{{$t('menu.list.Print.page3.scope')}}</p>
                            <hr class="radius-5 auto mt-5">
                        </div>
                        <div class="text-center">
                            <p class="font-14">{{$t('menu.list.Print.page3.avg')}}</p>
                            <hr class="radius-5 auto mt-5" />
                        </div>
                    </div>
                </div>
                <div class="echarts" v-if="BarLineVal">
                    <BarLine :data="BarLineVal" :userInfo="userInfo" :isDefaultUnit="isDefaultUnit" /> 
                </div>
            </div>
        </div>
        <div class="page-bottom pion-abs">
            <p>{{$t('menu.list.Print.page3.date')}}：{{toDay}}</p>
            <p>{{$t('menu.list.Print.page2.form')}}：{{ lang == 'en' ? '' : '糖动' }}CGMCARE</p>
            <!-- 3/{{ 4 + ( foodRecordRankLen > 0 ? 1 : 0 ) + Math.ceil((daysData.length  / 16 )) + Math.ceil(daysData.length / 4 ) + ( Math.ceil(daysData.length / 16) > 1 ? 2 : 1 ) + Number(pageAdd) }} -->
            <p>{{$t('menu.list.Print.page3.page')}}：{{ !parintList[0].check ? '2' : '3' }} / {{ sum }}</p>
        </div>
        <div class="not-vip pion-abs" v-if="userInfo.is_vip == 0 ">
            <div class="flex align-center justify-center">
                <div class="flex align-center" @click="initiatePay">
                    <img src="@/assets/images/print/vip.png" alt="">
                    <p class="ml-10">{{$t('menu.list.Print.page3.notVIP')}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { time, getItem } from '_js/Cache';
    import {
        BarLine,
        EveryDaysLine
    } from "_c/print";
    export default {
        props:{
            download: Boolean,
            logo: String,
            bindTime: Number,
            endTime: Number,
            days: Number,
            coverage: Number,
            deviceBloodSugarTotal: Number,
            daysData: Array || Object,
            everyDaysDate: Object,
            multiLineXArr: Array,
            colorList: Array,
            userInfo: Object,
            BarLineVal: Object,
            toDay: String,
            foodRecordRankLen: Number,
            parintList: Array,
            pageAdd: Number || String,
            isDefaultUnit: Number

        },
        components:{
            BarLine,
            EveryDaysLine
        },
        data(){
            return{
                sum: 0,
                lang: getItem('lang')
            }
        },
        watch:{
            parintList:{
                handler(){
                    this.getPageSum();
                },
                deep: true
            },
            pageAdd:{
                handler(){
                    this.getPageSum();
                },
                deep: true
            }
        },
        methods:{
            timeGet(start,end){
                return end ? time(new Date(start * 1000 ),this.lang == 'en' ? 'YYYY/MM/DD' : '年月日' ) + '—' + time(new Date(end * 1000 ),this.lang == 'en' ? 'MM/DD' : '月日') : time(new Date(start),'MM/DD');
            },
            getPageSum(){
                setTimeout(() =>{
                    let dom = document.getElementsByClassName('print-cont');
                    this.sum = dom.length;
                })
            }
        },
        mounted(){
            this.getPageSum();
        }
    }
</script>

<style lang="less" scoped>

    .picture{
        padding-top: 100px;
        border-color: #e5e5e5;
        .curve{
            h4{
                font-size: 29px;
                color: #000;
                letter-spacing: 1px;
            }
            .echarts{
                width: 100%;
                height: 450px;
                & /deep/ .charts{
                    width: 100%;
                    height: 100%;
                }
            }
            .color{
                margin-top: 50px;
                .list{
                    width: calc(~'100% / 8');
                    p{
                        color: #000;
                    }
                    hr{
                        width: 35px;
                        border-color: #000;
                        height: 4px;
                    }
                }
                &>.list:nth-of-type(1) hr{
                    background: #A4C400;
                }
                &>.list:nth-of-type(2) hr{
                    background: #6A00FF;
                }
                &>.list:nth-of-type(3) hr{
                    background: #FA6800;
                }
                &>.list:nth-of-type(4) hr{
                    background: #76608A;
                }
                &>.list:nth-of-type(5) hr{
                    background: #AA00FF;
                }
                &>.list:nth-of-type(6) hr{
                    background: #F0A30A;
                }
                &>.list:nth-of-type(7) hr{
                    background: #A0522D;
                }
                &>.list:nth-of-type(8) hr{
                    background: #008A00;
                }
                &>.list:nth-of-type(9) hr{
                    background: #F472D0;
                }
                &>.list:nth-of-type(10) hr{
                    background: #00ABA9;
                }
                &>.list:nth-of-type(11) hr{
                    background: #1BA1E2;
                }
                &>.list:nth-of-type(12) hr{
                    background: #A20025;
                }
                &>.list:nth-of-type(13) hr{
                    background: #6D8764;
                }
                &>.list:nth-of-type(14) hr{
                    background: #0050EF;
                }
                &>.list:nth-of-type(15) hr{
                    background: #17CCA6;
                }
                &>.list:nth-of-type(16) hr{
                    background: #D4FF00;
                }
                &>.list:nth-of-type(17) hr{
                    background: #5100C2;
                }
                &>.list:nth-of-type(18) hr{
                    background: #FE8026;
                }
                &>.list:nth-of-type(19) hr{
                    background: #C093EA;
                }
                &>.list:nth-of-type(20) hr{
                    background: #520976;
                }
                &>.list:nth-of-type(21) hr{
                    background: #BE820B;
                }
                &>.list:nth-of-type(22) hr{
                    background: #f37134;
                }
                &>.list:nth-of-type(23) hr{
                    background: #00F700;
                }
                &>.list:nth-of-type(24) hr{
                    background: #F949C7;
                }
                &>.list:nth-of-type(25) hr{
                    background: #02F2EE;
                }
                &>.list:nth-of-type(26) hr{
                    background: #00aaFF;
                }
                &>.list:nth-of-type(27) hr{
                    background: #F81F52;
                }
                &>.list:nth-of-type(28) hr{
                    background: #8FF16E;
                }
                &>.list:nth-of-type(29) hr{
                    background: #0239A7;
                }
                &>.list:nth-of-type(30) hr{
                    background: #00FEC7;
                }
                &>.list:nth-of-type(31) hr{
                    background: #A05017;
                }
            }
        }
        .atlas{
            margin-top: 140px;
            .tiptitle{
                h4{
                    font-size: 29px;
                    color: #000;
                    letter-spacing: 1px;
                }
                .colorBox{
                    max-width: 80%;
                    margin-left: auto;
                    margin-right: 40px;
                    div{
                        margin: 0px 10px;
                    }
                    hr{
                        width: 80%;
                        max-width: 80px;
                        height: 5px;
                    }
                    &>div:nth-of-type(1) hr{
                        background: #f7b500;
                    }
                    &>div:nth-of-type(2) hr{
                        background: #df3520;
                    }
                    &>div:nth-of-type(3) hr{
                        background: #c4c4c4;
                    }
                    &>div:nth-of-type(4) hr{
                        width: 62px;
                        border-bottom: 5px dashed #000;
                    }
                }
            }
            .echarts{
                margin-top: 22px;
                width: 100%;
                height: 520px;
            }
        }
    }

    .not-vip{
        width: 100%;
        height: 100%;
        padding: 0px;
        top: 0px;
        left: 0px;
        backdrop-filter: blur(10px);
        &>div{
            width: 100%;
            height: 100%;
            &>div{
                padding: 20px 40px;
                background: linear-gradient(181deg,#e8ce8b 0%, #ecd088 100%, #d1b059 100%, #ad8a30 100%);
                border-radius: 50px;
                font-size: 28px;
                color: #321F1F;
            }
        }
    }

    

</style>